import { type ReactNode, useCallback } from "react";
import type { AppState } from "@auth0/auth0-react";
import { Auth0 } from "@reifyhealth/picasso-auth0";
import { isDevEnabled } from "@lib/environment";
import { Auth0ConfigTypes } from "@types";
import config from "@config";
import { ENVIRONMENTS, GRAPHQL_URL } from "@app/constants";
import { QueryClient } from "react-query";
import { useNavigate } from "@app/hooks";

type Prop = {
  children: ReactNode;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  },
});

const Auth0ProviderWithHistory = ({ children }: Prop) => {
  const launchDarklyClientID =
    import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_ID || "60b7e8a172e6f20e2a678991";

  const { domain, clientID, scope, audience, issuer }: Auth0ConfigTypes =
    config.auth0ServiceConfig();

  const navigate = useNavigate();

  const onRedirectCallback = useCallback(
    (appState?: AppState | undefined) => {
      navigate(appState?.returnTo || "/");
    },
    [navigate]
  );

  return (
    <Auth0
      api={GRAPHQL_URL}
      personaId={undefined}
      domain={domain}
      issuer={issuer}
      queryClient={queryClient}
      clientId={clientID}
      authorizationParams={{
        redirect_uri: isDevEnabled()
          ? window.location.origin
          : `${window.location.origin}/finance`,
        audience,
        scope,
      }}
      onRedirectCallback={onRedirectCallback}
      launchDarkly={{
        clientSideID: launchDarklyClientID,
        overridesEnabled: !ENVIRONMENTS.isProduction,
      }}
    >
      {children}
    </Auth0>
  );
};

export { Auth0ProviderWithHistory };
