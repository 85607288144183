import {
  useGetPartnerDropdownItemsQuery,
  useGetPaymentDetailsQuery,
  useUpdatePaymentMutation,
  useUserSiteTrialsQuery,
} from "@app/service/generated";
import {
  Col,
  DatePicker,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
  Typography,
  message,
} from "@reifyhealth/picasso-pkg";
import { useParams } from "react-router-dom";
import { EditIcon } from "../common/icons/EditIcon";
import { useState } from "react";
import { useNavigate } from "@app/hooks";
import moment from "moment";
import { money, Money, round, toGraphQLInput } from "@lib/currency";
import { localDateToUTCDateString, utcDateStringToLocalDate } from "@lib/date";
import sortBy from "lodash/sortBy";
import { PaymentAttachmentType } from "@app/hooks/usePayments";
import MoneyInput from "@components/inputs/MoneyInput";

interface EditPaymentFormProps {
  paymentId: string;
  form: FormInstance<{
    siteTrialId: string | null;
    partnerId: string | null;
    amount: Money | null;
    date: string;
    memo: string | null;
    reference: string | null;
  }>;
  setAmountFn: React.Dispatch<React.SetStateAction<Money | null>>;
  selectedPaymentAllocations: any[];
  setPaymentAllocationEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  setDisableRecordPayment: React.Dispatch<React.SetStateAction<boolean>>;
  selectedAttachments: { id: string; contentType: string; filename: string }[];
  setSelectedAttachments: React.Dispatch<
    React.SetStateAction<
      {
        contentType: string;
        filename: string;
        id: string;
        type: PaymentAttachmentType;
      }[]
    >
  >;
}

export const EditPaymentForm = ({
  paymentId,
  form,
  setAmountFn,
  selectedPaymentAllocations,
  setPaymentAllocationEnabled,
  setDisableRecordPayment,
  selectedAttachments,
  setSelectedAttachments,
}: EditPaymentFormProps) => {
  const navigate = useNavigate();
  const [editing, setEditing] = useState(false);
  const [text, setText] = useState("Include Memo");

  const params = useParams();

  const { mutate: updatePayment } = useUpdatePaymentMutation({
    onMutate: () => {
      setDisableRecordPayment(true);
    },
    onError: (error, variables, _context) => {
      console.error(error, variables);
      message.error("Failed to update payment");
      setDisableRecordPayment(false);
    },
    onSuccess() {
      setDisableRecordPayment(false);
      message.success("Payment updated!");
      navigate(`/finance/sites/${params.siteId}/payments`);
    },
  });

  const { data: userSiteTrialsWithBudget, isFetched: userSiteTrialsFetched } =
    useUserSiteTrialsQuery(
      {
        filter: { siteId: params.siteId!, hasBudget: true },
      },
      {
        queryKey: "userSiteTrialWithBudgetsOnly",
      }
    );

  const { data: partnerList, isFetched: partnerListFetched } =
    useGetPartnerDropdownItemsQuery({
      siteId: params.siteId!,
    });

  const {
    isSuccess: paymentDetailsFetchedSuccess, // used for disable
  } = useGetPaymentDetailsQuery(
    { paymentId },
    {
      enabled: userSiteTrialsFetched && partnerListFetched,
      onSuccess(data) {
        const {
          memo,
          amount,
          attachments,
          date,
          partnerId,
          siteTrialId,
          reference,
        } = data.Financials2__paymentDetails.payment;

        if (memo) {
          setText(memo);
        }

        if (amount) {
          setAmountFn(money(amount));
        }

        if (attachments) {
          setSelectedAttachments(
            attachments.map((attachment) => ({
              ...attachment,
              ...{ type: PaymentAttachmentType.EXISTING },
            })) as {
              contentType: string;
              filename: string;
              id: string;
              type: PaymentAttachmentType;
            }[]
          );
        }

        form.setFieldsValue({
          amount: money(amount),
          memo,
          date: moment(utcDateStringToLocalDate(date)) as any,
          reference,
          siteTrialId,
          partnerId,
        });

        setPaymentAllocationEnabled(true);
      },
    }
  );

  const handleEditClick = () => {
    setText(text || "");
    setEditing(true);
  };

  const siteTrialOptions = sortBy(
    userSiteTrialsWithBudget?.Financials2__userSiteTrials.edges.map((edge) => ({
      value: edge.node.id,
      label: edge.node.name,
    })),
    "label"
  );

  const partnerOptions = sortBy(
    partnerList?.Financials2__partnerList.partners.map((partner) => ({
      value: partner.id,
      label: partner.name,
    })),
    "label"
  );

  return (
    <Form
      layout="vertical"
      form={form}
      initialValues={{
        partnerId: null,
        memo: null,
        reference: null,
      }}
      onValuesChange={(changedValues) => {
        // Currently no way to "watch" values change via antd.
        if (changedValues["amount"]) {
          setAmountFn(changedValues["amount"]);
        }
      }}
      onFinish={(values) => {
        const amount = toGraphQLInput(round(values.amount!));
        const date = localDateToUTCDateString(values.date);
        const siteTrialId = values.siteTrialId || "";

        updatePayment({
          input: {
            ...values,
            siteTrialId,
            allocations: selectedPaymentAllocations,
            memo: text,
            attachments: selectedAttachments.map((attachment) => ({
              id: attachment.id,
              contentType: attachment.contentType,
              filename: attachment.filename,
            })),
            date,
            paymentId: params.paymentId!,
            amount,
          },
        });
      }}
    >
      <Row gutter={16}>
        <Col flex={1}>
          <Form.Item
            label="Trial:"
            name="siteTrialId"
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
          >
            <Select
              data-testid="edit-payment-trial-select"
              disabled
              style={{ width: "100%" }}
              placeholder="Choose a Trial"
              options={siteTrialOptions}
            />
          </Form.Item>
        </Col>
        <Col flex={1}>
          <Form.Item name="partnerId" label="Partner:">
            <Select
              data-testid="edit-payment-partner-select"
              disabled={!paymentDetailsFetchedSuccess || !partnerListFetched}
              style={{ width: "100%" }}
              placeholder="Choose a Partner"
              options={partnerOptions}
              key={"value"}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="amount"
            label="Amount:"
            rules={[
              {
                required: true,
                message: "Amount is required to allocate items",
              },
            ]}
          >
            <MoneyInput
              data-testid="edit-payment-amount-input"
              disabled={!paymentDetailsFetchedSuccess}
              addonBefore="$" // TODO: localization
              size="large"
              onChange={(e) =>
                e.target.value
                  ? setAmountFn(money(e.target.value))
                  : setAmountFn(null)
              }
            />
          </Form.Item>
        </Col>
        <Col flex={1}>
          <Form.Item
            name="date"
            label="Date:"
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
          >
            <DatePicker
              data-testid="edit-payment-date-picker"
              disabled={!paymentDetailsFetchedSuccess}
              style={{ width: "100%" }}
              onChange={(date) =>
                form.setFields([{ value: date, name: "date" }])
              }
            />
          </Form.Item>
        </Col>
        <Col flex={1}>
          <Form.Item name="reference" label="Reference #:">
            <Input
              data-testid="edit-payment-reference-input"
              disabled={!paymentDetailsFetchedSuccess}
              placeholder="Optional"
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="memo" className="memo-field">
            <Typography.Text
              disabled={!paymentDetailsFetchedSuccess}
              onClick={handleEditClick}
              editable={{
                tooltip: false,
                icon: <EditIcon />,
                onCancel() {
                  setText(text);
                  setEditing(false);
                },
                onEnd() {
                  setEditing(false);
                },
                editing,
                onChange: (value) => {
                  setText(value as any);
                  form.setFields([{ value: text, name: "memo" }]);
                },
              }}
            >
              <span data-testid="edit-payment-memo-text">{text}</span>
            </Typography.Text>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
