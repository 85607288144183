import React from "react";
import {
  icons,
  Skeleton,
  Typography,
  Button,
  Space,
  Dropdown,
  Menu,
  Modal,
  Tooltip,
} from "@reifyhealth/picasso-pkg";
import { NavigateFunction, useParams } from "react-router-dom";
import { PageLayout } from "@components/PageLayout";
import { BudgetOverviewDetailsHeader } from "@components/headers";
import { BudgetActiveSwitch } from "@app/components/common/BudgetActiveSwitch";
import { styled } from "@linaria/react";
import {
  useDeleteBudgetVersionMutation,
  useGetBudgetSiteTrial3Query,
} from "@service/generated";
import { BudgetVersionLineItemsTable } from "@app/components/tables/BudgetVersionLineItemsTable";
import { useBudgetVersionOverview, useNavigate } from "@app/hooks";
import Loading from "@components/Loading";

const { EllipsisOutlined, CloseCircleFilled } = icons;

const { confirm } = Modal;

const showDeleteModal = (
  budgetId: string,
  deleteBudgetVersion: Function,
  siteId: string,
  navigate: NavigateFunction,
  budgetConfigVersionId: string
) => {
  confirm({
    icon: <CloseCircleFilled style={{ color: "red" }} />,
    title: "Delete Budget Version",
    content: (
      <section style={{ display: "flex" }}>
        This will remove all budget line items and related information. This
        cannot be undone.
      </section>
    ),
    okText: "Delete Budget Version",
    okType: "danger",
    okButtonProps: {
      color: "red",
      disabled: false,
    },
    cancelText: "Cancel",
    onOk() {
      deleteBudgetVersion(
        { input: { budgetId, budgetConfigVersionId } },
        {
          onSuccess: (result: any) => {
            if (result.deleted.success) {
              navigate(`/finance/sites/${siteId}/budgets`);
            }
          },
        }
      );
    },
    onCancel() {},
  });
};

const BudgetDetailHeaderComponent = styled.div`
  margin-bottom: 24px;

  .caption {
    display: flex;
    justify-content: space-between;
    color: var(--application-secondary-text);
  }

  .trial-name {
    display: flex;
    align-items: center;
    gap: var(--space-5);
    font-size: var(--font-size-h-4);

    .budget-status-tag {
      border-radius: 100px;

      &.active {
        background: var(--green-3);
        border-color: var(--green-6);
        color: var(--green-10);
      }

      &.inactive {
        background: transparent;
        border-color: transparent;
        color: var(--application-secondary-text);
      }
    }
  }
`;

interface BudgetVersionOverviewHeaderProps {
  budgetConfiguration?: BudgetConfig;
  budgetOverviewDetails?: OverviewBudget;
  isLoading: boolean;
  invalidateMutation: {
    siteTrialId: string;
    budgetId: string;
    budgetConfigVersionId: string;
  };
  siteTrialId: string;
  budgetName: string;
}

const BudgetVersionOverviewHeader = ({
  budgetOverviewDetails,
  budgetConfiguration,
  isLoading,
  invalidateMutation,
  siteTrialId,
  budgetName,
}: BudgetVersionOverviewHeaderProps) => {
  const params = useParams();
  const navigate = useNavigate();

  const { mutate: deleteBudgetVersion } = useDeleteBudgetVersionMutation({
    onSuccess: () => navigate(`/finance/sites/${params.siteId}/budgets`),
  });

  const menu = (
    <Menu>
      <Menu.Item key="deleteBudgetVersion">
        {budgetConfiguration?.status === "INACTIVE" ? (
          <Typography.Link
            type="danger"
            data-testid="delete-budget-version-link"
            onClick={() =>
              showDeleteModal(
                params.budgetId!,
                deleteBudgetVersion,
                params.siteId!,
                navigate,
                params.budgetConfigVersionId!
              )
            }
          >
            Delete
          </Typography.Link>
        ) : (
          <Tooltip
            trigger={"hover"}
            placement="left"
            title="Active versions cannot be deleted!"
          >
            <Typography.Text data-testid="delete-budget-version-link" disabled>
              Delete
            </Typography.Text>
          </Tooltip>
        )}
      </Menu.Item>
    </Menu>
  );

  return (
    <BudgetDetailHeaderComponent>
      <article>
        <div className="caption" data-testid="budget-version">
          <span>
            {isLoading ? (
              <Skeleton.Input />
            ) : (
              <>Version {budgetConfiguration?.number}</>
            )}
          </span>
          <section className="budget-version-actions">
            <Space>
              <BudgetActiveSwitch
                invalidateMutation={invalidateMutation}
                status={budgetConfiguration?.status}
              />
              <Button
                data-testid="create-new-version-btn"
                onClick={() => {
                  navigate(
                    `/finance/sites/${params.siteId}/budgets/${params.budgetId}/versions/new?budget-name=${budgetName}&trial-id=${siteTrialId}`
                  );
                }}
              >
                Create New Version
              </Button>
              <Button
                data-testid="edit-version-btn"
                onClick={() =>
                  navigate(
                    `/finance/sites/${params.siteId}/budgets/${params.budgetId}/versions/${params.budgetConfigVersionId}/visit-charges`
                  )
                }
              >
                Edit
              </Button>

              <Dropdown overlay={menu} trigger={["click"]}>
                <Button
                  data-testid="ellipsis-btn"
                  icon={<EllipsisOutlined />}
                />
              </Dropdown>
            </Space>
          </section>
        </div>
        {!isLoading ? (
          <div
            className="trial-name"
            data-testid="budget-version-overview-header-trial-name"
          >
            <Typography.Text>{budgetOverviewDetails?.name}</Typography.Text>
          </div>
        ) : (
          <div>
            <Skeleton.Button
              active={true}
              size={"small"}
              style={{
                width: 200,
                height: 18,
                marginTop: 8,
              }}
            />
          </div>
        )}
      </article>
    </BudgetDetailHeaderComponent>
  );
};

const BudgetVersionOverviewContent = ({
  siteTrialId,
  budgetName,
}: {
  siteTrialId: string;
  budgetName: string;
}) => {
  const {
    isLoading,
    isFetching,
    budgetConfiguration,
    budgetOverviewDetails,
    budgetVersionOverviewKey,
    variables,
    lineItems,
  } = useBudgetVersionOverview(siteTrialId);

  const protocolVersionId = budgetConfiguration?.selectedProtocolVersionId;

  if (!budgetOverviewDetails) return <Loading />;

  return (
    <div>
      <BudgetVersionOverviewHeader
        siteTrialId={siteTrialId}
        budgetName={budgetName}
        isLoading={isLoading}
        budgetConfiguration={budgetConfiguration}
        budgetOverviewDetails={budgetOverviewDetails!}
        invalidateMutation={variables}
      />
      <BudgetVersionLineItemsTable
        protocolVersionId={protocolVersionId!}
        siteTrialId={siteTrialId}
        lineItems={lineItems}
        budget={budgetOverviewDetails}
        budgetConfiguration={budgetConfiguration}
        budgetVersionOverviewKey={budgetVersionOverviewKey}
        loading={isLoading || isFetching || !protocolVersionId}
        disableSendToReceivables={isLoading || isFetching}
      />
    </div>
  );
};

export const BudgetVersionOverviewPage = () => {
  const params = useParams();
  const budgetId = params.budgetId!;
  const { data: budgetSiteTrial, isLoading: budgetSiteTrialIsLoading } =
    useGetBudgetSiteTrial3Query({ budgetId });

  const siteTrialId = budgetSiteTrial?.budget.trial.stId;
  const budgetName = budgetSiteTrial?.budget.name;

  if (budgetSiteTrialIsLoading || !siteTrialId || !budgetName) {
    return <Loading />;
  }

  const Header = () => (
    <BudgetOverviewDetailsHeader siteTrialId={siteTrialId} />
  );

  return (
    <PageLayout Header={Header}>
      <BudgetVersionOverviewContent
        siteTrialId={siteTrialId}
        budgetName={budgetName}
      />
    </PageLayout>
  );
};
