import { request } from "graphql-request";
import { notification } from "@reifyhealth/picasso-pkg";
import { useAuth } from "@hooks/auth";
import { GRAPHQL_URL } from "@constants";
import { useIdleTimerContext } from "react-idle-timer";
import { OperationDefinitionNode } from "graphql";
import { parse } from "graphql/language/parser";

const errorCodeMessageLookup = {
  END_DATE_BEFORE_START_DATE: "Effective date end must be after date start.",
  INVOICE_UPDATE_INVOICE_RECIPIENT_FAILURE:
    "A primary contact was not found for this recipient. Please add one and try again!",
  BUDGET_VERSION_DATE_CONFLICT:
    "The date range you have selected conflicts with another active budget version. Please update the Effective Dates for this budget.",
  START_DATE_EQUAL_TO_PRIOR_START_DATE:
    "The start date you have selected is the same as to the start date of an active version. Please update the Effective Dates for this budget.",
  START_DATE_BEFORE_PRIOR_START_DATE:
    "The start date you have selected is prior to the start date of an active version. Please update the Effective Dates for this budget.",
  ACCOUNT_RECEIVABLE_QUANTITY_EXCEEDED_FOR_ADDITIONAL_CHARGE_QUANTITY:
    "The additional charge quantity exceeds the amount billable.",
} as const;

const showError = (content: string, key: string) => {
  if (!content) {
    content = '"Unknown Error"';
  }

  if (!key) {
    key = content;
  }

  console.error({ content, key });
  notification.error({
    message: "Error",
    description: "Something went wrong. Please try again.",
    duration: 5,
  });
};

function humanMessage(errors: (keyof typeof errorCodeMessageLookup)[]) {
  if (errors.includes("START_DATE_EQUAL_TO_PRIOR_START_DATE")) {
    return errorCodeMessageLookup["START_DATE_EQUAL_TO_PRIOR_START_DATE"];
  } else {
    return errorCodeMessageLookup[errors[0]];
  }
}

const showDetailedError = (error: any) => {
  console.error({ error });

  if (Array.isArray(error.extensions?.response?.body?.errors)) {
    notification.error({
      message: "Error",
      description: humanMessage(error.extensions.response.body.errors),
      duration: 12,
    });
  } else if (error.message) {
    const errorMessage = error.message?.toString();
    notification.error({
      message: "Error",
      description: errorMessage,
      duration: 5,
    });
  }
};

async function timeout(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function useCodegenFetcher<TData, TVariables>(query: string) {
  const { getAccessTokenSilently, isAuthenticated } = useAuth();
  const { activate } = useIdleTimerContext();

  return async (variables?: TVariables): Promise<TData> => {
    if (isAuthenticated) {
      try {
        var accessToken = await getAccessTokenSilently();
        activate();
      } catch (e) {
        showError("Unable to get access token.", query);
      }
    }

    const headers: HeadersInit = {};

    if (accessToken!) {
      headers.authorization = `Bearer ${accessToken}`;
    }

    const parsedOperation = parse(query);
    const operationNode = parsedOperation.definitions.find(
      (f) => f.kind === "OperationDefinition"
    )! as OperationDefinitionNode;
    const operationName = operationNode.name!.value;

    async function sendRequest(retry: Boolean): Promise<any> {
      return request<TData, TVariables>(
        `${GRAPHQL_URL}?operationName=${operationName}&service=financials`,
        query,
        variables,
        headers
      ).catch(async (reason) => {
        console.error(reason);
        if (reason.message === "Network request failed" && retry) {
          await timeout(5000);
          return await sendRequest(false);
        } else {
          if (reason?.response?.error) {
            showError(reason?.response?.error, query);
          } else {
            reason?.response?.errors.map((obj: any) => {
              return showDetailedError(obj);
            }) || showError(reason.toString(), query);
          }

          return Promise.reject(reason);
        }
      });
    }

    return await sendRequest(true);
  };
}
