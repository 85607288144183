import { useAuth0 } from "@reifyhealth/picasso-auth0";

interface FinancialsUser {
  id: string;
  email: string;
}

type FinancialsAuthResult = Pick<
  ReturnType<typeof useAuth0>,
  | "logout"
  | "getAccessTokenSilently"
  | "isAuthenticated"
  | "isLoading"
  | "error"
  | "loginWithRedirect"
> & {
  user: FinancialsUser;
};

/**
 * Return a FinancialsUser derived from the Auth0 hook user data.
 *
 * The sub is the Auth0 ID (the idp_id).
 * The https://studyteamapp.com/user-id claim is the PK user/id
 * from the User table. For some legacy people these are the same.
 * For others the User/id looks like a sub because we just kept
 * those around (it used to be that User/id and idp_id were one and the same).
 * When we did SSO we separated them out and just kept existing ID's the same
 * for consistency and treated them as opaque string identifiers.
 * For any new users we now use UUIDs for the User/id and the idp_id is the
 * semantically meaningful one to link to the Auth0 user.
 *
 * Matt Anderson (1/6/2022)
 */
const financialsUser: (
  auth0User: ReturnType<typeof useAuth0>["user"]
) => FinancialsUser = (auth0User) => {
  if (!auth0User) {
    return {
      id: "unknown",
      email: "unknown",
    };
  }

  return {
    id:
      auth0User["https://studyteamapp.com/user-id"] ||
      auth0User.sub ||
      "unknown",
    email: auth0User.email || "unknown",
  };
};

/**
 * Application-level wrapper for Auth0 integration.
 *
 * This is intended to hide details of how Auth0 data maps to our own concept of
 * a user. For example, there are multiple attributes on an Auth0 user that we
 * need to consider for user IDs, but we only care about the end result from
 * the component's perspective.
 *
 * The component-level API is defined in FinancialsAuthResult.
 */
export const useAuth = (): FinancialsAuthResult => {
  const auth0Result = useAuth0();
  return { ...auth0Result, user: financialsUser(auth0Result.user) };
};
