import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { Store } from "./types";
import { createBudgetAggregateSlice } from "@app/store/slices/budgetAggregateSlice";
import { createMatrixSlice } from "@app/store/slices/matrixSlice";
import { createBudgetFlowSlice } from "@app/store/slices/budgetFlowSlice";
import { createBudgetAdditionalChargesSlice } from "@app/store/slices/budgetAdditionalChargesSlice";

export const useStore = create<Store>()(
  devtools((...set) => ({
    ...createBudgetAggregateSlice(...set),

    // deprecated
    ...createMatrixSlice(...set),
    ...createBudgetFlowSlice(...set),
    ...createBudgetAdditionalChargesSlice(...set),
  }))
);
