import { ProtocolVersionEffectiveDates } from "./ProtocolVersionEffectiveDates";
import { ProtocolVersionAlertDismissBanner } from "./ProtocolVersionAlertDismissBanner";
import {
  useBudgetVersionListingItems3Query,
  useToggleBudgetVersionNoticeMutation,
} from "@app/service/generated";
import { useQueryClient } from "react-query";
import sortBy from "lodash/sortBy";
import { css } from "@linaria/core";

const ListStyle = css`
  .protocol-versions li.version-list-item {
    background: var(--component-background-subtle);
    transition: var(--duration-2);

    &:hover {
      background: var(--component-background);
    }

    &.version-list-item-active {
      background: var(--component-background);
      padding: var(--size-4) var(--size-6);
      gap: var(--size-4);
      display: flex;
      flex-direction: column;
    }

    .clickable-list {
      flex-grow: 1;
      cursor: pointer;
    }
  }
`;

interface ProtocolVersionListProps {
  budgetId: string;
  siteId: string;
  budgetName: string;
  siteTrialId: string;
  budgetConfigVersionId: string;
  protocolVersions:
    | {
        id: string;
        name: string;
      }[]
    | undefined;
  budgetConfigurationProtocolVersions: {
    protocolVersionId: string;
    isCreateBudgetVersionNoticeEnabled: boolean;
    effectiveDateStart: any;
    effectiveDateEnd: any;
  }[];
}

const findProtocol = (
  protocol: {
    protocolVersionId: string;
    isCreateBudgetVersionNoticeEnabled: boolean;
    effectiveDateStart: any;
    effectiveDateEnd: any;
  },
  versions:
    | {
        id: string;
        name: string;
      }[]
    | undefined
) =>
  versions &&
  versions.find((version) => version.id === protocol.protocolVersionId);

export const ProtocolVersionList = ({
  siteId,
  budgetId,
  budgetConfigVersionId,
  protocolVersions,
  budgetConfigurationProtocolVersions,
  budgetName,
  siteTrialId,
}: ProtocolVersionListProps) => {
  const queryClient = useQueryClient();

  const { mutate } = useToggleBudgetVersionNoticeMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(
        useBudgetVersionListingItems3Query.getKey({ siteId })
      );
    },
  });

  const protocolVersionById = Object.fromEntries(
    (protocolVersions ?? []).map((pv, index) => {
      return [pv.id, { ...pv, number: index + 1 }];
    })
  );
  const numberedProtocolVersions = budgetConfigurationProtocolVersions.map(
    (bcpv) => {
      const pv = protocolVersionById[bcpv.protocolVersionId];
      return { ...bcpv, number: pv.number };
    }
  );
  const sortedProtocolVersions = sortBy(numberedProtocolVersions, "number");

  return (
    <div className={ListStyle}>
      <ul className="protocol-versions">
        {sortedProtocolVersions.map((protocolVersion) => {
          const foundProtocol = findProtocol(protocolVersion, protocolVersions);

          if (protocolVersion.isCreateBudgetVersionNoticeEnabled) {
            const {
              protocolVersionId,
              isCreateBudgetVersionNoticeEnabled,
              effectiveDateEnd,
              effectiveDateStart,
            } = protocolVersion;

            return (
              <li
                className="version-list-item version-list-item-active"
                key={`${protocolVersion.protocolVersionId}-${protocolVersion.number}`}
              >
                <section
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {foundProtocol?.name}
                  <ProtocolVersionEffectiveDates
                    budgetId={budgetId}
                    siteId={siteId}
                    protocolVersionId={protocolVersionId}
                    budgetConfigVersionId={budgetConfigVersionId}
                    startDate={effectiveDateStart}
                    endDate={effectiveDateEnd}
                  />
                </section>
                <section>
                  <ProtocolVersionAlertDismissBanner
                    budgetId={budgetId}
                    isCreateBudgetVersionNoticeEnabled={
                      isCreateBudgetVersionNoticeEnabled
                    }
                    siteId={siteId}
                    protocolVersionId={protocolVersionId}
                    budgetConfigVersionId={budgetConfigVersionId}
                    budgetName={budgetName}
                    siteTrialId={siteTrialId}
                  />
                </section>
              </li>
            );
          }

          return (
            <li
              key={protocolVersion.protocolVersionId}
              className="version-list-item"
            >
              <section
                data-testid="protocol-name"
                className="clickable-list"
                onClick={() =>
                  mutate({
                    input: {
                      budgetId,
                      budgetConfigVersionId,
                      protocolVersionId: protocolVersion.protocolVersionId,
                      enabled:
                        !protocolVersion.isCreateBudgetVersionNoticeEnabled,
                    },
                  })
                }
              >
                {foundProtocol?.name}
              </section>
              <ProtocolVersionEffectiveDates
                siteId={siteId}
                budgetId={budgetId}
                protocolVersionId={protocolVersion.protocolVersionId}
                budgetConfigVersionId={budgetConfigVersionId}
                startDate={protocolVersion.effectiveDateStart}
                endDate={protocolVersion.effectiveDateEnd}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};
