import { Tooltip, TooltipProps } from "@reifyhealth/picasso-pkg";

interface TooltipButtonProps {
  children: React.ReactChild;
  showTooltip: boolean;
  tooltip: TooltipProps;
}

export const WithTooltip = ({
  showTooltip,
  children,
  tooltip,
}: TooltipButtonProps) => {
  if (showTooltip) {
    return <Tooltip {...tooltip}>{children}</Tooltip>;
  }

  return <>{children}</>;
};
