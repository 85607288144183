export const InvoicedSeparatelyIcon = () => {
  return (
    <svg
      data-testid="invoiced-separately-icon"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 0.125C3.65117 0.125 0.125 3.65117 0.125 8C0.125 12.3488 3.65117 15.875 8 15.875C12.3488 15.875 15.875 12.3488 15.875 8C15.875 3.65117 12.3488 0.125 8 0.125ZM8.39199 11.818L8.39551 12.3752C8.39551 12.4525 8.33223 12.5176 8.25488 12.5176H7.75566C7.67832 12.5176 7.61504 12.4543 7.61504 12.377V11.825C6.0541 11.709 5.31934 10.8195 5.24023 9.85273C5.2332 9.77012 5.29824 9.69981 5.38086 9.69981H6.19297C6.26152 9.69981 6.32129 9.74902 6.33184 9.81582C6.42148 10.373 6.85566 10.7896 7.63438 10.8934V8.38496L7.2002 8.27422C6.28086 8.05449 5.40547 7.48145 5.40547 6.29316C5.40547 5.01172 6.3793 4.32266 7.62383 4.20137V3.62129C7.62383 3.54395 7.68711 3.48066 7.76445 3.48066H8.2584C8.33574 3.48066 8.39902 3.54395 8.39902 3.62129V4.19609C9.60313 4.31738 10.5066 5.02051 10.6121 6.11562C10.6209 6.19824 10.5559 6.27031 10.4715 6.27031H9.68223C9.61191 6.27031 9.55215 6.21758 9.54336 6.14902C9.47305 5.63574 9.06172 5.21738 8.39199 5.12598V7.48672L8.83848 7.59043C9.97754 7.87168 10.7527 8.4166 10.7527 9.63652C10.7527 10.9602 9.76836 11.6984 8.39199 11.818ZM6.49883 6.2123C6.49883 6.65879 6.7748 7.00508 7.36895 7.21953C7.45156 7.25293 7.53418 7.2793 7.63262 7.30742V5.12773C6.98398 5.21035 6.49883 5.57422 6.49883 6.2123ZM8.54668 8.58887C8.49746 8.57832 8.44824 8.56602 8.39199 8.55019V10.9004C9.14082 10.8336 9.65762 10.4223 9.65762 9.7332C9.65762 9.19355 9.37812 8.84199 8.54668 8.58887Z"
        fill="#30A46C"
      />
    </svg>
  );
};
