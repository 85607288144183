import { StepSummaryBlock } from "@app/components/common/SummaryBlock";
import {
  useAdjustProtocolVersionEffectiveDatesMutation,
  useGetBudgetVersionSummary3Query,
  useUpdateBudgetMetadataMutation,
} from "@app/service/generated";
import { Select, DatePicker, Input, Skeleton } from "@reifyhealth/picasso-pkg";
import { useQueryClient } from "react-query";
import moment from "moment";
import {
  DEFAULT_DATEPICKER_FORMAT,
  localDateToUTCDateString,
  utcDateStringToLocalDate,
} from "@lib/date";
import useDebounce from "@app/hooks/useDebounce";
import { DEFAULT_DEBOUNCE_TIME } from "@app/constants";
import { useStore } from "@app/store";
import PercentageInput from "@components/inputs/PercentageInput";
import { useBudgetMatrix } from "@model/budgets/matrix/hooks";

const { RangePicker } = DatePicker;

interface IBudgetVersionSummaryHeaderProps {
  holdback?: { enabled?: boolean | null; percentage?: string | null } | null;
  overhead?: { enabled?: boolean | null; percentage?: string | null } | null;
  budgetName: string | undefined;
  budgetTrialName: string | undefined;
  budgetProtocolVersionName: string | undefined;
  budgetEffectiveStartDate: any;
  budgetEffectiveEndDate: any;
  budgetId: string | undefined;
  budgetConfigVersionId: string | undefined;
  paymentTermDays: number | null | undefined;
  protocolVersionId: string | null | undefined;
  budgetSiteTrialId: string | null | undefined;
}

export const BudgetVersionSummaryHeader = ({
  budgetName,
  budgetTrialName,
  budgetProtocolVersionName,
  budgetEffectiveStartDate,
  budgetEffectiveEndDate,
  budgetId,
  budgetConfigVersionId,
  holdback,
  overhead,
  paymentTermDays,
  protocolVersionId,
  budgetSiteTrialId,
}: IBudgetVersionSummaryHeaderProps) => {
  const setAutosaving = useStore((store) => store.setAutosaving);
  const queryClient = useQueryClient();
  const [, updateMatrix] = useBudgetMatrix({
    budgetId,
    siteTrialId: budgetSiteTrialId,
    budgetConfigVersionId,
    track: "all",
  });

  const { mutateAsync: updateBudgetMetadata } = useUpdateBudgetMetadataMutation(
    {
      onMutate: (variables) => {
        setAutosaving(true);
        if (budgetConfigVersionId && budgetId && budgetSiteTrialId) {
          const queryKey = useGetBudgetVersionSummary3Query.getKey({
            siteTrialId: budgetSiteTrialId,
            budgetId,
            budgetConfigVersionId,
          });

          const previousData: BudgetVersionSummaryQuery | undefined =
            queryClient.getQueryData(queryKey);

          queryClient.setQueryData(
            queryKey,
            (oldData: BudgetVersionSummaryQuery | void) => {
              if (oldData) {
                const { name, paymentTermDays } = variables.input;

                return {
                  budget: {
                    ...oldData.budget,
                    ...(name ? { name } : {}),
                    ...(paymentTermDays ? { paymentTermDays } : {}),
                  },
                };
              }

              return oldData;
            }
          );

          if (previousData) {
            return { previousData };
          }
        }
      },
      onError: (error, variables, context) => {
        setAutosaving(false);
        console.error({ error, variables });

        if (budgetConfigVersionId && budgetId && budgetSiteTrialId) {
          const queryKey = useGetBudgetVersionSummary3Query.getKey({
            siteTrialId: budgetSiteTrialId,
            budgetId,
            budgetConfigVersionId,
          });

          queryClient.setQueryData(queryKey, context?.previousData);
        }
      },
      onSuccess: () => {
        setAutosaving(false);
        if (budgetConfigVersionId && budgetId && budgetSiteTrialId) {
          queryClient.invalidateQueries(
            useGetBudgetVersionSummary3Query.getKey({
              siteTrialId: budgetSiteTrialId,
              budgetId,
              budgetConfigVersionId,
            })
          );
        }
      },
    }
  );

  const { mutate: adjustProtocolVersionEffectiveDates } =
    useAdjustProtocolVersionEffectiveDatesMutation({
      onMutate: (variables) => {
        setAutosaving(true);
        if (budgetConfigVersionId && budgetId && budgetSiteTrialId) {
          const queryKey = useGetBudgetVersionSummary3Query.getKey({
            siteTrialId: budgetSiteTrialId,
            budgetId,
            budgetConfigVersionId,
          });

          const previousData: BudgetVersionSummaryQuery | undefined =
            queryClient.getQueryData(queryKey);

          queryClient.setQueryData(
            queryKey,
            (oldData: BudgetVersionSummaryQuery | void) => {
              if (oldData) {
                const {
                  protocolVersionId,
                  effectiveDateEnd,
                  effectiveDateStart,
                } = variables.input;

                const newConfigVersions = oldData.budget.configVersions.map(
                  (configVersion: any) => {
                    return {
                      ...configVersion,
                      ...{
                        protocolVersions: configVersion.protocolVersions.map(
                          (version: any) => {
                            if (
                              version.protocolVersionId === protocolVersionId
                            ) {
                              return {
                                ...version,
                                ...{ effectiveDateStart, effectiveDateEnd },
                              };
                            }

                            return version;
                          }
                        ),
                      },
                    };
                  }
                );

                return {
                  budget: {
                    ...oldData.budget,
                    configVersions: newConfigVersions,
                  },
                };
              }

              return oldData;
            }
          );

          if (previousData) {
            return { previousData };
          }
        }
      },
      onError: (error, variables, context) => {
        setAutosaving(false);
        console.error({ error, variables });

        if (budgetConfigVersionId && budgetId && budgetSiteTrialId) {
          const queryKey = useGetBudgetVersionSummary3Query.getKey({
            siteTrialId: budgetSiteTrialId,
            budgetId,
            budgetConfigVersionId,
          });

          queryClient.setQueryData(queryKey, context?.previousData);
        }
      },
      onSuccess: () => {
        setAutosaving(false);
        if (budgetConfigVersionId && budgetId && budgetSiteTrialId) {
          queryClient.invalidateQueries(
            useGetBudgetVersionSummary3Query.getKey({
              siteTrialId: budgetSiteTrialId,
              budgetId,
              budgetConfigVersionId,
            })
          );
        }
      },
    });

  const handleDateRangeChange = (value: any) => {
    if (budgetId && value[0] && budgetConfigVersionId && protocolVersionId) {
      adjustProtocolVersionEffectiveDates({
        input: {
          budgetId,
          budgetConfigVersionId,
          protocolVersionId,
          effectiveDateEnd: value[1]
            ? localDateToUTCDateString(value[1])
            : null,
          effectiveDateStart: localDateToUTCDateString(value[0]),
        },
      });
    }
  };

  const handleChange = (value: number) => {
    if (budgetId && budgetName) {
      updateBudgetMetadata({
        input: { budgetId, name: budgetName, paymentTermDays: value },
      });
    }
  };

  const handleInputChange = async (fieldName: string, value: string | null) => {
    if (budgetId && budgetConfigVersionId) {
      await updateBudgetMetadata({
        input: {
          budgetId,
          budgetConfigVersionId,
          [fieldName]: value,
        },
      });
      await updateMatrix("updateBudgetMetadata", { [fieldName]: value });
    }
  };

  const handleNameInputChange = useDebounce(
    (value: string | null) => handleInputChange("name", value),
    DEFAULT_DEBOUNCE_TIME
  );

  const handleHoldbackInputChange = useDebounce(
    (value: string | null) => handleInputChange("holdbackPercentage", value),
    DEFAULT_DEBOUNCE_TIME
  );

  const handleOverheadInputChange = useDebounce(
    (value: string | null) => handleInputChange("overheadPercentage", value),
    DEFAULT_DEBOUNCE_TIME
  );

  const paymentTermOptions = [15, 30, 45, 60, 90].map((day) => ({
    value: day,
    label: `Net ${day}`,
  }));

  return (
    <section className="details grey-background">
      <StepSummaryBlock
        align="flex-start"
        title="Trial"
        value={
          <Select
            data-testid="budget-trial-name-disabled-select"
            disabled
            value={budgetTrialName}
            style={{ width: "auto", maxWidth: "300px", minWidth: "140px" }}
          />
        }
      />
      <StepSummaryBlock
        align="flex-start"
        title="Protocol Version"
        value={
          <Select
            data-testid="budget-protocol-version-name-disable-select"
            disabled
            value={budgetProtocolVersionName}
            style={{ width: "auto", maxWidth: "300px", minWidth: "140px" }}
          />
        }
      />
      <StepSummaryBlock
        align="flex-start"
        title="Payment Terms"
        value={
          paymentTermDays ? (
            <Select
              data-testid="payment-terms-select"
              disabled
              onChange={handleChange}
              value={paymentTermDays}
              options={paymentTermOptions}
              style={{ width: "130px" }}
            />
          ) : (
            <Skeleton.Input active style={{ width: "130px" }} />
          )
        }
      />
      <StepSummaryBlock
        align="flex-start"
        title="Holdback"
        value={
          holdback ? (
            <PercentageInput
              data-testid="budget-holdback-input"
              defaultValue={holdback.percentage ?? undefined}
              onChange={(e) => {
                const v = e.target.value.length ? e.target.value : null;
                handleHoldbackInputChange(v);
              }}
            />
          ) : (
            <span>
              <PercentageInput data-testid="budget-holdback-input" loading />
            </span>
          )
        }
      />
      <StepSummaryBlock
        align="flex-start"
        title="Overhead"
        value={
          overhead ? (
            <PercentageInput
              data-testid="budget-overhead-input"
              defaultValue={overhead.percentage ?? undefined}
              onChange={(e) => {
                const v = e.target.value.length ? e.target.value : null;
                handleOverheadInputChange(v);
              }}
            />
          ) : (
            <span>
              <PercentageInput data-testid="budget-overhead-input" loading />
            </span>
          )
        }
      />
      <StepSummaryBlock
        align="flex-start"
        title="Budget Name"
        value={
          budgetName ? (
            <Input
              onChange={(e) => {
                e.persist();
                handleNameInputChange(e.target.value);
              }}
              defaultValue={budgetName}
              style={{ width: "228px" }}
            />
          ) : (
            <Skeleton.Input active style={{ width: "228px" }} />
          )
        }
      />
      <StepSummaryBlock
        align="flex-start"
        title="Effective Dates"
        value={
          budgetEffectiveStartDate || budgetEffectiveEndDate ? (
            <RangePicker
              data-testid="effective-dates-range-picker"
              onCalendarChange={handleDateRangeChange}
              format={DEFAULT_DATEPICKER_FORMAT}
              value={[
                moment(utcDateStringToLocalDate(budgetEffectiveStartDate)),
                budgetEffectiveEndDate &&
                  moment(utcDateStringToLocalDate(budgetEffectiveEndDate)),
              ]}
              placeholder={["Start Date", "End Date"]}
            />
          ) : (
            <Skeleton.Input active style={{ width: "328px" }} />
          )
        }
      />
    </section>
  );
};
