import { formatExternalStatus } from "@model/invoices";
import { displayText, Money } from "@lib/currency";
import { DEFAULT_DATEPICKER_FORMAT, formatDate } from "@lib/date";
import { css } from "@linaria/core";
import { MagnifyingGlassRegular } from "@reifyhealth/picasso-icons";
import {
  Input,
  Table,
  Tag,
  Typography,
  DatePicker,
  Button,
  Empty,
} from "@reifyhealth/picasso-pkg";
import { useState } from "react";
import { PaymentAllocationUpdateAmount } from "./PaymentAllocationUpdateAmount";
import { format, isAfter, isBefore, isWithinInterval } from "date-fns";
import {
  isAllocatedPaymentAllocationTableItem,
  PaymentAllocationTableItem,
} from "@model/payments";

const { RangePicker } = DatePicker;

const statusTag = css`
  /* stylelint-disable */
  &.OPEN {
    background: var(--blue-3);
    color: var(--blue-10);
    border: none;
  }

  &.PAST_DUE {
    background: var(--red-3);
    color: var(--red-10);
    border: none;
  }

  &.PAID {
    background: var(--green-3);
    color: var(--green-10);
  }
  /* stylelint-enable */
`;

interface PaymentAllocationTableProps {
  tableIsLoading: boolean;
  paymentAllocations: PaymentAllocationTableItem[];
  handleSelectChange: any;
  selectedRowKeys: React.Key[];
  searchTerm: string;
  onSearchTermChange: Function;
  setAmountOverrideFn: (id: string, amountOverride: string | null) => void;
  paymentRemainingAmount: Money | null;
}

export const PaymentAllocationTable = ({
  tableIsLoading,
  handleSelectChange,
  paymentAllocations,
  selectedRowKeys,
  searchTerm,
  onSearchTermChange,
  setAmountOverrideFn,
  paymentRemainingAmount,
}: PaymentAllocationTableProps) => {
  const [dueDateRange, setDueDateRange] = useState<any>([]);

  const rowSelection = {
    selectedRowKeys,
    onChange: handleSelectChange,
    getCheckboxProps: (_record: any) => ({ disabled: !paymentRemainingAmount }),
  };

  const filteredPaymentAllocations = paymentAllocations?.filter(
    (item) =>
      selectedRowKeys.includes(item.rowKey) ||
      item.description?.toLowerCase().includes(searchTerm.trim().toLowerCase())
  );

  const visitNameFilters = [
    ...new Set(filteredPaymentAllocations?.map((v) => v.visitName)),
  ]
    .map((visitName) => {
      if (!visitName) {
        return "N/A";
      }
      return visitName;
    })
    .sort((a, b) => a!.localeCompare(b!))
    .map((visitName) => ({
      value: visitName,
      text: visitName,
    }));

  const subjectIDFilters = [
    ...new Set(filteredPaymentAllocations?.map((v) => v.subjectId)),
  ]
    .map((subjectId) => {
      if (!subjectId) {
        return "N/A";
      }

      return subjectId;
    })
    .sort((a, b) => a!.localeCompare(b!))
    .map((subjectId) => ({
      value: subjectId,
      text: subjectId,
    }));

  const statusFilters = [
    ...new Set(filteredPaymentAllocations?.map((v) => v.status)),
  ]
    .sort((a, b) => a.localeCompare(b))
    .map((externalStatus) => ({
      value: externalStatus,
      text: (
        <Tag
          data-testid="external-status-filter-options"
          className={`${statusTag} ${externalStatus}`}
        >
          {formatExternalStatus(externalStatus)}
        </Tag>
      ),
    }));

  return (
    <Table
      scroll={{ x: 500 }}
      locale={{
        emptyText: (
          <Empty
            data-testid="payment-allocations-no-results-empty"
            description={`No results matching "${searchTerm}"`}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        ),
      }}
      title={() => (
        <div>
          <Input
            allowClear
            prefix={<MagnifyingGlassRegular width={16} height={16} />}
            placeholder="Find Open Items"
            data-testid="create-payment-find-open-items-input"
            value={searchTerm}
            onChange={(e) => onSearchTermChange(e.target.value)}
          />
        </div>
      )}
      bordered={true}
      size="small"
      rowKey="rowKey"
      data-testid="create-payment-allocations-table"
      dataSource={searchTerm ? filteredPaymentAllocations : paymentAllocations}
      rowSelection={rowSelection}
      loading={tableIsLoading}
    >
      <Table.Column
        key="description"
        fixed="left"
        width={300}
        showSorterTooltip={false}
        sorter={(
          a: PaymentAllocationTableItem,
          b: PaymentAllocationTableItem
        ) => a.description.localeCompare(b.description)}
        title={
          <Typography.Text data-testid="open-items-title">
            Open Items
          </Typography.Text>
        }
        render={(_description, record: PaymentAllocationTableItem) => (
          <Typography.Text data-testid="payment-allocations-table-description-cell-text">
            {record.description}
          </Typography.Text>
        )}
      />
      <Table.Column
        key="status"
        width={100}
        title={
          <Typography.Text data-testid="status-title">Status</Typography.Text>
        }
        filters={statusFilters}
        onFilter={(value, record: PaymentAllocationTableItem) =>
          record.status === value
        }
        render={(_, record: PaymentAllocationTableItem) => {
          return (
            <Tag
              data-testid="status"
              className={`${statusTag} ${record.status}`}
            >
              {formatExternalStatus(record.status)}
            </Tag>
          );
        }}
      />
      <Table.Column
        key="dueDate"
        filterDropdown={({ setSelectedKeys, confirm }) => (
          <div style={{ marginRight: "8px" }}>
            <RangePicker
              data-testid="filter-due-date-rangepicker"
              onCalendarChange={(value: any) => {
                setDueDateRange(value);
              }}
              format={DEFAULT_DATEPICKER_FORMAT}
              value={dueDateRange}
              size="small"
              placeholder={["Start Date", "End Date"]}
            />
            <section
              style={{
                display: "flex",
                justifyContent: "flex-end",
                placeItems: "center",
              }}
            >
              <Button
                disabled={!dueDateRange}
                data-testid="due-date-ok-btn"
                type="primary"
                size="small"
                onClick={() => {
                  setSelectedKeys(dueDateRange);
                  confirm();
                }}
              >
                OK
              </Button>
              <Button
                data-testid="due-date-clear-btn"
                type="link"
                onClick={() => {
                  setSelectedKeys([]);
                  setDueDateRange([null, null]);
                  confirm();
                }}
              >
                Clear
              </Button>
            </section>
          </div>
        )}
        onFilter={(_, record) => {
          const [start, end] = dueDateRange;

          const startDateISOString =
            start &&
            new Date(format(new Date(start.toISOString()), "dd-MMM-yyy"));
          const endDateISOString =
            end && new Date(format(new Date(end.toISOString()), "dd-MMM-yyy"));

          const dueDate = new Date((record as any).dueDate);

          if (startDateISOString && endDateISOString) {
            return isWithinInterval(dueDate, {
              start: startDateISOString,
              end: endDateISOString,
            });
          } else if (start) {
            return isAfter(dueDate, startDateISOString);
          } else if (end) {
            return isBefore(dueDate, endDateISOString);
          }

          return true;
        }}
        sorter={(
          a: PaymentAllocationTableItem,
          b: PaymentAllocationTableItem
        ) =>
          ((new Date(a.dueDate) as any) || 0) -
          ((new Date(b.dueDate) as any) || 0)
        }
        showSorterTooltip={false}
        title={
          <Typography.Text data-testid="due-date-title">
            Due Date
          </Typography.Text>
        }
        width={160}
        render={(_dueDate, record) => {
          return (
            <span data-testid="payment-allocations-table-due-date-cell-text">
              {record.dueDate && formatDate(record.dueDate)}
            </span>
          );
        }}
      />
      <Table.Column
        key="visitName"
        title={
          <Typography.Text data-testid="visit-title">Visit</Typography.Text>
        }
        width={120}
        filters={visitNameFilters}
        onFilter={(value, record: PaymentAllocationTableItem) => {
          if (!record.visitName) {
            return "N/A" === value;
          }

          return record.visitName === value;
        }}
        render={(_visitName, record) => {
          return (
            <span data-testid="payment-allocations-table-visit-cell-text">
              {record.visitName || "N/A"}
            </span>
          );
        }}
      />
      <Table.Column
        key="subjectId"
        title={
          <Typography.Text data-testid="subject-id-title">
            Subject ID
          </Typography.Text>
        }
        filters={subjectIDFilters}
        onFilter={(value, record: PaymentAllocationTableItem) => {
          if (!record.subjectId) {
            return "N/A" === value;
          }

          return record.subjectId === value;
        }}
        width={120}
        render={(_subjectId, record) => {
          return (
            <span data-testid="payment-allocations-table-subject-id-cell-text">
              <Tag>{record.subjectId || "N/A"}</Tag>
            </span>
          );
        }}
      />
      <Table.Column
        key="remainingAmount"
        width={240}
        fixed="right"
        align="right"
        title={
          <Typography.Text data-testid="amount-title">Amount</Typography.Text>
        }
        showSorterTooltip={false}
        render={(_, record: PaymentAllocationTableItem) => {
          return (
            <div data-testid="create-payment-amount-cell">
              {!isAllocatedPaymentAllocationTableItem(record) ? (
                <Typography.Text data-testid="create-payment-item-total-amount-text">
                  {displayText(record.paymentItemMaxAllocationAmount!)}
                </Typography.Text>
              ) : (
                <PaymentAllocationUpdateAmount
                  record={record}
                  setAmountOverrideFn={setAmountOverrideFn}
                  tableIsLoading={tableIsLoading}
                  remainingPaymentAmount={paymentRemainingAmount}
                />
              )}
            </div>
          );
        }}
      />
    </Table>
  );
};
