import { ChangeEvent } from "react";
import { Input, Tooltip, icons } from "@reifyhealth/picasso-pkg";
import { useBudget, useBudgetMatrix } from "@model/budgets/matrix/hooks";
import { useParams } from "react-router-dom";
import { isVisitMatch } from "@model/budgets/matrix";

const { SearchOutlined } = icons;

export interface TableHeaderProps {
  budgetId: string;
  budgetConfigVersionId: string;
  selectedTrack: string;
  handleActivityFilterChange: (event: ChangeEvent<HTMLInputElement>) => void;
  visitFilter: string;
}

export function TableHeader({
  budgetId,
  budgetConfigVersionId,
  selectedTrack,
  handleActivityFilterChange,
  visitFilter,
}: TableHeaderProps) {
  const params = useParams();
  const { budget } = useBudget({
    budgetId: params.budgetId,
    budgetConfigVersionId: params.budgetConfigVersionId,
    track: selectedTrack,
  });
  const [matrix] = useBudgetMatrix({
    budgetId,
    budgetConfigVersionId,
    siteTrialId: budget?.trial?.stId,
    track: selectedTrack,
  });

  return (
    <thead>
      <tr>
        <th className="activities-header">
          <Input
            allowClear
            style={{ width: "100%" }}
            data-testid="find-activities-input"
            onChange={(e) => handleActivityFilterChange(e)}
            placeholder="Find Activities"
            prefix={<SearchOutlined />}
          />
        </th>
        {(matrix.columns ?? [])
          .filter((column) => isVisitMatch({ visit: column }, visitFilter))
          .map((column) => {
            return (
              <Tooltip
                key={column.visitCrossVersionId}
                data-testid="visit-name-tooltip"
                trigger={"hover"}
                placement="top"
                title={column.name}
              >
                <th
                  key={column.visitCrossVersionId}
                  className="sticky-top visit-header"
                  data-protocol-visit-cross-version-id={
                    column.visitCrossVersionId
                  }
                >
                  {column.name.length > 20
                    ? column.name.substring(0, 17) + "..."
                    : column.name}
                </th>
              </Tooltip>
            );
          })}
      </tr>
    </thead>
  );
}
