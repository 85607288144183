import { css } from "@linaria/core";
import { Space, Typography } from "@reifyhealth/picasso-pkg";
import { CheckCircleFill, ProhibitRegular } from "@reifyhealth/picasso-icons";
import { displayText, gt, Money } from "@lib/currency";

const allocationStateLabel = css`
  font-size: var(--font-size-footnote);
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .icon {
    width: var(--size-6);
    height: var(--size-6);
    color: var(--shared-success);
    margin-right: var(--size-3);
  }

  .void-payment-icon {
    width: var(--size-6);
    height: var(--size-6);
    color: var(--component-disabled-text);
    margin-right: var(--size-3);
  }
`;

interface AllocationAmountProps {
  allocationStatus: IFinancials2__PaymentAllocationStatus | null;
  paymentStatus: IFinancials2__PaymentStatus | null;
  allocatedAmount?: Money;
  totalAmount?: Money;
  remainingTotal?: Money;
}

export const AllocationAmount = ({
  allocationStatus,
  paymentStatus,
  totalAmount,
  remainingTotal,
  allocatedAmount,
}: AllocationAmountProps) => {
  if (paymentStatus && totalAmount && paymentStatus === "VOID") {
    return (
      <>
        <Space>
          <Typography.Text>{displayText(totalAmount)}</Typography.Text>
        </Space>
        <div>
          <Typography.Text className={allocationStateLabel} type="secondary">
            <ProhibitRegular className="void-payment-icon" /> Payment Void
          </Typography.Text>
        </div>
      </>
    );
  }
  if (allocationStatus && totalAmount && allocatedAmount && remainingTotal) {
    return (
      <>
        {allocationStatus === "FULLY_ALLOCATED" && (
          <>
            <Space>
              <Typography.Text data-testid="allocation-amount">
                {displayText(totalAmount)}
              </Typography.Text>
            </Space>
            <div>
              <Typography.Text
                className={allocationStateLabel}
                type="secondary"
              >
                <CheckCircleFill className="icon" /> Fully Allocated
              </Typography.Text>
            </div>
          </>
        )}

        {allocationStatus === "PARTIALLY_ALLOCATED" &&
          gt(remainingTotal, 0) && (
            <>
              <Space data-testid="allocation-amount">
                <Typography.Text strong>
                  {displayText(allocatedAmount)}
                </Typography.Text>
                <Typography.Text type="secondary">
                  / {displayText(totalAmount)}
                </Typography.Text>
              </Space>
              <div>
                <Typography.Text
                  className={allocationStateLabel}
                  type="secondary"
                >
                  Partially Allocated
                </Typography.Text>
              </div>
            </>
          )}

        {allocationStatus === "UNALLOCATED" && (
          <>
            <Space data-testid="allocation-amount">
              <Typography.Text type="secondary">
                {displayText(allocatedAmount)}
              </Typography.Text>
              <Typography.Text strong>
                / {displayText(totalAmount)}
              </Typography.Text>
            </Space>
          </>
        )}
      </>
    );
  }

  return null;
};
