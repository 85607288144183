import { Space, Typography } from "@reifyhealth/picasso-pkg";
import { displayText, Money, toNumber } from "@lib/currency";
import { useState } from "react";

import { AllocatedPaymentAllocationTableItem } from "@model/payments";
import MoneyInput from "@components/inputs/MoneyInput";

export const PaymentAllocationUpdateAmount = ({
  record,
  setAmountOverrideFn,
  tableIsLoading,
  remainingPaymentAmount,
}: {
  record: AllocatedPaymentAllocationTableItem;
  setAmountOverrideFn: (id: string, amountOverride: string | null) => void;
  tableIsLoading: boolean;
  remainingPaymentAmount: Money | null;
}) => {
  const [inputValue, setInputValue] = useState(
    record.clientAllocation
      ? displayText(record.clientAllocation).replace(/[^\d.]/g, "")
      : ""
  );

  const [
    userAttemptedOverMaximumAllowableValue,
    setUserAttemptedOverMaximumAllowableValue,
  ] = useState(false);

  return (
    <Space>
      <MoneyInput
        placeholder="Add Amount"
        disabled={tableIsLoading || !remainingPaymentAmount}
        style={{
          width: 100,
          borderColor: userAttemptedOverMaximumAllowableValue ? "red" : "",
        }}
        data-testid="payment-allocation-add-amount-input"
        value={inputValue}
        onChange={(e) => {
          const newValue = e.target.value;

          const maxAllowableValue = toNumber(record.totalAmount);

          if (!newValue) {
            setInputValue("");
            setAmountOverrideFn(record.rowKey, null);
            setUserAttemptedOverMaximumAllowableValue(false);
          }

          if (!isNaN(+newValue) && parseFloat(newValue) > maxAllowableValue) {
            setUserAttemptedOverMaximumAllowableValue(true);
          }

          if (!isNaN(+newValue) && parseFloat(newValue) <= maxAllowableValue) {
            setAmountOverrideFn(record.rowKey, newValue);
            setInputValue(newValue);
            setUserAttemptedOverMaximumAllowableValue(false);
          }
        }}
      />
      <Typography.Text type="secondary">
        /
        <span data-testid="create-payment-item-total-amount-text">
          {displayText(record.paymentItemMaxAllocationAmount ?? "N/A")}
        </span>
      </Typography.Text>
    </Space>
  );
};
