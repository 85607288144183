import { styled } from "@linaria/react";
import { Col, Row, icons } from "@reifyhealth/picasso-pkg";
import { UserControls } from "@components/headers/UserControls";
import { useParams, useLocation } from "react-router-dom";
import { useNavigate } from "@app/hooks";

const PartnerDetailsHeaderComponent = styled.div`
  color: var(--component-text);
  background: var(--component-background);
  border-bottom: 1px solid var(--component-border);

  .back-action {
    display: flex;
    padding: 0 var(--space-5);
    align-items: center;
    border-right: 1px solid var(--component-border);
    justify-content: center;
    gap: var(--size-4);
    cursor: pointer;

    span {
      font-size: var(--font-size-body);
    }
  }

  .details {
    /* Remove when details are back */
    height: 55px;
    padding: var(--space-3) var(--space-5);
    display: flex;
    align-items: center;
    gap: var(--space-7);

    .caption {
      font-size: var(--font-size-caption);
      color: var(--application-secondary-text);

      .ant-typography {
        color: inherit;
        margin: 0;
      }
    }
  }
`;

interface PartnerDetailsHeaderProps {
  partner: Partner;
  navigate: (to: string) => void;
  backPath: string;
}

export const PartnerDetailsHeaderView = ({
  partner,
  navigate,
  backPath,
}: PartnerDetailsHeaderProps) => {
  return (
    <PartnerDetailsHeaderComponent>
      <Row>
        <Col
          className="back-action"
          data-testid="back-action"
          onClick={() => navigate(backPath)}
        >
          <icons.ArrowLeftOutlined />
          <span>Back</span>
        </Col>
        <Col className="details" flex="1">
          {/* TODO: Uncomment */}
          {/* <article>
            <div className="caption">
              <Typography.Text># of Trials</Typography.Text>
            </div>
            <div data-testid="n umber-of-trials">
              <Typography.Text>0</Typography.Text>
            </div>
          </article>

          <article>
            <div className="caption">
              <Typography.Text>Outstanding Invoices</Typography.Text>
            </div>
            <div data-testid="number-of-invoices">
              <Typography.Text>0</Typography.Text>
            </div>
          </article> */}

          {/* TODO: Add Balances */}
          {/* TODO: Add Terms */}
        </Col>
        <Col>
          <UserControls />
        </Col>
      </Row>
    </PartnerDetailsHeaderComponent>
  );
};

export const PartnerDetailsHeader = () => {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const backPath = `/finance/sites/${params.siteId}/partners${location.search}`;

  // TODO: Add partner details query
  return (
    <PartnerDetailsHeaderView
      partner={{
        id: "",
        name: "",
        primaryContact: null,
        invoices: [],
        payments: [],
      }}
      navigate={navigate}
      backPath={backPath}
    />
  );
};
