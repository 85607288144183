import React, { FC, ReactElement } from "react";
import { Spin, icons } from "@reifyhealth/picasso-pkg";
import { styled } from "@linaria/react";
import classnames from "classnames";

const { LoadingOutlined } = icons;

type LoadingProps = {
  className?: string;
  message?: string;
  Tag?: "p" | "div" | "h3" | "h4" | "h5" | "h6";
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const Loading: FC<LoadingProps> = (props): ReactElement => {
  const { message = "Loading...", className, Tag = "h3" } = props;

  return (
    <Container
      data-testid="loading-container"
      className={classnames("spin-container", className)}
    >
      <Spin indicator={antIcon} className="spin-indicator" />
      <Tag>{message}</Tag>
    </Container>
  );
};

export default Loading;
