import {
  BudgetAggregateSlice,
  VisitActivitySidebarProps,
} from "@app/store/types";
import {
  BudgetMatrix,
  getCachedBudgetAggregate,
  updateBudgetAggregateCache,
} from "@model/budgets/matrix";
import { useStore } from "@app/store";
import useDebounce from "@hooks/useDebounce";
import { DEFAULT_DEBOUNCE_TIME } from "@constants";
import { toGraphQLInput } from "@lib/currency";
import {
  BudgetAggregate,
  BudgetMatrixNonEmptyCell,
  deriveBudgetAggregate,
} from "@model/budgets/matrix";
import { isUndefined, omit, pick } from "lodash";
import {
  useUpdateBudgetForActivityMutation,
  useGetBudgetMatrixQuery,
  useUpdateBudgetForVisitMutation,
  useOverrideActivityBudgetForVisitMutation,
  useResetBudgetForActivityToDefaultsMutation,
  useGetBudgetMatrixBudgetVersion3Query,
  useGetBudgetVersionTotals3Query,
  useGetBudgetMatrixProtocolQuery,
  useClearBudgetForVisitMutation,
} from "@app/service/generated";
import { useCallback, useEffect } from "react";
import { FinancialsFlagSet, useFlags } from "@app/hooks/useFlags";

function assertExhaustiveSwitch(x: never): never {
  throw new Error(`Switch is missing case for ${JSON.stringify(x)}`);
}

export function useBudget({
  track,
  budgetId,
  budgetConfigVersionId,
}: {
  track?: string | null;
  budgetId?: string;
  budgetConfigVersionId?: string;
}) {
  const flags = useFlags();
  const pageBudgetId = budgetId;
  const pageBudgetConfigVersionId = budgetConfigVersionId;
  const {
    data: budgetData,
    isLoading: budgetIsLoading,
    isError: budgetLoadingError,
  } = useGetBudgetMatrixBudgetVersion3Query(
    {
      budgetId: pageBudgetId!,
      budgetConfigVersionId: pageBudgetConfigVersionId!,
    },
    {
      enabled: !!pageBudgetId && !!pageBudgetConfigVersionId,
    }
  );

  const siteTrialId = budgetData?.budget.trial.stId;

  const {
    data: budgetVersionTotalData,
    isLoading: budgetVersionTotalIsLoading,
    isError: budgetVersionTotalError,
    isFetching: budgetVersionTotalFetching,
  } = useGetBudgetVersionTotals3Query(
    {
      budgetId: pageBudgetId!,
      budgetConfigVersionId: pageBudgetConfigVersionId!,
      siteTrialId: siteTrialId!,
    },
    {
      enabled: !!pageBudgetId && !!pageBudgetConfigVersionId && !!siteTrialId,
    }
  );

  const {
    data: protocolData,
    isLoading: protocolIsLoading,
    isError: protocolLoadingError,
  } = useGetBudgetMatrixProtocolQuery(
    {
      siteTrialId: budgetData?.budget.trial.stId!,
    },
    {
      enabled: !!budgetData && !!pageBudgetConfigVersionId,
    }
  );

  let budgetAggregate = null;

  if (budgetVersionTotalData?.budget && !isUndefined(track)) {
    budgetAggregate = deriveBudgetAggregate(
      budgetVersionTotalData?.budget.configVersions[0]!,
      budgetVersionTotalData?.budget.protocol!,
      track,
      pageBudgetId!,
      siteTrialId!,
      flags
    );
  }

  return {
    pageBudgetId,
    isLoading: budgetIsLoading || protocolIsLoading,
    isError: budgetLoadingError || protocolLoadingError,
    protocol: protocolData?.Financials2__getSiteTrialProtocol,
    budget: budgetData?.budget,
    pageBudgetConfigVersionId,
    budgetAggregate,
    totals: budgetVersionTotalData?.budget,
    budgetVersionTotalIsLoading: budgetVersionTotalIsLoading,
    budgetVersionTotalError: budgetVersionTotalError,
    budgetVersionTotalFetching: budgetVersionTotalFetching,
  };
}

function findSelectedCell(
  matrix: BudgetMatrix | undefined,
  visitActivitySidebarProps: VisitActivitySidebarProps | null
): BudgetMatrixNonEmptyCell | null {
  const row = (matrix?.rows ?? []).find((row) => {
    return (
      visitActivitySidebarProps?.activity.crossVersionId ===
      row.activityCrossVersionId
    );
  });

  return (
    ((row?.cells ?? []).find((cell) => {
      return (
        visitActivitySidebarProps?.visit.crossVersionId ===
        cell.visitCrossVersionId
      );
    }) as BudgetMatrixNonEmptyCell | undefined) ?? null
  );
}

type CachedMatrixResult = Partial<BudgetAggregate["matrix"]> & {
  loading: boolean;
  budgetAggregate: BudgetAggregate | null;
  calculating: boolean;
  selectedCell: BudgetMatrixNonEmptyCell | null;
};

function handleBudgetMatrixResponse({
  store,
  data,
  track,
  budgetConfigVersionId,
  flags,
}: {
  store: BudgetAggregateSlice;
  data: IGetBudgetMatrixQuery;
  track: string | null;
  budgetConfigVersionId?: string | null;
  flags: FinancialsFlagSet;
}) {
  let cache = store.budgetAggregateCache;
  cache = updateBudgetAggregateCache(cache, {
    budgetConfigVersionId: budgetConfigVersionId!,
    track: "all",
    budgetAggregate: deriveBudgetAggregate(
      data.budget.configVersions[0],
      data.budget.protocol,
      "all",
      data.budget.id,
      data.budget.trial.stId,
      flags
    ),
  });
  if (track !== "all") {
    cache = updateBudgetAggregateCache(cache, {
      budgetConfigVersionId: budgetConfigVersionId!,
      track,
      budgetAggregate: deriveBudgetAggregate(
        data.budget.configVersions[0],
        data.budget.protocol,
        track,
        data.budget.id,
        data.budget.trial.stId,
        flags
      ),
    });
  }
  store.setBudgetAggregateCache(cache);
}

function useCachedMatrix({
  budgetId,
  budgetConfigVersionId,
  siteTrialId,
  track,
  flags,
}: UseBudgetMatrixOptions & {
  flags: FinancialsFlagSet;
}): CachedMatrixResult & { invalidate: () => void } {
  const store = useStore((store) =>
    pick(store, [
      "budgetAggregateCache",
      "setBudgetAggregateCache",
      "visitActivitySidebarProps",
    ])
  );

  const budgetAggregate = getCachedBudgetAggregate(store.budgetAggregateCache, {
    budgetConfigVersionId: budgetConfigVersionId!,
    track,
  });

  const selectedCell = findSelectedCell(
    budgetAggregate?.matrix,
    store.visitActivitySidebarProps
  );

  const query = useGetBudgetMatrixQuery(
    {
      budgetId: budgetId!,
      budgetConfigVersionId: budgetConfigVersionId!,
      siteTrialId: siteTrialId!,
    },
    {
      enabled: false,
      onSuccess: (data) =>
        handleBudgetMatrixResponse({
          store,
          data,
          track,
          budgetConfigVersionId,
          flags,
        }),
    }
  );

  const ready = Boolean(budgetId && budgetConfigVersionId && siteTrialId);

  useEffect(() => {
    if (!budgetAggregate && ready) {
      query.refetch();
    }
  }, [query, budgetAggregate, budgetConfigVersionId, ready, track]);

  return {
    ...budgetAggregate?.matrix,
    budgetAggregate,
    loading: query.isLoading,
    calculating: query.isLoading || query.isFetching,
    selectedCell,
    invalidate: query.refetch,
  };
}

type MutateAsync<T extends (...args: any) => { mutateAsync: Function }> =
  ReturnType<T>["mutateAsync"];

function useMatrixMutations({ invalidate }: { invalidate: () => void }): {
  updateBudgetForVisit: MutateAsync<typeof useUpdateBudgetForVisitMutation>;
  updateBudgetForActivity: MutateAsync<
    typeof useUpdateBudgetForActivityMutation
  >;
  overrideActivityBudgetForVisit: MutateAsync<
    typeof useOverrideActivityBudgetForVisitMutation
  >;
  clearBudgetForVisit: MutateAsync<typeof useClearBudgetForVisitMutation>;
  resetBudgetForActivityToDefaults: MutateAsync<
    typeof useResetBudgetForActivityToDefaultsMutation
  >;
} {
  const updateBudgetForVisitMutation = useUpdateBudgetForVisitMutation();
  const clearBudgetForVisitMutation = useClearBudgetForVisitMutation();
  const visitUpdateFn = useCallback(
    async (
      mutationName: ("updateBudgetForVisit" | "clearBudgetForVisit") &
        UpdateMatrixEventName,
      variables
    ) => {
      let result;
      switch (mutationName) {
        case "updateBudgetForVisit":
          result = await updateBudgetForVisitMutation.mutateAsync(variables);
          break;

        case "clearBudgetForVisit":
          result = await clearBudgetForVisitMutation.mutateAsync(variables);
          break;

        default:
          return assertExhaustiveSwitch(mutationName);
      }
      invalidate();
      return result;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const visitUpdateWrapper = useDebounce(visitUpdateFn, DEFAULT_DEBOUNCE_TIME);
  const updateBudgetForVisitDebounced = useCallback(
    (variables) => {
      return visitUpdateWrapper("updateBudgetForVisit", variables);
    },
    [visitUpdateWrapper]
  ) as MutateAsync<typeof useUpdateBudgetForVisitMutation>;
  const clearBudgetForVisitDebounced = useCallback(
    (variables) => {
      return visitUpdateWrapper("clearBudgetForVisit", variables);
    },
    [visitUpdateWrapper]
  ) as MutateAsync<typeof useClearBudgetForVisitMutation>;

  const updateBudgetForActivityMutation = useUpdateBudgetForActivityMutation();
  const updateBudgetForActivityFn = useCallback(async (variables) => {
    const result = await updateBudgetForActivityMutation.mutateAsync(variables);
    invalidate();
    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const updateBudgetForActivityDebounced = useDebounce(
    updateBudgetForActivityFn,
    DEFAULT_DEBOUNCE_TIME
  );

  const overrideBudgetActivityForVisitMutation =
    useOverrideActivityBudgetForVisitMutation();
  const resetBudgetForActivityToDefaultsMutation =
    useResetBudgetForActivityToDefaultsMutation();
  const visitActivityUpdateFn = useCallback(
    async (
      mutationName: (
        | "overrideActivityBudgetForVisit"
        | "resetBudgetForActivityToDefaults"
      ) &
        UpdateMatrixEventName,
      variables
    ) => {
      let result;
      switch (mutationName) {
        case "overrideActivityBudgetForVisit":
          result = await overrideBudgetActivityForVisitMutation.mutateAsync(
            variables
          );
          break;

        case "resetBudgetForActivityToDefaults":
          result = await resetBudgetForActivityToDefaultsMutation.mutateAsync(
            variables
          );
          break;

        default:
          return assertExhaustiveSwitch(mutationName);
      }
      invalidate();
      return result;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const visitActivityUpdateWrapper = useDebounce(
    visitActivityUpdateFn,
    DEFAULT_DEBOUNCE_TIME
  );
  const overrideActivityBudgetForVisitDebounced = useCallback(
    (variables) => {
      return visitActivityUpdateWrapper(
        "overrideActivityBudgetForVisit",
        variables
      );
    },
    [visitActivityUpdateWrapper]
  ) as MutateAsync<typeof useOverrideActivityBudgetForVisitMutation>;
  const resetBudgetForActivityToDefaultsDebounced = useCallback(
    (variables) => {
      return visitActivityUpdateWrapper(
        "resetBudgetForActivityToDefaults",
        variables
      );
    },
    [visitActivityUpdateWrapper]
  ) as MutateAsync<typeof useResetBudgetForActivityToDefaultsMutation>;

  return {
    updateBudgetForVisit: updateBudgetForVisitDebounced,
    updateBudgetForActivity: updateBudgetForActivityDebounced,
    overrideActivityBudgetForVisit: overrideActivityBudgetForVisitDebounced,
    clearBudgetForVisit: clearBudgetForVisitDebounced,
    resetBudgetForActivityToDefaults: resetBudgetForActivityToDefaultsDebounced,
  };
}

function updateBudgetForVisitVariables({
  budgetId,
  budgetConfigVersionId,
  visitCrossVersionId,
  cost,
  charge,
  holdbackEnabled,
  overheadEnabled,
}: BudgetMatrixNonEmptyCell) {
  return {
    input: {
      budgetId: budgetId!,
      budgetConfigVersionId: budgetConfigVersionId!,
      protocolVisitCrossVersionId: visitCrossVersionId!,
      cost: cost ? toGraphQLInput(cost) : null,
      charge: charge ? toGraphQLInput(charge) : null,
      holdbackEnabled: holdbackEnabled,
      overheadEnabled: overheadEnabled,
    },
  };
}

function updateBudgetForActivityVariables({
  activityCrossVersionId,
  budgetId,
  budgetConfigVersionId,
  holdbackEnabled,
  overheadEnabled,
  cost,
  charge,
  invoiceable,
}: BudgetMatrixNonEmptyCell) {
  return {
    input: {
      budgetId: budgetId!,
      budgetConfigVersionId: budgetConfigVersionId!,
      protocolActivityCrossVersionId: activityCrossVersionId,
      cost: cost ? toGraphQLInput(cost) : null,
      charge: charge ? toGraphQLInput(charge) : null,
      invoiceable: invoiceable ?? false,
      holdbackEnabled: holdbackEnabled,
      overheadEnabled: overheadEnabled,
    },
  };
}

function overrideActivityBudgetForVisitVariables({
  activityCrossVersionId,
  visitCrossVersionId,
  budgetId,
  budgetConfigVersionId,
  holdbackEnabled,
  overheadEnabled,
  cost,
  charge,
  invoiceable,
}: BudgetMatrixNonEmptyCell) {
  return {
    input: {
      budgetId: budgetId!,
      budgetConfigVersionId: budgetConfigVersionId!,
      protocolActivityCrossVersionId: activityCrossVersionId,
      protocolVisitCrossVersionId: visitCrossVersionId!,
      cost: cost ? toGraphQLInput(cost) : null,
      charge: charge ? toGraphQLInput(charge) : null,
      invoiceable: invoiceable ?? false,
      holdbackEnabled: holdbackEnabled,
      overheadEnabled: overheadEnabled,
    },
  };
}

function clearBudgetForVisitVariables({
  visitCrossVersionId,
  budgetId,
  budgetConfigVersionId,
}: BudgetMatrixNonEmptyCell) {
  return {
    input: {
      budgetId: budgetId!,
      budgetConfigVersionId: budgetConfigVersionId!,
      protocolVisitCrossVersionId: visitCrossVersionId!,
    },
  };
}

function resetBudgetForActivityToDefaultsVariables({
  visitCrossVersionId,
  activityCrossVersionId,
  budgetId,
  budgetConfigVersionId,
}: BudgetMatrixNonEmptyCell) {
  return {
    input: {
      budgetId,
      budgetConfigVersionId,
      protocolVisitCrossVersionId: visitCrossVersionId!,
      protocolActivityCrossVersionId: activityCrossVersionId,
    },
  };
}

type UpdateMatrixEventName =
  | "init"
  | "startEditing"
  | "stopEditing"
  | "updateBudgetForVisit"
  | "updateBudgetForActivity"
  | "overrideActivityBudgetForVisit"
  | "clearBudgetForVisit"
  | "resetBudgetForActivityToDefaults"
  | "updateBudgetAdditionalItem"
  | "updateBudgetMetadata";

export type UpdateMatrixFn = (
  eventName: UpdateMatrixEventName,
  eventOptions?: Record<string, any>
) => Promise<void>;

function useUpdateMatrix({
  invalidate,
}: {
  invalidate: () => void;
}): UpdateMatrixFn {
  const store = useStore((store) =>
    pick(store, [
      "setActiveEditOptions",
      "activateVisitActivitySidebar",
      "activeEdit",
      "clearVisitActivitySidebar",
    ])
  );

  const mutations = useMatrixMutations({ invalidate });

  return useCallback<UpdateMatrixFn>(
    async (eventName, eventOptions) => {
      switch (eventName) {
        case "init":
          invalidate();
          break;

        case "startEditing":
          const cell: BudgetMatrixNonEmptyCell = eventOptions!.cell;
          store.setActiveEditOptions(cell.activity.crossVersionId, "activity");
          store.setActiveEditOptions(
            cell.visit?.crossVersionId ?? null,
            "visit"
          );
          store.activateVisitActivitySidebar({
            activity: cell.activity,
            visit: cell.visit!,
            visitActivity: cell,
            trackName: cell.track,
          });
          break;

        case "stopEditing":
          store.setActiveEditOptions(null, "visit");
          store.setActiveEditOptions(null, "activity");
          store.clearVisitActivitySidebar();
          break;

        case "updateBudgetForVisit":
          await mutations.updateBudgetForVisit(
            updateBudgetForVisitVariables(
              eventOptions! as BudgetMatrixNonEmptyCell
            )
          );
          break;

        case "updateBudgetForActivity":
          await mutations.updateBudgetForActivity(
            updateBudgetForActivityVariables(
              eventOptions! as BudgetMatrixNonEmptyCell
            )
          );
          break;

        case "overrideActivityBudgetForVisit":
          await mutations.overrideActivityBudgetForVisit(
            overrideActivityBudgetForVisitVariables(
              eventOptions! as BudgetMatrixNonEmptyCell
            )
          );
          break;

        case "clearBudgetForVisit":
          await mutations.clearBudgetForVisit(
            clearBudgetForVisitVariables(
              eventOptions! as BudgetMatrixNonEmptyCell
            )
          );
          break;

        case "resetBudgetForActivityToDefaults":
          await mutations.resetBudgetForActivityToDefaults(
            resetBudgetForActivityToDefaultsVariables(
              eventOptions! as BudgetMatrixNonEmptyCell
            )
          );
          break;

        case "updateBudgetAdditionalItem":
          invalidate();
          break;

        case "updateBudgetMetadata":
          invalidate();
          break;

        default:
          return assertExhaustiveSwitch(eventName);
      }
    },
    [store, mutations, invalidate]
  );
}

interface UseBudgetMatrixOptions {
  budgetId?: string | null;
  siteTrialId?: string | null;
  budgetConfigVersionId?: string | null;
  track: string | null;
}

export function useBudgetMatrix(
  options: UseBudgetMatrixOptions
): [CachedMatrixResult, UpdateMatrixFn] {
  const flags = useFlags();
  const cachedMatrix = useCachedMatrix({ ...options, flags });
  const updateMatrix = useUpdateMatrix(cachedMatrix);

  return [omit(cachedMatrix, "invalidate"), updateMatrix];
}
