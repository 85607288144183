import { displayText, money } from "@lib/currency";
import { Table } from "@reifyhealth/picasso-pkg";
import { useStore } from "@app/store";
import { shallow } from "zustand/shallow";
import { styled } from "@linaria/react";

const AdditionalChargesSummary = styled.section`
  margin-top: var(--size-6);

  table {
    font-size: var(--font-size-footnote);
  }

  .additional-charges-total {
    font-weight: var(--font-weight-bold);
  }
`;

export const BudgetVersionAdditionalChargeSummary = () => {
  const {
    protocolCloseOutFee,
    protocolExecutionFee,
    protocolStartUpFee,
    miscellaneousFee,
    overallCostChargeMargin,
  } = useStore((store) => store.totals, shallow);

  const fees = [
    {
      label: "Protocol Start-Up Fees",
      className: "fee",
      testId: "start-up-fee",
      cost: protocolStartUpFee.cost,
      charge: protocolStartUpFee.charge,
      margin: protocolStartUpFee.margin,
    },
    {
      label: "Protocol Execution Fees",
      className: "fee",
      testId: "execution-fee",
      cost: protocolExecutionFee.cost,
      charge: protocolExecutionFee.charge,
      margin: protocolExecutionFee.margin,
    },
    {
      label: "Protocol Close-Out Fees",
      className: "fee",
      testId: "close-up-fee",
      cost: protocolCloseOutFee.cost,
      charge: protocolCloseOutFee.charge,
      margin: protocolCloseOutFee.margin,
    },
    {
      label: "Miscellaneous Fees",
      className: "fee",
      testId: "misc-fee",
      cost: miscellaneousFee.cost,
      charge: miscellaneousFee.charge,
      margin: miscellaneousFee.margin,
    },
    {
      label: "Totals",
      className: "visitTotal",
      testId: "totals",
      cost: overallCostChargeMargin.cost,
      charge: overallCostChargeMargin.charge,
      margin: overallCostChargeMargin.margin,
    },
  ];

  return (
    <AdditionalChargesSummary>
      <Table
        size="small"
        bordered
        pagination={false}
        dataSource={fees}
        rowClassName={(record): any => {
          return (
            record.className === "visitTotal" && "additional-charges-total"
          );
        }}
      >
        <Table.Column
          key="category"
          title="Category"
          render={(text) => {
            return <span>{text.label}</span>;
          }}
        />
        <Table.Column
          width={100}
          align="right"
          key="cost"
          title="Cost"
          render={(text) => {
            return (
              <span data-testid={`${text.testId}-summary-cost`}>
                {displayText(money(text.cost))}
              </span>
            );
          }}
        />
        <Table.Column
          width={100}
          align="right"
          key="charge"
          title="Charge"
          render={(text) => {
            return (
              <span data-testid={`${text.testId}-summary-charge`}>
                {displayText(money(text.charge))}
              </span>
            );
          }}
        />
        <Table.Column
          width={100}
          align="right"
          key="margin"
          title="Margin"
          render={(text) => {
            return (
              <span data-testid={`${text.testId}-summary-margin`}>
                {displayText(money(text.margin))}
              </span>
            );
          }}
        />
      </Table>
    </AdditionalChargesSummary>
  );
};
