import { deriveBudgetAggregate } from "@model/budgets/matrix";
import {
  useGetBudgetMatrixBudgetVersion3Query,
  useGetBudgetMatrixProtocolQuery,
  useGetBudgetVersionTotals3Query,
} from "@app/service/generated";
import { useParams } from "react-router-dom";
import { isUndefined } from "lodash";
import { useFlags } from "./useFlags";

export function useBudget({ track }: { track: string | null | undefined }) {
  const params = useParams();
  const flags = useFlags();

  const pageBudgetId = params.budgetId;
  const pageBudgetConfigVersionId = params.budgetConfigVersionId;

  const {
    data: budgetData,
    isLoading: budgetIsLoading,
    isError: budgetLoadingError,
  } = useGetBudgetMatrixBudgetVersion3Query(
    {
      budgetId: pageBudgetId!,
      budgetConfigVersionId: pageBudgetConfigVersionId!,
    },
    {
      enabled: !!pageBudgetId && !!pageBudgetConfigVersionId,
    }
  );

  const {
    data: budgetVersionTotalData,
    isLoading: budgetVersionTotalIsLoading,
    isError: budgetVersionTotalError,
    isFetching: budgetVersionTotalFetching,
  } = useGetBudgetVersionTotals3Query(
    {
      budgetId: pageBudgetId!,
      budgetConfigVersionId: pageBudgetConfigVersionId!,
      siteTrialId: budgetData?.budget.trial.stId!,
    },
    {
      enabled:
        !!pageBudgetId &&
        !!pageBudgetConfigVersionId &&
        !!budgetData?.budget.trial.stId,
    }
  );

  const {
    data: protocolData,
    isLoading: protocolIsLoading,
    isError: protocolLoadingError,
  } = useGetBudgetMatrixProtocolQuery(
    {
      siteTrialId: budgetData?.budget.trial.stId!,
    },
    {
      enabled: !!budgetData && !!pageBudgetConfigVersionId,
    }
  );

  let budgetAggregate = null;

  if (budgetVersionTotalData?.budget && !isUndefined(track)) {
    budgetAggregate = deriveBudgetAggregate(
      budgetVersionTotalData?.budget.configVersions[0]!,
      budgetVersionTotalData?.budget.protocol!,
      track,
      pageBudgetId!,
      params.siteTrialId!,
      flags
    );
  }

  return {
    pageBudgetId,
    isLoading: budgetIsLoading || protocolIsLoading,
    isError: budgetLoadingError || protocolLoadingError,
    protocol: protocolData?.Financials2__getSiteTrialProtocol,
    budget: budgetData?.budget,
    pageBudgetConfigVersionId,
    budgetAggregate,
    totals: budgetVersionTotalData?.budget,
    budgetVersionTotalIsLoading: budgetVersionTotalIsLoading,
    budgetVersionTotalError: budgetVersionTotalError,
    budgetVersionTotalFetching: budgetVersionTotalFetching,
  };
}
