import { Card, Col, Row, Space, Typography } from "@reifyhealth/picasso-pkg";
import { css } from "@linaria/core";
import * as budgetMatrix from "@model/budgets/matrix";
import { BudgetActiveSwitch } from "@app/components/common/BudgetActiveSwitch";
import { StepSummaryBlock } from "@components/common/SummaryBlock";
import { displayText, Money, toNumber } from "@lib/currency";
import { RedMarginTrendDownwardArrow } from "@components/common/icons/RedMarginTrendDownwardArrow";
import { GreenMarginTrendUpwardArrow } from "@components/common/icons/GreenMarginTrendUpwardArrow";
import { ReactNode } from "react";
import { useBudgetSummary } from "@app/hooks/budgetSummary";
import { BudgetVersionSummaryHeader } from "./BudgetVersionSummaryHeader";

const BudgetVersionSummaryContainer = css`
  padding: 0;

  .ant-card-body {
    padding: 0;
  }

  form {
    display: flex;
    flex-direction: row;
  }

  .activate-budget-title-active {
    padding: var(--size-6);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background: var(--green-4);
  }

  .activate-budget-title-inactive {
    padding: var(--size-6);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .activate-budget-body {
    display: flex;
    flex-direction: column;
  }

  .activate-budget-switch {
    display: flex;
    align-items: flex-end;
  }

  .title {
    font-size: var(--font-size-h-5);
  }

  .value {
    font-size: var(--font-size-footnote);
  }

  .details {
    display: flex;
    align-items: center;
    gap: var(--space-7);

    .caption {
      font-size: var(--font-size-footnote);
      color: var(--application-secondary-text);

      .ant-typography {
        color: inherit;
        margin: 0;
      }
    }

    padding: var(--size-6);
  }

  .statistics {
    &:first-child {
      border-top: 1px solid var(--component-border);
    }

    padding: var(--size-6);
    border-bottom: 1px solid var(--component-border);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .breakdown {
      display: flex;
      flex-direction: row;

      & > div > div {
        width: 125px;
      }
    }
  }

  .grey-background {
    background: var(--component-background-subtle);
  }

  .invoiced-separately {
    display: flex;
    align-items: center;
  }
`;

const isNumberNegative = (num: number) => num < 0;
const isNumberPositive = (num: number) => num > 0;

export const BudgetVersionSummary = () => {
  const {
    holdback,
    overhead,
    budgetAggregate,
    budgetConfigVersionId,
    budgetId,
    budgetSiteTrialId,
    budgetStatus,
    budgetName,
    budgetPaymentTermDays,
    budgetProtocolVersionName,
    budgetEffectiveEndDate,
    budgetEffectiveStartDate,
    budgetTrialName,
    isLoading,
    protocolVersionId,
  } = useBudgetSummary();

  const visitCharges = budgetAggregate
    ? budgetMatrix.visitCharges(budgetAggregate)
    : null;
  const additionalCharges =
    budgetAggregate && budgetMatrix.additionalCharges(budgetAggregate);
  const summary =
    budgetAggregate &&
    visitCharges &&
    additionalCharges &&
    budgetMatrix.summary(visitCharges, additionalCharges);

  const statistics: {
    key: string;
    className: string;
    testId: string;
    title: ReactNode;
    cost?: Money | null;
    charge?: Money | null;
    margin?: Money | null;
  }[] = [
    {
      key: "visit-charges",
      className: "",
      title: <span>Visit Charges</span>,
      testId: "visit-charges",
      cost: visitCharges?.totalCost,
      charge: visitCharges?.totalCharge,
      margin: visitCharges?.margin,
    },
    {
      key: "additional-charges",
      className: "",
      testId: "additional-charges",
      title: <span>Additional Charges</span>,
      cost: additionalCharges?.totalCost,
      charge: additionalCharges?.totalCharge,
      margin: additionalCharges?.margin,
    },
    {
      key: "line-items",
      className: "grey-background",
      testId: "line-items-total",
      title: (
        <Space>
          <Typography.Text>Line Items Total</Typography.Text>
        </Space>
      ),
      cost: summary?.totalCost,
      charge: summary?.totalCharge,
      margin: summary?.margin,
    },
  ];

  const renderMargin = (testId: string, margin?: Money | null) => {
    if (isLoading || !margin) {
      return (
        <StepSummaryBlock
          data-testid={`${testId}-margin`}
          title="Margin:"
          value={"Calculating..."}
        />
      );
    }

    if (isNumberNegative(toNumber(margin))) {
      return (
        <StepSummaryBlock
          data-testid={`${testId}-margin`}
          title="Margin:"
          value={
            <>
              <RedMarginTrendDownwardArrow />{" "}
              <span
                style={{ color: "red", fontSize: "var(--font-size-footnote)" }}
              >
                {displayText(margin)}
              </span>
            </>
          }
        />
      );
    }

    if (isNumberPositive(toNumber(margin))) {
      return (
        <StepSummaryBlock
          data-testid={`${testId}-margin`}
          title="Margin:"
          value={
            <>
              <GreenMarginTrendUpwardArrow />{" "}
              <span
                style={{
                  color: "green",
                  fontSize: "var(--font-size-footnote)",
                }}
              >
                {displayText(margin)}
              </span>
            </>
          }
        />
      );
    }

    return (
      <StepSummaryBlock
        data-testid={`${testId}-margin`}
        title="Margin:"
        value={displayText(margin)}
      />
    );
  };

  return (
    <Card className={BudgetVersionSummaryContainer}>
      <BudgetVersionSummaryHeader
        budgetId={budgetId}
        protocolVersionId={protocolVersionId}
        paymentTermDays={budgetPaymentTermDays}
        holdback={holdback}
        overhead={overhead}
        budgetName={budgetName}
        budgetTrialName={budgetTrialName}
        budgetProtocolVersionName={budgetProtocolVersionName}
        budgetEffectiveStartDate={budgetEffectiveStartDate}
        budgetEffectiveEndDate={budgetEffectiveEndDate}
        budgetConfigVersionId={budgetConfigVersionId}
        budgetSiteTrialId={budgetSiteTrialId}
      />
      <Col>
        <Row
          className={`activate-budget-title-${
            budgetStatus === "ACTIVE" ? "inactive" : "active"
          }`}
        >
          <div className="activate-budget-body">
            <span className="title">
              {budgetStatus === "ACTIVE" ? "Deactivate" : "Activate"} Budget
            </span>
            <span className="value">
              {budgetStatus === "ACTIVE"
                ? "Set this budget to Inactive to stop receiving completed activities in Accounts Receivable"
                : "Great! Set this budget to Active to begin receiving completed activities in Accounts Receivable"}
            </span>
          </div>
          <section className="activate-budget-switch">
            <BudgetActiveSwitch
              invalidateMutation={{
                budgetConfigVersionId: budgetConfigVersionId!,
                budgetId: budgetId!,
                siteTrialId: budgetSiteTrialId!,
              }}
              status={budgetStatus}
            />
          </section>
        </Row>
      </Col>
      <Col>
        {statistics.map((statistic) => (
          <Row
            className={`statistics ${statistic.className}`}
            key={statistic.key}
          >
            {statistic.title}
            <section className="breakdown">
              <Space>
                <StepSummaryBlock
                  data-testid={`${statistic.testId}-cost`}
                  title="Total Cost:"
                  value={
                    isLoading || !statistic.cost
                      ? "Calculating..."
                      : displayText(statistic.cost)
                  }
                />
                <StepSummaryBlock
                  data-testid={`${statistic.testId}-charge`}
                  title="Total Charge:"
                  value={
                    isLoading || !statistic.charge
                      ? "Calculating..."
                      : displayText(statistic.charge)
                  }
                />
                {renderMargin(statistic.testId, statistic.margin)}
              </Space>
            </section>
          </Row>
        ))}
      </Col>
    </Card>
  );
};
