import { omit } from "lodash";
import NumericInput, { NumericInputProps } from "./NumericInput";

type PercentageInputProps = NumericInputProps & {
  loading?: boolean;
};

export default function PercentageInput(props: PercentageInputProps) {
  let disabled;
  let placeholder;
  if (props.loading) {
    placeholder = "";
    disabled = true;
  } else {
    placeholder = props.placeholder ?? "Enter Percentage";
    disabled = props.disabled;
  }

  return (
    <NumericInput
      disabled={disabled}
      placeholder={placeholder}
      addonAfter="%"
      decimalScale={undefined}
      min={0}
      max={100}
      {...omit(props, "loading")}
    />
  );
}
