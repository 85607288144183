import { TableActions } from "./TableActions";
import { TableBody } from "./TableBody";
import { TableFooter } from "./TableFooter";
import { TableHeader } from "./TableHeader";
import { TableRoot } from "./TableRoot";

export const BudgetMatrix = () => {
  return (
    <TableRoot
      Actions={TableActions}
      Header={TableHeader}
      Body={TableBody}
      Footer={TableFooter}
    />
  );
};
