import { Input, SizeType } from "@reifyhealth/picasso-pkg";
import { omit, isNumber } from "lodash";
import { useCallback } from "react";
import {
  NumericFormat,
  NumericFormatProps,
  NumberFormatValues,
  InputAttributes,
} from "react-number-format";
import { isMoney, Money, toString } from "@lib/currency";

function isWithinMinMax(
  values: NumberFormatValues,
  min: NumericInputProps["min"],
  max: NumericInputProps["max"]
) {
  const { floatValue } = values;

  return (
    !isNumber(floatValue) ||
    ((!isNumber(min) || floatValue >= min) &&
      (!isNumber(max) || floatValue <= max))
  );
}

function triggerOnChange(
  values: NumberFormatValues,
  onChange: NumericInputProps["onChange"]
) {
  const { value, floatValue } = values;

  if (!value) {
    onChange?.({ target: { value: "" } });
  } else if (!value.endsWith(".") && isNumber(floatValue)) {
    onChange?.({ target: { value: String(floatValue) } });
  }
}

export type NumericInputProps = Omit<
  NumericFormatProps,
  | "isAllowed"
  | "onValueChange"
  | "onChange"
  | "size"
  | "type"
  | "prefix"
  | "suffix"
  | "value"
> & {
  value?: string | Money;
  size?: SizeType;
  allowClear?: boolean;
  addonBefore?: string;
  addonAfter?: string;
  min?: number;
  max?: number;
  onChange?: (event: { target: { value: string } }) => void;
};

function coerceValue(value: NumericInputProps["value"]): string {
  return isMoney(value) ? toString(value) : String(value ?? "");
}

export default function NumericInput(props: NumericInputProps) {
  const isAllowed = useCallback(
    (values: NumberFormatValues) =>
      isWithinMinMax(values, props.min, props.max),
    [props.min, props.max]
  );

  const onValueChange = useCallback(
    (values: NumberFormatValues) => triggerOnChange(values, props.onChange),
    [props.onChange]
  );

  const customInput = useCallback(
    (inputProps: InputAttributes) => {
      return (
        <Input
          {...inputProps}
          size={props.size}
          addonBefore={props.addonBefore}
          addonAfter={props.addonAfter}
          allowClear={props.allowClear}
        />
      );
    },
    [props.size, props.addonBefore, props.addonAfter, props.allowClear]
  );

  return (
    <NumericFormat
      className={props.className}
      isAllowed={isAllowed}
      onValueChange={onValueChange}
      customInput={customInput}
      value={props.value === undefined ? undefined : coerceValue(props.value)}
      fixedDecimalScale
      {...omit(
        props,
        "value",
        "className",
        "onChange",
        "min",
        "max",
        "size",
        "addonBefore",
        "addonAfter",
        "allowClear"
      )}
    />
  );
}
