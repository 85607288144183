import { Tag } from "@reifyhealth/picasso-pkg";
import { Link } from "react-router-dom";

interface BudgetVersionInfoProps {
  siteId: string;
  budgetId: string;
  budgetConfigVersionId: string;
  number: number;
  status: "ACTIVE" | "INACTIVE";
}

export const BudgetVersionInfo = ({
  siteId,
  budgetId,
  budgetConfigVersionId,
  number,
  status,
}: BudgetVersionInfoProps) => {
  const generateTag = (status: "ACTIVE" | "INACTIVE") => {
    if (status === "ACTIVE") {
      return (
        <Tag data-testid="active-tag" className="active-tag">
          Active
        </Tag>
      );
    }

    if (status === "INACTIVE") {
      return (
        <Tag data-testid="inactive-tag" className="inactive-tag">
          Inactive
        </Tag>
      );
    }

    return null;
  };

  return (
    <section>
      <Link
        data-cy="budget-version-link"
        data-testid={`budget-version-${number}-link`}
        to={`/finance/sites/${siteId}/budgets/${budgetId}/versions/${budgetConfigVersionId}`}
      >
        Version {number}
      </Link>{" "}
      {generateTag(status)}
    </section>
  );
};
