import { showDeleteModal } from "@app/components/common/modals";
import { useSelectedSite, useNavigateWithQueryString } from "@app/hooks";
import {
  useDeletePartnerMutation,
  useGetPartnerListQuery,
} from "@app/service/generated";
import { css } from "@linaria/core";
import {
  Button,
  Input,
  Space,
  Table,
  Tooltip,
  Typography,
  notification,
} from "@reifyhealth/picasso-pkg";
import { useState } from "react";
import { useQueryClient } from "react-query";

const tableHeaderControls = css`
  display: flex;
  gap: var(--size-4);
`;

export const PartnersListingTable = () => {
  const navigate = useNavigateWithQueryString();
  const { data } = useSelectedSite();
  const siteId = data?.selectedSite?.value ?? "";
  const [searchQuery, setSearchQuery] = useState("");
  const queryClient = useQueryClient();

  const partnerQueryResult = useGetPartnerListQuery(
    { siteId },
    { enabled: Boolean(siteId) }
  );
  const { mutate: deletePartner } = useDeletePartnerMutation({
    onError: () => {
      notification.error({
        duration: 2,
        message: "Partner was not deleted",
      });
    },
    onSuccess: () => {
      notification.success({
        duration: 2,
        message: "Partner and contacts have been deleted",
      });

      queryClient.invalidateQueries(useGetPartnerListQuery.getKey({ siteId }));
    },
  });
  const partners =
    partnerQueryResult.data?.Financials2__partnerList.partners.sort(
      (partnerA, partnerB) => partnerA.name.localeCompare(partnerB.name)
    ) ?? [];

  const filterValue = partners.filter(
    (v) =>
      v.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      v.primaryContact?.name
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      v.primaryContact?.emailAddress
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
  );

  return (
    <div>
      <Table
        size="small"
        bordered
        rowKey={"id"}
        loading={partnerQueryResult.isFetching || partnerQueryResult.isLoading}
        title={() => {
          return (
            <div className={tableHeaderControls}>
              <Input
                onChange={(e: any) => setSearchQuery(e.target.value)}
                data-testid="find-partner-input"
                placeholder="Find Partner"
              />
              <Button
                type="primary"
                data-testid="create-partner-btn"
                onClick={() => {
                  navigate(`/finance/sites/${siteId}/partners/new`);
                }}
              >
                Create Partner
              </Button>
            </div>
          );
        }}
        dataSource={filterValue}
      >
        <Table.Column
          dataIndex="name"
          key="name"
          title={
            <Typography.Text data-testid="partner-name-title">
              Partner Name
            </Typography.Text>
          }
          render={(name, record: Partner) => {
            return (
              <Typography.Link
                data-testid="partner-name-btn"
                onClick={() => navigate(`${record.id}`)}
              >
                {name}
              </Typography.Link>
            );
          }}
        />
        <Table.Column
          title={
            <Typography.Text data-testid="primary-contact-title">
              Primary Contact
            </Typography.Text>
          }
          dataIndex="primaryContact"
          key="primaryContact.name"
          render={(_text, pc: Partner) => (
            <Typography.Text data-testid="primary-contact-name">
              {pc.primaryContact?.name ?? "[Not Set]"}
            </Typography.Text>
          )}
        />
        <Table.Column
          title={
            <Typography.Text data-testid="email-address-title">
              Email Address
            </Typography.Text>
          }
          dataIndex="primaryContact"
          key="primaryContact.emailAddress"
          render={(_text, pc: Partner) => (
            <Typography.Text data-testid="primary-contact-email-address">
              {pc.primaryContact?.emailAddress ?? "[Not Set]"}
            </Typography.Text>
          )}
        />
        <Table.Column
          dataIndex="primaryContact"
          key="primaryContact.phoneNumber"
          render={(_text, pc: Partner) => (
            <Typography.Text data-testid="primary-contact-phone-number">
              {pc.primaryContact?.phoneNumber ?? "[Not Set]"}
            </Typography.Text>
          )}
          title={
            <Typography.Text data-testid="phone-number-title">
              Phone Number
            </Typography.Text>
          }
        />
        <Table.Column
          key="actions"
          render={(_text, record: Partner) => {
            const partnerCannotDelete =
              record.payments.some(
                (payment) =>
                  payment.allocationStatus === "PARTIALLY_ALLOCATED" ||
                  payment.allocationStatus === "FULLY_ALLOCATED"
              ) ||
              record.invoices.some(
                (invoice) => invoice.externalStatus === "OPEN"
              );

            return (
              <Space className="table-row-controls-wrapper">
                <Typography.Link
                  data-testid="edit-partner-btn"
                  onClick={() => navigate(`${record.id}/edit`)}
                >
                  Edit
                </Typography.Link>
                {partnerCannotDelete ? (
                  <Tooltip
                    trigger={"hover"}
                    placement="left"
                    title={
                      "Partner with open invoices or partially allocated payments cannot be deleted!"
                    }
                  >
                    <Typography.Text data-testid="delete-partner-link" disabled>
                      Delete
                    </Typography.Text>
                  </Tooltip>
                ) : (
                  <Typography.Link
                    type="danger"
                    data-testid="delete-partner-link"
                    onClick={() => {
                      showDeleteModal({
                        title: "Delete Partner",
                        content: (
                          <section style={{ display: "flex" }}>
                            This will remove partner and any related information
                            to the partner. This cannot be undone.
                          </section>
                        ),
                        okText: "",
                        onOkFunction: () => {
                          deletePartner({
                            input: { partnerId: record.id },
                          });
                        },
                      });
                    }}
                  >
                    Delete
                  </Typography.Link>
                )}
              </Space>
            );
          }}
        />
      </Table>
    </div>
  );
};
