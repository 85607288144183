import React from "react";
import { OSTLogo } from "@components/common/Logo";
import { UserControls } from "@components/headers/UserControls";
import { styled } from "@linaria/react";
import {
  Select,
  Typography,
  Col,
  Row,
  Skeleton,
} from "@reifyhealth/picasso-pkg";
import { NavLink, useLocation } from "react-router-dom";
import { useSelectedSite } from "@hooks/sites";
import { useNavigate } from "@app/hooks";
import { isUUID } from "@lib/string";

interface HeaderProps {
  applicationTitle?: string;
  availableWaffleApps?: any;
  onChangeAvailableSite?: () => void;
  onClickHelp?: () => void;
  onClickLogout?: () => void;
  onClickWaffleApp?: () => void;
}
interface SiteSelectorHeaderProps {
  currentSite?: string | null;
  availableSites?: { value: string; label: string }[];
  onSelectorChangeFn: (value: string) => void;
}

const HeaderComponent = styled.div`
  color: var(--component-text);
  background: var(--component-background);
  border-bottom: 1px solid var(--component-border);

  .application-title {
    padding: 0 var(--space-6);
    display: flex;
    align-items: center;
    width: 240px;
    border-right: 1px solid var(--component-border);

    a {
      display: flex;
      align-items: center;
      gap: var(--space-4);
      color: var(--component-primary-textt);
    }

    .application-title-label {
      font-size: 1.2rem;
      font-weight: var(--font-weight-normal);
    }
  }

  .site-selector {
    padding: var(--size-2) var(--size-6);
    display: flex;
    justify-content: flex-start;
    min-height: var(--size-13);

    .caption {
      font-size: var(--font-size-caption);
      position: relative;
      top: var(--size-3);
      left: 0;
      color: var(--application-secondary-text);

      .ant-typography {
        color: inherit;
      }
    }

    .ant-select-borderless {
      cursor: pointer !important;
    }

    .ant-select-selector {
      padding: 0 !important;
      gap: var(--size-6);
      background: red;
    }

    .ant-select-arrow {
      right: calc(var(--size-2) * -1);
    }
  }
`;

export const SiteSelectorHeaderView = ({
  currentSite,
  availableSites,
  applicationTitle = "Site Financials",
  onSelectorChangeFn,
}: HeaderProps & SiteSelectorHeaderProps) => {
  return (
    <HeaderComponent>
      <Row>
        <Col className="application-title">
          <NavLink data-testid="page-layout-site-home" to={`/`}>
            <OSTLogo />
            <div className="application-title-label">{applicationTitle}</div>
          </NavLink>
        </Col>
        <Col flex="1" className="site-selector">
          <div>
            <div className="caption">
              <Typography.Text>Site Name</Typography.Text>
            </div>
            {currentSite ? (
              <div>
                <Select
                  data-testid="budget-version-site-selector-header-site-select"
                  showSearch={false}
                  bordered={false}
                  defaultValue={currentSite}
                  options={availableSites}
                  onChange={onSelectorChangeFn}
                  virtual={false}
                />
              </div>
            ) : (
              <div>
                <Skeleton.Button
                  active={true}
                  style={{ width: 200, height: 24 }}
                />
              </div>
            )}
          </div>
        </Col>
        <Col>
          {/* TODO: Implement */}
          <UserControls
          // onClickLogout={() => console.log('logout')}
          // onClickHelp={() => console.log('help')}
          // onClickWaffleApp={() => console.log('change app')}
          />
        </Col>
      </Row>
    </HeaderComponent>
  );
};

export const BudgetVersionSiteSelectorHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { data } = useSelectedSite();

  const handleChange = (value: string) => {
    if (location.pathname === "/finance") {
      navigate(`/finance/sites/${value}/budgets${location.search}`);
    } else {
      const existingPageLocation = location.pathname
        .split("/")
        .map((entry) => (isUUID(entry) ? value : entry))
        .join("/");

      navigate(`${existingPageLocation}${location.search}`);
    }
  };

  return (
    <SiteSelectorHeaderView
      currentSite={data?.selectedSite?.label}
      availableSites={data?.allowedSites}
      onSelectorChangeFn={handleChange}
    />
  );
};
