import { CaretDownRegular } from "@reifyhealth/picasso-icons";
import {
  Button,
  Dropdown,
  Menu,
  Typography,
  message,
} from "@reifyhealth/picasso-pkg";
import {
  useAccountsReceivableSourceEventsQuery,
  useClearHoldbacksMutation,
  useMarkLineItemsAsPaidMutation,
  useMarkLineItemsAsVoidMutation,
} from "@app/service/generated";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { PaymentActionsModal } from "./PaymentActionsModal";
import * as ar from "@model/accountsReceivable";
import { showWarningModal } from "@app/components/common/modals";
import {
  selectedStatuses,
  transformForARActions,
} from "@model/accountsReceivable";

const MoreActionsOverlay = ({
  siteId,
  lineItems,
  selectedRowKeys,
  setSelectedRowKeysFn,
  onPaidClickFn,
  onVoidClickFn,
  clearHoldbackFn,
}: {
  siteId: string;
  lineItems: any;
  selectedRowKeys: any;
  setSelectedRowKeysFn: any;
  onPaidClickFn: Function;
  onVoidClickFn: Function;
  clearHoldbackFn: Function;
}) => {
  const selectedLineItemStatuses = selectedStatuses(lineItems, selectedRowKeys);

  const shouldPaidBeDisabled = !ar.isMarkPaidActionAllowed(
    selectedLineItemStatuses
  );
  const shouldVoidBeDisabled = !ar.isMarkVoidActionAllowed(
    selectedLineItemStatuses
  );
  const shouldClearHoldbackBeDisabled = !ar.isClearHoldbacksActionAllowed(
    selectedLineItemStatuses
  );

  return (
    <Menu>
      <PaymentActionsModal
        siteId={siteId}
        onPaidClickFn={onPaidClickFn}
        lineItems={lineItems}
        selectedRowKeys={selectedRowKeys}
        shouldPaidBeDisabled={shouldPaidBeDisabled}
        setSelectedRowKeysFn={setSelectedRowKeysFn}
      />
      <Menu.Item
        key="mark-as-void"
        disabled={shouldVoidBeDisabled}
        onClick={() => {
          const { tableIds, transformedLineItems } = transformForARActions(
            lineItems,
            selectedRowKeys
          );

          setSelectedRowKeysFn(tableIds); // in case paired down to a single sponsor

          onVoidClickFn({
            input: {
              siteId,
              lineItems: transformedLineItems,
            },
          });
        }}
      >
        <Typography.Text
          data-testid="ar-mark-as-void-btn"
          disabled={shouldVoidBeDisabled}
        >
          Mark as Void
        </Typography.Text>
      </Menu.Item>
      <Menu.Item
        key="clear-holdbacks"
        disabled={shouldClearHoldbackBeDisabled}
        onClick={() => {
          const { tableIds, transformedLineItems } = transformForARActions(
            lineItems,
            selectedRowKeys
          );

          setSelectedRowKeysFn(tableIds); // in case paired down to a single sponsor

          showWarningModal({
            title: "Clear Holdbacks",
            content: <>This will remove the holdback on selected receivables</>,
            okText: "Clear Holdbacks",
            onOkFunction: () =>
              clearHoldbackFn({
                input: {
                  siteId,
                  lineItems: transformedLineItems,
                },
              }),
          });
        }}
      >
        <Typography.Text
          data-testid="ar-clear-holdbacks-btn"
          disabled={shouldClearHoldbackBeDisabled}
        >
          Clear Holdbacks
        </Typography.Text>
      </Menu.Item>
    </Menu>
  );
};

interface MoreActionsMenuProps {
  lineItems: any;
  selectedRowKeys: any;
  setSelectedRowKeysFn: any;
  hasSelected: boolean;
  hasDisallowedStatuses: boolean;
  allClearableHoldbacks: boolean;
  holdbacksEnabled?: boolean;
  reactQueryKey: { siteId: string };
}

export const MoreActionsMenu = ({
  lineItems,
  selectedRowKeys,
  setSelectedRowKeysFn,
  hasSelected,
  reactQueryKey,
  hasDisallowedStatuses,
  allClearableHoldbacks,
  holdbacksEnabled,
}: MoreActionsMenuProps) => {
  const queryClient = useQueryClient();

  const { mutate: markLineItemsAsPaid } = useMarkLineItemsAsPaidMutation({
    onMutate: () => {
      // TODO: when status is complete. come back.
    },
    onError: () => {
      // TODO: when status is complete. come back.
    },
    onSuccess(resp) {
      if (resp.data.success) {
        message.success("Items marked as paid");

        setSelectedRowKeysFn([]); // clear select

        queryClient.invalidateQueries(
          useAccountsReceivableSourceEventsQuery.getKey(reactQueryKey)
        );
      }
    },
  });

  const { mutate: markLineItemsAsVoid } = useMarkLineItemsAsVoidMutation({
    onMutate: () => {
      // TODO: when status is complete. come back.
    },
    onError: () => {
      // TODO: when status is complete. come back.
    },
    onSuccess(resp) {
      if (resp.data.success) {
        message.success("Items marked as void");

        setSelectedRowKeysFn([]); // clear select

        queryClient.invalidateQueries(
          useAccountsReceivableSourceEventsQuery.getKey(reactQueryKey)
        );
      }
    },
  });

  const { mutate: clearHoldbacks } = useClearHoldbacksMutation({
    onMutate: () => {
      // TODO: when status is complete. come back.
    },
    onError: () => {
      // TODO: when status is complete. come back.
    },
    onSuccess(resp) {
      if (resp.data.success) {
        message.success("Item holdbacks cleared");

        setSelectedRowKeysFn([]); // clear select

        queryClient.invalidateQueries(
          useAccountsReceivableSourceEventsQuery.getKey(reactQueryKey)
        );
      }
    },
  });

  const params = useParams();
  const siteId = params.siteId!;

  return (
    <Dropdown
      disabled={
        !hasSelected || (hasDisallowedStatuses && !allClearableHoldbacks)
      }
      // FIXME: Styles get messed up if passed in as JSX
      overlay={MoreActionsOverlay({
        siteId,
        lineItems,
        selectedRowKeys,
        setSelectedRowKeysFn,
        onPaidClickFn: markLineItemsAsPaid,
        onVoidClickFn: markLineItemsAsVoid,
        clearHoldbackFn: clearHoldbacks,
      })}
      trigger={["click"]}
    >
      <Button
        data-testid="more-actions-menu"
        type="default"
        style={{ display: "flex" }}
      >
        <div>More Actions</div>
        <div style={{ paddingTop: "3px", marginLeft: "6px" }}>
          <CaretDownRegular style={{ width: "16px", height: "16px" }} />
        </div>
      </Button>
    </Dropdown>
  );
};
