export const RedMarginTrendDownwardArrow = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 6 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.72097 5.72097C5.59893 5.84301 5.40107 5.84301 5.27903 5.72097L0.279029 0.720971C0.15699 0.598932 0.15699 0.401068 0.279029 0.279029C0.401068 0.15699 0.598932 0.15699 0.720971 0.279029L5.72097 5.27903C5.84301 5.40107 5.84301 5.59893 5.72097 5.72097Z"
        fill="var(--shared-danger)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 1.125C5.67259 1.125 5.8125 1.26491 5.8125 1.4375V5.5C5.8125 5.67259 5.67259 5.8125 5.5 5.8125H1.4375C1.26491 5.8125 1.125 5.67259 1.125 5.5C1.125 5.32741 1.26491 5.1875 1.4375 5.1875H5.1875V1.4375C5.1875 1.26491 5.32741 1.125 5.5 1.125Z"
        fill="var(--shared-danger)"
      />
    </svg>
  );
};
