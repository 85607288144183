import { Modal, icons } from "@reifyhealth/picasso-pkg";
import { ReactNode } from "react";
const {
  CloseCircleFilled,
  CheckCircleFilled,
  LockFilled,
  ExclamationCircleFilled,
} = icons;
const { confirm } = Modal;

interface showDeleteModalArgs {
  title: string;
  content: ReactNode;
  okText: string;
  onOkFunction: Function;
}

interface showFinalizeInvoiceModalArgs {
  title: string;
  content: ReactNode;
  okText: string;
  onOkFunction: Function;
}

export const showFinalizedInvoiceModal = ({
  title,
  content,
  okText,
  onOkFunction,
}: showFinalizeInvoiceModalArgs) =>
  confirm({
    icon: <LockFilled style={{ color: "gray" }} />,
    title,
    content,
    okText,
    okType: "primary",
    cancelText: "Cancel",
    okButtonProps: {
      className: "finalize-invoice-modal-ok-btn",
      autoFocus: true,
    },
    cancelButtonProps: {
      className: "finalize-invoice-modal-cancel-btn",
    },
    onOk() {
      onOkFunction();
    },
    onCancel() {},
  });

export const showDeleteModal = ({
  title,
  content,
  okText,
  onOkFunction,
}: showDeleteModalArgs) =>
  confirm({
    icon: <CloseCircleFilled style={{ color: "red" }} />,
    title,
    content,
    okText,
    okType: "primary",
    okButtonProps: {
      color: "red",
      danger: true,
      disabled: false,
      className: "delete-modal-ok-btn",
    },
    cancelText: "Cancel",
    cancelButtonProps: {
      className: "delete-modal-cancel-btn",
    },
    onOk() {
      onOkFunction();
    },
    onCancel() {},
  });

export const showSuccessModal = ({
  title,
  content,
  okText,
  onOkFunction,
}: showDeleteModalArgs) =>
  confirm({
    icon: <CheckCircleFilled style={{ color: "green" }} />,
    title,
    content,
    okText,
    okType: "primary",
    okButtonProps: {
      color: "green",
      disabled: false,
      className: "success-modal-ok-btn",
    },
    cancelText: "Cancel",
    cancelButtonProps: {
      className: "success-modal-cancel-btn",
    },
    onOk() {
      onOkFunction();
    },
    onCancel() {},
  });

export const showWarningModal = ({
  title,
  content,
  okText,
  onOkFunction,
}: showDeleteModalArgs) =>
  confirm({
    icon: <ExclamationCircleFilled />,
    title,
    content,
    okText,
    type: "warning",
    okButtonProps: {
      disabled: false,
      style: {
        background: "var(--button-warning-background)",
        borderColor: "var(--button-warning-border)",
      },
    },
    cancelText: "Cancel",
    cancelButtonProps: {
      className: "warning-modal-cancel-btn",
    },
    onOk() {
      onOkFunction();
    },
    onCancel() {},
  });
