import { PageLayout } from "@components/PageLayout";
import { BudgetVersionSiteSelectorHeader } from "@app/components/headers/BudgetVersionSiteSelectorHeader";
import { PartnerContactFormModal, PartnerForm } from "@app/components/forms";
import { useState } from "react";
import { Typography, useForm, Button, List } from "@reifyhealth/picasso-pkg";
import { PartnerContact } from "@app/components/cards";
import {
  useCreateContactMutation,
  useDeleteContactMutation,
  useEditPartnerDetailsQuery,
  useUpdateContactMutation,
  useUpdatePartnerMutation,
} from "@app/service/generated";
import { useQueryClient } from "react-query";
import { useNavigate } from "@app/hooks";
import { useParams, useSearchParams } from "react-router-dom";
import { sortBy } from "lodash";

export const EditPartnerPage = () => {
  const params = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [showAddContactForm, setShowAddContactForm] = useState(false);
  const {
    data,
    isLoading: loadingPartnerDetails,
    isFetching: fetchingPartnerDetails,
  } = useEditPartnerDetailsQuery(
    { partnerId: params.partnerId! },
    {
      onSuccess: (data) => {
        const { name, addressLine1, addressLine2, state, zipCode, city } =
          data.Financials2__partnerDetails.partner;
        partnerForm.setFieldsValue({
          name,
          addressLine1,
          addressLine2: addressLine2 ?? "",
          state,
          zipCode,
          city,
        });
      },
      enabled: !!params.partnerId,
    }
  );

  const { mutate: updatePartner, isLoading: isUpdatingPartner } =
    useUpdatePartnerMutation({
      onSuccess: () => {
        navigate(
          `/finance/sites/${params.siteId}/partners/${params.partnerId}?${searchParams}`
        );
      },
    });

  const { mutate: deleteContact } = useDeleteContactMutation({
    onMutate: (variables) => {
      const queryKey = useEditPartnerDetailsQuery.getKey({
        partnerId: params.partnerId!,
      });
      const previousData: IPartnerDetailsQuery | undefined =
        queryClient.getQueryData(queryKey);

      queryClient.setQueryData(
        queryKey,
        (oldData: IPartnerDetailsQuery | void) => {
          if (oldData) {
            const updatedContacts =
              oldData.Financials2__partnerDetails.partner.contacts.filter(
                (contact) => contact.id !== variables.input.contactId
              );

            return {
              Financials2__partnerDetails: {
                partner: {
                  ...oldData.Financials2__partnerDetails.partner,
                  contacts: updatedContacts,
                },
              },
            };
          }

          return oldData;
        }
      );

      if (previousData) {
        return { previousData };
      }
    },
    onError: (error, variables, context) => {
      console.error({ error, variables });
      const queryKey = useEditPartnerDetailsQuery.getKey({
        partnerId: params.partnerId!,
      });

      queryClient.setQueryData(queryKey, context?.previousData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(
        useEditPartnerDetailsQuery.getKey({ partnerId: params.partnerId! })
      );
    },
  });

  const { mutate: createContact, isLoading: isCreatingContact } =
    useCreateContactMutation({
      onMutate: (variables) => {
        const queryKey = useEditPartnerDetailsQuery.getKey({
          partnerId: params.partnerId!,
        });
        const previousData: IEditPartnerDetailsQuery | undefined =
          queryClient.getQueryData(queryKey);

        queryClient.setQueryData(
          queryKey,
          (oldData: IEditPartnerDetailsQuery | void) => {
            if (oldData) {
              const updatedContacts =
                oldData.Financials2__partnerDetails.partner.contacts.concat([
                  { id: "", ...variables.input },
                ]);

              return {
                Financials2__partnerDetails: {
                  partner: {
                    ...oldData.Financials2__partnerDetails.partner,
                    contacts: updatedContacts,
                  },
                },
              };
            }

            return oldData;
          }
        );

        if (previousData) {
          return { previousData };
        }
      },
      onError: (error, variables, context) => {
        console.error({ error, variables });
        const queryKey = useEditPartnerDetailsQuery.getKey({
          partnerId: params.partnerId!,
        });

        queryClient.setQueryData(queryKey, context?.previousData);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(
          useEditPartnerDetailsQuery.getKey({ partnerId: params.partnerId! })
        );
      },
    });

  const { mutate: updateContact, isLoading: isUpdatingContact } =
    useUpdateContactMutation({
      onMutate: (variables) => {
        const queryKey = useEditPartnerDetailsQuery.getKey({
          partnerId: params.partnerId!,
        });
        const previousData: IEditPartnerDetailsQuery | undefined =
          queryClient.getQueryData(queryKey);

        queryClient.setQueryData(
          queryKey,
          (oldData: IEditPartnerDetailsQuery | void) => {
            if (oldData) {
              const updatedContacts =
                oldData.Financials2__partnerDetails.partner.contacts.map(
                  (contact) => {
                    if (contact.id === variables.input.contactId) {
                      const { partnerId, contactId, ...rest } = variables.input;

                      return { ...rest, id: contactId };
                    }

                    return contact;
                  }
                );

              return {
                Financials2__partnerDetails: {
                  partner: {
                    ...oldData.Financials2__partnerDetails.partner,
                    contacts: updatedContacts,
                  },
                },
              };
            }

            return oldData;
          }
        );

        if (previousData) {
          return { previousData };
        }
      },
      onError: (error, variables, context) => {
        console.error({ error, variables });
        const queryKey = useEditPartnerDetailsQuery.getKey({
          partnerId: params.partnerId!,
        });

        queryClient.setQueryData(queryKey, context?.previousData);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(
          useEditPartnerDetailsQuery.getKey({ partnerId: params.partnerId! })
        );
      },
    });

  const toggleCreateContactForm = (contact: {
    emailAddress: string;
    id: string;
    isPrimary: boolean;
    name: string;
    phoneNumber: string;
  }) => {
    if (!showAddContactForm) {
      partnerContactForm.setFieldsValue(contact);
    } else {
      partnerContactForm.resetFields();
    }

    setShowAddContactForm(!showAddContactForm);
  };

  const [partnerForm] = useForm<{
    name: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    zipCode: string;
  }>();

  const [partnerContactForm] = useForm<{
    id?: string;
    name: string;
    emailAddress: string;
    phoneNumber: string;
    isPrimary: boolean;
  }>();

  const onPartnerFormCancelFn = () => {
    partnerForm.resetFields();
    navigate(`/finance/sites/${params.siteId}/payments`);
  };

  const onPartnerFormFinishFn = async (values: {
    name: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    zipCode: string;
  }) => {
    updatePartner({ input: { partnerId: params.partnerId!, ...values } });
  };

  const invoiceContactIds = data?.Financials2__partnerDetails.partner.invoices
    .filter((invoice) => invoice.externalStatus === "DRAFT")
    .map((invoice) => invoice.toContactId);

  const partnerContacts = sortBy(
    data?.Financials2__partnerDetails.partner.contacts ?? [],
    "name"
  );

  const contactList = (
    <>
      <List bordered={true}>
        {partnerContacts.map((contact) => {
          return (
            <PartnerContact
              isContactTiedToDraftInvoice={
                invoiceContactIds?.some(
                  (invoiceContactId) => invoiceContactId === contact.id
                ) ?? false
              }
              key={contact.emailAddress}
              hasPrimaryContact={partnerContacts.some(
                (contact) => contact.isPrimary
              )}
              toggleCreateContactFormFn={() => toggleCreateContactForm(contact)}
              onCheckboxChange={(e) => {
                const { checked } = e.target;

                // id is not part of update contact. removed.
                const { id, ...contactWithoutId } = contact;

                updateContact({
                  input: {
                    ...contactWithoutId,
                    ...{ isPrimary: checked },
                    ...{
                      contactId: contact.id,
                      partnerId: params.partnerId!,
                    },
                  },
                });
              }}
              onDeleteClickFn={() =>
                deleteContact({ input: { contactId: contact.id } })
              }
              contact={contact}
            />
          );
        })}
      </List>
      <Button
        block
        type="link"
        data-testid="add-contact-btn"
        onClick={() => {
          toggleCreateContactForm({
            id: "",
            isPrimary: false,
            emailAddress: "",
            name: "",
            phoneNumber: "",
          });
        }}
      >
        Add Contact
      </Button>
    </>
  );

  const onPartnerContactFormFinishFn = (values: {
    id?: string;
    name: string;
    emailAddress: string;
    phoneNumber: string;
    isPrimary: boolean;
  }) => {
    const { id, ...valuesWithoutId } = values;

    if (id) {
      updateContact({
        input: {
          ...valuesWithoutId,
          contactId: id,
          partnerId: params.partnerId!,
        },
      });
    } else {
      createContact({
        input: { ...valuesWithoutId, partnerId: params.partnerId! },
      });
    }
    partnerContactForm.resetFields();
    toggleCreateContactForm({ ...values, ...{ id: "" } });
  };

  return (
    <PageLayout Header={BudgetVersionSiteSelectorHeader} showSider>
      <section style={{ width: 768 }}>
        <PartnerContactFormModal
          form={partnerContactForm}
          showAddContactForm={showAddContactForm}
          hasPrimaryContact={
            data?.Financials2__partnerDetails.partner.contacts.some(
              (contact) => contact.isPrimary
            ) ?? false
          }
          onFormFinishFn={onPartnerContactFormFinishFn}
          toggleCreateContactForm={toggleCreateContactForm}
          showPrimaryCheckbox
          isSavingContact={isUpdatingContact || isCreatingContact}
        />
        <div>
          <Typography.Title level={4}>Edit Partner</Typography.Title>
          <Typography.Paragraph type="secondary">
            Invoices you create can be assigned to this partner.
          </Typography.Paragraph>
        </div>
        <PartnerForm
          form={partnerForm}
          contactList={contactList}
          isLoading={
            isUpdatingPartner ||
            loadingPartnerDetails ||
            fetchingPartnerDetails ||
            isUpdatingContact ||
            isCreatingContact
          }
          onFormCancelFn={onPartnerFormCancelFn}
          onFormFinishFn={onPartnerFormFinishFn}
        />
      </section>
    </PageLayout>
  );
};
