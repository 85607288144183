import React from "react";
import { Navigate, Route } from "react-router-dom";
import * as reactRouter from "react-router-dom";

import {
  BudgetVersionOverviewPage,
  BudgetCreation2Page,
  BudgetListingPage,
  AccountsReceivablePage,
  SettingsPage,
  CreatePartnersPage,
  PartnersPage,
  InvoicesPage,
  PartnerDetailPage,
  InvoiceDetailPage,
  EditPartnerPage,
  PaymentListingPage,
  PaymentDetailsPage,
  CreatePaymentPage,
} from "@pages";

const Routes = () => {
  return (
    <reactRouter.Routes>
      {/* Only here for old ost-fin-webapp servers */}
      <Route path="/" element={<Navigate to={"/finance"} />} index />
      <Route path="/finance">
        <Route path="" element={<BudgetListingPage />} index />
        <Route
          path="sites/:siteId/accounts-receivable"
          element={<AccountsReceivablePage />}
        >
          <Route path=":tab" element={<AccountsReceivablePage />} />
          <Route path="" element={<AccountsReceivablePage />} />
        </Route>
        <Route path="sites/:siteId/budgets" element={<BudgetListingPage />} />
        <Route path="sites/:siteId/">
          <Route path="settings" element={<SettingsPage />} />

          <Route
            path="invoices/all"
            element={<InvoicesPage activeTab="all" />}
          />
          <Route
            path="invoices/open"
            element={<InvoicesPage activeTab="open" />}
          />
          <Route path="invoices" element={<InvoicesPage />} />
          <Route path="invoices/:invoiceId" element={<InvoiceDetailPage />} />
          <Route path="payments/:paymentId" element={<PaymentDetailsPage />} />
          <Route path="payments" element={<PaymentListingPage />} />
          <Route
            path="payments/all"
            element={<PaymentListingPage tab="all" />}
          />
          <Route
            path="payments/open"
            element={<PaymentListingPage tab="open" />}
          />
          <Route path="payments/new" element={<CreatePaymentPage />} />
          <Route path="partners" element={<PartnersPage />} />
          <Route
            path="partners/:partnerId/edit"
            element={<EditPartnerPage />}
          />
          <Route
            path="partners/:partnerId/contacts"
            element={<PartnerDetailPage tab="contacts" />}
          />
          <Route
            path="partners/:partnerId/invoices"
            element={<PartnerDetailPage tab="invoices" />}
          />
          <Route
            path="partners/:partnerId/payments"
            element={<PartnerDetailPage tab="payments" />}
          />
          <Route
            path="partners/:partnerId"
            element={<PartnerDetailPage tab="contacts" />}
          />
          <Route path="partners/new" element={<CreatePartnersPage />} />
        </Route>
        <Route
          path="sites/:siteId/budgets/new"
          element={<BudgetCreation2Page currentStep="budget-details" />}
        />
        <Route
          path="sites/:siteId/budgets/:budgetId/versions/new"
          element={<BudgetCreation2Page currentStep="budget-details" />}
        />
        <Route path="sites/:siteId/budgets/:budgetId/versions/:budgetConfigVersionId">
          <Route path=":tab" element={<BudgetVersionOverviewPage />} />
          <Route path="" element={<BudgetVersionOverviewPage />} />
          <Route
            path="budget-details"
            element={<BudgetCreation2Page currentStep="budget-details" />}
          />
          <Route
            path="visit-charges"
            element={<BudgetCreation2Page currentStep="visit-charges" />}
          />
          <Route
            path="additional-charges"
            element={<BudgetCreation2Page currentStep="additional-charges" />}
          />
          <Route
            path="budget-summary"
            element={<BudgetCreation2Page currentStep="budget-summary" />}
          />
        </Route>
      </Route>
    </reactRouter.Routes>
  );
};

export default Routes;
