import { useAccountsReceivable } from "@app/hooks/accountsReceivable";
import {
  useAccountsReceivableSourceEventsQuery,
  useCreateAdHocChargeMutation,
  useUpdateAdHocChargeMutation,
} from "@app/service/generated";
import { toGraphQLInput } from "@lib/currency";
import {
  Drawer,
  Button,
  Space,
  FormInstance,
  notification,
} from "@reifyhealth/picasso-pkg";
import { useQueryClient } from "react-query";
import { localDateToUTCDateString } from "@lib/date";
import { AdHocReceivablesForm } from "../forms";

interface AccountsReceivableDrawerProps {
  type: "create" | "edit";
  siteId: string;
  form: FormInstance<{
    description: string;
    charge: string;
    siteTrialId: string;
    protocolVersionId: string;
    completedAt: string;
    dueDate: string;
    category: string;
    chargeType: string;
    subjectId: string;
    protocolVisitCrossVersionId: string;
    invoiceable: boolean;
  }>;
  visible: boolean;
  setVisible: React.Dispatch<boolean>;
  adHocReceivableId: string | null;
  existingAdHocReceivable: any;
  onCloseFn: any;
}

export const AccountsReceivablesDrawer = ({
  type,
  form,
  siteId,
  adHocReceivableId,
  visible,
  existingAdHocReceivable,
  onCloseFn,
}: AccountsReceivableDrawerProps) => {
  const queryClient = useQueryClient();
  const { reactQueryKey } = useAccountsReceivable();

  const { mutate: createAdHocCharge } = useCreateAdHocChargeMutation({
    onSuccess: () => {
      onCloseFn();

      notification.success({
        duration: 12,
        message: "Item has been added to Accounts Receivable",
      });

      queryClient.invalidateQueries(
        useAccountsReceivableSourceEventsQuery.getKey(reactQueryKey)
      );
    },
  });
  const { mutate: updateAdHocCharge } = useUpdateAdHocChargeMutation({
    onSuccess: () => {
      onCloseFn();

      notification.success({
        duration: 12,
        message: "Item has been updated on Accounts Receivable",
      });

      queryClient.invalidateQueries(
        useAccountsReceivableSourceEventsQuery.getKey(reactQueryKey)
      );
    },
  });

  return (
    <Drawer
      title="New Charge"
      destroyOnClose
      closable
      width={340}
      visible={visible}
      onClose={onCloseFn}
      footerStyle={{ textAlign: "right" }}
      footer={
        <Space>
          <Button
            data-testid="account-receivables-drawer-cancel-btn"
            onClick={onCloseFn}
          >
            Cancel
          </Button>
          <Button
            data-testid="accounts-receivable-new-charge-save-button"
            onClick={async () => {
              // used only for UI elements (manual validation fires off proper error states in form)
              await form?.validateFields().catch(() => null);

              const noErrorExistsInAnyField = form
                ?.getFieldsError()
                .every((field) => field.errors.length === 0);

              if (noErrorExistsInAnyField && form) {
                const existingForm = form.getFieldsValue();

                if (type === "create") {
                  createAdHocCharge({
                    input: {
                      ...existingForm,
                      siteId,
                      subjectId:
                        existingForm.chargeType === "VISIT_ACTIVITY_CHARGE"
                          ? existingForm.subjectId
                          : null,
                      category:
                        existingForm.chargeType === "ADDITIONAL_CHARGE"
                          ? existingForm.category
                          : null,
                      chargeType:
                        existingForm.chargeType as IFinancials2__ChargeType,
                      completedAt: localDateToUTCDateString(
                        existingForm.completedAt
                      ),
                      dueDate: localDateToUTCDateString(existingForm.dueDate),
                      charge: toGraphQLInput(existingForm.charge),
                    },
                  });
                } else if (type === "edit" && adHocReceivableId) {
                  updateAdHocCharge({
                    input: {
                      ...existingForm,
                      adHocReceivableId,
                      siteId,
                      subjectId:
                        existingForm.chargeType === "VISIT_ACTIVITY_CHARGE"
                          ? existingForm.subjectId
                          : null,
                      category:
                        existingForm.chargeType === "ADDITIONAL_CHARGE"
                          ? existingForm.category
                          : null,
                      chargeType:
                        existingForm.chargeType as IFinancials2__ChargeType,
                      completedAt: localDateToUTCDateString(
                        existingForm.completedAt
                      ),
                      dueDate: localDateToUTCDateString(existingForm.dueDate),
                      charge: toGraphQLInput(existingForm.charge),
                    },
                  });
                } else {
                  console.error(`No type exists: ${type}`);
                }
              }
            }}
            type="primary"
          >
            Save
          </Button>
        </Space>
      }
    >
      <AdHocReceivablesForm
        form={form}
        existingAdHocReceivable={existingAdHocReceivable}
      />
    </Drawer>
  );
};
