import NumericInput, { NumericInputProps } from "./NumericInput";
import { omit } from "lodash";

type MoneyInputProps = Omit<NumericInputProps, "decimalScale">;

export default function MoneyInput(props: MoneyInputProps) {
  return (
    <NumericInput
      min={0}
      placeholder="0.00"
      decimalScale={2}
      {...omit(props, "decimalScale")}
    />
  );
}
