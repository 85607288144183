import { useEffect, useState } from "react";
import { Typography } from "@reifyhealth/picasso-pkg";
import { css } from "@linaria/core";
import {
  SiteBudgetMenuIcon,
  ARMenuIcon,
  InvoicesIcon,
  PartnersIcon,
  SettingsIcon,
  PaymentsIcon,
} from "./common/icons/SiteBudgetsMenuIcon";
import { NavLink, useLocation } from "react-router-dom";
import { useSelectedSite } from "@app/hooks";

const StyledMenu = css`
  padding: var(--size-4);
`;

const MenuItem = css`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: var(--size-4);
  padding: var(--size-4) var(--size-6);
  border-radius: var(--size-2);
  color: var(--component-secondary-text);

  svg,
  i {
    width: var(--size-7);
    height: var(--size-7);
  }

  &.navigation-item-active {
    cursor: pointer;
    background-color: var(--component-focus-background);
    color: var(--component-primary-text);
  }
`;

export const NavMenu = () => {
  const location = useLocation();
  const { data } = useSelectedSite();
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (data?.selectedSite?.value) setHasLoaded(true);
  }, [data?.selectedSite?.value]);

  if (!hasLoaded) return null;

  return (
    <div className={StyledMenu}>
      {/* TODO: Set 'Site Budgets' nav item to be selected when the page becomes visible */}
      <NavLink
        data-testid="page-layout-site-budgets-link"
        to={`/finance/sites/${data?.selectedSite?.value}/budgets${location.search}`}
        className={({ isActive }) => {
          return isActive || location.pathname === "/finance"
            ? `${MenuItem} navigation-item-active`
            : `${MenuItem} `;
        }}
      >
        <SiteBudgetMenuIcon />
        <Typography.Text>Site Budgets</Typography.Text>
      </NavLink>
      <NavLink
        data-testid="page-layout-accounts-receivable-link"
        to={`/finance/sites/${data?.selectedSite?.value}/accounts-receivable${location.search}`}
        className={({ isActive }) =>
          isActive ? `${MenuItem} navigation-item-active` : `${MenuItem}`
        }
      >
        <ARMenuIcon />
        <Typography.Text>Accounts Receivable</Typography.Text>
      </NavLink>
      <NavLink
        data-testid="page-layout-invoices-link"
        to={`/finance/sites/${data?.selectedSite?.value}/invoices${location.search}`}
        className={({ isActive }) =>
          isActive ? `${MenuItem} navigation-item-active` : `${MenuItem}`
        }
      >
        <InvoicesIcon />
        <Typography.Text>Invoices</Typography.Text>
      </NavLink>
      <NavLink
        data-testid="page-layout-payment-link"
        to={`/finance/sites/${data?.selectedSite?.value}/payments${location.search}`}
        className={({ isActive }) =>
          isActive ? `${MenuItem} navigation-item-active` : `${MenuItem}`
        }
      >
        <PaymentsIcon />
        <Typography.Text>Payments</Typography.Text>
      </NavLink>
      <NavLink
        data-testid="page-layout-partners-link"
        to={`/finance/sites/${data?.selectedSite?.value}/partners${location.search}`}
        className={({ isActive }) =>
          isActive ? `${MenuItem} navigation-item-active` : `${MenuItem}`
        }
      >
        <PartnersIcon />
        <Typography.Text>Partners</Typography.Text>
      </NavLink>
      <NavLink
        data-testid="page-layout-settings-link"
        to={`/finance/sites/${data?.selectedSite?.value}/settings${location.search}`}
        className={({ isActive }) =>
          isActive ? `${MenuItem} navigation-item-active` : `${MenuItem}`
        }
      >
        <SettingsIcon />
        <Typography.Text>Settings</Typography.Text>
      </NavLink>
    </div>
  );
};
