import { ComponentType, ReactChild } from "react";
import { Layout } from "@reifyhealth/picasso-pkg";
import { css } from "@linaria/core";
import { NavMenu } from "./NavMenu";

const { Content, Sider } = Layout;

const MainLayout = css`
  height: 100%;
  padding: 0;
  background-color: var(--application-background-subtle);
  display: flex;
  flex-direction: column;
`;

const StyledSider = css`
  background: var(--component-background);
  border-right: 1px solid var(--component-border);
`;

const MainContent = css`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  background-color: var(--application-background-subtle);
  overflow: auto;
  padding: var(--size-8);
`;

interface PageLayoutProps {
  children: ReactChild;
  Header?: ComponentType;
  showSider?: boolean;
}

export const PageLayout = ({
  children,
  Header,
  showSider = false,
}: PageLayoutProps) => {
  if (showSider) {
    return (
      <Layout className={MainLayout}>
        {Header ? <Header /> : null}
        <Layout>
          {/* TODO: Change to 224 */}
          <Sider className={StyledSider} width={240}>
            <NavMenu />
          </Sider>
          <Content className={MainContent}>{children}</Content>
        </Layout>
      </Layout>
    );
  }

  return (
    <Layout className={MainLayout}>
      {Header ? <Header /> : null}
      <Content className={MainContent}>{children}</Content>
    </Layout>
  );
};
