const auth0ServiceConfig = () => ({
  domain: import.meta.env.VITE_AUTH0_DOMAIN as string,
  clientID: import.meta.env.VITE_AUTH0_CLIENT_ID as string,
  audience: import.meta.env.VITE_AUTH0_AUDIENCE as string,
  issuer: import.meta.env.VITE_AUTH0_ISSUER as string,
  scope: "openid profile email studyteam admin",
  responseType: "id_token" as string,
});

const config = {
  auth0ServiceConfig,
};

export default config;
