import { displayText, gt, money, Money } from "@lib/currency";
import { styled } from "@linaria/react";
import { Statistic } from "@reifyhealth/picasso-pkg";

const Wrapper = styled.section<{ hasPastDueTotal: boolean }>`
  display: inline-flex;
  gap: var(--size-4);
  margin-bottom: var(--size-4);

  .ant-statistic {
    background: var(--component-background);
    border: 1px solid var(--component-border);
    padding: var(--size-4) var(--size-6);
    border-radius: var(--border-3);
  }

  .total {
    color: var(--component-tertiary-text, #7e868c);
  }

  .past-due {
    .ant-statistic-title,
    .ant-statistic-content {
      color: ${(props: { hasPastDueTotal: boolean }) =>
        props.hasPastDueTotal ? "#e5484d" : "#7e868c"};
    }
  }
`;

interface TotalHeaderProps {
  title?: string;
  // FIXME: Remove number type after removing number from Invoices
  overallTotal?: Money | number;
  pastDueTotal?: Money | number;
  holdbackTotal?: Money | number | null;
}

export const TotalHeader = ({
  title = "Total",
  overallTotal = money(0),
  pastDueTotal = money(0),
  holdbackTotal = null,
}: TotalHeaderProps) => (
  <Wrapper hasPastDueTotal={gt(pastDueTotal, 0)}>
    <div data-testid="overall-total">
      <Statistic title={`${title}:`} value={displayText(overallTotal)} />
    </div>
    <div data-testid="past-due-total">
      <Statistic
        className="past-due"
        title={`Past Due:`}
        value={displayText(pastDueTotal)}
      />
    </div>
    {holdbackTotal === null ? null : (
      <div data-testid="holdback-total">
        <Statistic title={`Holdbacks:`} value={displayText(holdbackTotal)} />
      </div>
    )}
  </Wrapper>
);
