import { useAuth } from "@hooks/auth";
import { Typography, Button, icons } from "@reifyhealth/picasso-pkg";
import { styled } from "@linaria/react";
import { css } from "@linaria/core";

const { LockFilled } = icons;
const noAccessMailToLink = (email?: string) =>
  `mailto:support@reifyhealth.com?subject=OST Financials Access Request&body=${
    email || "A user"
  } is requesting access to the OST Financials application`;
const noAccessLearnMoreLink = "https://www.onestudyteam.com/";

const NoAccessContainer = styled.section`
  margin: 100px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 35px 40px 25px;
  width: 377px;
  height: max-content;
  background: #fff;
  text-align: center;
`;

const NoAccessActionContainer = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const NoAccessTitle = styled.h4`
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-h-4);
  line-height: var(--h-4-medium);
  color: var(--application-primary-text);
  margin-top: var(--size-6);
  margin-bottom: 0;
  padding-bottom: var(--size-6);
`;

const NoAccessParagraph = css`
  font-weight: var(--font-weight-normal);
  font-size: var(--font-size-body);
  line-height: var(--body-medium);
  color: rgb(0 0 0 / 45%);
  padding-bottom: var(--size-5);
`;

const Lock = styled.section`
  background-color: #e1e2e7;
  border-radius: 56px;
  width: 49px;
  height: 48px;
  padding: 14px;

  & > span > svg {
    height: 20px;
    width: 20px;
    color: #595959;
  }
`;

export const NoAccess = ({
  title,
  message,
}: {
  title?: string;
  message?: string;
}) => {
  const { user } = useAuth();

  return (
    <NoAccessContainer data-testid="no-access-container">
      <Lock>
        <LockFilled />
      </Lock>
      <NoAccessTitle>{title || <>No Access</>}</NoAccessTitle>
      <Typography.Paragraph className={NoAccessParagraph}>
        {message || (
          <>
            Your account <br /> <strong>{user?.email ? user.email : ""}</strong>{" "}
            does not have access to OneStudyTeam Financials.
          </>
        )}
      </Typography.Paragraph>
      <NoAccessActionContainer>
        {/* TODO: External Link is not yet defined for this application. Using sane default. */}
        <a
          href={noAccessLearnMoreLink}
          target="_blank"
          rel="noreferrer"
          style={{ paddingRight: "24px" }}
          data-testid="no-access-learn-more-link"
        >
          Learn more
        </a>
        <a
          href={noAccessMailToLink(user?.email)}
          data-testid="no-access-request-access-link"
        >
          <Button
            type="default"
            style={{ color: "#fff", backgroundColor: "#46A758" }}
          >
            Request Access
          </Button>
        </a>
      </NoAccessActionContainer>
    </NoAccessContainer>
  );
};
