import { PageLayout } from "@components/PageLayout";
import { BudgetVersionSiteSelectorHeader } from "@app/components/headers/BudgetVersionSiteSelectorHeader";
import { PartnerContactFormModal, PartnerForm } from "@app/components/forms";
import { useState } from "react";
import { Typography, useForm, Button, List } from "@reifyhealth/picasso-pkg";
import { useCreatePartnerMutation } from "@app/service/generated";
import { useParams, useSearchParams } from "react-router-dom";
import { PartnerContact } from "@app/components/cards";
import { useNavigate } from "@app/hooks";
import { sortBy } from "lodash";

export const CreatePartnersPage = () => {
  const [searchParams] = useSearchParams();
  const params = useParams();
  const navigate = useNavigate();
  const [showAddContactForm, setShowAddContactForm] = useState(false);
  const [contacts, setContacts] = useState<
    {
      id: string;
      name: string;
      emailAddress: string;
      phoneNumber: string;
      isPrimary: boolean;
    }[]
  >([]);

  const [partnerForm] = useForm<{
    name: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    zipCode: string;
  }>();

  const [partnerContactForm] = useForm<{
    id?: string;
    name: string;
    emailAddress: string;
    phoneNumber: string;
    isPrimary: boolean;
  }>();

  const toggleCreateContactForm = (contact: {
    emailAddress: string;
    id: string;
    isPrimary: boolean;
    name: string;
    phoneNumber: string;
  }) => {
    if (!showAddContactForm) {
      if (contact.id) {
        partnerContactForm.setFieldsValue(contact);
      } else {
        partnerContactForm.setFieldsValue({
          ...contact,
          ...{ id: crypto.randomUUID() },
        });
      }
    } else {
      partnerContactForm.resetFields();
    }

    setShowAddContactForm(!showAddContactForm);
  };

  const { mutate: createPartner, isLoading } = useCreatePartnerMutation({
    onError: () => {
      // TODO
    },
    onSuccess: (data) => {
      navigate(
        `/finance/sites/${params.siteId}/partners/${data.Financials2__createPartner.partnerId}?${searchParams}`
      );
    },
  });

  const onFormCancelFn = () => {
    setContacts([]);
    partnerForm.resetFields();
    navigate(-1);
  };

  const onPartnerContactFormFinishFn = (values: {
    id?: string;
    name: string;
    emailAddress: string;
    phoneNumber: string;
    isPrimary: boolean;
  }) => {
    if (values.id) {
      const id = values.id;

      if (contacts.some((contact) => contact.id === id)) {
        setContacts(
          contacts.map((contact) => {
            if (contact.id === id) {
              return { ...values, id };
            }

            return contact;
          })
        );
      } else {
        setContacts(contacts.concat([{ ...values, id }]));
      }
    }
    partnerContactForm.resetFields();
    toggleCreateContactForm({
      name: "",
      phoneNumber: "",
      isPrimary: false,
      emailAddress: "",
      id: "",
    });
  };

  const onFormFinishFn = async (values: {
    name: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    zipCode: string;
  }) => {
    await createPartner({
      input: {
        ...values,
        siteId: params.siteId!,
        contacts: contacts.map((contact) => {
          // drop id for mutation. only used to help delete
          const { id, ...rest } = contact;

          return rest;
        }),
      },
    });
  };

  const contactList = (
    <>
      <List bordered={contacts.length < 1 ? false : true}>
        {sortBy(contacts, "name").map((contact) => {
          return (
            <PartnerContact
              key={contact.emailAddress}
              isContactTiedToDraftInvoice={false}
              toggleCreateContactFormFn={() => toggleCreateContactForm(contact)}
              hasPrimaryContact={contacts.some((contact) => contact.isPrimary)}
              onCheckboxChange={(e) => {
                const { checked } = e.target;

                setContacts(
                  contacts.map((checkContact) => {
                    if (checkContact.id === contact.id) {
                      return {
                        ...checkContact,
                        ...{ isPrimary: checked },
                      };
                    }

                    return checkContact;
                  })
                );
              }}
              onDeleteClickFn={() => {
                setContacts(
                  contacts.filter(
                    (deleteContact) => deleteContact.id !== contact.id
                  )
                );
              }}
              contact={contact}
            />
          );
        })}
      </List>

      <Button
        style={{ marginTop: 8 }}
        data-testid="create-partner-add-btn"
        onClick={() =>
          toggleCreateContactForm({
            id: "",
            name: "",
            phoneNumber: "",
            emailAddress: "",
            isPrimary: false,
          })
        }
      >
        Add
      </Button>
    </>
  );

  return (
    <PageLayout Header={BudgetVersionSiteSelectorHeader} showSider>
      <section style={{ width: 768 }}>
        <PartnerContactFormModal
          form={partnerContactForm}
          showAddContactForm={showAddContactForm}
          hasPrimaryContact={contacts.some((contact) => contact.isPrimary)}
          onFormFinishFn={onPartnerContactFormFinishFn}
          toggleCreateContactForm={toggleCreateContactForm}
          showPrimaryCheckbox
          isSavingContact={false} // local state no network calls
        />
        <div>
          <Typography.Title level={4}>Create Partner</Typography.Title>
          <Typography.Paragraph type="secondary">
            Invoices you create can be assigned to this partner.
          </Typography.Paragraph>
        </div>
        <PartnerForm
          contactList={contactList}
          isLoading={isLoading}
          onFormCancelFn={onFormCancelFn}
          form={partnerForm}
          onFormFinishFn={onFormFinishFn}
        />
      </section>
    </PageLayout>
  );
};
