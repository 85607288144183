import debounce from "lodash/debounce";
import { useMemo } from "react";

/**
 * Returns a debounced function with useMemo already applied.
 *
 * NOTE: The input function should also have useMemo applied before passing in
 * to this function.
 *
 * @param func A memoized function
 * @param wait Debounce interval
 */
export default function useDebounce<T>(func: T, wait: number) {
  return useMemo(() => debounce(func as any, wait), [func, wait]) as T;
}
