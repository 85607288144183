import { isAfter, isWithinInterval } from "date-fns";
import { flow, pick } from "lodash";
import { utcDateStringToLocalDate } from "@lib/date";
import { Money, toNumber, GraphQLMoney } from "@lib/currency";
import { BudgetAggregate } from "@model/budgets/matrix";
import { getVisitsFromSchedule } from "@app/model/protocols";
import { FinancialsFlagSet } from "@app/hooks/useFlags";

/**
 * Function which will return the total cost, charge, and margins for a given
 * additional charge fee category
 */
export const deriveAdditionalChargesFeeCostChargeMargin = (
  budgetAdditionalCharges: BudgetAdditionalCharge[],
  category: string
) => {
  const specifiedFee = budgetAdditionalCharges.filter(
    (charge) => charge.category === category
  );

  if (specifiedFee.length === 0) {
    return { cost: 0, charge: 0, margin: 0 };
  }

  const feeTotalCharge = specifiedFee.reduce(sumChargesWithQuantity, 0);
  const feeTotalCost = specifiedFee.reduce(sumCostsWithQuantity, 0);

  return {
    charge: feeTotalCharge,
    cost: feeTotalCost,
    margin: feeTotalCharge - feeTotalCost,
  };
};

/**
 * Function which is used to sum up charges with quantity objects.
 * Ideally used a helper function to be plugged directly into a .reduce() function
 *
 * Quantity currently exists only within Additional Charges
 */
export function sumChargesWithQuantity(
  acc: number,
  curr: {
    quantity: number | null;
    cost: Money | GraphQLMoney | null;
    charge: Money | GraphQLMoney | null;
    overhead?: { enabled?: boolean | null; money?: GraphQLMoney | null } | null;
  }
): number {
  if (curr.charge) {
    if (curr.quantity) {
      return +(
        (toNumber(curr.charge) + toNumber(curr.overhead?.money ?? 0)) *
          curr.quantity +
        acc
      ).toFixed(2);
    }

    return +(
      toNumber(curr.charge) +
      toNumber(curr.overhead?.money ?? 0) +
      acc
    ).toFixed(2);
  }

  return acc;
}

/**
 * Function which is used to sum up cost with quantity objects.
 * Ideally used a helper function to be plugged directly into a .reduce() function
 *
 * Quantity currently exists only within Additional Charges
 */
export function sumCostsWithQuantity(
  acc: number,
  curr: {
    quantity: number | null;
    cost: Money | GraphQLMoney | null;
    charge: Money | GraphQLMoney | null;
  }
) {
  if (curr.cost) {
    if (curr.quantity) {
      return +(toNumber(curr.cost) * curr.quantity + acc).toFixed(2);
    }

    return +(toNumber(curr.cost) + acc).toFixed(2);
  }
  return acc;
}

/**
 * Function which returns the schedule existing for a given
 * protocol version. At the time of this doc protocols only ever
 * have a single schedule by design.
 *
 * This function will need to be motified to support multi-schedule
 * support for a protocol version but the UI doesn't allow it currently
 *
 * @param protocol Protocol
 * @param protocolVersionId String for version id
 * @returns Protocol schedule
 */
export function getProtocolSchedule(
  protocol: BudgetProtocol | BudgetMatrixProtocol,
  protocolVersionId?: string | null
): NonNullable<BudgetProtocolVersionSchedule> {
  return protocol?.versions
    .filter((version: any) => version.id === protocolVersionId)
    .flatMap((protocol: any) => protocol.schedules)[0]!;
}

/**
 * Function which returns the first protocol version on a given budget configuration
 *
 * @param budgetConfigVersion Budget Configuration Version
 * @returns protocol version
 */
export function getFirstProtocolVersion(
  budgetConfigVersion:
    | BudgetVersionOverviewTableRow["budgetConfigVersion"]
    | MatrixBodyDerivationInput["budget"]["configVersions"][number]
) {
  return budgetConfigVersion.protocolVersions[0];
}

/**
 * Function which returns the selected protocol version on a given budget configuration
 *
 * @param budgetConfigVersion Budget Configuration Version
 * @returns protocol version
 */
export function getSelectedProtocolVersion(
  budgetConfigVersion:
    | BudgetVersionOverviewTableRow["budgetConfigVersion"]
    | MatrixBodyDerivationInput["budget"]["configVersions"][number]
) {
  return budgetConfigVersion.protocolVersions.find(
    (version: any) =>
      version.protocolVersionId ===
      budgetConfigVersion.selectedProtocolVersionId
  );
}

/**
 * Function which returns the selected protocol version on a given budget configuration
 *
 * @param budgetConfigVersion Budget Configurdation Version
 * @returns protocol version
 */
export function getBudgetConfigurationProtocolVersion(
  budgetConfigVersion:
    | BudgetVersionOverviewTableRow["budgetConfigVersion"]
    | MatrixBodyDerivationInput["budget"]["configVersions"][number]
) {
  const { selectedProtocolVersionId } = budgetConfigVersion;

  return selectedProtocolVersionId
    ? getSelectedProtocolVersion(budgetConfigVersion)
    : getFirstProtocolVersion(budgetConfigVersion);
}

export function isWithinAvailableQuantity(
  availableCount: number,
  sentToAccountsReceivableQuantity: number | null
) {
  if (
    !sentToAccountsReceivableQuantity ||
    !availableCount ||
    (sentToAccountsReceivableQuantity && sentToAccountsReceivableQuantity === 0)
  ) {
    return true;
  }

  return sentToAccountsReceivableQuantity < availableCount;
}

export function isWithinEffectiveDates(protocolVersion: any, date: string) {
  const localDate = utcDateStringToLocalDate(date);
  const localStart = utcDateStringToLocalDate(
    protocolVersion.effectiveDateStart
  );
  const localEnd = protocolVersion.effectiveDateEnd
    ? utcDateStringToLocalDate(protocolVersion.effectiveDateEnd)
    : null;

  if (localEnd) {
    return isWithinInterval(localDate, {
      start: localStart,
      end: localEnd,
    });
  } else {
    return isAfter(localDate, localStart);
  }
}

export function findTreatmentArms({
  protocolActivityCrossVersionId,
  protocolVisitCrossVersionId,
  protocolVersionSchedule,
  flags,
}: BudgetVersionOverviewTableRow & {
  protocolActivityCrossVersionId: string;
  protocolVisitCrossVersionId: string;
  flags: FinancialsFlagSet;
}) {
  if (protocolVisitCrossVersionId) {
    const visits = getVisitsFromSchedule(protocolVersionSchedule, flags).filter(
      (visit) => visit.crossVersionId === protocolVisitCrossVersionId
    );

    if (visits?.find((v) => v.unscheduled)) return "Unscheduled Visit";

    return (
      [...new Set(visits?.map((visit) => visit.track))].join(", ") ?? "No Track"
    );
  }

  if (protocolActivityCrossVersionId) {
    const allMatchVisit = protocolVersionSchedule?.siteAssociations?.filter(
      (association) =>
        association.activityCrossVersionId === protocolActivityCrossVersionId
    );

    return (
      [
        ...new Set(
          allMatchVisit?.flatMap((visit) => {
            const visits = getVisitsFromSchedule(
              protocolVersionSchedule,
              flags
            ).filter(
              (protocolVersionVisit) =>
                protocolVersionVisit.crossVersionId ===
                visit?.visitCrossVersionId
            );

            return visits?.flatMap((visitTracks) => visitTracks.track);
          })
        ),
      ].join(", ") ?? "No Track"
    );
  }

  return "No Track";
}

export function findDescription({
  description,
  protocolActivityCrossVersionId,
  protocolVisitCrossVersionId,
  protocolVersionSchedule,
  flags,
}: BudgetVersionOverviewTableRow & {
  description?: string;
  protocolActivityCrossVersionId: string;
  protocolVisitCrossVersionId: string;
  flags: FinancialsFlagSet;
}) {
  if (description) {
    return description;
  } else {
    if (
      (protocolVisitCrossVersionId && protocolActivityCrossVersionId) ||
      protocolActivityCrossVersionId
    ) {
      return (
        protocolVersionSchedule?.siteActivities?.find(
          (activity) =>
            activity.crossVersionId === protocolActivityCrossVersionId
        )?.name ?? "[Not Set]"
      );
    } else if (protocolVisitCrossVersionId) {
      const visits = getVisitsFromSchedule(protocolVersionSchedule, flags);
      return (
        (visits &&
          visits.find(
            (visit) => visit.crossVersionId === protocolVisitCrossVersionId
          )?.name) ??
        "[Not Set]"
      );
    } else {
      return "[Not Set]";
    }
  }
}

// TODO: This may be sourced externally but now hard-coded.
export const additionalChargesCategories = [
  "Protocol Start-Up Fee",
  "Protocol Execution Fee",
  "Protocol Close-Out Fee",
  "Miscellaneous Fee",
];

export interface MatrixBodyDerivationInput {
  budget: BudgetMatrixBudget;
  budgetConfigVersionId: string;
  protocol: BudgetMatrixProtocol;
  flags: FinancialsFlagSet;
}

function addActivityQuantity(protocolActivities: any[]) {
  return protocolActivities.map((lineItem) => {
    return {
      ...lineItem,
      quantity: lineItem.visitIds.length,
      chargeType: "Activity",
    };
  });
}

function addDescription(
  lineItems: any[],
  protocolVersionSchedule: BudgetProtocolVersionSchedule,
  flags: FinancialsFlagSet
) {
  return lineItems.map((lineItem) => {
    const description = findDescription({
      ...lineItem,
      description: lineItem?.description,
      protocolActivityCrossVersionId: lineItem?.crossVersionId,
      protocolVisitCrossVersionId: lineItem?.id,
      protocolVersionSchedule,
      flags,
    });

    return {
      ...lineItem,
      description,
    };
  });
}

function addProtocolVersion(
  lineItems: any[],
  budgetConfigVersion: BudgetConfig
) {
  return lineItems.map((lineItem) => {
    return {
      ...lineItem,
      protocolVersion: budgetConfigVersion.number,
    };
  });
}

function addTreatmentArms(
  lineItems: any[],
  protocolVersionSchedule: BudgetProtocolVersionSchedule,
  flags: FinancialsFlagSet
) {
  return lineItems.map((lineItem) => {
    const treatmentArm = findTreatmentArms({
      ...lineItem,
      protocolActivityCrossVersionId: lineItem?.crossVersionId,
      protocolVisitCrossVersionId: lineItem?.id,
      protocolVersionSchedule,
      flags,
    });

    return {
      ...lineItem,
      treatmentArm,
    };
  });
}

function addVisitsToActivities(
  protocolActivities: any[],
  protocolAssociations: any[]
) {
  return protocolActivities.map((activity) => {
    const associatedActivityVisitIds = protocolAssociations
      .filter(
        (association) =>
          association.activityCrossVersionId === activity.crossVersionId
      )
      .map((associations) => associations.visitCrossVersionId);

    return {
      ...activity,
      visitIds: associatedActivityVisitIds,
    };
  });
}

function removeActivitiesWithEmptyVisits(activities: any[]) {
  return activities.filter((activity) => activity.visitIds.length > 0);
}

function getEffectiveDates(
  protocolEntity: any[],
  budgetConfigVersion: BudgetConfig
) {
  return protocolEntity.map((entity) => {
    const foundVersion = budgetConfigVersion.protocolVersions.find(
      (version) =>
        version.protocolVersionId ===
        budgetConfigVersion.selectedProtocolVersionId
    );

    if (foundVersion) {
      const effectiveDates = pick(foundVersion, [
        "effectiveDateEnd",
        "effectiveDateStart",
      ]);
      return { ...entity, ...effectiveDates };
    }
    return { ...entity };
  });
}

function getActivityCostCharge(
  protocolActivities: any[],
  budgetConfigVersion: BudgetConfig
) {
  return protocolActivities.map((protocolActivity) => {
    const activity = budgetConfigVersion.activities.find(
      (activity) => activity.id === protocolActivity.crossVersionId
    );
    const foundActivityCostCharge = pick(activity, [
      "cost",
      "charge",
      "holdback",
      "overhead",
      "derivedCharge",
      "totalCharge",
    ]);

    if (!activity) {
      return {
        ...protocolActivity,
        ...{
          charge: null,
          cost: null,
          holdback: null,
          overhead: null,
          derivedCharge: null,
          totalCharge: null,
        },
      };
    } else {
      return {
        ...protocolActivity,
        ...foundActivityCostCharge,
      };
    }
  });
}

function addCanBeAddedToReceivables(
  entities: any[],
  canBeAddedToReceivables: boolean
) {
  return entities.map((entity) => ({ ...entity, canBeAddedToReceivables }));
}

function getActivityCharges(
  budgetConfigVersion: BudgetConfig,
  protocolVersion: BudgetProtocolVersionSchedule,
  flags: FinancialsFlagSet
) {
  const protocolActivities = protocolVersion?.siteActivities ?? [];
  const protocolAssociations = protocolVersion?.siteAssociations ?? [];

  const transform = flow(
    (x) => addVisitsToActivities(x, protocolAssociations),
    removeActivitiesWithEmptyVisits,
    addActivityQuantity,
    (x) => addDescription(x, protocolVersion, flags),
    (x) => addTreatmentArms(x, protocolVersion, flags),
    (x) => getActivityCostCharge(x, budgetConfigVersion),
    (x) => addCanBeAddedToReceivables(x, false),
    (x) => addHoldback("activity", x, budgetConfigVersion),
    (x) => addOverhead("activity", x, budgetConfigVersion)
  );

  return transform(protocolActivities);
}

function getVisitCharges(
  budgetConfigVersion: BudgetConfig,
  protocolVersion: BudgetProtocolVersionSchedule,
  flags: FinancialsFlagSet
) {
  const protocolVisits = budgetConfigVersion.visits.map((visit) => ({
    ...visit,
    chargeType: "Visit",
    quantity: null,
  }));

  const transform = flow(
    (x) => addDescription(x, protocolVersion, flags),
    (x) => addTreatmentArms(x, protocolVersion, flags),
    (x) => addCanBeAddedToReceivables(x, false),
    (x) => addHoldback("visit", x, budgetConfigVersion),
    (x) => addOverhead("visit", x, budgetConfigVersion)
  );

  return transform(protocolVisits);
}

function getAdditionalCharges(
  budgetConfigVersion: BudgetConfig,
  protocolVersion: BudgetProtocolVersionSchedule,
  flags: FinancialsFlagSet
) {
  const budgetAdditionalCharges =
    budgetConfigVersion.additionalCharges.lineItems?.edges?.map((edge) => ({
      ...edge.node,
      chargeType: "Additional Charge",
      treatmentArm: null,
    })) || [];

  const transform = flow(
    (x) => addProtocolVersion(x, budgetConfigVersion),
    (x) => addDescription(x, protocolVersion, flags),
    (x) => getEffectiveDates(x, budgetConfigVersion),
    (x) => addCanBeAddedToReceivables(x, true),
    (x) => addHoldback("additionalCharge", x, budgetConfigVersion),
    (x) => addOverhead("additionalCharge", x, budgetConfigVersion)
  );

  return transform(budgetAdditionalCharges);
}

export function overviewLineItems(
  budgetConfigVersion: any,
  protocol: any,
  flags: FinancialsFlagSet
) {
  const { selectedProtocolVersionId } = budgetConfigVersion;

  const protocolVersion = selectedProtocolVersionId
    ? getProtocolSchedule(protocol, selectedProtocolVersionId)
    : getProtocolSchedule(protocol, protocol?.versions[0].id);

  return [
    ...getActivityCharges(budgetConfigVersion, protocolVersion, flags),
    ...getVisitCharges(budgetConfigVersion, protocolVersion, flags),
    ...getAdditionalCharges(budgetConfigVersion, protocolVersion, flags),
  ];
}

interface BudgetedItem {
  id?: string;
  holdbackEnabled?: boolean | null;
  holdback?: { enabled?: boolean | null } | null;
  overheadEnabled?: boolean | null;
  overhead?: {
    enabled?: boolean | null;
    money?: Money | GraphQLMoney | null;
  } | null;
}

function getHoldbackEnabled(
  budgetedItem?: BudgetedItem | null
): boolean | null | undefined {
  return budgetedItem?.holdbackEnabled ?? budgetedItem?.holdback?.enabled;
}

export function isHoldbackEnabled({
  budgetAggregate,
  visitId,
  visit,
  activity,
  visitActivity,
  additionalCharge,
  configVersion,
}: {
  budgetAggregate?: BudgetAggregate;
  visitId?: string | null;
  visitActivity?: BudgetedItem | null;
  activity?: BudgetedItem | null;
  visit?: BudgetedItem | null;
  additionalCharge?: BudgetedItem | null;
  configVersion?: { holdback?: { enabled?: boolean | null } | null } | null;
}): boolean {
  let result;
  const defaultValue = Boolean(configVersion?.holdback?.enabled);

  if (budgetAggregate && visitId) {
    const column = budgetAggregate.matrix.columns.find(
      (column) => column.visitCrossVersionId === visitId
    );
    result = getHoldbackEnabled(column) ?? defaultValue;
  } else if (visitActivity) {
    result =
      getHoldbackEnabled(visitActivity) ??
      getHoldbackEnabled(activity) ??
      defaultValue;
  } else if (activity) {
    result = getHoldbackEnabled(activity) ?? defaultValue;
  } else if (visit) {
    result = getHoldbackEnabled(visit) ?? defaultValue;
  } else if (additionalCharge) {
    // The default is always false for additional charges
    result = getHoldbackEnabled(additionalCharge) ?? false;
  } else {
    result = defaultValue;
  }

  return result;
}

function getOverheadEnabled(
  budgetedItem?: BudgetedItem | null
): boolean | null | undefined {
  return budgetedItem?.overheadEnabled ?? budgetedItem?.overhead?.enabled;
}

export function isOverheadEnabled({
  budgetAggregate,
  visitId,
  visit,
  activity,
  visitActivity,
  additionalCharge,
  configVersion,
}: {
  budgetAggregate?: BudgetAggregate;
  visitId?: string | null;
  visitActivity?: BudgetedItem | null;
  activity?: BudgetedItem | null;
  visit?: BudgetedItem | null;
  additionalCharge?: BudgetedItem | null;
  configVersion?: BudgetedItem | null;
}): boolean {
  let result;
  const defaultValue = Boolean(configVersion?.overhead?.enabled);

  if (budgetAggregate && visitId) {
    const column = budgetAggregate.matrix.columns.find(
      (column) => column.visitCrossVersionId === visitId
    );
    result = getOverheadEnabled(column) ?? defaultValue;
  } else if (visitActivity) {
    result =
      getOverheadEnabled(visitActivity) ??
      getOverheadEnabled(activity) ??
      defaultValue;
  } else if (activity) {
    result = getOverheadEnabled(activity) ?? defaultValue;
  } else if (visit) {
    result = getOverheadEnabled(visit) ?? defaultValue;
  } else if (additionalCharge) {
    result = getOverheadEnabled(additionalCharge) ?? defaultValue;
  } else {
    result = defaultValue;
  }

  return result;
}

function addHoldback(
  type: "activity" | "visit" | "additionalCharge",
  lineItems: any[],
  budgetConfigVersion: BudgetConfig
) {
  return lineItems.map((lineItem) => {
    return {
      ...lineItem,
      holdback: {
        ...lineItem.holdback,
        enabled: isHoldbackEnabled({
          [type]: lineItem,
          configVersion: budgetConfigVersion,
        }),
        percentage: budgetConfigVersion.holdback?.percentage,
      },
    };
  });
}

function addOverhead(
  type: "activity" | "visit" | "additionalCharge",
  lineItems: any[],
  budgetConfigVersion: BudgetConfig
) {
  return lineItems.map((lineItem) => {
    return {
      ...lineItem,
      overhead: {
        ...lineItem.overhead,
        enabled: isOverheadEnabled({
          [type]: lineItem,
          configVersion: budgetConfigVersion,
        }),
        percentage: budgetConfigVersion.overhead?.percentage,
      },
    };
  });
}
