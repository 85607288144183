import { EditIcon } from "@app/components/common/icons/EditIcon";
import {
  useAdjustProtocolVersionEffectiveDatesMutation,
  useBudgetVersionListingItems3Query,
} from "@app/service/generated";
import { Button, DatePicker, Typography } from "@reifyhealth/picasso-pkg";
import moment from "moment";
import { useState } from "react";
import { useQueryClient } from "react-query";
import {
  DEFAULT_DATEPICKER_FORMAT,
  formatDate,
  localDateToUTCDateString,
  utcDateStringToLocalDate,
} from "@lib/date";

const { RangePicker } = DatePicker;

interface IProtocolVersionEffectiveDatesProps {
  siteId: string;
  budgetId: string;
  budgetConfigVersionId: string;
  protocolVersionId: string;
  startDate: string;
  endDate: string | null;
}

export const ProtocolVersionEffectiveDates = ({
  siteId,
  budgetId,
  budgetConfigVersionId,
  protocolVersionId,
  startDate,
  endDate,
}: IProtocolVersionEffectiveDatesProps) => {
  const queryClient = useQueryClient();
  const [dateRange, setDateRange] = useState<any>([]);
  const [editEffectiveDates, setEditEffectiveDates] = useState(false);

  const { mutate } = useAdjustProtocolVersionEffectiveDatesMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(
        useBudgetVersionListingItems3Query.getKey({ siteId })
      );
    },
  });

  const handleDateRangeChange = (value: any) => {
    setDateRange(value);
  };

  return (
    <section className="effective-date">
      {editEffectiveDates ? (
        <RangePicker
          data-testid="effective-dates-range-picker"
          onCalendarChange={handleDateRangeChange}
          format={DEFAULT_DATEPICKER_FORMAT}
          value={dateRange}
          size="small"
          placeholder={["Start Date", "End Date"]}
        />
      ) : (
        <Typography.Text
          style={{ cursor: "pointer" }}
          data-testid="edit-effective-date-text"
          onClick={() => {
            setDateRange([
              moment(utcDateStringToLocalDate(startDate)),
              endDate && moment(utcDateStringToLocalDate(endDate)),
            ]);
            setEditEffectiveDates(!editEffectiveDates);
          }}
        >
          {formatDate(startDate)} - {endDate ? formatDate(endDate) : "onward"}
        </Typography.Text>
      )}
      {editEffectiveDates ? (
        <section>
          <Button
            data-testid="save-effective-dates-btn"
            disabled={!dateRange || !dateRange[0]}
            type="primary"
            size="small"
            onClick={() => {
              mutate(
                {
                  input: {
                    budgetId,
                    budgetConfigVersionId,
                    protocolVersionId,
                    effectiveDateEnd: dateRange[1]
                      ? localDateToUTCDateString(dateRange[1])
                      : null,
                    effectiveDateStart: localDateToUTCDateString(dateRange[0]),
                  },
                },
                {
                  onSuccess: () => {
                    setDateRange([]);
                    setEditEffectiveDates(false);
                  },
                }
              );
            }}
          >
            Save
          </Button>
        </section>
      ) : (
        <section
          data-testid="edit-effective-dates-btn"
          onClick={() => {
            setDateRange([
              moment(utcDateStringToLocalDate(startDate)),
              endDate && moment(utcDateStringToLocalDate(endDate)),
            ]);
            setEditEffectiveDates(!editEffectiveDates);
          }}
          className="edit-dates"
        >
          <EditIcon />
        </section>
      )}
    </section>
  );
};
