import { PageLayout } from "@app/components/PageLayout";
import { BudgetVersionSiteSelectorHeader } from "@app/components/headers/BudgetVersionSiteSelectorHeader";
import { PaymentsListingTable } from "@app/components/tables";
import { useParams } from "react-router-dom";
import { useGetPaymentsQuery } from "@app/service/generated";
import { Tabs, Typography, Badge } from "@reifyhealth/picasso-pkg";
import { useCallback, useState } from "react";

export const PaymentListingPage = ({ tab }: { tab?: string }) => {
  const params = useParams();
  const [overallRecordNumber, setOverallRecordNumber] = useState<number>(0);
  const [activeTab, setActiveTab] = useState<string>(tab ?? "open");
  const TabPane = Tabs.TabPane;
  const { data, isLoading, isFetching } = useGetPaymentsQuery({
    siteId: params.siteId!,
  });

  const payments = data?.Financials2__paymentList.payments || [];

  const openPayments = payments.filter(
    (payment) =>
      payment.allocationStatus !== "FULLY_ALLOCATED" &&
      payment.status === "RECEIVED"
  );

  const handleTabChange = useCallback(
    (key) => {
      // Stop re-render on tab change
      window.history.pushState(
        null,
        "",
        `/finance/sites/${params.siteId}/payments/${key}`
      );
      setActiveTab(key);
    },
    [params.siteId]
  );

  return (
    <PageLayout Header={BudgetVersionSiteSelectorHeader} showSider>
      <Tabs
        defaultActiveKey={params.tab || activeTab}
        onTabClick={handleTabChange}
        destroyInactiveTabPane
      >
        <TabPane
          tab={
            <Typography.Text data-testid="open-payments">
              Open{" "}
              <Badge
                overflowCount={100}
                style={{ backgroundColor: "#D7DBDF", color: "#000000A6" }}
                count={overallRecordNumber}
                showZero
              />
            </Typography.Text>
          }
          key="open"
        >
          <PaymentsListingTable
            payments={openPayments}
            isTableLoading={isLoading || isFetching}
            setOverallRecordNumberFn={setOverallRecordNumber}
          />
        </TabPane>
        <TabPane
          tab={
            <Typography.Text data-testid="all-payments">
              All Payments
            </Typography.Text>
          }
          key="all"
        >
          <PaymentsListingTable
            payments={payments}
            isTableLoading={isLoading || isFetching}
          />
        </TabPane>
      </Tabs>
    </PageLayout>
  );
};
