import { CaretDownRegular } from "@reifyhealth/picasso-icons";
import {
  Button,
  Dropdown,
  Menu,
  Typography,
  message,
} from "@reifyhealth/picasso-pkg";
import { selectedStatuses, transformForInvoiceActions } from "../helpers";
import { useParams } from "react-router-dom";
import { PaymentActionsModal } from "./PaymentActionsModal";
import {
  useGetInvoiceListQuery,
  useMarkInvoicesAsPaidMutation,
  useMarkInvoicesAsVoidMutation,
} from "@app/service/generated";
import { useQueryClient } from "react-query";
import * as invoiceLib from "@model/invoices";

const menu = (
  siteId: string,
  invoices: Invoice[],
  selectedRowKeys: any,
  setSelectedRowKeysFn: any,
  hasSelected: boolean,
  onPaidClickFn: Function,
  onVoidClickFn: Function
) => {
  const selectedInvoiceStatuses = selectedStatuses(invoices, selectedRowKeys);

  const shouldPaidBeDisabled =
    !hasSelected || selectedInvoiceStatuses.some(invoiceLib.hasDisabledActions);
  const shouldVoidBeDisabled =
    !hasSelected || selectedInvoiceStatuses.some(invoiceLib.hasDisabledActions);

  return (
    <Menu>
      <PaymentActionsModal
        siteId={siteId}
        onPaidClickFn={onPaidClickFn}
        invoices={invoices}
        selectedRowKeys={selectedRowKeys}
        shouldPaidBeDisabled={shouldPaidBeDisabled}
      />
      <Menu.Item
        key="mark-as-void"
        disabled={shouldVoidBeDisabled}
        onClick={() => {
          const { tableIds } = transformForInvoiceActions(
            invoices,
            selectedRowKeys
          );

          onVoidClickFn({
            input: {
              siteId,
              invoices: tableIds,
            },
          });
        }}
      >
        <Typography.Text
          data-testid="invoices-mark-as-void-btn"
          disabled={shouldVoidBeDisabled}
        >
          Mark as Void
        </Typography.Text>
      </Menu.Item>
    </Menu>
  );
};

interface MoreActionsMenuProps {
  invoices: any;
  selectedRowKeys: any;
  setSelectedRowKeysFn: any;
  hasSelected: boolean;
  hasDisallowedStatuses: boolean;
  reactQueryKey: { siteId: string };
}

export const MoreActionsMenu = ({
  invoices,
  selectedRowKeys,
  setSelectedRowKeysFn,
  hasSelected,
  reactQueryKey,
  hasDisallowedStatuses,
}: MoreActionsMenuProps) => {
  const queryClient = useQueryClient();

  const { mutate: markInvoicesAsPaid } = useMarkInvoicesAsPaidMutation({
    onMutate: () => {
      // TODO: complete.
    },
    onError: () => {
      // TODO: complete.
    },
    onSuccess: (resp) => {
      if (resp.data.success) {
        message.success("Items marked as paid");

        setSelectedRowKeysFn([]); // clear select

        queryClient.invalidateQueries(
          useGetInvoiceListQuery.getKey(reactQueryKey)
        );
      }
    },
  });

  const { mutate: markInvoicesAsVoid } = useMarkInvoicesAsVoidMutation({
    onMutate: () => {
      // TODO: complete.
    },
    onError: () => {
      // TODO: complete.
    },
    onSuccess: (resp) => {
      if (resp.data.success) {
        message.success("Items marked as void");

        setSelectedRowKeysFn([]); // clear select

        queryClient.invalidateQueries(
          useGetInvoiceListQuery.getKey(reactQueryKey)
        );
      }
    },
  });

  const params = useParams();
  const siteId = params.siteId!;

  return (
    <Dropdown
      disabled={!hasSelected || hasDisallowedStatuses}
      overlay={menu(
        siteId,
        invoices,
        selectedRowKeys,
        setSelectedRowKeysFn,
        hasSelected,
        markInvoicesAsPaid,
        markInvoicesAsVoid
      )}
      trigger={["click"]}
    >
      <Button
        type="default"
        data-testid="more-actions-menu"
        style={{ display: "flex" }}
      >
        <div>Payment Actions</div>
        <div style={{ paddingTop: "3px", marginLeft: "6px" }}>
          <CaretDownRegular style={{ width: "16px", height: "16px" }} />
        </div>
      </Button>
    </Dropdown>
  );
};
