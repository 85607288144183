import { Typography } from "@reifyhealth/picasso-pkg";
import { EditIcon } from "@app/components/common/icons/EditIcon";
import { useEffect, useState } from "react";

interface IBudgetVersionAdditionalChargeDescriptionProps {
  description: Maybe<string>;
  onChangeFn: (value: string) => void;
}

export const BudgetVersionAdditionalChargeDescription = ({
  description,
  onChangeFn,
}: IBudgetVersionAdditionalChargeDescriptionProps) => {
  const [editing, setEditing] = useState(false);
  const [text, setText] = useState(description || "Add Description");

  useEffect(() => {
    setText(description || "Add Description");
  }, [description]);

  const handleEditClick = () => {
    setText(description || "");
    setEditing(true);
  };

  return (
    <section style={{ padding: "8px 16px" }}>
      <Typography.Text
        data-testid="additional-charges-description-edit-text"
        editable={{
          tooltip: false,
          icon: <EditIcon />,
          onCancel() {
            setText(description || "Add Description");
            setEditing(false);
          },
          onEnd() {
            setEditing(false);
          },
          editing,
          onChange: (value) => {
            setText(value);
            onChangeFn(value);
          },
        }}
        onClick={handleEditClick}
      >
        {text ?? description}
      </Typography.Text>
    </section>
  );
};
