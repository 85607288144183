import {
  icons,
  Col,
  Input,
  Row,
  Table,
  Modal,
  DatePicker,
  Space,
  Button,
  Form,
  useForm,
} from "@reifyhealth/picasso-pkg";
import { columns } from "./columns";
import { css } from "@linaria/core";
import { useEffect, useState } from "react";
import { useSendToReceivablesMutation } from "@app/service/generated";
import { useParams } from "react-router-dom";
import { notification } from "@reifyhealth/picasso-pkg";
import { useQueryClient } from "react-query";
import { styled } from "@linaria/react";
import moment from "moment";
import * as ar from "@model/accountsReceivable";
import {
  getSelectedProtocolVersion,
  isWithinEffectiveDates,
} from "@model/budgets";
import {
  DEFAULT_DATEPICKER_FORMAT,
  formatDate,
  localDateToUTCDateString,
} from "@lib/date";

const budgetVersionLineItemTable = css`
  .ant-table-cell {
    border: 0 !important;
  }

  td.ant-table-cell {
    border-bottom: 1px solid var(--component-background-subtle) !important;
  }
`;

const { SearchOutlined } = icons;

const ModalActions = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const sendToReceivablesDescriptionStyle = css`
  margin-bottom: 6px;
`;

const defaultDueDateStyle = css`
  padding-top: 0;
  color: var(--component-tertiary-text);
  font-size: 15px;
`;

const completedDateFormItemStyle = css`
  margin-bottom: 6px !important;
`;

const datePickerStyle = css`
  width: 100%;
`;

export const SendToReceivablesFormModal = ({
  form,
  toggleSendToReceivablesForm,
  showSendToReceivablesForm,
  onFormFinishFn,
  budget,
  budgetProtocolVersion,
  isSending,
}: Record<string, any>) => {
  const [lineItem, setLineItem] = useState<{ completedDate: string } | any>();
  const completedDateValue = form.getFieldValue("completedDate");

  useEffect(() => {
    if (!lineItem && completedDateValue) {
      const completedDate = localDateToUTCDateString(completedDateValue);
      setLineItem({
        completedDate,
        defaultDueDate: ar.defaultDueDate(completedDate, budget),
      });
    }
  }, [form, budget, lineItem, completedDateValue]);

  return (
    <Modal
      title="Send to Receivables"
      visible={showSendToReceivablesForm}
      destroyOnClose
      footer={false}
      onCancel={() => {
        setLineItem(null);
        form.resetFields();
        toggleSendToReceivablesForm();
      }}
      okText="Send"
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={async () => {
          const result = {
            ...form.getFieldsValue(),
            ...showSendToReceivablesForm,
          };
          await onFormFinishFn(result);
          toggleSendToReceivablesForm();
        }}
        initialValues={{
          completedDate: moment(),
        }}
      >
        <p className={sendToReceivablesDescriptionStyle}>
          Include a Completed Date to calculate when this line Item will be due.
          This budget has <strong>Net {budget.paymentTermDays}</strong> payment
          terms.
        </p>
        <Form.Item
          rules={[{ required: true, message: "Please enter a completed date" }]}
          name="completedDate"
          className={completedDateFormItemStyle}
        >
          <DatePicker
            onChange={(date) => {
              form.setFields([{ value: date, name: "completedDate" }]);
              const completedDate = date
                ? localDateToUTCDateString(date)
                : null;
              setLineItem({
                completedDate,
                defaultDueDate: ar.defaultDueDate(completedDate, budget),
              });
            }}
            placeholder="Completed Date"
            className={datePickerStyle}
            format={DEFAULT_DATEPICKER_FORMAT}
            disabledDate={(current) =>
              !isWithinEffectiveDates(
                budgetProtocolVersion,
                localDateToUTCDateString(current)
              )
            }
          />
        </Form.Item>
        {lineItem?.defaultDueDate ? (
          <p className={defaultDueDateStyle}>
            Due on {formatDate(lineItem.defaultDueDate)}
          </p>
        ) : null}
        <ModalActions>
          <Space>
            <Button
              disabled={isSending}
              htmlType="reset"
              data-testid="budget-version-line-items-table-cancel-btn"
              onClick={() => {
                setLineItem(null);
                form.resetFields();
                toggleSendToReceivablesForm();
              }}
            >
              Cancel
            </Button>
            <Button
              data-testid="budget-version-line-items-table-send-btn"
              disabled={isSending}
              type="primary"
              htmlType="submit"
            >
              Send
            </Button>
          </Space>
        </ModalActions>
      </Form>
    </Modal>
  );
};

interface BudgetVersionLineItemsTableProps {
  lineItems?: any[];
  protocolVersionId: string;
  siteTrialId: string;
  budget: { paymentTermDays: number | null };
  budgetConfiguration: any;
  budgetVersionOverviewKey: (
    | string
    | Exact<{
        budgetId: string;
        budgetConfigVersionId: string;
        siteTrialId: string;
      }>
  )[];
  loading: boolean;
  disableSendToReceivables: boolean;
}

export const BudgetVersionLineItemsTable = ({
  lineItems,
  protocolVersionId,
  siteTrialId,
  budget,
  budgetConfiguration,
  budgetVersionOverviewKey,
  disableSendToReceivables,
  loading,
}: BudgetVersionLineItemsTableProps) => {
  const params = useParams();
  const queryClient = useQueryClient();

  const budgetProtocolVersion = getSelectedProtocolVersion(budgetConfiguration);

  const { mutate: sendToReceivables, isLoading: creatingReceivable } =
    useSendToReceivablesMutation({
      onError: () => {
        notification.error({
          duration: 12,
          message: "Item was not sent to Accounts Receivable",
        });
      },
      onSuccess: (data) => {
        notification.success({
          duration: 12,
          message: (
            <span
              data-accounts-receivable-line-item-id={
                data.Financials2__sendToReceivables.arLineItemId
              }
            >
              Item has been sent to Accounts Receivable
            </span>
          ),
        });
        queryClient.invalidateQueries(budgetVersionOverviewKey);
      },
    });

  const [searchTerm, setSearchTerm] = useState("");

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearchTerm(e.target.value);
  };

  const filteredLineItems = lineItems?.filter((item) =>
    item.description?.toLowerCase().includes(searchTerm.trim().toLowerCase())
  );

  const [sendToReceivablesForm] = useForm<{ completedDate: Date }>();
  const [showSendToReceivablesForm, setShowSendToReceivablesForm] = useState<{
    additionalChargeId: string;
  } | null>(null);

  const handleSendToReceivablesClick = (additionalChargeId: string) => {
    setShowSendToReceivablesForm({ additionalChargeId });
    sendToReceivablesForm.setFieldsValue({ completedDate: moment() });
  };

  const handleSendToReceivablesFormFinish = async ({
    additionalChargeId,
    completedDate,
  }: {
    completedDate: Date;
    additionalChargeId: string;
  }) => {
    await sendToReceivables({
      input: {
        siteId: params.siteId!,
        siteTrialId,
        protocolVersionId,
        additionalChargeId,
        completedAt: localDateToUTCDateString(completedDate),
      },
    });
  };

  const sortData = (data: any[] | undefined) => {
    if (!data) {
      return data;
    }

    return data
      .sort((a, b) => +b.canBeAddedToReceivables - +a.canBeAddedToReceivables)
      .sort((a, b) => {
        a = a.category ? a.category.toLowerCase() : null;
        b = b.category ? b.category.toLowerCase() : null;

        return b > a ? 1 : a > b ? -1 : 0;
      });
  };

  return (
    <>
      <SendToReceivablesFormModal
        form={sendToReceivablesForm}
        showSendToReceivablesForm={showSendToReceivablesForm}
        toggleSendToReceivablesForm={() => setShowSendToReceivablesForm(null)}
        onFormFinishFn={handleSendToReceivablesFormFinish}
        budget={budget}
        budgetProtocolVersion={budgetProtocolVersion}
        isSending={creatingReceivable}
      />
      <Table<BudgetVersionOverviewTableRow>
        className={budgetVersionLineItemTable}
        bordered
        loading={loading || creatingReceivable}
        scroll={{ y: "calc(100vh - 310px)" }}
        pagination={false}
        rowKey={crypto.randomUUID()}
        data-testid="budget-version-line-items-table"
        title={() => {
          return (
            <Row justify="space-between">
              <Col>
                <Input
                  data-testid="search-budget-version-input"
                  allowClear
                  style={{ width: "228px" }}
                  prefix={<SearchOutlined className="search-icon" />}
                  placeholder="Search Line Items"
                  onChange={onChange}
                />
              </Col>
              <Col />
            </Row>
          );
        }}
        columns={columns({
          onSendToReceivablesClick: handleSendToReceivablesClick,
          disableSendToReceivables,
        })}
        dataSource={sortData(searchTerm ? filteredLineItems : lineItems)}
        size="small"
      />
    </>
  );
};
