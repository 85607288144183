import { Fragment, ReactNode } from "react";
import { PageLayout } from "@components/PageLayout";
import { Modal, useForm, icons, notification } from "@reifyhealth/picasso-pkg";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { slugify } from "@lib/string";
import { StepHeader } from "./BudgetCreation2/StepHeader";
import { BudgetCreationFooter } from "@components/footers";
import {
  useCreateBudgetMutation,
  useCreateBudgetVersionMutation,
} from "@service/generated";
import { BudgetMatrix } from "./BudgetCreation2/BudgetMatrix";
import { useStore } from "@app/store";
import { BudgetVersionSummary } from "./BudgetCreation2/BudgetVersionSummary";
import { localDateToUTCDateString } from "@lib/date";
import { useNavigate } from "@app/hooks";
import { BudgetDetailsForm } from "@app/components/forms/BudgetDetailsForm";
import { AdditionalCharges } from "./BudgetCreation2/AdditionalCharges";

interface BudgetCreationPageProps {
  currentStep:
    | "budget-details"
    | "visit-activity-charges"
    | "visit-charges"
    | "additional-charges"
    | "budget-summary";
}

const { ExclamationCircleFilled } = icons;

export const BudgetCreation2Page = ({
  currentStep,
}: BudgetCreationPageProps) => {
  const navigate = useNavigate();
  const params = useParams();
  const [search] = useSearchParams();
  const setAutosaving = useStore((store) => store.setAutosaving);
  const { search: searchParams } = useLocation();

  // Mutations
  const { mutate: createBudget } = useCreateBudgetMutation({
    onMutate: () => setAutosaving(true),
    onError: () => setAutosaving(false),
    onSuccess: () => setAutosaving(false),
  });
  const { mutate: createBudgetVersion } = useCreateBudgetVersionMutation({
    onMutate: () => setAutosaving(true),
    onError: () => setAutosaving(false),
    onSuccess: () => setAutosaving(false),
  });

  const [form] = useForm<
    Partial<{
      budgetName: string;
      effectiveDates: [any, any];
      trialId: string;
      protocolVersionId: string;
      startDate: any;
      endDate: any;
      reason: string;
      paymentTermDays: number;
      holdbackPercentage: string;
      overheadPercentage: string;
    }>
  >();

  const { confirm } = Modal;

  const showErrorModal = (
    fields: string[],
    type: "budget" | "budget version"
  ) => {
    const lastRequiredField = fields.pop();

    confirm({
      icon: null,
      content: (
        <section style={{ display: "flex" }}>
          <section>
            <ExclamationCircleFilled style={{ color: "red" }} />
          </section>
          <section style={{ paddingLeft: "0.5rem" }}>
            You must provide a{" "}
            {fields.map((field, index) => (
              <>
                <strong>{field}</strong>
                {fields.length - 1 !== index ? ", " : ","}
              </>
            ))}{" "}
            and <strong>{lastRequiredField}</strong> before saving this {type}.
            Would you like to exit without saving this {type}?
          </section>
        </section>
      ),
      okText: "Exit Without Saving",
      okType: "danger",
      okButtonProps: {
        color: "red",
        disabled: false,
      },
      cancelText: "Cancel",
      onOk() {
        setAutosaving(false);
        navigate(`/finance/sites/${params.siteId}/budgets`);
      },
      onCancel() {},
    });
  };

  const saveAction = async (action: "next" | "exit", stepNumber?: number) => {
    setAutosaving(true);

    const {
      budgetName,
      trialId,
      effectiveDates,
      protocolVersionId,
      endDate,
      startDate,
      paymentTermDays,
      reason,
      holdbackPercentage,
      overheadPercentage,
    } = form.getFieldsValue();

    if (action === "exit") {
      if (
        params.budgetId &&
        (!reason || !budgetName || !trialId || !protocolVersionId || !startDate)
      ) {
        showErrorModal(
          ["Reason", "Start Date", "Protocol Version"],
          "budget version"
        );
      } else {
        if (!budgetName || !trialId || !protocolVersionId || !startDate) {
          showErrorModal(
            ["Budget Name", "Trial", "Protocol Version", "Start Date"],
            "budget"
          );
        }
      }
    }

    // used only for UI elements (manual validation fires off proper error states in form)
    await form.validateFields().catch(() => null);

    const noErrorExistsInAnyField = form
      .getFieldsError()
      .every((field) => field.errors.length === 0);

    if (params.budgetId) {
      if (
        noErrorExistsInAnyField &&
        budgetName &&
        protocolVersionId &&
        trialId &&
        startDate &&
        reason &&
        params.siteId &&
        params.budgetId
      ) {
        createBudgetVersion(
          {
            input: {
              protocolVersionId,
              budgetId: params.budgetId,
              reason,
              holdbackPercentage,
              overheadPercentage,
              effectiveDateStart: localDateToUTCDateString(startDate),
              effectiveDateEnd: endDate
                ? localDateToUTCDateString(endDate)
                : null,
            },
          },
          {
            onError(error) {
              // TODO: Will introduce error handling eventually
              console.error(error);
            },
            onSuccess(data) {
              if (action === "exit") {
                navigate(
                  `/finance/sites/${params.siteId}/budgets/${data.budget.id}/versions/${data.budget.budgetConfigVersionId}`
                );
              } else {
                navigate(
                  `/finance/sites/${params.siteId}/budgets/${
                    data.budget.id
                  }/versions/${data.budget.budgetConfigVersionId}/${slugify(
                    steps[stepNumber!].title
                  )}${searchParams}`
                );
              }
            },
          }
        );
      } else {
        setAutosaving(false);
      }
    } else {
      if (
        noErrorExistsInAnyField &&
        budgetName &&
        protocolVersionId &&
        trialId &&
        startDate &&
        params.siteId
      ) {
        createBudget(
          {
            input: {
              ...{
                name: budgetName,
                siteId: params.siteId,
                siteTrialId: trialId.trim(),
                protocolVersionId: protocolVersionId.trim(),
                paymentTermDays,
                holdbackPercentage,
                overheadPercentage,
              },
              ...(effectiveDates && effectiveDates[0] && effectiveDates[1]
                ? {
                    effectiveDateStart: localDateToUTCDateString(
                      effectiveDates[0]
                    ),
                    effectiveDateEnd: effectiveDates[1]
                      ? localDateToUTCDateString(effectiveDates[1])
                      : null,
                  }
                : {
                    effectiveDateEnd: endDate
                      ? localDateToUTCDateString(endDate)
                      : null,
                    effectiveDateStart: localDateToUTCDateString(startDate),
                  }),
            } as IFinancials2__CreateBudgetInput,
          },
          {
            onError(error) {
              // TODO: Will introduce error handling eventually
              console.error(error);
            },
            onSuccess(data) {
              if (action === "exit") {
                navigate(
                  `/finance/sites/${params.siteId}/budgets/${data.budget.id}/versions/${data.budget.budgetConfigVersionId}`
                );
              } else {
                navigate(
                  `/finance/sites/${params.siteId}/budgets/${
                    data.budget.id
                  }/versions/${data.budget.budgetConfigVersionId}/${slugify(
                    steps[stepNumber!].title
                  )}${searchParams}`
                );
              }
            },
          }
        );
      } else {
        setAutosaving(false);
      }
    }
  };

  interface Step {
    title: string;
    subtitle: string;
    stepNumber: number;
    statistics: boolean;
    content: ReactNode;
    actions: {
      saveAndExit: () => void;
      next: (stepNumber: number) => void;
      back: (stepNumber: number) => void;
    };
  }

  const steps: Step[] = [
    {
      title: "Budget Details",
      subtitle:
        "Describe your budget by providing a name and choosing a trial to associate with it.",
      stepNumber: 1,
      statistics: false,
      content: (
        <BudgetDetailsForm
          isBudgetVersionCreation={
            !!search.get("budget-name") && !!search.get("trial-id")
          }
          siteId={params.siteId!}
          form={form}
        />
      ),
      actions: {
        saveAndExit: () => saveAction("exit"),
        next: async (stepNumber: number) => saveAction("next", stepNumber),
        back: (stepNumber) => {
          navigate(
            `/finance/sites/${params.siteId}/budgets/${
              params.budgetId
            }/versions/${params.budgetConfigVersionId}/${slugify(
              steps[stepNumber - 2].title
            )}`
          );
        },
      },
    },
    {
      title: "Visit Charges",
      subtitle:
        "Define the cost of each visit or activity and include any invoiceable charges.",
      stepNumber: 2,
      statistics: true,
      content: <BudgetMatrix />,
      actions: {
        saveAndExit: () => {
          navigate(
            `/finance/sites/${params.siteId}/budgets/${params.budgetId}/versions/${params.budgetConfigVersionId}`
          );
        },
        next: (stepNumber) => {
          navigate(
            `/finance/sites/${params.siteId}/budgets/${
              params.budgetId
            }/versions/${params.budgetConfigVersionId}/${slugify(
              steps[stepNumber].title
            )}${searchParams}`
          );
        },
        back: (stepNumber) => {
          navigate(
            `/finance/sites/${params.siteId}/budgets/${
              params.budgetId
            }/versions/${params.budgetConfigVersionId}/${slugify(
              steps[stepNumber - 2].title
            )}${searchParams}`
          );
        },
      },
    },
    {
      title: "Additional Charges",
      subtitle: "Include any additional charges for this budget.",
      stepNumber: 3,
      statistics: true,
      content: (
        <AdditionalCharges
          budgetId={params.budgetId!}
          budgetConfigVersionId={params.budgetConfigVersionId!}
        />
      ),
      actions: {
        saveAndExit: () => {
          navigate(
            `/finance/sites/${params.siteId}/budgets/${params.budgetId}/versions/${params.budgetConfigVersionId}`
          );
        },
        next: (stepNumber) => {
          navigate(
            `/finance/sites/${params.siteId}/budgets/${
              params.budgetId
            }/versions/${params.budgetConfigVersionId}/${slugify(
              steps[stepNumber].title
            )}${searchParams}`
          );
        },
        back: (stepNumber) => {
          navigate(
            `/finance/sites/${params.siteId}/budgets/${
              params.budgetId
            }/versions/${params.budgetConfigVersionId}/${slugify(
              steps[stepNumber - 2].title
            )}${searchParams}`
          );
        },
      },
    },
    {
      title: "Budget Summary",
      subtitle: "Review your budget details below.",
      stepNumber: 4,
      statistics: false,
      content: params.budgetId && <BudgetVersionSummary />,
      actions: {
        saveAndExit: () => {
          navigate(
            `/finance/sites/${params.siteId}/budgets/${params.budgetId}/versions/${params.budgetConfigVersionId}`
          );
        },
        next: (_) => {
          notification.success({ message: "The budget has been saved" });
          navigate(
            `/finance/sites/${params.siteId}/budgets/${params.budgetId}/versions/${params.budgetConfigVersionId}`
          );
        },
        back: (stepNumber) => {
          navigate(
            `/finance/sites/${params.siteId}/budgets/${
              params.budgetId
            }/versions/${params.budgetConfigVersionId}/${slugify(
              steps[stepNumber - 2].title
            )}${searchParams}`
          );
        },
      },
    },
  ];

  return (
    <PageLayout>
      <>
        {steps
          .filter((step) => slugify(step.title) === currentStep)
          .map((step) => (
            <Fragment key={step.stepNumber}>
              <StepHeader
                step={step.stepNumber}
                title={step.title}
                subtitle={step.subtitle}
                totalSteps={steps.length}
                statistics={step.statistics}
              />
              {step.content}
              <BudgetCreationFooter
                step={step.stepNumber}
                nextStepTitle={steps[step.stepNumber]?.title || ""}
                finalStep={steps.length === step.stepNumber}
                onBackBtnClick={() => step.actions.back(step.stepNumber)}
                onNextBtnClick={() => step.actions.next(step.stepNumber)}
                onSaveAndExitBtnClick={step.actions.saveAndExit}
              />
            </Fragment>
          ))}
      </>
    </PageLayout>
  );
};
