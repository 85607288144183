import { EditIcon } from "@app/components/common/icons/EditIcon";
import {
  useBudgetVersionListingItems3Query,
  useUpdateBudgetMetadataMutation,
} from "@app/service/generated";
import { css } from "@linaria/core";
import { Typography, icons } from "@reifyhealth/picasso-pkg";
import { useQueryClient } from "react-query";

const { MedicineBoxOutlined } = icons;

interface BudgetInfoProps {
  name: string;
  trialName: string;
  siteId: string;
  budgetId: string;
}

const BudgetTrialName = css`
  color: var(--component-secondary-text);
`;

export const BudgetInfo = ({
  name,
  siteId,
  trialName,
  budgetId,
}: BudgetInfoProps) => {
  const queryClient = useQueryClient();

  const { mutate } = useUpdateBudgetMetadataMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(
        useBudgetVersionListingItems3Query.getKey({ siteId })
      );
    },
  });
  return (
    <section className="budget-info">
      <section className="budget-trial" data-testid="budget-trial-name">
        <MedicineBoxOutlined className={BudgetTrialName} />
        <Typography.Text className={BudgetTrialName}>
          {trialName}
        </Typography.Text>
      </section>
      <section className="budget-name">
        <Typography.Text
          data-testid="budget-name-editable-txt"
          editable={{
            tooltip: false,
            icon: <EditIcon />,
            onChange: (value) => {
              mutate({ input: { budgetId, name: value } });
            },
          }}
        >
          {name}
        </Typography.Text>
      </section>
    </section>
  );
};
