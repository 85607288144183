export const IncludedVisitActivityIcon = () => {
  return (
    <svg
      data-testid="included-visit-activity-icon"
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.2507 0.96875H12.1585C12.0054 0.96875 11.8601 1.03906 11.7663 1.15937L5.32416 9.32031L2.2351 5.40625C2.18837 5.34692 2.1288 5.29895 2.06088 5.26593C1.99295 5.23292 1.91843 5.21572 1.84291 5.21563H0.750722C0.646034 5.21563 0.588222 5.33594 0.652284 5.41719L4.93197 10.8391C5.13197 11.0922 5.51635 11.0922 5.71791 10.8391L13.3492 1.16875C13.4132 1.08906 13.3554 0.96875 13.2507 0.96875Z"
        fill="#171717"
      />
    </svg>
  );
};
