import { useEffect } from "react";
import { useConsentState } from "@hooks/useConsentState";
import {
  initializePendo,
  updateOptions,
  getPendoInstance,
  isPendoAvailable,
} from "pendo/pendo";
import { useAuth } from "@hooks/auth";

/** Component used to initialize Pendo and set proper flag for the financials-service UI app */
export const Pendo = () => {
  const consentState = useConsentState();
  const { user } = useAuth();

  useEffect(() => {
    if (consentState && isPendoAvailable() && user) {
      initializePendo(getPendoInstance(), consentState, user.id);
    }
  }, [consentState, user]);

  useEffect(() => {
    if (consentState && isPendoAvailable() && !!user) {
      updateOptions(getPendoInstance(), user);
    }
  }, [consentState, user]);

  return null;
};

export default Pendo;
