import { camelCase, sortBy } from "lodash";
import { BudgetAdditionalChargeSlice, StoreCreator } from "../types";
import {
  additionalChargesCategories,
  deriveAdditionalChargesFeeCostChargeMargin,
  sumChargesWithQuantity,
  sumCostsWithQuantity,
} from "@model/budgets";
import { round, toNumber } from "@lib/currency";

/**
 * @deprecated
 */
export const createBudgetAdditionalChargesSlice: StoreCreator<
  BudgetAdditionalChargeSlice
> = (set) => ({
  budgetAdditionalCharges: [],
  totals: {
    protocolStartUpFee: {
      cost: 0,
      charge: 0,
      margin: 0,
    },
    protocolExecutionFee: {
      cost: 0,
      charge: 0,
      margin: 0,
    },
    protocolCloseOutFee: {
      cost: 0,
      charge: 0,
      margin: 0,
    },
    miscellaneousFee: {
      cost: 0,
      charge: 0,
      margin: 0,
    },
    overallCostChargeMargin: {
      cost: 0,
      charge: 0,
      margin: 0,
    },
  },
  removeBudgetAdditionalCharge: (id: string) =>
    set((state) => {
      const chargesWithRemovedEntry = state.budgetAdditionalCharges.filter(
        (charge) => charge.id !== id
      );

      return {
        ...state,
        ...{ budgetAdditionalCharges: chargesWithRemovedEntry },
      };
    }),
  setBudgetAdditionalCharges: (
    budgetAdditionalCharges: BudgetAdditionalCharge[]
  ) =>
    set((state) => {
      const sortedBudgetAdditionalCharges = sortBy(
        budgetAdditionalCharges,
        "createdAt"
      );

      const feeTotals = additionalChargesCategories.map((category) => ({
        [camelCase(category)]: deriveAdditionalChargesFeeCostChargeMargin(
          budgetAdditionalCharges,
          category
        ),
      }));

      const totalCost = budgetAdditionalCharges.reduce(sumCostsWithQuantity, 0);
      const totalCharge = budgetAdditionalCharges.reduce(
        sumChargesWithQuantity,
        0
      );

      return {
        ...state,
        ...{
          budgetAdditionalCharges: sortedBudgetAdditionalCharges,
          totals: {
            ...Object.assign({}, ...feeTotals),
            overallCostChargeMargin: {
              cost: toNumber(round(totalCost)),
              charge: toNumber(round(totalCharge)),
              margin: toNumber(round(totalCharge - totalCost)),
            },
          },
        },
      };
    }),
  updateBudgetAdditionalCharge: (id: string, entity: string, value: any) =>
    set((state) => {
      const additionalChargesWithUpdatedEntity =
        state.budgetAdditionalCharges.map((charge) => {
          if (charge.id === id) {
            return { ...charge, [entity]: value };
          }

          return charge;
        });

      return {
        ...state,
        ...{ budgetAdditionalCharges: additionalChargesWithUpdatedEntity },
      };
    }),
});
