import { useStore } from "@app/store";
import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import { icons } from "@reifyhealth/picasso-pkg";
const { LoadingOutlined, CheckOutlined } = icons;

const Saved = css`
  transition: 1.5s;
  opacity: 0.5;
  animation-name: "fade-out-animation";
  animation-duration: 3s;
  animation-iteration-count: 1;

  @keyframes pulse-animation {
    0%,
    100% {
      opacity: 1;
    }

    50% {
      opacity: 0.5;
    }
  }
`;

const Saving = css`
  transition: 0.3s;
  animation-name: "pulse-animation";
  animation-duration: 1s;
  animation-iteration-count: infinite;

  @keyframes fade-out-animation {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }
`;

const SaveIndicationStyle = styled.div`
  display: flex;
  gap: var(--space-2);
  color: var(--component-tertiary-text);
`;

export const SaveIndication = () => {
  const autosaving = useStore((store) => store.autosaving);

  return (
    <SaveIndicationStyle data-testid="autosave-indicator">
      <span className={autosaving ? Saving : Saved}>
        <span>{autosaving ? "Saving" : "Saved"} </span>
        <span>{autosaving ? <LoadingOutlined /> : <CheckOutlined />}</span>
      </span>
    </SaveIndicationStyle>
  );
};
