import { showDeleteModal } from "@app/components/common/modals";
import { useSelectedSite, useNavigate } from "@app/hooks";
import { css } from "@linaria/core";
import {
  Button,
  Input,
  Space,
  Spin,
  Table,
  Typography,
  icons,
} from "@reifyhealth/picasso-pkg";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { AllocationAmount } from "./components/AllocationAmount";
import { formatAllocationStatus } from "@model/payments";
import {
  useGetPaymentsQuery,
  useVoidPaymentMutation,
} from "@app/service/generated";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { utcDateStringToLocalDate } from "@lib/date";
import { money, toNumber } from "@lib/currency";
import { ExportModal } from "../common/modals/ExportModal";

const { LoadingOutlined } = icons;

const tableHeaderControls = css`
  display: flex;
  gap: var(--size-4);
`;

const paymentTable = css`
  .payment-void {
    background: var(--component-background-subtle);
    color: var(--component-disabled-text);

    .ant-typography {
      color: inherit;
    }
  }
`;

interface PaymentsTableProps {
  payments: Payment[];
  isTableLoading: boolean;
  setOverallRecordNumberFn?: React.Dispatch<React.SetStateAction<number>>;
}

export const PaymentsListingTable = ({
  payments,
  isTableLoading,
  setOverallRecordNumberFn,
}: PaymentsTableProps) => {
  const navigate = useNavigate();
  const params = useParams();
  const { data } = useSelectedSite();
  const siteId = data?.selectedSite?.value ?? "";
  const queryClient = useQueryClient();
  const [searchQuery, setSearchQuery] = useState("");

  const selectedPartnerId = params.partnerId || null;

  const [showExportModal, setShowExportModal] = useState(false);

  const { mutate: voidPayment } = useVoidPaymentMutation({
    onMutate: (variables) => {
      const queryKey = useGetPaymentsQuery.getKey({ siteId });

      const previousData: IGetPaymentsQuery | undefined =
        queryClient.getQueryData(queryKey);

      queryClient.setQueryData(
        queryKey,
        (oldData: IGetPaymentsQuery | void) => {
          if (oldData) {
            const { paymentId } = variables.input;

            const newExistingPaymentList =
              oldData.Financials2__paymentList.payments.map((payment) => {
                if (payment.id === paymentId) {
                  return {
                    ...payment,
                    ...{ status: "VOID" as IFinancials2__PaymentStatus },
                  };
                }

                return payment;
              });

            return {
              Financials2__paymentList: {
                payments: newExistingPaymentList,
              },
            };
          }

          return oldData;
        }
      );

      if (previousData) {
        return { previousData };
      }
    },
    onError: (error, variables, context) => {
      console.error({ error, variables });

      const queryKey = useGetPaymentsQuery.getKey({
        siteId,
      });

      queryClient.setQueryData(queryKey, context?.previousData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(
        useGetPaymentsQuery.getKey({
          siteId,
        })
      );
    },
  });

  const filterValue = payments.filter((v) =>
    v.paymentNumber.toLowerCase().includes(searchQuery.toLowerCase())
  );

  useEffect(() => {
    if (setOverallRecordNumberFn) {
      setOverallRecordNumberFn(filterValue.length);
    }
  }, [filterValue, setOverallRecordNumberFn]);

  const statusFilters = [...new Set(payments?.map((v) => v.allocationStatus))]
    .sort((a, b) => (a || "").localeCompare(b || ""))
    .map((externalStatus) => ({
      value: externalStatus ?? "UNALLOCATED",
      text: (
        <Typography.Text>
          {formatAllocationStatus(externalStatus)}
        </Typography.Text>
      ),
    }));

  const siteTrialNameFilters = [
    ...new Set(payments?.map((v) => v.siteTrial.name)),
  ]
    .sort((a, b) => a.localeCompare(b))
    .map((siteTrialName) => ({
      value: siteTrialName,
      text: <Typography.Text>{siteTrialName}</Typography.Text>,
    }));

  return (
    <div className={paymentTable}>
      <ExportModal
        category={"payments"}
        visible={showExportModal}
        setVisible={setShowExportModal}
        siteId={siteId}
        reportType={"PAYMENTS"}
      />
      <Spin spinning={isTableLoading} indicator={<LoadingOutlined />}>
        <Table
          size="small"
          bordered
          scroll={{ x: 1000, y: "calc(100vh - 320px)" }}
          rowKey={"id"}
          rowClassName={(record) =>
            record.status === "VOID" ? "payment-void" : ""
          }
          title={() => {
            return (
              <div className={tableHeaderControls}>
                <Input
                  data-testid="find-payments-input"
                  onChange={(e: any) => setSearchQuery(e.target.value)}
                  placeholder="Find Payments"
                />
                <Button
                  data-test-id="payments-export-button"
                  onClick={() => setShowExportModal(true)}
                >
                  Generate Report
                </Button>
                <Button
                  type="primary"
                  data-testid="payments-create-payment-button"
                  onClick={() =>
                    navigate(`/finance/sites/${siteId}/payments/new`, {
                      state: { data: { selectedPartnerId } },
                    })
                  }
                >
                  Create Payment
                </Button>
              </div>
            );
          }}
          dataSource={filterValue}
        >
          <Table.Column
            dataIndex="paymentNumber"
            key="paymentNumber"
            title={
              <Typography.Text data-testid="payment-title">
                Payment
              </Typography.Text>
            }
            defaultSortOrder="descend"
            showSorterTooltip={false}
            sorter={(a: Payment, b: Payment) =>
              parseInt(a.paymentNumber, 10) - parseInt(b.paymentNumber, 10)
            }
            render={(text, record: Payment) => {
              if (record.status === "VOID") {
                return (
                  <Typography.Text data-testid="payments-payment-number-text">
                    #{text}
                  </Typography.Text>
                );
              } else {
                return (
                  <Typography.Link
                    data-testid="payments-payment-number-link"
                    onClick={() =>
                      navigate(`/finance/sites/${siteId}/payments/${record.id}`)
                    }
                  >
                    #{text}
                  </Typography.Link>
                );
              }
            }}
          />
          <Table.Column
            dataIndex="allocationStatus"
            key="allocationStatus"
            showSorterTooltip={false}
            sorter={(a: Payment, b: Payment) =>
              toNumber(a.amount) - toNumber(b.amount)
            }
            align="right"
            filters={statusFilters}
            onFilter={(value, record: Payment) =>
              record.allocationStatus === value
            }
            title={
              <Typography.Text data-testid="amount-title">
                Amount
              </Typography.Text>
            }
            width={260}
            render={(_, record: Payment) => (
              <AllocationAmount
                paymentStatus={record.status}
                totalAmount={money(record.amount)}
                remainingTotal={money(record.remainingAmount)}
                allocationStatus={record.allocationStatus}
                allocatedAmount={money(record.allocatedAmount)}
              />
            )}
          />
          <Table.Column
            title={
              <Typography.Text data-testid="trial-title">
                Trial Name
              </Typography.Text>
            }
            showSorterTooltip={false}
            sorter={(a: Payment, b: Payment) =>
              (a.siteTrial.name || "").localeCompare(b.siteTrial.name || "")
            }
            filters={siteTrialNameFilters}
            onFilter={(value, record: Payment) =>
              record.siteTrial.name === value
            }
            render={(_, record: Payment) => (
              <Typography.Text data-testid="site-trial-name">
                {record.siteTrial?.name}
              </Typography.Text>
            )}
          />
          <Table.Column
            dataIndex="date"
            key="date"
            title={
              <Typography.Text data-testid="date-title">Date</Typography.Text>
            }
            showSorterTooltip={false}
            render={(date) => {
              const localDate = utcDateStringToLocalDate(date);

              return (
                <div data-testid="date">
                  {localDate && format(new Date(localDate), "dd-MMM-yyy")}
                </div>
              );
            }}
          />
          <Table.Column
            key="actions"
            align="right"
            showSorterTooltip={false}
            render={(_text, record: Payment) => {
              return (
                <Space className="table-row-controls-wrapper">
                  <Typography.Link
                    data-testid="edit-payment-link"
                    style={{
                      visibility:
                        record.status === "VOID" ? "hidden" : "visible",
                    }}
                    onClick={() =>
                      navigate(`/finance/sites/${siteId}/payments/${record.id}`)
                    }
                  >
                    Edit
                  </Typography.Link>
                  {/* TODO: Add Delete Functionality */}
                  <Typography.Link
                    type="danger"
                    data-testid="delete-payment-link"
                    style={{
                      visibility:
                        record.status === "VOID" ? "hidden" : "visible",
                    }}
                    disabled={
                      record.status === "VOID" ||
                      record.allocationStatus !== "UNALLOCATED"
                    }
                    onClick={() =>
                      showDeleteModal({
                        title: "Void Payment",
                        content: (
                          <>
                            This will mark the Payment as Void and cannot be
                            undone.
                          </>
                        ),
                        onOkFunction: () =>
                          voidPayment({ input: { paymentId: record.id } }),
                        okText: "Void",
                      })
                    }
                  >
                    Void
                  </Typography.Link>
                </Space>
              );
            }}
          />
        </Table>
      </Spin>
    </div>
  );
};
