import { useNavigateWithQueryString } from "@app/hooks";
import useDeepCompareEffect from "use-deep-compare-effect";
import {
  useAccountsReceivableSourceEventsQuery,
  useCreateInvoiceMutation,
  useRemoveAdHocChargeMutation,
  useReopenAccountsReceivableLineItemMutation,
} from "@app/service/generated";
import { css } from "@linaria/core";
import {
  icons,
  Alert,
  Button,
  Col,
  FilterValue,
  Input,
  Modal,
  Row,
  DatePicker,
  SorterResult,
  Space,
  Table,
  TablePaginationConfig,
  Typography,
  useForm,
  ConfigProvider,
  Empty,
  Tag,
  notification,
} from "@reifyhealth/picasso-pkg";
import React, { HTMLAttributes, useState } from "react";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { AccountsReceivablesDrawer } from "@app/components/drawers";
import { MoreActionsMenu } from "./components/MoreActionsMenu";
import { showSuccessModal } from "@app/components/common/modals";
import { formatExternalStatus } from "@model/invoices";
import { PaidAmount2 } from "../components/PaidAmount";
import { displayText, Money, money, toNumber } from "@lib/currency";
import {
  compareAsc,
  format,
  isAfter,
  isBefore,
  isWithinInterval,
  parseISO,
} from "date-fns";
import * as ar from "@model/accountsReceivable";
import { DEFAULT_DATEPICKER_FORMAT } from "@lib/date";
import {
  hasMoreActions,
  isGenerateInvoiceAllowed,
  LineItem,
  selectedStatuses,
  transformForARActions,
} from "@model/accountsReceivable";
import { ExportModal } from "../../common/modals/ExportModal";

const { CloseCircleFilled, SearchOutlined } = icons;
const { confirm } = Modal;

function toTableFilter(names: string[]) {
  return names.map((name) => ({ text: name, value: name }));
}

const { RangePicker } = DatePicker;

const strikethrough = css`
  text-decoration: line-through;
`;

const invoiceStatusTagElement = css`
  /* stylelint-disable */
  &.DRAFT {
    background: var(--amber-3);
    color: var(--amber-10);
    border: none;
  }

  &.OPEN {
    background: var(--blue-3);
    color: var(--blue-10);
    border: none;
  }

  &.PAST_DUE {
    background: var(--red-3);
    color: var(--red-10);
    border: none;
  }

  &.VOID {
    background: var(--slate-3);
    color: var(--slate-9);
    border: none;
  }

  &.PAID {
    background: var(--green-3);
    color: var(--green-10);
    border: none;
  }

  &.HOLDBACK {
    background: var(--purple-3);
    color: var(--purple-10);
    border: none;
  }
  /* stylelint-enable */
`;

const showDeleteModal = (
  adHocReceivableId: string,
  adHocReceivableDescription: string,
  removeAdHocCharge: Function
) => {
  confirm({
    icon: <CloseCircleFilled style={{ color: "red" }} />,
    title: "Delete Charge?",
    content: (
      <section style={{ display: "flex" }}>
        Are you sure you want to delete this charge?
      </section>
    ),
    okText: "Delete Charge",
    okType: "danger",
    okButtonProps: {
      color: "red",
      disabled: false,
    },
    cancelText: "Cancel",
    onOk() {
      removeAdHocCharge({
        description: adHocReceivableDescription,
        input: { adHocReceivableId },
      });
    },
    onCancel() {},
  });
};

interface AdHocReceivablesFormShape {
  description: string;
  charge: string;
  siteTrialId: string;
  protocolVersionId: string;
  completedAt: string;
  dueDate: string;
  category: string;
  chargeType: string;
  subjectId: string;
  protocolVisitCrossVersionId: string;
  invoiceable: boolean;
}

type ARTableFilters = {
  siteTrialNames: string[];
  visitNames: string[];
  subjectIds: string[];
  invoiceables: string[];
  externalStatus: { value: string; text: JSX.Element }[];
};

type TableLineItem = ar.FilterableLineItem & ar.TotalsLineItem;

interface LineItemsTableProps {
  siteId: string;
  lineItems: TableLineItem[];
  reactQueryKey: { siteId: string };
  isLoading: boolean;
  setOverallTotalFn?: React.Dispatch<React.SetStateAction<Money>>;
  setPastDueTotalFn?: React.Dispatch<React.SetStateAction<Money>>;
  setOverallRecordNumberFn?: React.Dispatch<React.SetStateAction<number>>;
  setHoldbackTotalFn?: React.Dispatch<React.SetStateAction<Money | null>>;
}

export const ARLineItemsTable = ({
  siteId,
  lineItems,
  reactQueryKey,
  isLoading,
  setOverallTotalFn,
  setPastDueTotalFn,
  setOverallRecordNumberFn,
  setHoldbackTotalFn,
}: LineItemsTableProps) => {
  const [show, setShow] = useState<boolean>(false);
  const [accountReceivableId, setAccountReceivableId] = useState<string | null>(
    null
  );
  const [
    selectedAccountReceivableRecordForEdit,
    setSelectedAccountReceivableRecordForEdit,
  ] = useState<LineItem | null>(null);
  const [type, setType] = useState<"create" | "edit">("create");

  const [showExportModal, setShowExportModal] = useState(false);

  const filters: ARTableFilters = {
    siteTrialNames: [
      ...new Set<string>(lineItems.flatMap((li: any) => li.siteTrial.name)),
    ],
    visitNames: [
      ...new Set<string>(
        lineItems
          .filter((li: any) => li.visitName !== null)
          .map((li: any) => li.visitName)
      ),
    ],
    subjectIds: [
      ...new Set<string>(lineItems.flatMap((li: any) => li.subjectId || "N/A")),
    ].sort((a, b) => a.localeCompare(b)),
    externalStatus: [
      ...new Set<string>(lineItems.flatMap((li: any) => li.externalStatus)),
    ]
      .sort((a, b) => a.localeCompare(b))
      .map((externalStatus) => ({
        value: externalStatus,
        text: (
          <Tag className={`${invoiceStatusTagElement} ${externalStatus}`}>
            {formatExternalStatus(externalStatus as any)}
          </Tag>
        ),
      })),
    invoiceables: [
      ...new Set<string>(
        lineItems.flatMap((li: any) => (li.invoiceable ? "Yes" : "No"))
      ),
    ],
  };

  const params = useParams();
  const queryClient = useQueryClient();

  const [visitDateRange, setVisitDateRange] = useState<any>([]);
  const [dueDateRange, setDueDateRange] = useState<any>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedSiteTrial, setSelectedSiteTrial] = useState<string[]>([]);
  const navigate = useNavigateWithQueryString();
  const { mutate: createInvoice, isLoading: isCreatingInvoice } =
    useCreateInvoiceMutation({
      onMutate: () => {},
      onSuccess: (data) => {
        navigate(
          `/finance/sites/${params.siteId}/invoices/${data.Financials2__createInvoice.invoiceId}`
        );
      },
      onError: () => {},
    });

  const { mutate: removeAdHocChargeMutate } = useRemoveAdHocChargeMutation({
    onSuccess() {
      queryClient.invalidateQueries(
        useAccountsReceivableSourceEventsQuery.getKey(reactQueryKey)
      );
    },
  });

  const removeAdHocCharge = ({
    input,
    description,
  }: {
    input: { adHocReceivableId: string };
    description: string;
  }) => {
    removeAdHocChargeMutate({ input });
    notification.success({
      message: `${description} has been removed from Accounts Receivable`,
    });
  };

  const { mutate: reopenAccountsReceivableLineItem } =
    useReopenAccountsReceivableLineItemMutation({
      onSuccess: () => {
        queryClient.invalidateQueries(
          useAccountsReceivableSourceEventsQuery.getKey(reactQueryKey)
        );
      },
    });

  const [searchTerm, setSearchTerm] = useState("");
  const [form] = useForm<AdHocReceivablesFormShape>();
  const [activeFilter, setActiveFilter] = useState({});

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSelectedRowKeys([]); // remove selections that could be hidden
    setSearchTerm(e.target.value);
  };

  const filteredLineItems = ar.filteredLineItems(lineItems, {
    activeFilter,
    searchTerm,
  });

  const handleFilterChange = (
    _pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    _sorter: SorterResult<any> | SorterResult<any>[]
  ) => {
    setSelectedRowKeys([]); // remove selections that could be hidden
    setActiveFilter(filters);
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    const siteTrialId = lineItems.filter(
      (lineItem) => lineItem.id && newSelectedRowKeys.includes(lineItem.id)
    );

    if (siteTrialId.length === 0) {
      setSelectedSiteTrial([]);
    } else {
      setSelectedSiteTrial([
        ...new Set(siteTrialId.map((lineItem) => lineItem.siteTrial?.id ?? "")),
      ]);
    }

    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const hasSelected =
    selectedRowKeys.length > 0 && selectedSiteTrial.length === 1;

  const {
    overallTotal,
    overallPastDueTotal,
    holdbackTotal,
    overallRecordCount,
  } = ar.totals(filteredLineItems);

  useDeepCompareEffect(() => {
    if (setOverallTotalFn) {
      setOverallTotalFn(overallTotal);
    }

    if (setPastDueTotalFn) {
      setPastDueTotalFn(overallPastDueTotal);
    }

    if (setOverallRecordNumberFn) {
      setOverallRecordNumberFn(overallRecordCount);
    }

    if (setHoldbackTotalFn) {
      setHoldbackTotalFn(holdbackTotal);
    }
  }, [
    overallTotal,
    overallPastDueTotal,
    holdbackTotal,
    setOverallTotalFn,
    setPastDueTotalFn,
    setOverallRecordNumberFn,
    setHoldbackTotalFn,
    overallRecordCount,
  ]);

  const selectedLineItemStatuses = selectedStatuses(lineItems, selectedRowKeys);

  return (
    <ConfigProvider
      renderEmpty={() => <Empty description="No Open A/R Items" />}
    >
      <ExportModal
        category={"accounts-receivable"}
        visible={showExportModal}
        setVisible={setShowExportModal}
        siteId={siteId}
        reportType={"ACCOUNTS_RECEIVABLE"}
      ></ExportModal>
      <AccountsReceivablesDrawer
        onCloseFn={() => {
          // form.resetFields() will yield initial values on edit
          form.setFieldsValue({
            protocolVisitCrossVersionId: "",
            charge: "",
            category: "",
            chargeType: "ADDITIONAL_CHARGE",
            invoiceable: true,
            subjectId: "",
            siteTrialId: "",
            description: "",
            protocolVersionId: "",
            completedAt: "",
            dueDate: "",
          });
          setShow(false);
          setSelectedAccountReceivableRecordForEdit(null);
          setAccountReceivableId(null);
          setType("create");
        }}
        existingAdHocReceivable={selectedAccountReceivableRecordForEdit}
        type={type}
        siteId={siteId}
        visible={show}
        setVisible={setShow}
        form={form}
        adHocReceivableId={accountReceivableId}
      />
      <Table
        size="small"
        onChange={handleFilterChange}
        bordered={true}
        showHeader={filteredLineItems.length > 0} // header squish with no data
        loading={isLoading}
        pagination={false}
        rowSelection={rowSelection}
        scroll={{ x: 1600, y: "calc(100vh - 500px)" }}
        rowKey={"id"}
        onRow={(record) =>
          ({
            "data-accounts-receivable-line-item-id": record.lineItemId,
          } as HTMLAttributes<HTMLElement>)
        }
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No A/R items available"
            />
          ),
        }}
        title={() => {
          return (
            <Space direction="vertical" style={{ width: "100%" }}>
              <Row justify="space-between">
                <Col>
                  <Space>
                    <Button
                      type="primary"
                      data-testid="generate-invoice-btn"
                      onClick={() => {
                        const {
                          tableIds,
                          sponsorId,
                          siteTrialId,
                          transformedLineItems,
                        } = transformForARActions(lineItems, selectedRowKeys);

                        setSelectedRowKeys(tableIds);

                        createInvoice({
                          input: {
                            sponsorId,
                            siteId,
                            siteTrialId,
                            lineItems: transformedLineItems,
                          },
                        });
                      }}
                      disabled={
                        !isGenerateInvoiceAllowed(selectedLineItemStatuses)
                      }
                      loading={isCreatingInvoice}
                    >
                      Generate Invoice
                    </Button>
                    <MoreActionsMenu
                      lineItems={lineItems}
                      selectedRowKeys={selectedRowKeys}
                      setSelectedRowKeysFn={setSelectedRowKeys}
                      hasSelected={hasSelected}
                      hasDisallowedStatuses={
                        !hasMoreActions(selectedLineItemStatuses)
                      }
                      allClearableHoldbacks={ar.isClearHoldbacksActionAllowed(
                        selectedLineItemStatuses
                      )}
                      reactQueryKey={reactQueryKey}
                    />
                    <Input
                      allowClear
                      autoComplete="off"
                      spellCheck="false"
                      data-testid="search-accounts-receivable-input"
                      style={{ width: "var(--size-24)" }}
                      prefix={<SearchOutlined className="search-icon" />}
                      placeholder="Find Activity"
                      onChange={onChange}
                    />
                  </Space>
                </Col>
                <Col>
                  <Space>
                    <Button
                      data-test-id="accounts-receivable-export-button"
                      onClick={() => setShowExportModal(true)}
                    >
                      Generate Report
                    </Button>
                    <Button
                      data-testid="new-charge-btn"
                      type="primary"
                      data-pendo="accounts-receivable-new-charge"
                      onClick={() => setShow(true)}
                    >
                      New Charge
                    </Button>
                  </Space>
                </Col>
              </Row>
              {selectedSiteTrial.length > 1 && (
                <Alert
                  data-testid="one-trial-applied-action-alert"
                  type="info"
                  message={
                    <Typography.Text>
                      Actions can only be applied to one trial at a time.
                    </Typography.Text>
                  }
                />
              )}
              {selectedSiteTrial.length === 1 &&
                ar.hasNoActions(selectedLineItemStatuses) && (
                  <Alert
                    type="info"
                    data-testid="open-item-with-partial-payment-action-alert"
                    message={
                      <Typography.Text>
                        Actions can only be applied to open items without
                        partial payment.
                      </Typography.Text>
                    }
                  />
                )}
            </Space>
          );
        }}
        dataSource={filteredLineItems}
      >
        <Table.Column
          title={
            <Typography.Text data-testid="description-title">
              Description
            </Typography.Text>
          }
          fixed="left"
          sorter={(a: any, b: any) =>
            a.description.localeCompare(b.description)
          }
          showSorterTooltip={false}
          width={300}
          dataIndex="description"
          render={(description) => {
            return <span data-testid="description">{description}</span>;
          }}
        />
        <Table.Column
          title={
            <Typography.Text data-testid="trial-name-title">
              Trial Name
            </Typography.Text>
          }
          sorter={(a: LineItem, b: LineItem) =>
            (a.siteTrial?.name || a.siteTrial?.id).localeCompare(
              b.siteTrial?.name || b.siteTrial?.id
            )
          }
          showSorterTooltip={false}
          width={240}
          dataIndex={"siteTrial"}
          render={(siteTrial) => {
            return (
              <span data-testid="trial-name">
                {siteTrial?.name || siteTrial?.id}
              </span>
            );
          }}
          filters={toTableFilter(filters.siteTrialNames)}
          onFilter={(value, record) => record.siteTrial.name === value}
        />
        <Table.Column
          title={
            <Typography.Text data-testid="subject-title">
              Subject
            </Typography.Text>
          }
          sorter={(a: LineItem, b: LineItem) =>
            (a.subjectId ?? "").localeCompare(b.subjectId ?? "")
          }
          filters={toTableFilter(filters.subjectIds)}
          onFilter={(value, record) => {
            if (!record.subjectId) {
              return "N/A" === value;
            }

            return record.subjectId === value;
          }}
          showSorterTooltip={false}
          width={178}
          dataIndex={"subjectId"}
          render={(subjectId) => {
            return <Tag data-testid="subject-id">{subjectId || "N/A"}</Tag>;
          }}
        />
        <Table.Column
          title={
            <Typography.Text data-testid="charge-type-title">
              Charge Type
            </Typography.Text>
          }
          sorter={(a: any, b: any) => a.chargeType.localeCompare(b.chargeType)}
          showSorterTooltip={false}
          width={178}
          dataIndex="chargeType"
          render={(chargeType) => {
            return <span data-testid="charge-type">{chargeType}</span>;
          }}
        />
        <Table.Column
          title={
            <Typography.Text data-testid="status-title">Status</Typography.Text>
          }
          dataIndex={"externalStatus"}
          width={178}
          showSorterTooltip={false}
          sorter={(a: any, b: any) =>
            a.externalStatus.localeCompare(b.externalStatus)
          }
          filters={filters.externalStatus}
          onFilter={(value, record: any) => record.externalStatus === value}
          render={(_, record: any) => {
            return (
              <Tag
                data-testid="status"
                className={`${invoiceStatusTagElement} ${record.externalStatus}`}
              >
                {formatExternalStatus(record.externalStatus)}
              </Tag>
            );
          }}
        />
        <Table.Column
          title={
            <Typography.Text data-testid="charge-title">Charge</Typography.Text>
          }
          sorter={(a: LineItem, b: LineItem) =>
            toNumber(a.totalPayableAmount ?? 0) -
            toNumber(b.totalPayableAmount ?? 0)
          }
          align="right"
          dataIndex={"charge"}
          width={178}
          showSorterTooltip={false}
          render={(charge: Money, record: LineItem) => {
            return (
              <span data-testid="charge">
                <PaidAmount2
                  remainingTotal={money(record.remainingAmount)}
                  totalAmount={charge}
                  totalPayableAmount={
                    record.totalPayableAmount
                      ? money(record.totalPayableAmount)
                      : null
                  }
                  paidStatus={record.paidStatus}
                  allocatedAmount={
                    record.allocatedAmount
                      ? money(record.allocatedAmount)
                      : null
                  }
                  externalStatus={record.externalStatus}
                />
              </span>
            );
          }}
        />
        <Table.Column
          title={
            <Typography.Text data-testid="holdback-title">
              Holdback
            </Typography.Text>
          }
          dataIndex={"holdback"}
          width={178}
          showSorterTooltip={false}
          align="left"
          sorter={(a: LineItem, b: LineItem) => {
            const { holdback: holdbackA } = a;
            const { holdback: holdbackB } = b;

            if (!holdbackA?.enabled && holdbackB?.enabled) {
              return -1;
            }

            if (holdbackA?.enabled && !holdbackB?.enabled) {
              return 1;
            }

            const aInEffect =
              holdbackA && holdbackA.enabled && !holdbackA.cleared;
            const bInEffect =
              holdbackB && holdbackB.enabled && !holdbackB.cleared;

            if (!aInEffect && bInEffect) {
              return -1;
            }

            if (aInEffect && !bInEffect) {
              return 1;
            }

            if (aInEffect && bInEffect) {
              return (
                toNumber(holdbackA.money ?? 0) - toNumber(holdbackB.money ?? 0)
              );
            }

            return 0;
          }}
          render={(holdback: IFinancials2__Holdback | null, _record: any) => {
            if (holdback && holdback.enabled && holdback.percentage) {
              if (holdback.cleared) {
                return (
                  <span data-testid="holdback">
                    <Typography.Text
                      type="secondary"
                      className={`${strikethrough}`}
                    >{`Cleared ${holdback.percentage}%`}</Typography.Text>
                  </span>
                );
              }

              return (
                <Space data-testid="holdback">
                  <Typography.Text type="danger">
                    -{displayText(holdback.money!)}
                  </Typography.Text>
                  <Typography.Text type="secondary">
                    {holdback.percentage}%
                  </Typography.Text>
                </Space>
              );
            }
          }}
        />
        <Table.Column
          title={
            <Typography.Text data-testid="total-charge-title">
              Total Charge
            </Typography.Text>
          }
          // dataIndex={"totalAmount"}
          width={178}
          showSorterTooltip={false}
          align="right"
          sorter={(a: any, b: any) =>
            toNumber(a.charge ?? 0) - toNumber(b.charge ?? 0)
          }
          // filters={filters.externalStatus}
          // onFilter={(value, record: any) => record.externalStatus === value}
          render={(_, record: LineItem) => {
            if (record.charge) {
              return (
                <span data-testid="total-charge">
                  {displayText(record.charge)}
                </span>
              );
            }
          }}
        />
        <Table.Column
          title={
            <Typography.Text data-testid="due-date-title">
              Due Date
            </Typography.Text>
          }
          dataIndex={"dueDate"}
          width={178}
          showSorterTooltip={false}
          filterDropdown={({ setSelectedKeys, confirm }) => (
            <div>
              <div style={{ padding: 8 }}>
                <RangePicker
                  data-testid="filter-due-date-rangepicker"
                  onCalendarChange={(value: any) => {
                    setDueDateRange(value);
                  }}
                  format={DEFAULT_DATEPICKER_FORMAT}
                  value={dueDateRange}
                  placeholder={["Start Date", "End Date"]}
                />
              </div>

              <section
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  placeItems: "center",
                  borderTop: "1px solid var(--component-border)",
                  padding: "4px 8px",
                }}
              >
                <Button
                  data-testid="due-date-clear-btn"
                  type="link"
                  onClick={() => {
                    setSelectedKeys([]);
                    setDueDateRange([null, null]);
                    confirm();
                  }}
                >
                  Clear
                </Button>
                <Button
                  disabled={!dueDateRange}
                  data-testid="due-date-ok-btn"
                  type="primary"
                  size="small"
                  onClick={() => {
                    setSelectedKeys(dueDateRange);
                    confirm();
                  }}
                >
                  OK
                </Button>
              </section>
            </div>
          )}
          onFilter={(_, record) => {
            const [start, end] = dueDateRange;

            const startDateISOString =
              start &&
              new Date(format(new Date(start.toISOString()), "dd-MMM-yyy"));
            const endDateISOString =
              end &&
              new Date(format(new Date(end.toISOString()), "dd-MMM-yyy"));

            const dueDate = new Date((record as any).dueDate);

            if (startDateISOString && endDateISOString) {
              return isWithinInterval(dueDate, {
                start: startDateISOString,
                end: endDateISOString,
              });
            } else if (start) {
              return isAfter(dueDate, startDateISOString);
            } else if (end) {
              return isBefore(dueDate, endDateISOString);
            }

            return true;
          }}
          sorter={(a, b) => {
            return (
              ((new Date((a as any).dueDate) as any) || 0) -
              ((new Date((b as any).dueDate) as any) || 0)
            );
          }}
          render={(dueDate) => {
            return (
              <Typography.Text data-testid="due-date">
                {dueDate}
              </Typography.Text>
            );
          }}
        />
        <Table.Column
          title={
            <Typography.Text data-testid="overhead-title">
              Overhead
            </Typography.Text>
          }
          dataIndex="overhead"
          width={178}
          showSorterTooltip={false}
          align="left"
          sorter={(a: LineItem, b: LineItem) => {
            const { overhead: overheadA } = a;
            const { overhead: overheadB } = b;

            if (!overheadA?.enabled && overheadB?.enabled) {
              return -1;
            }

            if (overheadA?.enabled && !overheadB?.enabled) {
              return 1;
            }

            const aInEffect = overheadA && overheadA.enabled;
            const bInEffect = overheadB && overheadB.enabled;

            if (!aInEffect && bInEffect) {
              return -1;
            }

            if (aInEffect && !bInEffect) {
              return 1;
            }

            if (aInEffect && bInEffect) {
              return toNumber(overheadA.money!) - toNumber(overheadB.money!);
            }

            return 0;
          }}
          render={(overhead: IFinancials2__Overhead | null, _record: any) => {
            if (overhead && overhead.enabled && overhead.percentage) {
              return (
                <Space data-testid="overhead">
                  <Typography.Text>
                    {displayText(overhead.money!)}
                  </Typography.Text>
                  <Typography.Text type="secondary">
                    {overhead.percentage}%
                  </Typography.Text>
                </Space>
              );
            }
          }}
        />

        <Table.Column
          title={
            <Typography.Text data-testid="invoiceable-title">
              Invoiceable
            </Typography.Text>
          }
          sorter={(a: LineItem, b: LineItem) => a.invoiceable - b.invoiceable}
          filters={toTableFilter(filters.invoiceables)}
          onFilter={(value, record) => {
            return (record.invoiceable ? "Yes" : "No") === value;
          }}
          showSorterTooltip={false}
          width={178}
          dataIndex={"invoiceable"}
          render={(invoiceable: boolean) => {
            return (
              <code data-testid="invoiceable">
                {invoiceable ? "Yes" : "No"}
              </code>
            );
          }}
        />
        <Table.Column
          title={
            <Typography.Text data-testid="completed-date-title">
              Completed Date
            </Typography.Text>
          }
          filterDropdown={({ setSelectedKeys, confirm }) => (
            <div>
              <div style={{ padding: 8 }}>
                <RangePicker
                  data-testid="filter-completed-date-rangepicker"
                  onCalendarChange={(value: any) => {
                    setVisitDateRange(value);
                  }}
                  format={DEFAULT_DATEPICKER_FORMAT}
                  value={visitDateRange}
                  placeholder={["Start Date", "End Date"]}
                />
              </div>

              <section
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  placeItems: "center",
                  borderTop: "1px solid var(--component-border)",
                  padding: "4px 8px",
                }}
              >
                <Button
                  data-testid="completed-date-clear-btn"
                  type="link"
                  onClick={() => {
                    setSelectedKeys([]);
                    setVisitDateRange([null, null]);
                    confirm();
                  }}
                >
                  Clear
                </Button>
                <Button
                  disabled={!visitDateRange}
                  data-testid="completed-date-ok-btn"
                  type="primary"
                  size="small"
                  onClick={() => {
                    setSelectedKeys(visitDateRange);
                    confirm();
                  }}
                >
                  OK
                </Button>
              </section>
            </div>
          )}
          onFilter={(_, record: LineItem) => {
            const [start, end] = visitDateRange;

            const startDateISOString =
              start &&
              new Date(format(new Date(start.toISOString()), "dd-MMM-yyy"));
            const endDateISOString =
              end &&
              new Date(format(new Date(end.toISOString()), "dd-MMM-yyy"));

            const completedDate = new Date(record.completedDate);

            if (startDateISOString && endDateISOString) {
              return isWithinInterval(completedDate, {
                start: startDateISOString,
                end: endDateISOString,
              });
            } else if (start) {
              return isAfter(completedDate, startDateISOString);
            } else if (end) {
              return isBefore(completedDate, endDateISOString);
            }

            return true;
          }}
          sorter={(a, b) =>
            compareAsc(
              parseISO(a.rawCompletedDate),
              parseISO(b.rawCompletedDate)
            )
          }
          showSorterTooltip={false}
          width={238}
          dataIndex={"completedDate"}
          defaultSortOrder={"ascend"}
          render={(completedDate) => (
            <span data-testid="completed-date">{completedDate}</span>
          )}
        />
        <Table.Column
          title={
            <Typography.Text data-testid="protocol-version-title">
              Protocol Version
            </Typography.Text>
          }
          sorter={(a: LineItem, b: LineItem) =>
            (a.protocolVersionName ?? "").localeCompare(
              b.protocolVersionName ?? ""
            )
          }
          showSorterTooltip={false}
          width={178}
          dataIndex={"protocolVersionName"}
          render={(protocolVersionName) => {
            return (
              <Tag data-testid="protocol-version-name">
                {protocolVersionName}
              </Tag>
            );
          }}
        />

        <Table.Column
          title={
            <Typography.Text data-testid="visit-title-title">
              Visit
            </Typography.Text>
          }
          filters={toTableFilter(filters.visitNames)}
          onFilter={(value, record: LineItem) => record.visitName === value}
          sorter={(a, b) =>
            (a.visitName ?? "").localeCompare(b.visitName ?? "")
          }
          showSorterTooltip={false}
          width={240}
          dataIndex={"visitName"}
          render={(visitName) => {
            return <span data-testid="visit-name">{visitName}</span>;
          }}
        />
        <Table.Column
          title={""}
          key={"action"}
          align="right"
          width={180}
          render={(_, record: LineItem) => (
            <Space size={8}>
              <Typography.Link
                data-testid="reopen-btn"
                onClick={() =>
                  showSuccessModal({
                    title: "Reopen A/R Line Item?",
                    content: (
                      <>
                        By reopening this A/R line item, you are changing the
                        status from <em>Paid</em> to <em>Open</em>.
                      </>
                    ),
                    onOkFunction: () => {
                      const { transformedLineItems } = transformForARActions(
                        [record],
                        [record.id]
                      );

                      reopenAccountsReceivableLineItem({
                        input: { ...transformedLineItems[0], siteId },
                      });
                    },
                    okText: "Reopen",
                  })
                }
                style={{
                  visibility: ar.isReopenVisible(record) ? "visible" : "hidden",
                }}
              >
                Reopen
              </Typography.Link>
              {((record.chargeType === "Additional Charge" ||
                record.chargeType === "Activity") &&
                record.source === "adHocForm") ||
              record.source === "sendToReceivables" ? (
                <>
                  <Typography.Link
                    data-testid="edit-ad-hoc-btn"
                    onClick={() => {
                      setSelectedAccountReceivableRecordForEdit(record);
                      setAccountReceivableId(record.id);
                      setType("edit");
                      setShow(true);
                    }}
                    disabled={
                      record.externalStatus === "PAID" ||
                      record.source !== "adHocForm"
                    }
                  >
                    Edit
                  </Typography.Link>
                  <Typography.Link
                    type="danger"
                    disabled={record.externalStatus === "PAID"}
                    data-testid="delete-ad-hoc-btn"
                    onClick={() =>
                      showDeleteModal(
                        record.id,
                        record.description || "Item",
                        removeAdHocCharge
                      )
                    }
                  >
                    Delete
                  </Typography.Link>
                </>
              ) : (
                <></>
              )}
            </Space>
          )}
        />
      </Table>
    </ConfigProvider>
  );
};
