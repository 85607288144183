import {
  useCreatePaymentMutation,
  useGetPartnerDropdownItemsQuery,
  useUserSiteTrialsQuery,
} from "@app/service/generated";
import {
  Col,
  DatePicker,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
  Typography,
  message,
} from "@reifyhealth/picasso-pkg";
import { useParams } from "react-router-dom";
import { EditIcon } from "../common/icons/EditIcon";
import { useState } from "react";
import { Money, round, toGraphQLInput } from "@lib/currency";
import { localDateToUTCDateString } from "@lib/date";
import { useNavigate } from "@app/hooks";
import sortBy from "lodash/sortBy";
import MoneyInput from "../inputs/MoneyInput";

interface CreatePaymentFormProps {
  siteId: string;
  form: FormInstance<{
    siteTrialId: string | null;
    partnerId: string | null;
    amount: Money | null;
    date: string;
    memo: string | null;
    reference: string | null;
  }>;
  onTrialSelect: Function;
  addAnotherPayment: boolean;
  selectedPaymentAllocations: any[];
  onPaymentCreated: Function;
  onAmountChange: Function;
  setDisableRecordPayment: React.Dispatch<React.SetStateAction<boolean>>;
  selectedAttachments: { id: string; contentType: string; filename: string }[];
}

export const CreatePaymentForm = ({
  siteId,
  form,
  onTrialSelect,
  addAnotherPayment,
  selectedPaymentAllocations,
  onPaymentCreated,
  onAmountChange,
  setDisableRecordPayment,
  selectedAttachments,
}: CreatePaymentFormProps) => {
  const navigate = useNavigate();
  const [editing, setEditing] = useState(false);
  const [text, setText] = useState("Include Memo");

  const params = useParams();

  const { data: userSiteTrialsWithBudget } = useUserSiteTrialsQuery(
    {
      filter: { siteId: params.siteId!, hasBudget: true },
    },
    {
      queryKey: "userSiteTrialWithBudgetsOnly",
    }
  );

  const {
    data: partnerList,
    isFetching: partnerListLoading,
    isLoading: partnerListFetching,
  } = useGetPartnerDropdownItemsQuery({
    siteId: params.siteId!,
  });

  const handleEditClick = () => {
    setText(text || "");
    setEditing(true);
  };

  const siteTrialOptions = sortBy(
    userSiteTrialsWithBudget?.Financials2__userSiteTrials.edges.map((edge) => ({
      value: edge.node.id,
      label: edge.node.name,
    })),
    "label"
  );

  const partnerOptions = sortBy(
    partnerList?.Financials2__partnerList.partners.map((partner) => ({
      value: partner.id,
      label: partner.name,
    })),
    "label"
  );

  const { mutate: createPayment } = useCreatePaymentMutation({
    onMutate: () => {
      setDisableRecordPayment(true);
    },
    onError: (error, variables, _context) => {
      console.error(error, variables);
      message.error("Failed to create payment");
      setDisableRecordPayment(false);
    },
    onSuccess() {
      message.success("Payment created!");
      if (addAnotherPayment) {
        setText("Include Memo");
        form.resetFields();
        onPaymentCreated();
      } else {
        navigate(`/finance/sites/${params.siteId}/payments`);
        setDisableRecordPayment(false);
      }
    },
  });

  return (
    <Form
      layout="vertical"
      form={form}
      initialValues={{
        partnerId: null,
        memo: null,
        reference: null,
      }}
      onValuesChange={(changedValues) => {
        // Currently no way to "watch" values change via antd.
        if (changedValues["amount"]) {
          onAmountChange(changedValues["amount"]);
        }
      }}
      onFinish={(values) => {
        const amount = toGraphQLInput(round(values.amount!));
        const date = localDateToUTCDateString(values.date);
        const siteTrialId = values.siteTrialId || "";
        const partnerId = values.partnerId ?? null;

        createPayment({
          input: {
            ...values,
            siteTrialId,
            partnerId,
            allocations: selectedPaymentAllocations,
            memo: text,
            attachments: selectedAttachments.map((attachment) => ({
              id: attachment.id,
              contentType: attachment.contentType,
              filename: attachment.filename,
            })),
            date,
            siteId,
            amount,
          },
        });
      }}
    >
      <Row gutter={16} wrap={true}>
        <Col flex={1}>
          <Form.Item
            label="Trial:"
            name="siteTrialId"
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
          >
            <Select
              style={{ width: "100%" }}
              placeholder="Choose a Trial"
              options={siteTrialOptions}
              data-testid="create-payment-trial-select"
              onSelect={(value) => {
                if (value) {
                  onTrialSelect();
                }
              }}
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item name="partnerId" label="Partner:">
            <Select
              disabled={partnerListLoading || partnerListFetching}
              style={{ width: 250 }}
              virtual={false}
              placeholder="Choose a Partner"
              options={partnerOptions}
              data-testid="create-payment-partner-select"
              key={"value"}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="amount"
            label="Amount:"
            rules={[
              {
                required: true,
                message: "Amount is required to allocate items",
              },
            ]}
          >
            <MoneyInput
              addonBefore="$" // TODO: localization
              data-testid="create-payment-amount-input"
              size="large"
              onChange={(e) => onAmountChange(e.target.value)}
            />
          </Form.Item>
        </Col>
        <Col flex={1}>
          <Form.Item
            name="date"
            label="Date:"
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
          >
            <DatePicker
              style={{ width: "100%" }}
              data-testid="create-payment-date-picker"
              onChange={(date) =>
                form.setFields([{ value: date, name: "date" }])
              }
            />
          </Form.Item>
        </Col>
        <Col flex={1}>
          <Form.Item name="reference" label="Reference #:">
            <Input
              placeholder="Optional"
              data-testid="create-payment-reference-input"
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="memo"
            className="memo-field"
            data-testid="create-payment-memo-form-item"
          >
            <Typography.Text
              onClick={handleEditClick}
              editable={{
                tooltip: false,
                icon: <EditIcon data-testid="create-payment-memo-edit-icon" />,
                onCancel() {
                  setText(text || "Include Memo");
                  setEditing(false);
                },
                onEnd() {
                  setEditing(false);
                },
                editing,
                onChange: (value) => {
                  setText(value);
                  form.setFields([{ value: text, name: "memo" }]);
                },
              }}
            >
              <span data-testid="create-payment-memo-text">{text}</span>
            </Typography.Text>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
