import React from "react";
import { Spin } from "@reifyhealth/picasso-pkg";
import { PageLayout } from "@components/PageLayout";
import { useSelectedSite } from "@hooks/sites";
import { NoAccess } from "@pages";
import { BudgetVersionCardList } from "./BudgetVersionCardList";
import { BudgetVersionSiteSelectorHeader } from "@app/components/headers/BudgetVersionSiteSelectorHeader";

const Loading = () => {
  return <Spin />;
};

export const BudgetListingPage = () => {
  const { data, isLoading } = useSelectedSite();

  if (!isLoading && !data?.selectedSite) {
    return (
      <PageLayout>
        <NoAccess
          title="No Sites Available"
          message={"Your user has no available sites"}
        />
      </PageLayout>
    );
  }

  return (
    <PageLayout Header={BudgetVersionSiteSelectorHeader} showSider>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <BudgetVersionCardList site={data?.selectedSite!} />
        </>
      )}
    </PageLayout>
  );
};
