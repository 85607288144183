import { BudgetFlowSlice, StoreCreator } from "../types";

/**
 * @deprecated
 */
export const createBudgetFlowSlice: StoreCreator<BudgetFlowSlice> = (set) => ({
  autosaving: false,
  setAutosaving: (pred: boolean) =>
    set((state) => ({ ...state, ...{ autosaving: pred } })),
});
