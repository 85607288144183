import {
  Empty,
  Typography,
  Tag,
  Form,
  Checkbox,
  Space,
} from "@reifyhealth/picasso-pkg";
import { CustomActivityPriceIcon } from "@components/common/icons/CustomActivityPriceIcon";
import { InvoicedSeparatelyIcon } from "@components/common/icons/InvoicedSeparatelyIcon";
import { css } from "@linaria/core";
import { IncludedVisitActivityIcon } from "@app/components/common/icons/IncludedVisitActivityIcon";
import { displayText, eq } from "@lib/currency";
import {
  BudgetMatrixEmptyCell,
  BudgetMatrixNonEmptyCell,
  BudgetMatrixRow,
  isActivityMatch,
  isInvoicedSeparately,
  isVisitMatch,
} from "@model/budgets/matrix";
import { useBudgetMatrix } from "@model/budgets/matrix/hooks";
import { ReactChild, useState } from "react";
import { styled } from "@linaria/react";
import { Popover, FormInstance, Button } from "@reifyhealth/picasso-pkg";
import MoneyInput from "@components/inputs/MoneyInput";
import { toString } from "@lib/currency";

interface ActivityFormProps {
  cell: BudgetMatrixNonEmptyCell;
}

export const ActivityForm = ({ cell }: ActivityFormProps) => {
  const [, updateMatrix] = useBudgetMatrix(cell);
  const {
    invoiceable: activityInvoiceable,
    cost: activityCost,
    charge: activityCharge,
    holdbackEnabled,
    overheadEnabled,
    holdbackPercentage,
    overheadPercentage,
  } = cell;
  const initialValues = {
    cost: activityCost ? toString(activityCost) : undefined,
    charge: activityCharge ? toString(activityCharge) : undefined,
    invoiceable: activityInvoiceable,
    holdbackEnabled,
    overheadEnabled,
  };

  const [form] = Form.useForm<{
    charge: string;
    cost: string;
    invoiceable: boolean | null;
    holdbackEnabled: boolean;
    overheadEnabled: boolean;
  }>();

  return (
    <Form
      form={form}
      initialValues={initialValues}
      onValuesChange={() =>
        updateMatrix("updateBudgetForActivity", {
          ...cell,
          ...form.getFieldsValue(),
        })
      }
      layout="vertical"
      className="default-value-form"
    >
      <Space size={16}>
        <Form.Item name="cost" label="Cost:">
          <MoneyInput autoFocus data-testid="activity-cost-input" />
        </Form.Item>
        <Form.Item required name="charge" label="Charge:">
          <MoneyInput data-testid="activity-charge-input" />
        </Form.Item>
      </Space>
      <div className="whats-this">
        <Typography.Link type="secondary">What's this?</Typography.Link>
      </div>
      <Form.Item
        label="Additional Options:"
        valuePropName="checked"
        name="invoiceable"
        style={{ marginTop: 16 }}
      >
        <Checkbox data-testid="activity-invoiceable-checkbox">
          Invoiceable
        </Checkbox>
      </Form.Item>
      {holdbackPercentage ? (
        <Form.Item valuePropName="checked" name="holdbackEnabled">
          <Checkbox data-testid="activity-holdback-checkbox">
            Holdback ({holdbackPercentage}%)
          </Checkbox>
        </Form.Item>
      ) : null}
      {overheadPercentage ? (
        <Form.Item valuePropName="checked" name="overheadEnabled">
          <Checkbox data-testid="activity-overhead-checkbox">
            Overhead ({overheadPercentage}%)
          </Checkbox>
        </Form.Item>
      ) : null}
    </Form>
  );
};

interface VisitActivityPopOverProps {
  children: ReactChild;
  cell: BudgetMatrixNonEmptyCell;
  providedForm?: FormInstance<{
    charge: string;
    cost: string;
    invoiceable: boolean;
  }>;
}

const DefaultConfigPopoverContent = styled.section`
  display: flex;
  flex-direction: column;
  width: 360px;

  .dismiss {
    place-self: flex-end;
    font-size: var(--font-size-caption);
  }

  .title {
    display: flex;
    gap: var(--size-4);
    align-items: center;
    margin-bottom: var(--size-5);
    color: var(--component-primary-text);
    font-size: var(--font-size-body);
    font-weight: var(--font-weight-medium);

    .fa {
      color: var(--component-tertiary-text);
    }
  }
`;

export const ActivityPopover = ({
  children,
  cell,
}: VisitActivityPopOverProps) => {
  const [visible, setVisible] = useState(false);

  const handleVisibleChange = (visible: boolean) => {
    setVisible(visible);
  };

  return (
    <Popover
      placement="bottom"
      trigger="click"
      visible={visible}
      content={
        <DefaultConfigPopoverContent>
          <div className="title">
            <span>Default Cost &amp; Charge Amounts</span>
          </div>
          <div className="activity-form">
            {cell.empty ? null : <ActivityForm cell={cell} />}
          </div>
          <div className="dismiss">
            <Button data-testid="dismiss-btn" onClick={() => setVisible(false)}>
              Done
            </Button>
          </div>
        </DefaultConfigPopoverContent>
      }
      onVisibleChange={handleVisibleChange}
      arrowContent={null}
      destroyTooltipOnHide
    >
      {children}
    </Popover>
  );
};

const activeCellClass = css`
  position: relative;
  background: var(--component-hover-background) !important;
  transition: var(--duration-2);

  &::before {
    content: "";
    border: 2px dashed var(--component-focus-highlight);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
  }
`;

const CellContainer = styled.td`
  cursor: pointer;
  width: var(--table-column-width-default);
  min-width: var(--table-column-width-default);
  background: var(--component-background);
  transition: var(--duration-2);

  &:hover {
    background: var(--component-hover-background);
  }
`;

const CellInnerWrapper = styled.span`
  display: flex;
  gap: var(--size-4);
  align-items: center;
  justify-content: center;
`;

type IconState =
  | "CustomActivityPrice"
  | "IncludedVisitActivity"
  | "InvoicedSeparately";

function getIconState(cell: BudgetMatrixNonEmptyCell): IconState | null {
  if (!cell.empty) {
    if (cell.visit?.cost || cell.visit?.charge) {
      if (isInvoicedSeparately(cell)) {
        return "InvoicedSeparately";
      }

      return "IncludedVisitActivity";
    }

    if (isInvoicedSeparately(cell)) {
      return "InvoicedSeparately";
    }

    if (cell.charge || cell.cost) {
      if (!cell.activity.charge || !cell.activity.cost) {
        return "CustomActivityPrice";
      }

      if (
        cell.charge &&
        cell.activity.charge &&
        !eq(cell.charge, cell.activity.charge)
      ) {
        return "CustomActivityPrice";
      }

      if (
        cell.cost &&
        cell.activity.cost &&
        !eq(cell.cost, cell.activity.cost)
      ) {
        return "CustomActivityPrice";
      }

      return "IncludedVisitActivity";
    }

    if (isInvoicedSeparately(cell)) {
      return "InvoicedSeparately";
    }

    return "IncludedVisitActivity";
  }

  return null;
}

const CellIcon = ({ cell }: { cell: BudgetMatrixNonEmptyCell }) => {
  const iconState = getIconState(cell);

  switch (iconState) {
    case "CustomActivityPrice":
      return <CustomActivityPriceIcon />;
    case "IncludedVisitActivity":
      return <IncludedVisitActivityIcon />;
    case "InvoicedSeparately":
      return <InvoicedSeparatelyIcon />;
    case null:
      return <></>;
  }
};

function cellCost(cell: BudgetMatrixNonEmptyCell) {
  return cell?.cost ?? cell?.defaultCost;
}

function cellCharge(cell: BudgetMatrixNonEmptyCell) {
  return cell?.charge ?? cell?.defaultCharge;
}

function cellInvoiceable(cell: BudgetMatrixNonEmptyCell) {
  return cell?.invoiceable ?? cell?.defaultInvoiceable;
}

function isAmountHidden(cell: BudgetMatrixNonEmptyCell) {
  return (
    (!cellCost(cell) && !cellCharge(cell)) ||
    (cellCost(cell) &&
      eq(cellCost(cell)!, 0) &&
      cellCharge(cell) &&
      eq(cellCharge(cell)!, 0)) ||
    (!cellInvoiceable(cell) && (cell.visit?.charge || cell.visit?.cost))
  );
}

/**
 * Returns displayable cost/charges to the given body cell given
 * the following scenarios:
 *
 * - Cost and Charge doesn't exist => <></>
 * - Cost and Charge exists but they are both zeroed out => <></>
 * - Cost or Charge exists => Cash information
 */
const CellAmount = ({ cell }: { cell: BudgetMatrixNonEmptyCell }) => {
  if (isAmountHidden(cell)) return null;

  return (
    <>
      {displayText(cellCost(cell) ?? 0)} / {displayText(cellCharge(cell) ?? 0)}
    </>
  );
};

const VisitActivityEmptyCell = ({ cell }: { cell: BudgetMatrixEmptyCell }) => {
  return (
    <td
      data-testid="matrix-body-cell"
      key={`${cell.visitCrossVersionId}-empty`}
    />
  );
};

function isActive(
  cell: BudgetMatrixNonEmptyCell,
  selectedCell: BudgetMatrixNonEmptyCell | null
) {
  return (
    cell.activityCrossVersionId === selectedCell?.activityCrossVersionId &&
    cell.visitCrossVersionId === selectedCell.visitCrossVersionId
  );
}

const VisitActivityNonEmptyCell = ({
  cell,
}: {
  cell: BudgetMatrixNonEmptyCell;
}) => {
  const [{ selectedCell }, updateMatrix] = useBudgetMatrix(cell);

  return (
    <CellContainer
      data-testid="matrix-body-cell"
      data-protocol-visit-cross-version-id={cell.visit?.crossVersionId}
      data-protocol-activity-cross-version-id={cell.activity.crossVersionId}
      className={isActive(cell, selectedCell) ? activeCellClass : ""}
      onClick={() => updateMatrix("startEditing", { cell })}
    >
      <CellInnerWrapper>
        <CellIcon cell={cell} />
        <CellAmount cell={cell} />
      </CellInnerWrapper>
    </CellContainer>
  );
};

export interface TableBodyProps {
  visits: BudgetMatrixVisit[];
  activities: BudgetMatrixActivity[];
  visitActivities: BudgetMatrixVisitActivity[];
  budgetId: string | null;
  budgetConfigVersionId: string | null;
  siteTrialId: string | null;
  selectedTrack: string;
  activityFilter: string;
  visitFilter: string;
}

function ActivityCell({ cell }: { cell: BudgetMatrixNonEmptyCell }) {
  return (
    <ActivityPopover data-testid="activity-popover" cell={cell}>
      <td
        data-testid="activity"
        data-protocol-activity-cross-version-id={cell.activity.crossVersionId}
        className="activity-block sticky-left text-left"
        key={`${cell.activity.crossVersionId}-td`}
      >
        <div className="activity-item-content">
          <div>
            {cell.cost || cell.charge || cell.invoiceable ? (
              <Tag
                style={{
                  background: "var(--green-3)",
                  color: "var(--green-12)",
                  borderColor: "var(--green-5)",
                }}
              >
                {cell.cost ? displayText(cell.cost) : "-"}
                &nbsp;/&nbsp;
                {cell.charge ? displayText(cell.charge) : "-"}
              </Tag>
            ) : (
              <Typography.Link
                data-testid="set-default-amount-link"
                data-pendo="set-default-amount"
              >
                Set Default Amount
              </Typography.Link>
            )}
          </div>
          <div className="activity-item-content-label">{cell.name}</div>
        </div>
      </td>
    </ActivityPopover>
  );
}

function NoActivitiesFound() {
  return (
    <td className="sticky-left" data-testid="no-activity-present">
      <Empty
        style={{ width: 400, height: 400 }}
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description="No Activities Found"
      />
    </td>
  );
}

function TableBodyRow({
  row,
  visitFilter,
}: {
  row: BudgetMatrixRow;
  visitFilter: string;
}) {
  if (row.cells[0]?.empty) return null;

  return (
    <tr>
      <ActivityCell cell={row.cells[0]} />
      {(row.cells.slice(1) ?? [])
        .filter((cell) => isVisitMatch(cell, visitFilter))
        .map((cell) => {
          return cell.empty ? (
            <VisitActivityEmptyCell key={cell.key} cell={cell} />
          ) : (
            <VisitActivityNonEmptyCell key={cell.key} cell={cell} />
          );
        })}
    </tr>
  );
}

export function TableBody({
  activities,
  budgetId,
  budgetConfigVersionId,
  siteTrialId,
  activityFilter,
  visitFilter,
  selectedTrack,
}: TableBodyProps) {
  const [matrix] = useBudgetMatrix({
    budgetId,
    budgetConfigVersionId,
    siteTrialId,
    track: selectedTrack,
  });

  if (!activities.length) {
    return (
      <tbody>
        <NoActivitiesFound />
      </tbody>
    );
  }

  return (
    <tbody>
      {(matrix.rows ?? [])
        .filter((row) => isActivityMatch(row, activityFilter))
        .map((row) => (
          <TableBodyRow key={row.key} row={row} visitFilter={visitFilter} />
        ))}
    </tbody>
  );
}
