import { PageLayout } from "@app/components/PageLayout";
import { styled } from "@linaria/react";
import { css } from "@linaria/core";
import {
  Button,
  Checkbox,
  Space,
  Form,
  message,
  PageHeader,
} from "@reifyhealth/picasso-pkg";
import { useNavigate, useParams } from "react-router-dom";
import { CreatePaymentForm } from "@app/components/forms";
import { useState } from "react";
import { PaymentChart } from "@app/components/charts/PaymentChart";
import { PaymentAllocationTable } from "@app/components/tables";
import { lte, Money } from "@lib/currency";
import { usePayments } from "@app/hooks/usePayments";
import { PaymentAttachmentsUploader } from "@app/components/uploader";

const paymentViewWrapper = css`
  min-width: 860px;
  max-width: 80%;
  margin: 0 auto;
`;

const PaymentCreationHeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;

  .lead-instructions {
    font-size: 16px;
    color: var(--component-secondary-text);
    margin-bottom: var(--size-8);
  }

  .title-container {
    display: flex;
    gap: var(--size-6);
    align-items: center;
    margin-bottom: var(--size-4);

    .title {
      font-size: 23px;
      font-weight: var(--font-weight-medium);
      color: var(--component-primary-text);
    }

    .payment-number {
      color: var(--component-secondary-text, #687076);
      font-size: var(--font-size-footnote);
      font-weight: var(--font-weight-normal);
    }
  }
`;

const paymentWrapper = css`
  padding: var(--size-8);
  border-radius: var(--border-2);
  border: 1px solid var(--component-border);
  background: var(--component-background);
`;

const emptyContainer = css`
  padding: 16px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  background: var(--component-background-subtle, #f8f9fa);
  color: var(--component-tertiary-text, #7e868c);
  text-align: center;
`;

const footer = css`
  margin-top: var(--size-8);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const formElements = css`
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--size-8);
  gap: var(--size-6);

  .flex-item {
    flex: 0 0 auto;
  }

  .flex-item.upload {
    width: 200px;

    .ant-upload.ant-upload-drag {
      height: unset;
    }

    .upload-icon {
      width: var(--size-10);
      height: var(--size-10);
      color: var(--shared-info);
    }
  }

  .configure {
    flex: 1 1 auto;

    .ant-form {
      .ant-form-item {
        margin-bottom: var(--size-4);
      }
    }

    .memo-field {
      padding: var(--size-6);
      background: var(--component-background-subtle);

      .ant-typography-edit-content {
        left: 0;
        margin: 0;

        .ant-input {
          line-height: unset;
          position: unset;
        }
      }
    }
  }

  .result {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 200px;
    text-align: center;
    border-left: 1px solid var(--component-border);
    padding: var(--size-6);

    .ant-progress {
      margin-bottom: var(--size-4);
    }

    h5.ant-typography {
      margin-bottom: 0;
    }
  }
`;

export const CreatePaymentPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm<{
    siteTrialId: string | null;
    partnerId: string | null;
    amount: Money | null;
    date: string;
    memo: string | null;
    reference: string | null;
  }>();

  const {
    paymentAllocationsAreLoading,
    setPaymentAllocationEnabled,
    paymentAllocationEnabled,
    paymentAllocations,
    handleSelectChange,
    selectedRowKeys,
    setAmount,
    amount,
    percentage,
    setAmountOverride,
    selectedPaymentAllocationsMutationInput,
    remainingAmount,
    refetchOpenPayableItems,
    searchTerm,
    setSearchTerm,
    setSelectedAttachments,
    selectedAttachments,
    isRecordButtonDisabled,
  } = usePayments(form);

  const [addAnotherPayment, setAnotherPayment] = useState(false);
  const [disableRecordPayment, setDisableRecordPayment] = useState(false);
  const [resetAttachments, setResetAttachments] = useState(false);

  return (
    <PageLayout>
      <section className={paymentViewWrapper}>
        <PaymentCreationHeaderContainer>
          <PageHeader
            style={{ padding: 0, width: "100%" }}
            title="Create a Payment"
            extra={
              <Space>
                <Checkbox
                  onChange={(event) => setAnotherPayment(event.target.checked)}
                  checked={addAnotherPayment}
                  data-testid="create-payment-save-and-add-another-checkbox"
                >
                  Save and add another
                </Checkbox>
                <Button
                  disabled={
                    isRecordButtonDisabled ||
                    !paymentAllocationEnabled ||
                    percentage > 100 ||
                    paymentAllocationsAreLoading ||
                    disableRecordPayment ||
                    !amount ||
                    lte(amount, 0)
                  }
                  onClick={async () => {
                    // used only for UI elements (manual validation fires off proper error states in form)
                    await form.validateFields().catch(() => null);

                    const noErrorExistsInAnyField = form
                      .getFieldsError()
                      .every((field) => field.errors.length === 0);

                    if (noErrorExistsInAnyField) {
                      form.submit();
                    } else {
                      message.error("Validation failed!");
                    }
                  }}
                  htmlType="submit"
                  type="primary"
                  data-testid="create-payment-record-payment-button"
                >
                  Record Payment
                </Button>
              </Space>
            }
            onBack={() => navigate(`/finance/sites/${params.siteId}/payments`)}
          >
            <p className="lead-instructions">
              Open Invoices and Receivables can be applied to payments.
            </p>
          </PageHeader>
        </PaymentCreationHeaderContainer>
        <section className={paymentWrapper}>
          <section className={formElements}>
            <section className="flex-item upload">
              <PaymentAttachmentsUploader
                resetFileList={resetAttachments}
                setSelectedAttachments={setSelectedAttachments}
                selectedAttachments={selectedAttachments}
              />
            </section>
            <section className="flex-item configure">
              <CreatePaymentForm
                form={form}
                siteId={params.siteId!}
                onTrialSelect={() => {
                  if (paymentAllocationEnabled) {
                    refetchOpenPayableItems();
                  }

                  setPaymentAllocationEnabled(true);
                }}
                addAnotherPayment={addAnotherPayment}
                selectedPaymentAllocations={
                  selectedPaymentAllocationsMutationInput
                }
                onAmountChange={setAmount}
                setDisableRecordPayment={setDisableRecordPayment}
                selectedAttachments={selectedAttachments}
                onPaymentCreated={() => {
                  if (addAnotherPayment) {
                    setPaymentAllocationEnabled(false);
                    setAnotherPayment(false);
                    setDisableRecordPayment(false);
                    setResetAttachments(true);
                  }

                  setAmount(null);
                  setSearchTerm("");
                  setDisableRecordPayment(false);
                  setSelectedAttachments([]);
                  setResetAttachments(false);
                }}
              />
            </section>
            <section className="flex-item result">
              <PaymentChart
                amount={amount}
                percentage={percentage}
                remainingAmount={remainingAmount}
                showInfo={paymentAllocationEnabled}
              />
            </section>
          </section>
          {paymentAllocationEnabled ? (
            <PaymentAllocationTable
              paymentAllocations={paymentAllocations}
              tableIsLoading={paymentAllocationsAreLoading}
              handleSelectChange={handleSelectChange}
              selectedRowKeys={selectedRowKeys}
              searchTerm={searchTerm}
              onSearchTermChange={setSearchTerm}
              setAmountOverrideFn={setAmountOverride}
              paymentRemainingAmount={remainingAmount}
            />
          ) : (
            <section className={emptyContainer}>
              Choose a Trial for this payment
            </section>
          )}
        </section>
        <section className={footer}>
          <section>
            <Button
              onClick={() =>
                navigate(`/finance/sites/${params.siteId}/payments`)
              }
              data-testid="create-payment-cancel-button"
            >
              Cancel
            </Button>
          </section>
          <section>
            <Space>
              <Checkbox
                onChange={(event) => setAnotherPayment(event.target.checked)}
                checked={addAnotherPayment}
                data-testid="create-payment-save-and-add-another-checkbox"
              >
                Save and add another
              </Checkbox>
              <Button
                disabled={
                  isRecordButtonDisabled ||
                  !paymentAllocationEnabled ||
                  percentage > 100 ||
                  paymentAllocationsAreLoading ||
                  disableRecordPayment ||
                  !amount ||
                  lte(amount, 0)
                }
                onClick={async () => {
                  // used only for UI elements (manual validation fires off proper error states in form)
                  await form.validateFields().catch(() => null);

                  const noErrorExistsInAnyField = form
                    .getFieldsError()
                    .every((field) => field.errors.length === 0);

                  if (noErrorExistsInAnyField) {
                    form.submit();
                  } else {
                    message.error("Validation failed!");
                  }
                }}
                htmlType="submit"
                type="primary"
                data-testid="create-payment-record-payment-button"
              >
                Record Payment
              </Button>
            </Space>
          </section>
        </section>
      </section>
    </PageLayout>
  );
};
