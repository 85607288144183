import { PageLayout } from "@components/PageLayout";
import { BudgetVersionSiteSelectorHeader } from "@app/components/headers/BudgetVersionSiteSelectorHeader";
import { InvoicesTable } from "@app/components/tables/InvoicesTable";
import { useParams } from "react-router-dom";
import { useGetInvoiceListQuery } from "@app/service/generated";
import { Badge, Tabs, Typography } from "@reifyhealth/picasso-pkg";
import { useNavigate } from "@app/hooks";
import { TotalHeader } from "@app/components/headers/TotalHeader";
import { useState } from "react";

export const InvoicesPage = ({ activeTab }: { activeTab?: string }) => {
  const [overallTotal, setOverallTotal] = useState<number>(0);
  const [overallRecordNumber, setOverallRecordNumber] = useState<number>(0);
  const [pastDueTotal, setPastDueTotal] = useState<number>(0);

  const params = useParams();
  const navigate = useNavigate();
  const TabPane = Tabs.TabPane;

  const { data, isLoading, isFetching } = useGetInvoiceListQuery({
    siteId: params.siteId!,
  });

  const openInvoices =
    data?.Financials2__invoiceList.invoices
      .filter(
        (invoice) =>
          invoice.externalStatus === "OPEN" ||
          invoice.externalStatus === "PAST_DUE" ||
          invoice.externalStatus === "DRAFT"
      )
      .sort((a, b) => Number(b.invoiceNumber) - Number(a.invoiceNumber)) || [];

  const allInvoices =
    data?.Financials2__invoiceList.invoices.sort(
      (a, b) => Number(b.invoiceNumber) - Number(a.invoiceNumber)
    ) || [];

  return (
    <PageLayout Header={BudgetVersionSiteSelectorHeader} showSider>
      <>
        <TotalHeader
          title="Open Invoices"
          overallTotal={overallTotal}
          pastDueTotal={pastDueTotal}
        />
        <Tabs
          onChange={(tab) => {
            navigate(`/finance/sites/${params.siteId}/invoices/${tab}`);
          }}
          defaultActiveKey={activeTab ?? "open"}
        >
          <TabPane
            tab={
              <Typography.Text>
                Open{" "}
                <Badge
                  overflowCount={100}
                  style={{ backgroundColor: "#D7DBDF", color: "#000000A6" }}
                  count={overallRecordNumber}
                  showZero
                />
              </Typography.Text>
            }
            key="open"
          >
            <InvoicesTable
              isLoading={isLoading || isFetching}
              invoices={openInvoices}
              siteId={params.siteId!}
              withTitle
              setOverallTotalFn={setOverallTotal}
              setPastDueTotalFn={setPastDueTotal}
              setOverallRecordNumberFn={setOverallRecordNumber}
              reactQueryKey={{
                siteId: params.siteId!,
              }}
            />
          </TabPane>
          <TabPane tab="All Invoices" key="all">
            <InvoicesTable
              isLoading={isLoading || isFetching}
              invoices={allInvoices}
              siteId={params.siteId!}
              withTitle
              setOverallTotalFn={setOverallTotal}
              setPastDueTotalFn={setPastDueTotal}
              setOverallRecordNumberFn={setOverallRecordNumber}
              reactQueryKey={{
                siteId: params.siteId!,
              }}
            />
          </TabPane>
        </Tabs>
      </>
    </PageLayout>
  );
};
