import React, { CSSProperties, useCallback, useState } from "react";
import type { FieldData } from "rc-field-form/lib/interface";
import { InvoicedSeparatelyIcon } from "@components/common/icons/InvoicedSeparatelyIcon";
import {
  Col,
  FormInstance,
  Space,
  Switch,
  Typography,
  useForm,
} from "@reifyhealth/picasso-pkg";
import { Form, Row } from "@reifyhealth/picasso-pkg";
import { css } from "@linaria/core";
import MoneyInput from "@components/inputs/MoneyInput";
import * as budgetMatrix from "@model/budgets/matrix";
import { UpdateMatrixFn, useBudgetMatrix } from "@model/budgets/matrix/hooks";
import { displayText, money, toFixed } from "@lib/currency";
import { BudgetMatrixColumn } from "@model/budgets/matrix";

const CustomToggle = css`
  font-size: var(--font-size-footnote);
`;

export interface TableFooterProps {
  budgetId: string;
  budgetConfigVersionId: string;
  siteTrialId: string;
  selectedTrack: string;
  visitFilter: string;
}

function overriddenStyle(overridden: boolean): CSSProperties {
  return overridden
    ? {}
    : {
        visibility: "hidden",
        pointerEvents: "none",
        transition: "none",
      };
}

function handleVisitCellFieldChange({
  changed,
  updateMatrix,
  setOverridden,
  form,
  column,
}: {
  changed: FieldData[];
  updateMatrix: UpdateMatrixFn;
  setOverridden: (overridden: boolean) => void;
  form: FormInstance<VisitCellFieldValues>;
  column: BudgetMatrixColumn;
}) {
  const toggled = (changed[0]?.name as string | null)?.[0] === "overridden";

  if (toggled && changed[0]!.value) {
    setOverridden(true);
    form.setFieldsValue({
      cost: toFixed(money(0)),
      charge: toFixed(money(0)),
      holdbackEnabled: column.holdbackEnabled,
      overheadEnabled: column.overheadEnabled,
    });
    updateMatrix("updateBudgetForVisit", {
      ...column,
      ...form.getFieldsValue(),
    });
  } else if (toggled && !changed[0]!.value) {
    setOverridden(false);
    form.setFieldsValue({
      cost: null,
      charge: null,
      holdbackEnabled: column.holdbackEnabled,
      overheadEnabled: column.overheadEnabled,
    });
    updateMatrix("clearBudgetForVisit", column);
  } else {
    updateMatrix("updateBudgetForVisit", {
      ...column,
      ...form.getFieldsValue(),
    });
  }
}

type VisitCellFieldValues = Partial<{
  overridden: boolean;
  cost: string | null;
  charge: string | null;
  holdbackEnabled: boolean;
  overheadEnabled: boolean;
}>;

function VisitCell({
  budgetId,
  budgetConfigVersionId,
  siteTrialId,
  selectedTrack,
  column,
}: {
  budgetId: string;
  budgetConfigVersionId: string;
  siteTrialId: string;
  selectedTrack: string;
  column: budgetMatrix.BudgetMatrixColumn;
}) {
  const { visitCrossVersionId } = column;
  const [{ budgetAggregate }, updateMatrix] = useBudgetMatrix({
    budgetId,
    budgetConfigVersionId,
    siteTrialId,
    track: selectedTrack,
  });
  const [form] = useForm<VisitCellFieldValues>();
  const [overridden, setOverridden] = useState(column.overridden);
  const visitSubTotal = budgetAggregate
    ? budgetMatrix.visitSubTotal(budgetAggregate, column.visitCrossVersionId)
    : null;

  const handleVisitCellFieldChangeMemoized = useCallback(
    (changed) => {
      handleVisitCellFieldChange({
        changed,
        updateMatrix,
        setOverridden,
        form,
        column,
      });
    },
    [updateMatrix, setOverridden, form, column]
  );

  return (
    <td
      id={`${visitCrossVersionId}-summary-footer-cell`}
      data-protocol-visit-cross-version-id={visitCrossVersionId}
      className={`col-summary-footer ${overridden && "override"}`}
    >
      <Form
        form={form}
        initialValues={column}
        onFieldsChange={handleVisitCellFieldChangeMemoized}
      >
        <section className="cost-charge-container">
          <div>
            <Typography.Text strong>Visit Sub-total</Typography.Text>
          </div>
          <>
            <Row justify="space-between" align="middle">
              <Space>
                <Form.Item name="overridden" valuePropName="checked">
                  <Switch
                    title="Custom Sub-total"
                    data-pendo="custom-sub-total"
                    size="small"
                    data-testid="custom-sub-total-switch"
                  />
                </Form.Item>

                <Typography.Text type="secondary" className={CustomToggle}>
                  Custom Sub-total
                </Typography.Text>
              </Space>
            </Row>
            {column.holdbackPercentage ? (
              <Row justify="space-between" align="middle">
                <Space style={overriddenStyle(overridden)}>
                  <Form.Item name="holdbackEnabled" valuePropName="checked">
                    <Switch
                      disabled={!overridden}
                      title="Holdback"
                      data-pendo="visit-holdback-enabled"
                      size="small"
                      data-testid="visit-holdback-enabled"
                      style={{
                        marginBottom: 6,
                        ...overriddenStyle(overridden),
                      }}
                    />
                  </Form.Item>
                  <Typography.Text type="secondary" className={CustomToggle}>
                    Holdback ({column.holdbackPercentage}%)
                  </Typography.Text>
                </Space>
              </Row>
            ) : null}
            {column.overheadPercentage ? (
              <Row justify="space-between" align="middle">
                <Space style={overriddenStyle(overridden)}>
                  <Form.Item name="overheadEnabled" valuePropName="checked">
                    <Switch
                      disabled={!overridden}
                      title="Overhead"
                      data-pendo="visit-overhead-enabled"
                      size="small"
                      data-testid="visit-overhead-enabled"
                      style={{
                        marginBottom: 6,
                        ...overriddenStyle(overridden),
                      }}
                    />
                  </Form.Item>
                  <Typography.Text type="secondary" className={CustomToggle}>
                    Overhead ({column.overheadPercentage}%)
                  </Typography.Text>
                </Space>
              </Row>
            ) : null}
          </>
          <Row justify="space-between" align="middle">
            <Col>
              <Typography.Text>Cost:</Typography.Text>
            </Col>
            <Col>
              <Row>
                <Form.Item
                  name="cost"
                  hidden={!overridden}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 0,
                  }}
                >
                  <MoneyInput
                    hidden={!overridden}
                    data-testid="visit-cost-input"
                    style={{ width: 80 }}
                  />
                </Form.Item>
                {!overridden && (
                  <Typography.Text data-testid="visit-sub-total.cost-text">
                    {displayText(visitSubTotal?.column.cost ?? 0)}
                  </Typography.Text>
                )}
              </Row>
            </Col>
          </Row>
          <Row justify="space-between" align="middle">
            <Col>
              <Typography.Text>Charge:</Typography.Text>
            </Col>
            <Col>
              <Row>
                <Form.Item
                  name="charge"
                  hidden={!overridden}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 0,
                  }}
                >
                  <MoneyInput
                    hidden={!overridden}
                    style={{ width: 80 }}
                    data-testid="visit-charge-input"
                  />
                </Form.Item>
                {!overridden && (
                  <Typography.Text data-testid="visit-sub-total.cost-text">
                    {displayText(visitSubTotal?.column.charge ?? 0)}
                  </Typography.Text>
                )}
              </Row>
            </Col>
          </Row>
        </section>
        <section className="invoiced-separately-block">
          <div className="title">
            <InvoicedSeparatelyIcon />
            <Typography.Text type="secondary">
              Invoiced Separately
            </Typography.Text>
          </div>

          <Row style={{ justifyContent: "space-between" }}>
            <Col>
              <Typography.Text>Cost:</Typography.Text>
            </Col>
            <Col data-testid="visit-sub-total-invoiced-sep-cost-txt">
              {displayText(visitSubTotal?.invoicedSeparately.cost ?? 0)}
            </Col>
          </Row>
          <Row style={{ justifyContent: "space-between" }}>
            <Col>
              <Typography.Text>Charge:</Typography.Text>
            </Col>
            <Col data-testid="visit-sub-total-invoiced-sep-charge-txt">
              {displayText(visitSubTotal?.invoicedSeparately.charge ?? 0)}
            </Col>
          </Row>
        </section>
      </Form>
    </td>
  );
}

export function TableFooter({
  budgetId,
  budgetConfigVersionId,
  siteTrialId,
  selectedTrack,
  visitFilter,
}: TableFooterProps) {
  const [matrix] = useBudgetMatrix({
    budgetId,
    budgetConfigVersionId,
    siteTrialId,
    track: selectedTrack,
  });

  return (
    <tfoot className="sticky-bottom">
      <tr>
        <td className="activity-footer"></td>
        {(matrix.columns ?? [])
          .filter((column) =>
            budgetMatrix.isVisitMatch({ visit: column }, visitFilter)
          )
          .map((column) => (
            <VisitCell
              budgetId={budgetId}
              budgetConfigVersionId={budgetConfigVersionId}
              siteTrialId={siteTrialId}
              selectedTrack={selectedTrack}
              key={`${column.visitCrossVersionId}-footer`}
              column={column}
            />
          ))}
      </tr>
    </tfoot>
  );
}
