import { css } from "@linaria/core";
import { Typography } from "@reifyhealth/picasso-pkg";
import { displayText } from "@lib/currency";
import * as ar from "@model/accountsReceivable";

const paidStateLabel = css`
  font-size: var(--font-size-footnote);
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .icon {
    width: var(--size-6);
    height: var(--size-6);
    color: var(--shared-success);
    margin-right: var(--size-3);
  }
`;

export const PaidAmount2 = (props: ar.PaidAmountProps) => {
  const paidAmount = ar.paidAmount2(props);

  if (paidAmount?.length === 1) {
    return (
      <Typography.Text data-testid="paid-amount">
        {displayText(paidAmount[0])}
      </Typography.Text>
    );
  } else if (paidAmount?.length === 2) {
    return (
      <>
        <div data-testid="paid-amount">
          <Typography.Text strong>{displayText(paidAmount[0])}</Typography.Text>
          <Typography.Text type="secondary">
            / {displayText(paidAmount[1])}
          </Typography.Text>
        </div>
        <Typography.Text className={paidStateLabel} type="secondary">
          Partially Paid
        </Typography.Text>
      </>
    );
  } else {
    return null;
  }
};
