import { Spin, Modal, Form, DatePicker, icons } from "@reifyhealth/picasso-pkg";
import { useState } from "react";
import { useGenerateReportMutation } from "@app/service/generated";
import { DEFAULT_DATEPICKER_FORMAT, localDateToUTCDateString } from "@lib/date";

const { LoadingOutlined } = icons;

const { RangePicker } = DatePicker;

export interface ExportModalProps {
  category: "payments" | "invoices" | "accounts-receivable";
  visible: boolean;
  setVisible: (_: boolean) => void;
  siteId: string;
  reportType: IFinancials2__ReportType;
}

export const ExportModal = ({
  category,
  visible,
  setVisible,
  siteId,
  reportType,
}: ExportModalProps) => {
  const [dateRange, setDateRange] = useState<
    [moment.Moment | null, moment.Moment | null] | null
  >();

  const { mutate: generateReport, isLoading: isReportLoading } =
    useGenerateReportMutation({
      onSuccess: (response) => {
        window.open(response.data.downloadUrl, "_blank");
        setVisible(false);
        setDateRange(null);
      },
    });

  const triggerGenerateReport = () => {
    generateReport({
      input: {
        siteId,
        reportType,
        startDate: localDateToUTCDateString(dateRange![0]!),
        endDate: localDateToUTCDateString(dateRange![1]!),
      },
    });
  };

  const exportButtonEnabled = dateRange && dateRange[0] && dateRange[1];

  return (
    <Modal
      title="Generate Report"
      visible={visible}
      onOk={triggerGenerateReport}
      okText={"Generate Report"}
      onCancel={() => setVisible(false)}
      okButtonProps={{
        disabled: isReportLoading || !exportButtonEnabled,
        loading: isReportLoading,
      }}
    >
      <Spin spinning={isReportLoading} indicator={<LoadingOutlined />}>
        <Form layout={"vertical"}>
          <Form.Item required={true} label={"Choose Timeframe"}>
            <RangePicker
              style={{ width: "100%" }}
              data-testid={`${category}-export-range-picker`}
              format={DEFAULT_DATEPICKER_FORMAT}
              value={dateRange}
              onCalendarChange={(val) => setDateRange(val)}
            />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};
