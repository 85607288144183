export const EditIcon = (props?: Record<string, any>) => (
  <svg
    width="12"
    height="11"
    viewBox="0 0 12 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.0312 9.92969H0.96875C0.726758 9.92969 0.53125 10.1252 0.53125 10.3672V10.8594C0.53125 10.9195 0.580469 10.9688 0.640625 10.9688H11.3594C11.4195 10.9688 11.4688 10.9195 11.4688 10.8594V10.3672C11.4688 10.1252 11.2732 9.92969 11.0312 9.92969ZM2.52324 8.78125C2.55059 8.78125 2.57793 8.77852 2.60527 8.77441L4.90488 8.37109C4.93223 8.36563 4.9582 8.35332 4.97734 8.33281L10.7729 2.5373C10.7855 2.52466 10.7956 2.50963 10.8024 2.49309C10.8093 2.47655 10.8128 2.45882 10.8128 2.44092C10.8128 2.42301 10.8093 2.40528 10.8024 2.38874C10.7956 2.3722 10.7855 2.35718 10.7729 2.34453L8.50059 0.0708985C8.47461 0.0449219 8.44043 0.03125 8.40352 0.03125C8.3666 0.03125 8.33242 0.0449219 8.30645 0.0708985L2.51094 5.86641C2.49043 5.88691 2.47812 5.91152 2.47266 5.93887L2.06934 8.23848C2.05604 8.31172 2.06079 8.3871 2.08318 8.45809C2.10557 8.52908 2.14493 8.59354 2.19785 8.6459C2.28809 8.7334 2.40156 8.78125 2.52324 8.78125Z"
      fill="#0091FF"
    />
  </svg>
);
