import {
  DownloadSimpleRegular,
  ReceiptRegular,
  UserListRegular,
  WalletRegular,
  GearRegular,
  MoneyRegular,
} from "@reifyhealth/picasso-icons";

export const SiteBudgetMenuIcon = () => <WalletRegular />;
export const ARMenuIcon = () => <DownloadSimpleRegular />;
export const InvoicesIcon = () => <ReceiptRegular />;
export const PartnersIcon = () => <UserListRegular />;
export const PaymentsIcon = () => <MoneyRegular />;
export const SettingsIcon = () => <GearRegular />;
