import React, { ReactNode } from "react";
import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import {
  Card,
  Form,
  Input,
  Space,
  Row,
  Col,
  Button,
  Steps,
  FormInstance,
} from "@reifyhealth/picasso-pkg";
import { validateZipCode } from "./validators";

const AddContactsContainer = styled.div`
  padding: var(--size-4);
  border-radius: var(--size-2);
  background: var(--component-background-subtle);

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }
`;

const AddressBlock = css`
  width: 100%;

  .ant-form-item {
    margin-bottom: var(--space-4);
  }
`;

interface PartnerFormProps {
  onFormFinishFn: (values: {
    name: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    zipCode: string;
  }) => void;
  form: FormInstance<{
    name: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    zipCode: string;
  }>;
  onFormCancelFn: () => void;
  isLoading: boolean;
  contactList: ReactNode;
}

export const PartnerForm = ({
  contactList,
  isLoading,
  form,
  onFormFinishFn,
  onFormCancelFn,
}: PartnerFormProps) => {
  return (
    <Card>
      <Form
        layout="vertical"
        form={form}
        initialValues={{ addressLine2: "" }}
        onFinish={onFormFinishFn}
      >
        <Steps direction="vertical" status="wait">
          <Steps.Step
            title="Enter Partner Information"
            description={
              <div>
                <Form.Item
                  rules={[
                    { required: true, message: "Partner Name is required" },
                  ]}
                  name="name"
                  label="Partner Name"
                  required
                >
                  <Input
                    data-testid="partner-name-input"
                    disabled={isLoading}
                  />
                </Form.Item>
                <section className={AddressBlock}>
                  <Form.Item
                    name={"addressLine1"}
                    label="Address"
                    rules={[
                      { required: true, message: "Address 1 is required" },
                    ]}
                  >
                    <Input
                      data-testid="partner-address-1-input"
                      disabled={isLoading}
                      placeholder="Address 1"
                    />
                  </Form.Item>
                  <Form.Item name={"addressLine2"}>
                    <Input
                      data-testid="partner-address-2-input"
                      disabled={isLoading}
                      placeholder="Address 2"
                    />
                  </Form.Item>
                  <Form.Item
                    name={"city"}
                    rules={[{ required: true, message: "City is required" }]}
                  >
                    <Input
                      data-testid="partner-city-input"
                      disabled={isLoading}
                      placeholder="City"
                    />
                  </Form.Item>
                  <Row gutter={8} className={AddressBlock}>
                    <Col span={12}>
                      <Form.Item
                        name={"state"}
                        rules={[
                          { required: true, message: "State is required" },
                        ]}
                      >
                        <Input
                          data-testid="partner-state-input"
                          disabled={isLoading}
                          placeholder="State"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        rules={[
                          { required: true, message: "Zip code required." },
                          {
                            validator: validateZipCode,
                            validateTrigger: "onSubmit",
                          },
                        ]}
                        name={"zipCode"}
                      >
                        <Input
                          disabled={isLoading}
                          type="number"
                          data-testid="partner-zip-code-input"
                          placeholder="Zipcode"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </section>
              </div>
            }
          >
            1
          </Steps.Step>
          <Steps.Step
            title="Add Contacts"
            description={
              <AddContactsContainer>{contactList}</AddContactsContainer>
            }
          >
            2
          </Steps.Step>
        </Steps>
        <Form.Item style={{ marginBottom: 0 }}>
          <Space>
            <Button
              data-testid="partner-save-btn"
              htmlType="submit"
              type="primary"
              disabled={isLoading}
            >
              Save
            </Button>
            <Button
              data-testid="partner-cancel-btn"
              disabled={isLoading}
              onClick={onFormCancelFn}
            >
              Cancel
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Card>
  );
};
