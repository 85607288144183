import { Button, Space, icons } from "@reifyhealth/picasso-pkg";
import { styled } from "@linaria/react";
import { useStore } from "@app/store";
const { ArrowRightOutlined } = icons;

const BudgetCreationFooterContainer = styled.footer`
  margin: 1.5rem 0;
  display: flex;
  justify-content: space-between;

  .budget-slider {
    .ant-typography {
      margin: 0%;
    }

    .ant-slider {
      width: var(--size-18);
      margin: var(--size-4) var(--size-4);
    }
  }
`;

export const BudgetCreationFooter = ({
  step,
  finalStep,
  nextStepTitle,
  onNextBtnClick,
  onBackBtnClick,
  onSaveAndExitBtnClick,
}: {
  step: number;
  finalStep: boolean;
  nextStepTitle: string;
  onNextBtnClick: () => void;
  onBackBtnClick: () => void;
  onSaveAndExitBtnClick: () => void;
}) => {
  const autosaving = useStore((store) => store.autosaving);

  return (
    <BudgetCreationFooterContainer>
      <Button
        htmlType="button"
        data-testid="save-and-exit-btn"
        disabled={autosaving}
        onClick={(e) => {
          e.preventDefault();
          onSaveAndExitBtnClick();
        }}
      >
        Save &amp; Exit
      </Button>

      <Space>
        {step > 2 && (
          <Button
            htmlType="button"
            data-testid="back-btn"
            disabled={autosaving}
            onClick={(e) => {
              e.preventDefault();
              onBackBtnClick();
            }}
          >
            Back
          </Button>
        )}
        {finalStep ? (
          <Button
            htmlType="button"
            data-testid="done-btn"
            type="primary"
            onClick={(e) => {
              e.preventDefault();
              onNextBtnClick();
            }}
          >
            Done
          </Button>
        ) : (
          <Button
            htmlType="button"
            data-testid="next-btn"
            type="primary"
            disabled={autosaving}
            onClick={(e) => {
              e.preventDefault();

              onNextBtnClick();
            }}
          >
            {nextStepTitle}
            <ArrowRightOutlined />
          </Button>
        )}
      </Space>
    </BudgetCreationFooterContainer>
  );
};
