export const validateEmail = (_rule: any, value: any) => {
  if (!value) {
    return Promise.reject(null);
  }

  const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

  if (!emailRegex.test(value)) {
    return Promise.reject("Please enter a valid email address");
  }

  return Promise.resolve();
};

export const validatePhoneNumber = (_rule: any, value: any) => {
  const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;

  if (value && !phoneRegex.test(value)) {
    return Promise.reject("Please enter a valid phone number");
  }

  return Promise.resolve();
};

export const validateZipCode = (_rule: any, value: any) => {
  if (!value) {
    return Promise.reject("Please enter your zip code");
  }

  const zipCodeRegex = /^\d{4,5}(?:-\d{4})?$/;

  if (!zipCodeRegex.test(value)) {
    return Promise.reject("Please enter a valid zip code");
  }

  return Promise.resolve();
};
