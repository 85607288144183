export const GreenMarginTrendUpwardArrow = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 6 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.72097 0.279029C5.84301 0.401068 5.84301 0.598932 5.72097 0.720971L0.720971 5.72097C0.598932 5.84301 0.401068 5.84301 0.279029 5.72097C0.15699 5.59893 0.15699 5.40107 0.279029 5.27903L5.27903 0.279029C5.40107 0.15699 5.59893 0.15699 5.72097 0.279029Z"
        fill="var(--shared-success)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.125 0.5C1.125 0.327411 1.26491 0.1875 1.4375 0.1875H5.5C5.67259 0.1875 5.8125 0.327411 5.8125 0.5V4.5625C5.8125 4.73509 5.67259 4.875 5.5 4.875C5.32741 4.875 5.1875 4.73509 5.1875 4.5625V0.8125H1.4375C1.26491 0.8125 1.125 0.672589 1.125 0.5Z"
        fill="var(--shared-success)"
      />
    </svg>
  );
};
