import * as reactRouter from "react-router-dom";

export const useNavigate = () => {
  const navigate = reactRouter.useNavigate();

  return (
    to: reactRouter.To | number,
    options: reactRouter.NavigateOptions = {}
  ) => {
    console.info(`Navigate ${to}`, options);

    if (typeof to === "number") {
      navigate(to);
    } else {
      navigate(to, options);
    }
  };
};

/**
 * A wrapper for useNavigate that preserves the query string.
 */
export const useNavigateWithQueryString = () => {
  const navigate = useNavigate();

  return (pathname: string) => {
    navigate({
      pathname,
      search: window.location.search,
    });
  };
};
