import { styled } from "@linaria/react";
import { ChangeEvent } from "react";
import {
  Button,
  Divider,
  Input,
  Select,
  Space,
  icons,
} from "@reifyhealth/picasso-pkg";
import { IncludedVisitActivityIcon } from "@components/common/icons/IncludedVisitActivityIcon";
import { CustomActivityPriceIcon } from "@components/common/icons/CustomActivityPriceIcon";
import { InvoicedSeparatelyIcon } from "@components/common/icons/InvoicedSeparatelyIcon";
import { useFlags } from "@app/hooks/useFlags";

const { SearchOutlined } = icons;

const StyledTableActions = styled.section`
  display: flex;
  justify-content: space-between;
  background: var(--component-background);
  padding: var(--size-4);
`;

const Legend = styled.section`
  display: flex;
  align-items: center;

  svg:not(:first-child) {
    margin: 0 var(--size-3) 0 var(--size-7);
  }

  svg:first-child {
    margin: 0 var(--size-3);
  }
`;

export interface TableActionsProps {
  handleVisitFilterChange: (event: ChangeEvent<HTMLInputElement>) => void;
  tracks: BudgetMatrixBodyTrack[];
  selectedTrack: string;
  onSelectNewActiveTrack: (value: string) => void;
  budgetTrackOptions: {
    label: string;
    options: { value: string; label: string }[];
  }[];
  hasUnscheduledVisitTemplate: boolean;
}

export function TableActions({
  handleVisitFilterChange,
  tracks,
  selectedTrack,
  onSelectNewActiveTrack,
  budgetTrackOptions,
  hasUnscheduledVisitTemplate,
}: TableActionsProps) {
  const flags = useFlags();

  return (
    <StyledTableActions>
      <Legend>
        <IncludedVisitActivityIcon />
        Included Visit Activity
        <CustomActivityPriceIcon />
        Custom Activity Price
        <InvoicedSeparatelyIcon />
        Invoiced Separately
      </Legend>
      <Space>
        <Input
          prefix={<SearchOutlined />}
          data-testid="find-visits-input"
          onChange={(e) => handleVisitFilterChange(e)}
          placeholder="Find Visits"
        />
        <Select
          disabled={tracks.length === 0}
          value={selectedTrack}
          onChange={onSelectNewActiveTrack}
          style={{ width: "226px" }}
          data-testid="track-selector"
          options={budgetTrackOptions}
          dropdownRender={(menu) => (
            <>
              {menu}
              {flags["site-financials-unscheduled-visits"] &&
                hasUnscheduledVisitTemplate && (
                  <>
                    <Divider style={{ margin: 0 }} />
                    <Button
                      block
                      style={{ textAlign: "left" }}
                      type="text"
                      onClick={() =>
                        onSelectNewActiveTrack("Unscheduled Visit")
                      }
                    >
                      Unscheduled Visit
                    </Button>
                  </>
                )}
            </>
          )}
        />
      </Space>
    </StyledTableActions>
  );
}
