import type { LogoutOptions } from "@auth0/auth0-react";

// NOTE: this is a little awkward, but the upside is that it provides a convenient
// place to preconfigure logout calls without needing to copy-paste at each call site.
export const logoutWithRedirect =
  (logout: (options?: LogoutOptions) => Promise<void>) =>
  // NOTE: the returnTo must be fully qualified and be on the allow list of logoutUrls in the auth0 client terraform configuration.
  (options?: LogoutOptions) =>
    logout({
      ...options,
      logoutParams: { returnTo: `${window.location.origin}/finance` },
    });
