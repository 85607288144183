import { Button, Card, Empty } from "@reifyhealth/picasso-pkg";
import { styled } from "@linaria/react";
import { css } from "@linaria/core";
import { BudgetInfo } from "./components/BudgetInfo";
import { BudgetActions } from "./components/BudgetActions";
import { ProtocolVersionList } from "./components/ProtocolVersionList";
import { BudgetVersionInfo } from "./components/BudgetVersionInfo";
import sortBy from "lodash/sortBy";
import { useNavigate } from "@app/hooks";

const BudgetCardElement = css`
  margin-bottom: var(--size-6) !important;

  .ant-card-body {
    padding: var(--size-6);
  }
`;

const BudgetVersionTitle = styled.section`
  display: flex;
  justify-content: space-between;

  .budget-info {
    display: flex;
    align-self: center;
    gap: var(--size-8);

    .budget-name {
      font-weight: 400;

      div.ant-typography-edit-content {
        top: 4px;
        left: 0;
        margin-bottom: 0;
        min-width: 400px;
      }
    }

    .budget-trial {
      span {
        margin-right: 8px;
        color: var(--component-secondary-text);
      }
    }
  }
`;

const BudgetVersionTable = styled.table`
  width: 100%;

  thead {
    border-bottom: 1px solid var(--component-border);
    text-align: left;
  }

  thead tr th {
    color: var(--component-tertiary-text);
    font-weight: 400;
  }

  tbody {
    background: var(--component-background-subtle);

    tr:not(:last-child) {
      border-bottom: 1px solid var(--component-border);
    }
  }

  .active-tag {
    color: var(--green-11);
    border: 1px solid var(--green-7);
    background: var(--green-4);
    border-radius: 100px;
  }

  .inactive-tag {
    color: var(--button-disabled-text);
    border: 1px solid var(--button-disabled-border);
    background: var(--button-disabled-background);
    border-radius: 100px;
  }

  td {
    padding-top: 16px;
  }

  .budget-version {
    vertical-align: top;
    padding-left: 16px;
    padding-bottom: 24px;
    width: 15%;
    min-width: 150px;
  }

  .protocol-version {
    vertical-align: top;
    min-width: 300px;
    width: 80%;
  }

  .protocol-versions {
    list-style: none;
    margin: 0;
    padding: 0;
    list-style-position: outside;
  }

  .protocol-versions li {
    padding: 4px 8px;
    margin-bottom: 4px;
    display: flex;
    justify-content: space-between;
    border: 1px solid var(--component-border);
    border-radius: var(--size-2);
  }

  .clickable-list {
    cursor: pointer;
  }

  .effective-date {
    display: flex;
    gap: var(--size-4);
  }

  .edit-dates {
    margin-left: 4px;

    &:hover {
      cursor: pointer;
    }
  }

  .actions {
    vertical-align: top;
    width: 5%;
    text-align: right;
  }
`;

interface IBudgetVersionCardProps {
  siteId: string;
  budget: BudgetVersion;
}

export const BudgetVersion = ({ siteId, budget }: IBudgetVersionCardProps) => {
  const navigate = useNavigate();

  const budgetConfigStatuses = budget.configVersions.map(
    (configVersion) => configVersion.status
  );
  const hasActiveBudget = (status: string) => status === "ACTIVE";

  const sortedConfigVersions = sortBy(budget.configVersions, "number");

  return (
    <Card
      size="small"
      extra={
        <BudgetActions
          siteTrialId={budget.trial.stId!}
          budgetName={budget.name}
          siteId={siteId}
          disableBudgetDelete={budgetConfigStatuses.some(hasActiveBudget)}
          budgetId={budget.id}
        />
      }
      title={
        <BudgetVersionTitle>
          <BudgetInfo
            siteId={siteId}
            budgetId={budget.id}
            name={budget.name}
            trialName={budget.trial.name}
          />
        </BudgetVersionTitle>
      }
      className={BudgetCardElement}
    >
      <BudgetVersionTable>
        <thead>
          <tr>
            <th style={{ paddingLeft: "16px" }}>Budget Version</th>
            <th>Protocol Version</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {sortedConfigVersions.length === 0 && (
            <tr key="empty">
              <td colSpan={2}>
                <Empty
                  data-testid="no-budget-versions"
                  description="No Budget Versions"
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
              </td>
            </tr>
          )}
          {sortedConfigVersions.map((configVersion) => (
            <tr key={configVersion.id}>
              <td className="budget-version">
                <BudgetVersionInfo
                  siteId={siteId}
                  budgetId={budget.id}
                  budgetConfigVersionId={configVersion.id}
                  number={configVersion.number}
                  status={configVersion.status}
                />
              </td>
              <td className="protocol-version">
                <ProtocolVersionList
                  budgetId={budget.id}
                  siteId={siteId}
                  key={`${configVersion.id}-protocol-version-list`}
                  budgetConfigVersionId={configVersion.id}
                  budgetConfigurationProtocolVersions={
                    configVersion.protocolVersions
                  }
                  protocolVersions={budget.protocol?.versions}
                  budgetName={budget.name}
                  siteTrialId={budget.trial.stId!}
                />
              </td>
              <td className="actions">
                <Button
                  data-testid="edit-budget-version-btn"
                  onClick={() =>
                    navigate(
                      `/finance/sites/${siteId}/budgets/${budget.id}/versions/${configVersion.id}/visit-charges`
                    )
                  }
                  style={{ margin: "0 16px" }}
                >
                  Edit
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </BudgetVersionTable>
    </Card>
  );
};
