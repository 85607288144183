import React, { useEffect, useState } from "react";
import { Typography, Switch, Popover } from "@reifyhealth/picasso-pkg";
import { useParams } from "react-router-dom";
import { styled } from "@linaria/react";
import {
  useActivateBudgetVersionMutation,
  useBudgetVersionOverviewDetails3Query,
  useDeactivateBudgetVersionMutation,
  useGetBudgetVersionSummary3Query,
} from "@service/generated";
import { useQueryClient } from "react-query";

const popoverActiveContent = (
  <div>
    <h4>
      <b>Deactivate Budget</b>
    </h4>
    <p>
      {" "}
      Set this budget to <i> Inactive </i> to<br></br> stop receiving completed
      <br></br> activities in{" "}
      <i>
        {" "}
        Accounts<br></br> Receivable.{" "}
      </i>
    </p>
  </div>
);

const popoverInactiveContent = (
  <div>
    <h4>
      <b>Activate Budget</b>
    </h4>
    <p>
      Set this budget to <i> Active </i> to<br></br> start receiving completed
      <br></br> activities in{" "}
      <i>
        {" "}
        Accounts<br></br> Receivable.
      </i>
    </p>
  </div>
);

const BudgetActiveSwitchContainer = styled.div`
  .budget-active-switch {
    margin-right: 8px;
  }

  .active-tag {
    color: var(--component-primary-text);
    cursor: pointer;
    padding: 3px 6px;
  }

  .inactive-tag {
    color: var(--component-disabled-text);
    cursor: pointer;
    padding: 3px 6px;
  }
`;

interface BudgetActiveSwitchProps {
  invalidateMutation: {
    siteTrialId: string;
    budgetId: string;
    budgetConfigVersionId: string;
  };
  status: "ACTIVE" | "INACTIVE" | undefined;
}

export const BudgetActiveSwitch = ({
  invalidateMutation,
  status,
}: BudgetActiveSwitchProps) => {
  const params = useParams();
  const queryClient = useQueryClient();
  const [budgetStatus, setBudgetStatus] = useState(false);
  useEffect(() => {
    if (status) {
      if (status === "ACTIVE") {
        setBudgetStatus(true);
      } else if (status === "INACTIVE") {
        setBudgetStatus(false);
      } else {
        setBudgetStatus(false);
      }
    }
  }, [status]);

  const { isLoading: activateIsLoading, mutate: activeBudgetVersion } =
    useActivateBudgetVersionMutation({
      onMutate: (_: any) => {
        setBudgetStatus(true);
      },
      onError: (_err: any, _entry: any) => {
        setBudgetStatus(false);
        queryClient.invalidateQueries(
          useGetBudgetVersionSummary3Query.getKey(invalidateMutation)
        );
        queryClient.invalidateQueries(
          useBudgetVersionOverviewDetails3Query.getKey(invalidateMutation)
        );
      },
      onSuccess: () => {
        queryClient.invalidateQueries(
          useGetBudgetVersionSummary3Query.getKey(invalidateMutation)
        );
        queryClient.invalidateQueries(
          useBudgetVersionOverviewDetails3Query.getKey(invalidateMutation)
        );
      },
    });
  const { isLoading: deactivateIsLoading, mutate: deactivateBudgetVersion } =
    useDeactivateBudgetVersionMutation({
      onMutate: (_: any) => {
        setBudgetStatus(false);
      },
      onError: (_err: any, _entry: any) => {
        setBudgetStatus(true);
        queryClient.invalidateQueries(
          useGetBudgetVersionSummary3Query.getKey(invalidateMutation)
        );
        queryClient.invalidateQueries(
          useBudgetVersionOverviewDetails3Query.getKey(invalidateMutation)
        );
      },
      onSuccess: () => {
        queryClient.invalidateQueries(
          useGetBudgetVersionSummary3Query.getKey(invalidateMutation)
        );
        queryClient.invalidateQueries(
          useBudgetVersionOverviewDetails3Query.getKey(invalidateMutation)
        );
      },
    });
  if (!status) {
    return null;
  }
  const budgetActive = (status: string) => {
    const popoverContent =
      status === "ACTIVE" ? popoverActiveContent : popoverInactiveContent;
    const classTags = status === "ACTIVE" ? "active-tag" : "inactive-tag";
    const clickFn =
      status === "ACTIVE"
        ? () =>
            deactivateBudgetVersion({
              input: {
                budgetId: params.budgetId!,
                budgetConfigVersionId: params.budgetConfigVersionId!,
              },
            })
        : () =>
            activeBudgetVersion({
              input: {
                budgetId: params.budgetId!,
                budgetConfigVersionId: params.budgetConfigVersionId!,
              },
            });
    const typographyType = status === "ACTIVE" ? undefined : "secondary";

    return (
      <BudgetActiveSwitchContainer>
        <Popover
          trigger="hover"
          placement="bottom"
          content={popoverContent}
          overlay={<></>}
        >
          <div className={classTags}>
            <Switch
              data-testid="budget-active-switch"
              loading={activateIsLoading || deactivateIsLoading}
              className="budget-active-switch"
              onClick={clickFn}
              checked={budgetStatus}
            ></Switch>
            <Typography.Text type={typographyType} onClick={clickFn}>
              Budget Active
            </Typography.Text>
          </div>
        </Popover>
      </BudgetActiveSwitchContainer>
    );
  };

  return budgetActive(status);
};
