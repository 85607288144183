import { styled } from "@linaria/react";
import { BudgetMatrixNonEmptyCell } from "@model/budgets/matrix";
import { useBudgetMatrix } from "@model/budgets/matrix/hooks";
import { css } from "@linaria/core";
import {
  Layout,
  icons,
  Alert,
  Checkbox,
  Form,
  Row,
  Space,
  Typography,
} from "@reifyhealth/picasso-pkg";
import { toFixed, toString } from "@lib/currency";
import MoneyInput from "@components/inputs/MoneyInput";
import { useEffect } from "react";

const { Sider } = Layout;
const { CloseOutlined } = icons;

interface VisitActivityFormInterface {
  charge: string | null;
  cost: string | null;
  invoiceable: boolean;
  category: string;
  holdbackEnabled: boolean;
  overheadEnabled: boolean;
}

const StyledForm = css`
  .with-padding {
    padding: var(--size-6);
  }

  .with-subtle-background {
    background-color: var(--component-background-subtle);
  }

  .ant-form-item {
    margin: 0;
  }

  .hidden {
    display: none;
  }

  .whats-this {
    margin-top: var(--size-4);
  }
`;

export const VisitActivityForm = ({
  budgetId,
  budgetConfigVersionId,
  siteTrialId,
  selectedTrack,
  cell,
}: {
  budgetId: string;
  budgetConfigVersionId: string;
  siteTrialId: string;
  selectedTrack: string;
  cell: BudgetMatrixNonEmptyCell;
}) => {
  const {
    holdbackPercentage,
    overheadPercentage,
    defaultCost,
    defaultCharge,
    visit,
  } = cell;
  const [, updateMatrix] = useBudgetMatrix({
    budgetId,
    budgetConfigVersionId,
    siteTrialId,
    track: selectedTrack,
  });

  const [form] = Form.useForm<VisitActivityFormInterface>();
  useEffect(() => {
    form.resetFields();
  }, [cell.activityCrossVersionId, cell.visitCrossVersionId, form]);

  const isInvoiceable = form.getFieldValue("invoiceable");
  const initialValues = {
    cost: cell.cost ? toString(cell.cost) : undefined,
    charge: cell.charge ? toString(cell.charge) : undefined,
    invoiceable: cell.invoiceable ?? cell.defaultInvoiceable,
    category: "",
    holdbackEnabled: cell.holdbackEnabled,
    overheadEnabled: cell.overheadEnabled,
  };

  return (
    <Form
      className={StyledForm}
      form={form}
      initialValues={initialValues}
      onFieldsChange={() =>
        updateMatrix("overrideActivityBudgetForVisit", {
          ...cell,
          ...form.getFieldsValue(),
        })
      }
      layout="vertical"
    >
      <section className="with-padding with-subtle-background">
        <Row>
          <Space>
            <Form.Item name="cost" label="Cost:">
              <MoneyInput
                data-testid="visit-activity-cost-input"
                placeholder={toFixed(defaultCost ?? "0.00")}
                disabled={!isInvoiceable && !!visit?.cost && !!visit.charge}
              />
            </Form.Item>
            <Form.Item required name="charge" label="Charge:">
              <MoneyInput
                data-testid="visit-activity-charge-input"
                placeholder={toFixed(defaultCharge ?? "0.00")}
                disabled={!isInvoiceable && !!visit?.cost && !!visit?.charge}
              />
            </Form.Item>
          </Space>
        </Row>
        {!isInvoiceable && !!visit?.cost && !!visit?.charge && (
          <Alert
            data-testid="visit-activity-adjust-alert"
            banner
            showIcon={false}
            type="warning"
            message="Cost / Charge can not be adjusted when there are Custom Visit Sub-totals, unless the activity is invoiceable."
          />
        )}
        <Form.Item
          label="Additional Options:"
          valuePropName="checked"
          name="invoiceable"
          style={{ marginTop: 16 }}
        >
          <Checkbox data-testid="visit-activity-invoiceable-checkbox">
            Invoiceable
          </Checkbox>
        </Form.Item>
        {holdbackPercentage ? (
          <Form.Item valuePropName="checked" name="holdbackEnabled">
            <Checkbox data-testid="visit-activity-holdback-checkbox">
              Holdback ({holdbackPercentage}%)
            </Checkbox>
          </Form.Item>
        ) : null}
        {overheadPercentage ? (
          <Form.Item valuePropName="checked" name="overheadEnabled">
            <Checkbox data-testid="visit-activity-overhead-checkbox">
              Overhead ({overheadPercentage}%)
            </Checkbox>
          </Form.Item>
        ) : null}
        <div style={{ marginTop: 16 }}>
          <Typography.Link
            disabled={!cell || !cell.hasBudgetVisitActivityConfig}
            data-testid="visit-activity-restore-defaults"
            type="danger"
            onClick={() => {
              form.setFieldsValue({
                cost: null,
                charge: null,
                invoiceable: cell.defaultInvoiceable,
                holdbackEnabled: cell.holdbackEnabled,
                overheadEnabled: cell.overheadEnabled,
              });
              updateMatrix("resetBudgetForActivityToDefaults", cell);
            }}
          >
            Restore Defaults
          </Typography.Link>
        </div>
      </section>
    </Form>
  );
};

const SiderContainer = styled.div<{ collapsed?: boolean }>`
  border-radius: var(--size-2);
  margin-left: var(--size-4);
  box-shadow: var(--shadows-elevated);
  background: var(--component-background);
  border: 1px solid var(--component-border);
  overflow: auto;

  header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--component-border);
    padding: var(--size-6);
    align-items: flex-start;
    position: sticky;
    top: 0;
    left: 0%;
    background: var(--component-background);
    z-index: 100;

    span {
      color: var(--component-tertiary-text);
      display: block;
      font-size: var(--font-size-body);
    }

    h5 {
      font-size: var(--font-size-h-5);
      font-weight: var(--font-weight-medium);
      margin: 0;
    }

    .close-button {
      padding: var(--size-4);
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: var(--duration-2);

      &:hover {
        background: var(--component-background-subtle);

        svg {
          fill: var(--component-primary-text);
        }
      }
    }
  }

  main {
    .activity-stats-readonly {
      padding: 0 var(--size-6);
      margin: var(--size-6) 0;
      display: flex;
      flex-direction: column;
    }
  }
`;

export const TableSidebar = ({
  budgetId,
  budgetConfigVersionId,
  siteTrialId,
  selectedTrack,
}: {
  budgetId: string;
  budgetConfigVersionId: string;
  siteTrialId: string;
  selectedTrack: string;
}) => {
  const [matrix, updateMatrix] = useBudgetMatrix({
    budgetId,
    budgetConfigVersionId,
    siteTrialId,
    track: selectedTrack,
  });
  const cell = matrix.selectedCell;
  if (!cell) return null;

  return (
    <SiderContainer data-testid="matrix-sidebar" collapsed={false}>
      <Sider
        defaultCollapsed
        collapsible
        trigger={null}
        collapsed={false}
        collapsedWidth={0}
        width={300}
      >
        <header>
          <div>
            <span>Visit Activity</span>
            <h5 data-testid="matrix-sidebar-visit-activity-name">
              {cell.activity.name}
            </h5>
          </div>

          <div
            className="close-button"
            onClick={() => {
              updateMatrix("stopEditing");
            }}
          >
            <CloseOutlined />
          </div>
        </header>
        <main>
          <VisitActivityForm
            budgetId={budgetId}
            budgetConfigVersionId={budgetConfigVersionId}
            siteTrialId={siteTrialId}
            selectedTrack={selectedTrack}
            cell={cell}
          />
          <div className="activity-stats-readonly">
            <Typography.Text type="secondary">Track Name</Typography.Text>
            <Typography.Text data-testid="matrix-sidebar-track-name">
              {selectedTrack}
            </Typography.Text>
          </div>
          <div className="activity-stats-readonly">
            <Typography.Text type="secondary">Visit Name</Typography.Text>
            <Typography.Text data-testid="matrix-sidebar-visit-name">
              {cell.visit?.name}
            </Typography.Text>
          </div>
        </main>
      </Sider>
    </SiderContainer>
  );
};
