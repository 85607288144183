import { useBudgetVersionOverviewDetails3Query } from "@app/service/generated";
import { useParams } from "react-router-dom";
import { overviewLineItems } from "@model/budgets";
import { useFlags } from "@hooks/useFlags";

export function useBudgetVersionOverview(siteTrialId: string) {
  const flags = useFlags();
  const params = useParams();

  const variables = {
    budgetId: params.budgetId!,
    budgetConfigVersionId: params.budgetConfigVersionId!,
    siteTrialId,
  };

  const {
    data: overviewDetails,
    isLoading,
    isFetching,
  } = useBudgetVersionOverviewDetails3Query(variables, {
    enabled: !!siteTrialId,
  });

  const budgetOverviewDetails = overviewDetails?.budget;

  const budgetConfiguration = budgetOverviewDetails?.configVersions[0];

  return {
    variables,
    budgetConfiguration,
    budgetOverviewDetails,
    isLoading,
    isFetching,
    budgetVersionOverviewKey:
      useBudgetVersionOverviewDetails3Query.getKey(variables),
    lineItems:
      !isLoading && budgetConfiguration && budgetOverviewDetails?.protocol
        ? overviewLineItems(
            budgetConfiguration,
            budgetOverviewDetails?.protocol,
            flags
          )
        : [],
  };
}
