import React, { useCallback } from "react";
import { styled } from "@linaria/react";
import { Table, Button, icons } from "@reifyhealth/picasso-pkg";
import {
  useCreateBudgetAdditionalItemMutation,
  useDeleteBudgetAdditionalItemMutation,
  useGetBudgetAdditionalCharges3Query,
  useGetBudgetVersionTotals3Query,
  useUpdateBudgetAdditionalItemMutation,
} from "@app/service/generated";
import { useStore } from "@app/store";
import { useQueryClient } from "react-query";
import { shallow } from "zustand/shallow";
import { BudgetVersionAdditionalChargeSummary } from "@components/tables/BudgetVersionAdditionalItemsTable/components/BudgetVersionAdditionalChargeSummary";
import { useBudget } from "@app/hooks";
import { isHoldbackEnabled, isOverheadEnabled } from "@model/budgets";

import { money } from "@lib/currency";
import {
  Checkbox,
  CheckboxChangeEvent,
  ColumnType,
  Select,
  TableColumnsType,
  Typography,
} from "@reifyhealth/picasso-pkg";
import { debounce } from "lodash";
import useDebounce from "@hooks/useDebounce";
import { DEFAULT_DEBOUNCE_TIME } from "@constants";
import { BudgetVersionAdditionalChargeDescription } from "@components/tables/BudgetVersionAdditionalItemsTable/components/BudgetVersionAdditionalChargeDescription";
import NumericInput from "@components/inputs/NumericInput";
import { additionalChargesCategories } from "@model/budgets";
import { toString } from "@lib/currency";
import MoneyInput from "@components/inputs/MoneyInput";
import { useBudgetMatrix } from "@model/budgets/matrix/hooks";

function nonNull<T>(array: T[]) {
  return array.filter((e): e is Exclude<typeof e, null> => e !== null);
}

export const useColumns = ({
  budgetId,
  budgetConfigVersionId,
  siteTrialId,
  holdbackPercentage,
  overheadPercentage,
  deleteBudgetAdditionalItem,
  updateBudgetAdditionalItem,
}: {
  budgetId: string;
  budgetConfigVersionId: string;
  siteTrialId: string;
  holdbackPercentage: string | null | undefined;
  overheadPercentage: string | null | undefined;
  deleteBudgetAdditionalItem: Function;
  updateBudgetAdditionalItem: Function;
}): TableColumnsType<BudgetAdditionalCharge> => {
  const categoryOptions = additionalChargesCategories.map((category) => ({
    label: category,
    value: category,
  }));
  const [, updateMatrix] = useBudgetMatrix({
    budgetId,
    budgetConfigVersionId,
    siteTrialId,
    track: "all",
  });

  const saveAdditionalCharge = useCallback(
    async (key: string, value: any, record: BudgetAdditionalCharge) => {
      if (key === "quantity" && value === 0) {
        await updateBudgetAdditionalItem({
          input: {
            ...{ [key]: null }, // quantity of 0 should be leveraged like Unlimited
            ...{ budgetId, budgetAdditionalChargeId: record.id },
          },
        });
      } else {
        await updateBudgetAdditionalItem({
          input: {
            ...{ [key]: value },
            ...{ budgetId, budgetAdditionalChargeId: record.id },
          },
        });
      }
      updateMatrix("updateBudgetAdditionalItem");
    },
    [updateMatrix, updateBudgetAdditionalItem, budgetId]
  );

  const debouncedChangeHandler = useDebounce(
    saveAdditionalCharge,
    DEFAULT_DEBOUNCE_TIME
  );

  const debouncedChangeHoldbackHandler = useDebounce(
    saveAdditionalCharge,
    DEFAULT_DEBOUNCE_TIME
  );

  const debouncedChangeOverheadHandler = useDebounce(
    saveAdditionalCharge,
    DEFAULT_DEBOUNCE_TIME
  );

  const debouncedChangeDescriptionHandler = useDebounce(
    saveAdditionalCharge,
    DEFAULT_DEBOUNCE_TIME
  );

  const debouncedChangeChargeHandler = useDebounce(
    saveAdditionalCharge,
    DEFAULT_DEBOUNCE_TIME
  );

  const debouncedChangeCostHandler = useDebounce(
    saveAdditionalCharge,
    DEFAULT_DEBOUNCE_TIME
  );

  const debouncedChangeQuantityHandler = useDebounce(
    saveAdditionalCharge,
    DEFAULT_DEBOUNCE_TIME
  );

  return nonNull<ColumnType<BudgetAdditionalCharge> | null>([
    {
      title: "",
      dataIndex: "",
      width: 80,
      render: (_: unknown, record: BudgetAdditionalCharge) => {
        return (
          <section style={{ textAlign: "center" }}>
            <Typography.Link
              data-testid="additional-charges-remove-link"
              onClick={() =>
                deleteBudgetAdditionalItem({
                  input: { budgetId, budgetAdditionalChargeId: record.id },
                })
              }
              type="danger"
            >
              Remove
            </Typography.Link>
          </section>
        );
      },
    },
    {
      title: () => (
        <Typography.Text data-testid="category-title">Category</Typography.Text>
      ),
      dataIndex: "category",
      key: "category",
      width: "192px",
      render: (
        _text: BudgetAdditionalCharge["category"],
        record: BudgetAdditionalCharge
      ) => {
        return (
          <Select
            data-testid="additional-charges-category-select"
            style={{ width: "100%" }}
            defaultValue={record.category!}
            onSelect={(value: string) =>
              debouncedChangeHandler("category", value, record)
            }
            placeholder="Choose a Category"
            options={categoryOptions}
          />
        );
      },
    },
    {
      title: () => (
        <Typography.Text data-testid="description-title">
          Description
        </Typography.Text>
      ),
      dataIndex: "description",
      key: "description",
      width: "419px",
      render: (
        text: BudgetAdditionalCharge["description"],
        record: BudgetAdditionalCharge
      ) => {
        return (
          <BudgetVersionAdditionalChargeDescription
            onChangeFn={(value) =>
              debouncedChangeDescriptionHandler("description", value, record)
            }
            description={text}
          />
        );
      },
    },
    {
      title: () => (
        <Typography.Text data-testid="invoiceable-title">
          Invoiceable
        </Typography.Text>
      ),
      dataIndex: "",
      width: "160px",
      align: "center",
      key: "invoiceable",
      render: (_: unknown, record: BudgetAdditionalCharge) => {
        return (
          <Checkbox
            data-testid="additional-charges-invoiceable-checkbox"
            onChange={debounce((event: CheckboxChangeEvent) =>
              debouncedChangeHandler(
                "invoiceable",
                event.target.checked,
                record
              )
            )}
            defaultChecked={record.invoiceable}
          />
        );
      },
    },
    holdbackPercentage
      ? {
          title: () => (
            <Typography.Text data-testid="holdback-title">
              Holdback
            </Typography.Text>
          ),
          dataIndex: "",
          width: "160px",
          align: "center",
          key: "holdback",
          render: (_: unknown, record: BudgetAdditionalCharge) => {
            const props = {
              "data-testid": "additional-charges-holdback-checkbox",
              defaultChecked: Boolean(record.holdback?.enabled),
              onChange: debounce((event: CheckboxChangeEvent) =>
                debouncedChangeHoldbackHandler(
                  "holdback",
                  event.target.checked,
                  record
                )
              ),
            };

            if (record.holdback?.percentage) {
              return (
                <Checkbox {...props}>{record.holdback?.percentage}%</Checkbox>
              );
            } else {
              return <Checkbox {...props} />;
            }
          },
        }
      : null,
    overheadPercentage
      ? {
          title: () => (
            <Typography.Text data-testid="overhead-title">
              Overhead
            </Typography.Text>
          ),
          dataIndex: "",
          width: "160px",
          align: "center",
          key: "overhead",
          render: (_: unknown, record: BudgetAdditionalCharge) => {
            const props = {
              "data-testid": "additional-charges-overhead-checkbox",
              defaultChecked: Boolean(record.overhead?.enabled),
              onChange: debounce((event: CheckboxChangeEvent) =>
                debouncedChangeOverheadHandler(
                  "overhead",
                  event.target.checked,
                  record
                )
              ),
            };

            if (record.overhead?.percentage) {
              return (
                <Checkbox {...props}>{record.overhead?.percentage}%</Checkbox>
              );
            } else {
              return <Checkbox {...props} />;
            }
          },
        }
      : null,
    {
      title: () => (
        <Typography.Text data-testid="cost-title">Cost</Typography.Text>
      ),
      dataIndex: "cost",
      key: "cost",
      width: "104px",
      render: (
        _: BudgetAdditionalCharge["cost"],
        record: BudgetAdditionalCharge
      ) => {
        return (
          <MoneyInput
            data-testid="additional-charge-cost-input"
            defaultValue={record.cost ? toString(record.cost) : ""}
            onChange={(e) =>
              debouncedChangeCostHandler(
                "cost",
                e.target.value ? money(e.target.value) : null,
                record
              )
            }
          />
        );
      },
    },
    {
      title: () => (
        <Typography.Text data-testid="charge-title">Charge</Typography.Text>
      ),
      dataIndex: "charge",
      key: "charge",
      width: "104px",
      render: (
        _: BudgetAdditionalCharge["charge"],
        record: BudgetAdditionalCharge
      ) => {
        return (
          <MoneyInput
            data-testid="additional-charge-charge-input"
            defaultValue={record.charge ? toString(record.charge) : ""}
            onChange={(e) =>
              debouncedChangeChargeHandler(
                "charge",
                e.target.value ? money(e.target.value) : null,
                record
              )
            }
          />
        );
      },
    },
    {
      title: () => (
        <Typography.Text data-testid="quantity-title">Quantity</Typography.Text>
      ),
      dataIndex: "quantity",
      key: "quantity",
      width: "122px",
      render: (
        _: BudgetAdditionalCharge["quantity"],
        record: BudgetAdditionalCharge
      ) => {
        return (
          <NumericInput
            data-testid="additional-quality-input"
            defaultValue={record.quantity!}
            min={0}
            max={999999}
            decimalScale={0}
            onChange={(e) => {
              const value = e.target.value ? +e.target.value : null;
              debouncedChangeQuantityHandler("quantity", value, record);
            }}
            placeholder="Unlimited"
            allowClear
          />
        );
      },
    },
  ]);
};

const AddAdditionalChargeSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
`;

const TableComponent = styled.div`
  .table-header {
    display: flex;
    justify-content: space-between;
  }

  .search-icon {
    color: var(--component-disabled-text);
    position: relative;
    top: var(--size-1);
    left: var(--size-1);
  }

  .active {
    background: var(--green-3);
    border-color: var(--green-6);
    color: var(--green-10);
    border-radius: 100px;
  }
`;

const LineItemsTable = ({
  budgetId,
  budgetConfigVersionId,
  additionalCharges,
  holdbackPercentage,
  overheadPercentage,
  siteTrialId,
}: {
  budgetId: string;
  budgetConfigVersionId: string;
  additionalCharges: BudgetAdditionalCharge[];
  holdbackPercentage?: string | null;
  overheadPercentage?: string | null;
  siteTrialId: string;
}) => {
  const setAutosaving = useStore((store) => store.setAutosaving);

  const queryClient = useQueryClient();

  const {
    mutate: createBudgetAdditionalItem,
    isLoading: createBudgetAdditionalItemLoading,
  } = useCreateBudgetAdditionalItemMutation({
    onMutate: () => setAutosaving(true),
    onError: () => setAutosaving(false),
    onSuccess: () => {
      setAutosaving(false);

      queryClient.invalidateQueries(
        useGetBudgetAdditionalCharges3Query.getKey({
          budgetId,
          budgetConfigVersionId,
        })
      );

      queryClient.invalidateQueries(
        useGetBudgetVersionTotals3Query.getKey({
          budgetId,
          budgetConfigVersionId,
          siteTrialId,
        })
      );
    },
  });

  const { mutateAsync: updateBudgetAdditionalItem } =
    useUpdateBudgetAdditionalItemMutation({
      onMutate: (variable) => {
        setAutosaving(true);

        const {
          input: { budgetId, budgetAdditionalChargeId, ...rest },
        } = variable;

        const additionalChargesQueryKey =
          useGetBudgetAdditionalCharges3Query.getKey({
            budgetId,
            budgetConfigVersionId,
          });

        const budgeVersionTotalQueryKey =
          useGetBudgetVersionTotals3Query.getKey({
            budgetId,
            budgetConfigVersionId,
            siteTrialId,
          });

        const previousAdditionalCharges:
          | BudgetAdditionalChargesQuery
          | undefined = queryClient.getQueryData(additionalChargesQueryKey);

        const previousTotals: BudgetVersionTotalsQuery | undefined =
          queryClient.getQueryData(budgeVersionTotalQueryKey);

        queryClient.setQueryData(
          budgeVersionTotalQueryKey,
          (oldData: BudgetVersionTotalsQuery | void) => {
            if (oldData) {
              oldData.budget.configVersions.map((configVersion) => {
                if (configVersion.id === budgetConfigVersionId) {
                  return configVersion.additionalCharges.lineItems.edges.map(
                    (edge) => {
                      if (edge.node.id === budgetAdditionalChargeId) {
                        return {
                          node: {
                            ...edge.node,
                            ...rest,
                          },
                        };
                      }

                      return edge;
                    }
                  );
                }

                return configVersion;
              });
            }

            return oldData;
          }
        );

        queryClient.setQueryData(
          additionalChargesQueryKey,
          (oldData: BudgetAdditionalChargesQuery | void) => {
            if (oldData) {
              oldData.budget.configVersions.map((configVersion) => {
                if (configVersion.id === budgetConfigVersionId) {
                  return configVersion.additionalCharges.lineItems.edges.map(
                    (edge) => {
                      if (edge.node.id === budgetAdditionalChargeId) {
                        return {
                          node: {
                            ...edge.node,
                            ...rest,
                          },
                        };
                      }

                      return edge;
                    }
                  );
                }

                return configVersion;
              });
            }

            return oldData;
          }
        );

        if (previousAdditionalCharges && previousTotals) {
          return { previousData: previousAdditionalCharges, previousTotals };
        }
      },
      onError: (error, variables, context) => {
        console.error({ error, variables });

        setAutosaving(false);
        const queryKey = useGetBudgetAdditionalCharges3Query.getKey({
          budgetId,
          budgetConfigVersionId,
        });

        const totals = useGetBudgetVersionTotals3Query.getKey({
          budgetId,
          budgetConfigVersionId,
          siteTrialId,
        });

        queryClient.setQueryData(queryKey, context?.previousData);
        queryClient.setQueryData(totals, context?.previousTotals);
      },
      onSuccess: () => {
        setAutosaving(false);
        queryClient.invalidateQueries(
          useGetBudgetAdditionalCharges3Query.getKey({
            budgetId,
            budgetConfigVersionId,
          })
        );

        queryClient.invalidateQueries(
          useGetBudgetVersionTotals3Query.getKey({
            budgetId,
            budgetConfigVersionId,
            siteTrialId,
          })
        );
      },
    });

  const { mutateAsync: deleteBudgetAdditionalItem } =
    useDeleteBudgetAdditionalItemMutation({
      onMutate: (variable) => {
        setAutosaving(true);

        const {
          input: { budgetId, budgetAdditionalChargeId },
        } = variable;

        const queryKey = useGetBudgetAdditionalCharges3Query.getKey({
          budgetId,
          budgetConfigVersionId,
        });
        const previousData: BudgetAdditionalChargesQuery | undefined =
          queryClient.getQueryData(queryKey);

        queryClient.setQueryData(
          queryKey,
          (oldData: BudgetAdditionalChargesQuery | void) => {
            if (oldData) {
              oldData.budget.configVersions.map((configVersion) => {
                if (configVersion.id === budgetConfigVersionId) {
                  return configVersion.additionalCharges.lineItems.edges.filter(
                    (edge) => edge.node.id !== budgetAdditionalChargeId
                  );
                }

                return configVersion;
              });
            }

            return oldData;
          }
        );

        if (previousData) {
          return { previousData };
        }
      },
      onError: (error, variables, context) => {
        console.error({ error, variables });

        setAutosaving(false);
        const queryKey = useGetBudgetAdditionalCharges3Query.getKey({
          budgetId,
          budgetConfigVersionId,
        });

        queryClient.setQueryData(queryKey, context?.previousData);
      },
      onSuccess: () => {
        setAutosaving(false);

        queryClient.invalidateQueries(
          useGetBudgetAdditionalCharges3Query.getKey({
            budgetId,
            budgetConfigVersionId,
          })
        );
        queryClient.invalidateQueries(
          useGetBudgetVersionTotals3Query.getKey({
            budgetId: budgetId!,
            budgetConfigVersionId: budgetConfigVersionId!,
            siteTrialId,
          })
        );
      },
    });

  const columns = useColumns({
    budgetId,
    budgetConfigVersionId: budgetConfigVersionId!,
    siteTrialId,
    holdbackPercentage,
    overheadPercentage,
    deleteBudgetAdditionalItem,
    updateBudgetAdditionalItem,
  });

  return (
    <TableComponent>
      <Table
        size="small"
        bordered={true}
        scroll={{ y: 400 }}
        pagination={false}
        columns={columns}
        dataSource={additionalCharges}
        footer={() => (
          <AddAdditionalChargeSection>
            <Button
              loading={createBudgetAdditionalItemLoading}
              type="dashed"
              size="large"
              block={true}
              icon={<icons.PlusOutlined />}
              data-testid="add-line-item-btn"
              onClick={() =>
                createBudgetAdditionalItem({
                  input: { budgetConfigVersionId, budgetId },
                })
              }
            >
              Line Item
            </Button>
          </AddAdditionalChargeSection>
        )}
      />
    </TableComponent>
  );
};

interface BudgetVersionListingTableProps {
  budgetId: string;
  budgetConfigVersionId: string;
}

export const AdditionalCharges = ({
  budgetId,
  budgetConfigVersionId,
}: BudgetVersionListingTableProps) => {
  const { budget } = useBudget({ track: "all" });

  const additionalCharges = useStore(
    (store) => store.budgetAdditionalCharges,
    shallow
  );
  const setAdditionalCharges = useStore(
    (store) => store.setBudgetAdditionalCharges,
    shallow
  );

  useGetBudgetAdditionalCharges3Query(
    { budgetId, budgetConfigVersionId },
    {
      onSuccess: (data) => {
        setAdditionalCharges(
          data.budget.configVersions.flatMap((version) =>
            version.additionalCharges.lineItems.edges.map((edge) => {
              return {
                ...edge.node,
                holdback: {
                  ...edge.node.holdback,
                  enabled: isHoldbackEnabled({ additionalCharge: edge.node }),
                  percentage: version.holdback?.percentage,
                },
                overhead: {
                  ...edge.node.overhead,
                  enabled: isOverheadEnabled({ additionalCharge: edge.node }),
                  percentage: version.overhead?.percentage,
                },
              };
            })
          )
        );
      },
    }
  );

  const budgetConfigVersion = (budget?.configVersions ?? []).find(
    (configVersion) => {
      return configVersion.id === budgetConfigVersionId;
    }
  );
  const holdbackPercentage = budgetConfigVersion?.holdback?.percentage;
  const overheadPercentage = budgetConfigVersion?.overhead?.percentage;

  return (
    <>
      <LineItemsTable
        budgetId={budgetId}
        budgetConfigVersionId={budgetConfigVersionId}
        siteTrialId={budget?.trial.stId || ""}
        holdbackPercentage={holdbackPercentage}
        overheadPercentage={overheadPercentage}
        additionalCharges={additionalCharges}
      />
      <BudgetVersionAdditionalChargeSummary />
    </>
  );
};
