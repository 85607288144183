import { useSelectedSite } from "@app/hooks";
import { styled } from "@linaria/react";
import { css } from "@linaria/core";
import { useParams } from "react-router-dom";
import {
  useGetSiteSettingsQuery,
  useUpdateSiteSettingsMutation,
} from "@app/service/generated";
import {
  Button,
  Card,
  Form,
  icons,
  Input,
  notification,
  Space,
  Typography,
  useForm,
  Row,
  Col,
  message,
} from "@reifyhealth/picasso-pkg";
import {
  validateEmail,
  validatePhoneNumber,
  validateZipCode,
} from "./validators";
import { formatPhoneNumber } from "./formatters";
import { useQueryClient } from "react-query";
import useFileUpload from "@app/hooks/useFileUpload";
import { useEffect, useState } from "react";

const { PlusOutlined } = icons;

interface SiteSettingsFormShape {
  siteId: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zipCode: string;
  phoneNumber: string;
  emailAddress: string;
  contactName: string;
  logoUrl: string;
}

const cardUploader = css`
  background: var(--component-background-subtle);
  gap: var(--size-6);
  padding: var(--size-6);
  display: flex;
  border-radius: var(--size-2);
  margin-bottom: var(--size-6);
`;

const siteSettingsContainer = css`
  width: 600px;

  .site-settings-title {
    display: flex;
    flex-direction: column;
    margin-bottom: var(--size-8);

    .title {
      color: var(--component-primary-text);
      font-size: var(--size-8);
      font-weight: var(--font-weight-medium);
    }

    .subtitle {
      color: var(--component-secondary-text);
    }
  }

  .ant-form-item {
    margin-bottom: var(--size-4);
  }

  .actions {
    margin-top: var(--size-5);
  }
`;

const Upload = styled.label`
  input[type="file"] {
    display: none;
  }

  cursor: pointer;
`;

const Section = styled.section`
  .container {
    text-align: center;
    position: relative;
    width: 120px;
    height: 120px;
    border: 1px solid var(--component-border);
    border-radius: var(--size-2);
    background: var(--component-background);
    animation: pulse 1s infinite ease-in-out;

    img {
      width: 100%;
    }

    &:hover {
      border: 1px solid var(--component-focus-border);
      box-shadow: var(--focus-input-focus);
    }
  }

  .removal {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .new-upload {
    width: 120px;
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: var(--component-background);
    padding: var(--size-11) var(--size-4);

    .title {
      margin-top: var(--size-4);
      width: 100%;
      font-size: var(--size-5);
    }

    border: 1px dashed var(--component-border);
    border-radius: 2%;
  }

  .bottom {
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.5;
    background-color: var(--component-active-highlight);
    color: var(--brand-white);
    text-align: center;
    width: 100%;
  }
`;

export const SiteSettingsForm = () => {
  const queryClient = useQueryClient();
  const [siteSettingsForm] = useForm<SiteSettingsFormShape>();
  const { data } = useSelectedSite();
  const params = useParams();
  const [logoUrl, setLogoUrl] = useState<string | undefined | null>(null);

  const siteId = data?.selectedSite?.value;

  const { handleImageUpload, uploadUrl, setUploadUrl, isUploading } =
    useFileUpload(params.siteId!);

  const { data: siteSettings, isLoading: siteSettingsLoading } =
    useGetSiteSettingsQuery({ siteId: siteId! }, { enabled: Boolean(siteId!) });

  const initialValues = siteSettings?.Financials2__siteSettings;

  useEffect(() => {
    setLogoUrl(siteSettings?.Financials2__siteSettings?.logoUrl);
  }, [siteSettings?.Financials2__siteSettings?.logoUrl]);

  const { mutate: updateSiteSettings, isLoading: updateSiteSettingsLoading } =
    useUpdateSiteSettingsMutation({
      onMutate: (variables) => {
        const queryKey = useGetSiteSettingsQuery.getKey({ siteId: siteId! });

        const previousData: IGetSiteSettingsQuery | undefined =
          queryClient.getQueryData(queryKey);

        queryClient.setQueryData(
          queryKey,
          (oldData: IGetSiteSettingsQuery | void) => {
            if (oldData) {
              const input = {
                ...variables.input,
                addressLine2: variables.input.addressLine2 ?? null,
                contactName: variables.input.contactName ?? null,
                logoUrl: variables.input.logoUrl ?? null,
              };
              const { siteId, ...rest } = input;

              return {
                Financials2__siteSettings: { site: { id: siteId }, ...rest },
              };
            }

            return oldData;
          }
        );

        if (previousData) {
          return { previousData };
        }
      },
      onError: (error, variables, context) => {
        console.error({ error, variables });
        const queryKey = useGetSiteSettingsQuery.getKey({
          siteId: siteId!,
        });

        queryClient.setQueryData(queryKey, context?.previousData);
      },
      onSuccess: () => {
        setUploadUrl(undefined);
        notification.success({
          duration: 2,
          message: "Site Settings have been saved.",
        });

        queryClient.invalidateQueries(
          useGetSiteSettingsQuery.getKey({ siteId: params.siteId! })
        );
      },
    });

  if (!siteSettings) {
    return null;
  }

  const isLoading = siteSettingsLoading || updateSiteSettingsLoading;

  const uploadButton = (
    <div className="new-upload">
      <PlusOutlined />
      <Typography.Text className="title">Upload Photo</Typography.Text>
    </div>
  );

  const handleFileChange = (file: File) => handleImageUpload(file);

  const handleCancel = () => {
    siteSettingsForm.resetFields();
    setUploadUrl(undefined);
  };

  const handleUploadInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const uploadFile = e.target.files[0];

      const maxSizeInBytes = 3000000; // 3 MB

      if (uploadFile.size > maxSizeInBytes) {
        message.error(
          "File exceeds allowed file upload size! Please try again!"
        );
        e.target.value = ""; // Reset the input field
      } else {
        setLogoUrl(null);
        setUploadUrl(undefined);
        handleFileChange(uploadFile);
      }
    }
  };

  const handleUploadDrop = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];

    const maxSizeInBytes = 3000000; // 3 MB

    const accept = "image/png,image/jpeg";

    if (file.size > maxSizeInBytes) {
      message.error("File exceeds allowed file upload size! Please try again!");
    } else if (!accept.includes(file.type)) {
      message.error("File is not of an acceptable type! Please try again!");
    } else {
      setLogoUrl(null);
      setUploadUrl(undefined);
      handleFileChange(file);
    }
  };

  const RemoveButton = () => (
    <Button
      onClick={() => {
        setLogoUrl(null);
        setUploadUrl(undefined);
      }}
      data-testid="site-settings-remove-btn"
      type="text"
      danger
    >
      Remove
    </Button>
  );

  return (
    <section className={siteSettingsContainer}>
      <div className="site-settings-title" data-testid="site-settings-title">
        <Typography.Text className="title">Site Settings</Typography.Text>
        <Typography.Text className="subtitle">
          Update site name, address, phone, and emails. Additionally, add a
          profile picture, or company logo.
        </Typography.Text>
      </div>
      <Card bordered>
        <div className={cardUploader}>
          <Section>
            <Upload
              htmlFor="uploadLogo"
              onDragOver={(e: any) => e.preventDefault()}
              onDrop={handleUploadDrop}
            >
              <input
                data-testid="site-settings-upload-input"
                disabled={isUploading}
                id="uploadLogo"
                type="file"
                accept="image/png,image/jpeg"
                onChange={handleUploadInputChange}
              />
              {!logoUrl && !uploadUrl && uploadButton}
            </Upload>
            {uploadUrl && (
              <section className="removal">
                <div className="container">
                  <img
                    data-testid="preview-img"
                    src={uploadUrl}
                    alt="site-logo-preview"
                  />
                  <div data-testid="preview-txt" className="bottom">
                    Preview
                  </div>
                </div>
                <RemoveButton />
              </section>
            )}
            {logoUrl && !uploadUrl && (
              <section className="removal">
                <div className="container">
                  <Upload
                    htmlFor="logoUrl"
                    onDragOver={(e: any) => e.preventDefault()}
                    onDrop={handleUploadDrop}
                  >
                    <img src={logoUrl} alt="site-logo" />
                    <input
                      data-testid="logo-url-input"
                      disabled={isUploading}
                      id="logoUrl"
                      type="file"
                      accept="image/png,image/jpeg"
                      onChange={handleUploadInputChange}
                    />
                  </Upload>
                </div>
                <RemoveButton />
              </section>
            )}
          </Section>
          <div>
            <Typography.Title level={5}>
              Upload your company logo
            </Typography.Title>
            <Typography.Paragraph type="secondary">
              Drag or click to upload a logo. We support <kbd>JPEG</kbd> and{" "}
              <kbd>PNG</kbd> formats that are 3 MB or less. For best results, we
              recommend using a square photo.
            </Typography.Paragraph>
          </div>
        </div>
        <Form
          id="siteSettings"
          form={siteSettingsForm}
          layout="vertical"
          initialValues={{
            // hydrated this way since site settings starts as null
            // mutation expects empty strings in input
            ...{
              siteId: siteId,
              addressLine1: "",
              addressLine2: "",
              city: "",
              state: "",
              zipCode: "",
              phoneNumber: "",
              emailAddress: "",
              contactName: "",
              logoUrl: "",
            },
            ...initialValues,
            ...{ logoUrl },
          }}
          onFinish={(values) =>
            updateSiteSettings({
              input: {
                ...values,
                ...(uploadUrl && { logoUrl: uploadUrl }),
                ...(!uploadUrl && !logoUrl && { logoUrl: null }),
              },
            })
          }
        >
          <Form.Item label="Site Name" required>
            <Input
              data-testid="site-settings-selected-site-input"
              disabled
              value={data?.selectedSite?.label}
            />
          </Form.Item>
          <Form.Item name="siteId" label="Site ID" style={{ display: "none" }}>
            <Input disabled />
          </Form.Item>
          <Form.Item
            required
            name="addressLine1"
            rules={[{ required: true, message: "Please enter your address 1" }]}
            label="Address"
          >
            <Input
              data-testid="site-settings-address-1-input"
              disabled={isLoading}
              placeholder="Address 1"
            />
          </Form.Item>
          <Form.Item name="addressLine2">
            <Input
              data-testid="site-settings-address-2-input"
              disabled={isLoading}
              placeholder="Address 2"
            />
          </Form.Item>
          <Form.Item
            name="city"
            required
            rules={[{ required: true, message: "Please enter your city" }]}
          >
            <Input
              disabled={isLoading}
              placeholder="City"
              data-testid="site-settings-city-input"
            />
          </Form.Item>
          <Row style={{ marginBottom: "12px", placeContent: "space-between" }}>
            <Col style={{ width: "49%" }}>
              <Form.Item
                name="state"
                required
                rules={[{ required: true, message: "Please enter your state" }]}
              >
                <Input
                  disabled={isLoading}
                  placeholder="State"
                  data-testid="site-settings-state-input"
                />
              </Form.Item>
            </Col>
            <Col style={{ width: "49%" }}>
              <Form.Item
                name="zipCode"
                required
                rules={[
                  { required: true, message: "" },
                  {
                    validator: validateZipCode,
                    validateTrigger: "onSubmit",
                  },
                ]}
              >
                <Input
                  disabled={isLoading}
                  placeholder="Zip Code"
                  type="number"
                  data-testid="site-settings-zipcode-input"
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            required
            name="phoneNumber"
            rules={[
              { message: "", required: true },
              {
                validator: validatePhoneNumber,
                validateTrigger: "onSubmit",
              },
            ]}
            label="Phone"
          >
            <Input
              placeholder="Enter a value"
              disabled={isLoading}
              data-testid="site-settings-phone-input"
              onChange={(e) => {
                const { value } = e.target;

                siteSettingsForm.setFieldsValue({
                  phoneNumber: formatPhoneNumber(value),
                });
              }}
            />
          </Form.Item>
          <Form.Item
            required
            name="emailAddress"
            rules={[
              { message: "Please enter your email address", required: true },
              {
                validator: validateEmail,
                validateTrigger: "onSubmit",
              },
            ]}
            label="Email"
          >
            <Input
              disabled={isLoading}
              placeholder="Enter a value"
              data-testid="site-settings-email-address-input"
            />
          </Form.Item>
          <Form.Item name="contactName" label="Contact Name">
            <Input
              placeholder="Enter a value"
              disabled={isLoading}
              data-testid="site-settings-contact-name-input"
            />
          </Form.Item>
          <Space className="actions">
            <Button
              type="primary"
              htmlType="submit"
              data-testid="site-settings-save-btn"
            >
              Save
            </Button>
            <Button
              data-testid="site-settings-cancel-btn"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Space>
        </Form>
      </Card>
    </section>
  );
};
