import { PageLayout } from "@components/PageLayout";
import { PartnerDetailsHeader } from "@app/components/headers/PartnerDetailsHeader";
import {
  Button,
  Tabs,
  Tooltip,
  Typography,
  Empty,
  notification,
  useForm,
  Space,
  List,
  icons,
} from "@reifyhealth/picasso-pkg";
import { styled } from "@linaria/react";
import { showDeleteModal } from "@app/components/common/modals";
import { InvoicesTable } from "@app/components/tables/InvoicesTable";
import { PartnerContact } from "@app/components/cards";
import {
  useCreateContactMutation,
  useDeleteContactMutation,
  useDeletePartnerMutation,
  usePartnerDetailsQuery,
  useUpdateContactMutation,
} from "@app/service/generated";
import { useParams, useSearchParams } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useCallback, useState } from "react";
import { PartnerContactFormModal } from "@app/components/forms";
import { useNavigate } from "@app/hooks";
import { PaymentsListingTable } from "@app/components/tables";
import Loading from "@app/components/Loading";
import { sortBy } from "lodash";
const { EnvironmentOutlined } = icons;

const TabPane = Tabs.TabPane;

const PartnerDetailsComponent = styled.section`
  display: flex;
  justify-content: space-between;
  color: var(--application-secondary-text);

  .partner-title {
    display: flex;
    flex-direction: column;
    align-items: left;
  }

  .partner {
    font-size: var(--size-5);
  }

  .partner-name {
    font-size: var(--size-7);
  }

  .partner-address {
    display: flex;
    gap: var(--size-4);
  }
`;

const AddPartnerActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: var(--size-4);
`;

export const PartnerDetailPage = ({ tab }: { tab?: string }) => {
  const queryClient = useQueryClient();
  const params = useParams();
  const navigate = useNavigate();
  const [showAddContactForm, setShowAddContactForm] = useState(false);
  const [searchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState<string>(tab ?? "contacts");

  const handleTabChange = useCallback(
    (key) => {
      // Stop re-render on tab change
      window.history.pushState(
        null,
        "",
        `/finance/sites/${params.siteId}/partners/${params.partnerId}/${key}?${searchParams}`
      );
      setActiveTab(key);
    },
    [params.siteId, params.partnerId, searchParams]
  );

  const { data, isLoading, isFetching } = usePartnerDetailsQuery(
    { partnerId: params.partnerId! },
    { enabled: !!params.partnerId }
  );

  const { mutate: deleteContact, isLoading: isCreatingContact } =
    useDeleteContactMutation({
      onMutate: (variables) => {
        const queryKey = usePartnerDetailsQuery.getKey({
          partnerId: params.partnerId!,
        });
        const previousData: IPartnerDetailsQuery | undefined =
          queryClient.getQueryData(queryKey);

        queryClient.setQueryData(
          queryKey,
          (oldData: IPartnerDetailsQuery | void) => {
            if (oldData) {
              const updatedContacts =
                oldData.Financials2__partnerDetails.partner.contacts.filter(
                  (contact) => contact.id !== variables.input.contactId
                );

              return {
                Financials2__partnerDetails: {
                  partner: {
                    ...oldData.Financials2__partnerDetails.partner,
                    contacts: updatedContacts,
                  },
                },
              };
            }

            return oldData;
          }
        );

        if (previousData) {
          return { previousData };
        }
      },
      onError: (error, variables, context) => {
        console.error({ error, variables });
        const queryKey = usePartnerDetailsQuery.getKey({
          partnerId: params.partnerId!,
        });

        queryClient.setQueryData(queryKey, context?.previousData);
      },
      onSuccess: () => {
        notification.success({
          duration: 2,
          message: "Contact has been deleted",
        });

        queryClient.invalidateQueries(
          usePartnerDetailsQuery.getKey({ partnerId: params.partnerId! })
        );
      },
    });

  const { mutate: createContact } = useCreateContactMutation({
    onMutate: (variables) => {
      const queryKey = usePartnerDetailsQuery.getKey({
        partnerId: params.partnerId!,
      });
      const previousData: IEditPartnerDetailsQuery | undefined =
        queryClient.getQueryData(queryKey);

      queryClient.setQueryData(
        queryKey,
        (oldData: IEditPartnerDetailsQuery | void) => {
          if (oldData) {
            const updatedContacts =
              oldData.Financials2__partnerDetails.partner.contacts.concat([
                { id: "", ...variables.input },
              ]);

            return {
              Financials2__partnerDetails: {
                partner: {
                  ...oldData.Financials2__partnerDetails.partner,
                  contacts: updatedContacts,
                },
              },
            };
          }

          return oldData;
        }
      );

      if (previousData) {
        return { previousData };
      }
    },
    onError: (error, variables, context) => {
      console.error({ error, variables });
      const queryKey = usePartnerDetailsQuery.getKey({
        partnerId: params.partnerId!,
      });

      queryClient.setQueryData(queryKey, context?.previousData);
    },
    onSuccess: () => {
      notification.success({
        duration: 2,
        message: "Contact has been added!",
      });

      queryClient.invalidateQueries(
        usePartnerDetailsQuery.getKey({ partnerId: params.partnerId! })
      );
    },
  });

  const { mutate: updateContact, isLoading: isUpdatingContact } =
    useUpdateContactMutation({
      onMutate: (variables) => {
        const queryKey = usePartnerDetailsQuery.getKey({
          partnerId: params.partnerId!,
        });
        const previousData: IPartnerDetailsQuery | undefined =
          queryClient.getQueryData(queryKey);

        queryClient.setQueryData(
          queryKey,
          (oldData: IPartnerDetailsQuery | void) => {
            if (oldData) {
              const updatedContacts =
                oldData.Financials2__partnerDetails.partner.contacts.map(
                  (contact) => {
                    if (contact.id === variables.input.contactId) {
                      const { partnerId, contactId, ...rest } = variables.input;

                      return { ...rest, id: contactId };
                    }

                    return contact;
                  }
                );

              return {
                Financials2__partnerDetails: {
                  partner: {
                    ...oldData.Financials2__partnerDetails.partner,
                    contacts: updatedContacts,
                  },
                },
              };
            }

            return oldData;
          }
        );

        if (previousData) {
          return { previousData };
        }
      },
      onError: (error, variables, context) => {
        console.error({ error, variables });
        const queryKey = usePartnerDetailsQuery.getKey({
          partnerId: params.partnerId!,
        });

        queryClient.setQueryData(queryKey, context?.previousData);
      },
      onSuccess: () => {
        notification.success({
          duration: 2,
          message: "Contact has been updated!",
        });

        queryClient.invalidateQueries(
          usePartnerDetailsQuery.getKey({ partnerId: params.partnerId! })
        );
      },
    });
  const [partnerContactForm] = useForm<{
    id?: string;
    name: string;
    emailAddress: string;
    phoneNumber: string;
    isPrimary: boolean;
  }>();

  const toggleCreateContactForm = (contact: {
    emailAddress: string;
    id: string;
    isPrimary: boolean;
    name: string;
    phoneNumber: string;
  }) => {
    if (!showAddContactForm) {
      partnerContactForm.setFieldsValue(contact);
    } else {
      partnerContactForm.resetFields();
    }

    setShowAddContactForm(!showAddContactForm);
  };

  const { mutate: deletePartner } = useDeletePartnerMutation({
    onError: () => {
      notification.error({
        duration: 2,
        message: "Partner was not deleted",
      });
    },
    onSuccess: () => {
      // since partner is being deleted move back to listing
      navigate(`/finance/sites/${params.siteId}/partners?${searchParams}`);

      notification.success({
        duration: 2,
        message: "Partner and contacts have been deleted",
      });
    },
  });

  const onPartnerContactFormFinishFn = (values: {
    id?: string;
    name: string;
    emailAddress: string;
    phoneNumber: string;
    isPrimary: boolean;
  }) => {
    const { id, ...valuesWithoutId } = values;

    if (id) {
      updateContact({
        input: {
          ...valuesWithoutId,
          contactId: id,
          partnerId: params.partnerId!,
        },
      });
    } else {
      createContact({
        input: { ...valuesWithoutId, partnerId: params.partnerId! },
      });
    }
    partnerContactForm.resetFields();
    toggleCreateContactForm({ ...values, id: "" });
  };

  const contacts =
    data?.Financials2__partnerDetails.partner.contacts.sort((a, b) =>
      a.name.localeCompare(b.name)
    ) ?? [];

  const invoiceContactIds = data?.Financials2__partnerDetails.partner.invoices
    .filter((invoice) => invoice.externalStatus === "DRAFT")
    .map((invoice) => invoice.toContactId);

  const invoices = data?.Financials2__partnerDetails.partner.invoices ?? [];

  const payments = data?.Financials2__partnerDetails.partner.payments ?? [];

  const partnerCannotDelete =
    payments.some(
      (payment) =>
        payment.allocationStatus === "PARTIALLY_ALLOCATED" ||
        payment.allocationStatus === "FULLY_ALLOCATED"
    ) || invoices.some((invoice) => invoice.externalStatus === "OPEN");

  return (
    <PageLayout Header={PartnerDetailsHeader} showSider>
      {isLoading || isFetching ? (
        <Loading />
      ) : (
        <>
          <PartnerContactFormModal
            form={partnerContactForm}
            showAddContactForm={showAddContactForm}
            hasPrimaryContact={
              data?.Financials2__partnerDetails.partner.contacts.some(
                (contact) => contact.isPrimary
              ) ?? false
            }
            onFormFinishFn={onPartnerContactFormFinishFn}
            toggleCreateContactForm={toggleCreateContactForm}
            isSavingContact={isUpdatingContact || isCreatingContact}
            showPrimaryCheckbox
          />
          <PartnerDetailsComponent>
            <div className="partner-title" data-testid="partner-title">
              <span className="partner">Partner</span>
              <Typography.Text
                className="partner-name"
                data-testid="partner-details-partner-name"
              >
                {data?.Financials2__partnerDetails.partner.name ?? ""}
              </Typography.Text>
              <Typography.Text className="partner-address" type="secondary">
                <span>
                  <EnvironmentOutlined />
                </span>
                <span data-testid="partner-details-partner-address-line-1">
                  {data?.Financials2__partnerDetails.partner.addressLine1}
                </span>

                <span data-testid="partner-details-partner-address-line-2">
                  {data?.Financials2__partnerDetails.partner.addressLine2}
                </span>

                <span data-testid="partner-details-city-and-state">
                  {data?.Financials2__partnerDetails.partner.city},{" "}
                  {data?.Financials2__partnerDetails.partner.state}
                </span>

                <span data-testid="partner-details-zipcode">
                  {data?.Financials2__partnerDetails.partner.zipCode}
                </span>
              </Typography.Text>
            </div>
            <div>
              <Space>
                <Button
                  data-testid="edit-partner-btn"
                  onClick={() => {
                    navigate(
                      `/finance/sites/${params.siteId}/partners/${params.partnerId}/edit?${searchParams}`
                    );
                  }}
                >
                  Edit Partner
                </Button>

                {partnerCannotDelete ? (
                  <Tooltip
                    trigger={"hover"}
                    placement="left"
                    title={
                      "Partner with open invoices or partially allocated payments cannot be deleted!"
                    }
                  >
                    <Button data-testid="delete-partner-link" disabled>
                      Delete Partner
                    </Button>
                  </Tooltip>
                ) : (
                  <Button
                    type="default"
                    danger={true}
                    data-testid="delete-partner-link"
                    onClick={() =>
                      showDeleteModal({
                        title: "Delete Partner",
                        content: (
                          <section style={{ display: "flex" }}>
                            This will remove partner and any related information
                            to the partner. This cannot be undone.
                          </section>
                        ),
                        okText: "",
                        onOkFunction: () => {
                          deletePartner({
                            input: { partnerId: params.partnerId! },
                          });
                        },
                      })
                    }
                  >
                    Delete Partner
                  </Button>
                )}
              </Space>
            </div>
          </PartnerDetailsComponent>
          <Tabs
            defaultActiveKey={tab || activeTab}
            onTabClick={handleTabChange}
          >
            <TabPane tab="Contacts" key="contacts">
              {contacts.length === 0 && (
                <Empty
                  description={
                    <section
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <Typography.Text
                        type="secondary"
                        data-testid="no-contacts-added-verbiage"
                      >
                        No contacts have been added
                      </Typography.Text>
                      <Typography.Link
                        data-testid="partner-detail-add-contact-btn"
                        onClick={() =>
                          toggleCreateContactForm({
                            id: "",
                            emailAddress: "",
                            phoneNumber: "",
                            name: "",
                            isPrimary: true,
                          })
                        }
                      >
                        Add Contact
                      </Typography.Link>
                    </section>
                  }
                />
              )}

              {contacts.length > 0 && (
                <>
                  <AddPartnerActionContainer>
                    <Button
                      data-testid="partner-detail-add-contact-btn"
                      onClick={() =>
                        toggleCreateContactForm({
                          id: "",
                          emailAddress: "",
                          phoneNumber: "",
                          name: "",
                          isPrimary: false,
                        })
                      }
                    >
                      Add Contact
                    </Button>
                  </AddPartnerActionContainer>

                  <List bordered>
                    {sortBy(contacts, "name").map((contact) => (
                      <PartnerContact
                        key={contact.id}
                        toggleCreateContactFormFn={() =>
                          toggleCreateContactForm(contact)
                        }
                        hasPrimaryContact={contacts.some(
                          (contact) => contact.isPrimary
                        )}
                        isContactTiedToDraftInvoice={
                          invoiceContactIds?.some(
                            (invoiceContactId) =>
                              invoiceContactId === contact.id
                          ) ?? false
                        }
                        contact={contact}
                        onDeleteClickFn={() =>
                          deleteContact({ input: { contactId: contact.id } })
                        }
                        onCheckboxChange={(e) => {
                          const { checked } = e.target;

                          // id is not part of update contact. removed.
                          const { id, ...contactWithoutId } = contact;

                          updateContact({
                            input: {
                              ...contactWithoutId,
                              ...{ isPrimary: checked },
                              ...{
                                contactId: contact.id,
                                partnerId: params.partnerId!,
                              },
                            },
                          });
                        }}
                      />
                    ))}
                  </List>
                </>
              )}
            </TabPane>
            <TabPane tab="Invoices" key="invoices">
              <InvoicesTable
                siteId={params.siteId!}
                invoices={invoices}
                isLoading={isLoading || isFetching}
                reactQueryKey={{ siteId: params.siteId! }}
              />
            </TabPane>
            <TabPane tab="Payments" key="payments">
              <PaymentsListingTable
                payments={payments}
                isTableLoading={isLoading || isFetching}
              />
            </TabPane>
          </Tabs>
        </>
      )}
    </PageLayout>
  );
};
