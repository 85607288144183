import { PageLayout } from "@components/PageLayout";
import { SiteSettingsForm } from "@app/components/forms";
import { BudgetVersionSiteSelectorHeader } from "@app/components/headers/BudgetVersionSiteSelectorHeader";

export const SettingsPage = () => {
  return (
    <PageLayout Header={BudgetVersionSiteSelectorHeader} showSider>
      <section>
        <SiteSettingsForm />
      </section>
    </PageLayout>
  );
};
