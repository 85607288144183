import { deriveBudgetAggregate } from "@model/budgets/matrix";
import {
  useGetBudgetMatrixBudgetVersion3Query,
  useGetBudgetVersionSummary3Query,
} from "@app/service/generated";
import { useParams } from "react-router-dom";
import { useFlags } from "./useFlags";

export function useBudgetSummary() {
  const params = useParams();
  const flags = useFlags();

  const budgetId = params.budgetId;
  const budgetConfigVersionId = params.budgetConfigVersionId;

  const {
    data: budgetData,
    isFetching: budgetDataFetching,
    isLoading: budgetDataLoading,
  } = useGetBudgetMatrixBudgetVersion3Query(
    {
      budgetId: budgetId!,
      budgetConfigVersionId: budgetConfigVersionId!,
    },
    {
      enabled: !!budgetId && !!budgetConfigVersionId,
    }
  );

  const { data: summary } = useGetBudgetVersionSummary3Query(
    {
      budgetId: budgetId!,
      budgetConfigVersionId: budgetConfigVersionId!,
      siteTrialId: budgetData?.budget.trial.stId!,
    },
    {
      enabled: !!budgetData?.budget.trial.stId!,
    }
  );

  let budgetAggregate = null;

  if (summary?.budget) {
    budgetAggregate = deriveBudgetAggregate(
      summary?.budget.configVersions[0]!,
      summary?.budget.protocol!,
      "all",
      budgetId!,
      params.siteTrialId!,
      flags
    );
  }

  return {
    isLoading: budgetDataLoading,
    isFetching: budgetDataFetching,
    budgetAggregate,
    holdback: summary?.budget.configVersions[0].holdback,
    overhead: summary?.budget.configVersions[0].overhead,
    budgetConfigVersionId: params.budgetConfigVersionId,
    budgetId: params.budgetId,
    budgetSiteTrialId: budgetData?.budget.trial.stId,
    budgetName: summary?.budget.name,
    budgetStatus: summary?.budget.configVersions[0].status,
    budgetPaymentTermDays: summary?.budget.paymentTermDays,
    budgetTrialName: summary?.budget.trial.name,
    protocolVersionId:
      summary?.budget.configVersions[0].selectedProtocolVersionId,
    budgetProtocolVersionName: summary?.budget.protocol?.versions.find(
      (version) =>
        version.id ===
        summary?.budget.configVersions[0].selectedProtocolVersionId
    )?.name,
    budgetEffectiveStartDate:
      summary?.budget.configVersions[0].protocolVersions.find(
        (protocolVersion) =>
          protocolVersion.protocolVersionId ===
          summary?.budget.configVersions[0].selectedProtocolVersionId
      )?.effectiveDateStart,
    budgetEffectiveEndDate:
      summary?.budget.configVersions[0].protocolVersions.find(
        (protocolVersion) =>
          protocolVersion.protocolVersionId ===
          summary?.budget.configVersions[0].selectedProtocolVersionId
      )?.effectiveDateEnd,
  };
}
