import React, { useEffect, useState } from "react";
import { css } from "@linaria/core";
import {
  Button,
  Table,
  Input,
  Space,
  Typography,
  Tag,
  FilterValue,
  Alert,
  Tooltip,
} from "@reifyhealth/picasso-pkg";
import {
  useDeleteInvoiceMutation,
  useGetInvoiceListQuery,
  usePartnerDetailsQuery,
} from "@app/service/generated";
import { useNavigateWithQueryString } from "@app/hooks";
import { money } from "@lib/currency";
import { formatDate } from "@lib/date";
import { useQueryClient } from "react-query";
import { showDeleteModal } from "../../common/modals";
import { useParams } from "react-router-dom";
import { formatExternalStatus } from "@model/invoices";
import { ExportModal } from "../../common/modals/ExportModal";
import { PaidAmount2 } from "../components/PaidAmount";
import { MoreActionsMenu } from "./components/MoreActionsMenu";
import { selectedStatuses } from "./helpers";
import * as invoiceModel from "@model/invoices";

const tableHeaderControls = css`
  display: flex;
  gap: var(--size-4);
`;

const trialName = css`
  width: 300px;
`;

const tableRowControlsWrapper = css`
  width: 100%;
  justify-content: flex-end;
`;

const invoiceStatusTagElement = css`
  /* stylelint-disable */
  &.DRAFT {
    background: var(--amber-3);
    color: var(--amber-10);
    border: none;
  }

  &.OPEN {
    background: var(--blue-3);
    color: var(--blue-10);
    border: none;
  }

  &.PAST_DUE {
    background: var(--red-3);
    color: var(--red-10);
    border: none;
  }

  &.VOID {
    background: var(--slate-3);
    color: var(--slate-9);
    border: none;
  }

  &.PAID {
    background: var(--green-3);
    color: var(--green-10);
    border: none;
  }
  /* stylelint-enable */
`;

export const InvoicesTable = ({
  siteId,
  invoices,
  withTitle = false,
  isLoading,
  reactQueryKey,
  setOverallTotalFn,
  setPastDueTotalFn,
  setOverallRecordNumberFn,
}: {
  siteId: string;
  withTitle?: boolean;
  invoices: PartnerInvoice[] | Invoice[];
  isLoading: boolean;
  reactQueryKey: { siteId: string };
  setOverallTotalFn?: React.Dispatch<React.SetStateAction<number>>;
  setPastDueTotalFn?: React.Dispatch<React.SetStateAction<number>>;
  setOverallRecordNumberFn?: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const queryClient = useQueryClient();
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedSiteTrial, setSelectedSiteTrial] = useState<string[]>([]);
  const [activeFilters, setActiveFilters] = useState<
    Record<string, FilterValue | null>
  >({ siteTrialName: null, externalStatus: null });
  const navigate = useNavigateWithQueryString();
  const params = useParams();

  const [showExportModal, setShowExportModal] = useState(false);

  const hasSelected =
    selectedRowKeys.length > 0 && selectedSiteTrial.length === 1;

  const { mutate: deleteInvoice } = useDeleteInvoiceMutation({
    onMutate: (variables) => {
      const queryKey = useGetInvoiceListQuery.getKey({
        siteId,
      });

      const previousData: IGetInvoiceListQuery | undefined =
        queryClient.getQueryData(queryKey);

      queryClient.setQueryData(
        queryKey,
        (oldData: IGetInvoiceListQuery | void) => {
          if (oldData) {
            const updatedInvoices =
              oldData.Financials2__invoiceList.invoices.filter(
                (invoice) => invoice.id !== variables.input.invoiceId
              );

            return {
              Financials2__invoiceList: {
                invoices: updatedInvoices,
              },
            };
          }

          return oldData;
        }
      );

      if (previousData) {
        return { previousData };
      }
    },
    onError: (error, variables, context) => {
      console.error({ error, variables });
      const queryKey = useGetInvoiceListQuery.getKey({
        siteId,
      });

      queryClient.setQueryData(queryKey, context?.previousData);
    },
    onSuccess: (data) => {
      if (data.Financials2__deleteInvoice.success) {
        if (params.partnerId) {
          queryClient.invalidateQueries(
            usePartnerDetailsQuery.getKey({ partnerId: params.partnerId })
          );
        }

        queryClient.invalidateQueries(
          useGetInvoiceListQuery.getKey({ siteId })
        );
      }
    },
  });

  const filterValue: PartnerInvoice[] | Invoice[] = invoices.filter((v) =>
    v.invoiceNumber.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const selectedInvoiceStatuses = selectedStatuses(
    filterValue,
    selectedRowKeys
  );

  const statusFilters = [...new Set(filterValue.map((v) => v.externalStatus))]
    .sort((a, b) => a.localeCompare(b))
    .map((externalStatus) => ({
      value: externalStatus,
      text: (
        <Tag className={`${invoiceStatusTagElement} ${externalStatus}`}>
          {formatExternalStatus(externalStatus)}
        </Tag>
      ),
    }));

  const siteTrialNameFilters = [
    ...new Set(
      filterValue.map((v) => {
        // Invoices should only ever be generated from a single site trial but just in case historical entries exist we join the unique entries
        const uniqueTrailNames = [
          ...new Set([
            ...v.lineItems.map((lineItem) => lineItem.siteTrial.name),
          ]),
        ];

        return uniqueTrailNames.join(", ");
      })
    ),
  ]
    .sort((a, b) => a.localeCompare(b))
    .map((trialName) => ({
      value: trialName,
      text: <Typography.Text>{trialName}</Typography.Text>,
    }));

  const { overallTotal, overallPastDueTotal, overallRecordCount } =
    invoiceModel.tableTotals({ filterValue, activeFilters });

  useEffect(() => {
    if (setOverallTotalFn) {
      setOverallTotalFn(overallTotal);
    }

    if (setPastDueTotalFn) {
      setPastDueTotalFn(overallPastDueTotal);
    }

    if (setOverallRecordNumberFn) {
      setOverallRecordNumberFn(overallRecordCount);
    }
  }, [
    overallTotal,
    overallPastDueTotal,
    setOverallTotalFn,
    setPastDueTotalFn,
    setOverallRecordNumberFn,
    overallRecordCount,
  ]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    const invoiceIds = invoices.filter((invoice) =>
      newSelectedRowKeys.includes(invoice.id)
    );

    const siteTrials = invoiceIds.map((invoice) => {
      if (invoice.lineItems.length > 0) {
        const [first] = invoice.lineItems;

        return first.siteTrial.id;
      }

      return "no-line-items";
    });

    if (siteTrials.length === 0) {
      setSelectedSiteTrial([]);
    } else {
      setSelectedSiteTrial([...new Set(siteTrials)]);
    }

    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <Table
      loading={isLoading}
      size="small"
      scroll={{ x: 1000, y: "calc(100vh - 500px)" }}
      bordered
      rowKey={"id"}
      key={"id"}
      rowSelection={rowSelection}
      onChange={(_pagination, filters, _sorter) => {
        setSelectedRowKeys([]);
        setActiveFilters(filters);
      }}
      title={
        withTitle
          ? () => {
              return (
                <Space direction="vertical" style={{ width: "100%" }}>
                  <div className={tableHeaderControls}>
                    <ExportModal
                      category={"invoices"}
                      visible={showExportModal}
                      setVisible={setShowExportModal}
                      siteId={siteId}
                      reportType={"INVOICES"}
                    ></ExportModal>
                    <MoreActionsMenu
                      invoices={invoices}
                      selectedRowKeys={selectedRowKeys}
                      setSelectedRowKeysFn={setSelectedRowKeys}
                      hasSelected={hasSelected}
                      hasDisallowedStatuses={selectedInvoiceStatuses.some(
                        invoiceModel.hasDisabledActions
                      )}
                      reactQueryKey={reactQueryKey}
                    />
                    <Input
                      value={searchQuery}
                      onChange={(e) => {
                        setSelectedRowKeys([]);
                        setSearchQuery(e.target.value);
                      }}
                      placeholder="Find Invoice"
                      data-testid="search-invoice-input"
                      disabled={
                        invoices.length === 0 ||
                        Boolean(activeFilters.externalStatus)
                      }
                    />
                    <Button
                      data-test-id="invoices-export-button"
                      onClick={() => setShowExportModal(true)}
                    >
                      Generate Report
                    </Button>
                  </div>
                  {selectedSiteTrial.length > 1 && (
                    <Alert
                      type="info"
                      message={
                        <Typography.Text>
                          Actions can only be applied to one trial at a time.
                        </Typography.Text>
                      }
                    />
                  )}
                  {selectedSiteTrial.length === 1 &&
                    selectedInvoiceStatuses.some(
                      invoiceModel.hasDisabledActions
                    ) && (
                      <Alert
                        type="info"
                        message={
                          <Typography.Text>
                            Actions can only be applied to open items without
                            partial payment.
                          </Typography.Text>
                        }
                      />
                    )}
                </Space>
              );
            }
          : undefined
      }
      dataSource={filterValue}
    >
      <Table.Column
        dataIndex="externalStatus"
        title={
          <Typography.Text data-testid="status-title">Status</Typography.Text>
        }
        fixed="left"
        filters={statusFilters}
        onFilter={(value, record: any) => record.externalStatus === value}
        width={100}
        render={(_, record: PartnerInvoice | Invoice) => {
          return (
            <Tag
              data-testid="invoice-status"
              className={`${invoiceStatusTagElement} ${record.externalStatus}`}
            >
              {formatExternalStatus(record.externalStatus)}
            </Tag>
          );
        }}
      />
      <Table.Column
        dataIndex="invoiceNumber"
        title={
          <Typography.Text data-testid="invoice-title">Invoice</Typography.Text>
        }
        fixed="left"
        width={120}
        render={(_, record: PartnerInvoice | Invoice) => {
          return (
            <Typography.Link
              data-testid="invoice-number-link"
              onClick={() => {
                navigate(`/finance/sites/${siteId}/invoices/${record.id}`);
              }}
            >
              #{record.invoiceNumber}
            </Typography.Link>
          );
        }}
      />
      <Table.Column
        dataIndex="siteTrialName"
        title={
          <Typography.Text data-testid="trial-name-title">
            Trial Name
          </Typography.Text>
        }
        filters={siteTrialNameFilters}
        onFilter={(value, record: PartnerInvoice | Invoice) =>
          record.lineItems[0].siteTrial.name === value
        }
        className={trialName}
        render={(_, record: PartnerInvoice | Invoice) => {
          // Invoices should only ever be generated from a single site trial at this point but just in case historical entries exist we join the unique entries
          const uniqueTrailNames = [
            ...new Set([
              ...record.lineItems.map((lineItem) => lineItem.siteTrial.name),
            ]),
          ];

          return (
            <Typography.Text data-testid="trial-name">
              {uniqueTrailNames.join(", ")}
            </Typography.Text>
          );
        }}
      />
      <Table.Column
        dataIndex="dueDate"
        sorter={(a, b) =>
          ((new Date((a as any).dueDate) as any) || 0) -
          ((new Date((b as any).dueDate) as any) || 0)
        }
        showSorterTooltip={false}
        title={
          <Typography.Text data-testid="due-date-title">
            Due Date
          </Typography.Text>
        }
        render={(dueDate) => {
          return (
            <Typography.Text data-testid="due-date">
              {dueDate && formatDate(dueDate)}
            </Typography.Text>
          );
        }}
      />
      <Table.Column
        dataIndex="issueDate"
        key="issueDate"
        title={
          <Typography.Text data-testid="issue-date-title">
            Issue Date
          </Typography.Text>
        }
        render={(value: string) => {
          return (
            <Typography.Text data-testid="issue-date">
              {formatDate(value)}
            </Typography.Text>
          );
        }}
      />
      <Table.Column
        title={
          <Typography.Text data-testid="amount-title">Amount</Typography.Text>
        }
        align="right"
        render={(_, record: Invoice) => (
          <PaidAmount2
            remainingTotal={money(record.remainingAmount ?? 0)}
            totalAmount={money(record.total ?? 0)}
            paidStatus={record.paidStatus}
            allocatedAmount={money(record.allocatedAmount ?? 0)}
          />
        )}
      />
      <Table.Column
        dataIndex="memo"
        ellipsis={{ showTitle: false }}
        key="memo"
        title={<Typography.Text data-testid="memo-title">Memo</Typography.Text>}
        render={(_, record: Invoice) => {
          return (
            <Tooltip trigger={"hover"} placement="topLeft" title={record.memo}>
              {record.memo}
            </Tooltip>
          );
        }}
      />
      <Table.Column
        width={120}
        key="actions"
        render={(_, record: PartnerInvoice | Invoice) => {
          return (
            <Space className={tableRowControlsWrapper}>
              <Typography.Link
                data-testid="edit-invoice-btn"
                onClick={() => {
                  navigate(`/finance/sites/${siteId}/invoices/${record.id}`);
                }}
              >
                Edit
              </Typography.Link>
              <Typography.Link
                onClick={() =>
                  showDeleteModal({
                    title: "Delete Invoice?",
                    content: <>Are you sure you want to delete this invoice?</>,
                    onOkFunction: () =>
                      deleteInvoice({ input: { invoiceId: record.id } }),
                    okText: "Delete Invoice",
                  })
                }
                type="danger"
                disabled={record.externalStatus !== "DRAFT"}
                data-testid="delete-invoice-btn"
              >
                Delete
              </Typography.Link>
            </Space>
          );
        }}
      />
    </Table>
  );
};
