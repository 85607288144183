import Big from "big.js";
import { curryRight } from "lodash";

/**
 * The `Money` type should be used to represent all money values on the
 * frontend. The amount is stored internally as an arbitrary-precision decimal.
 *
 * It's recommended to use `Money` instead of other types such as strings,
 * floats, or integers. There are helper functions for various use cases:
 *
 * - If you need to work with a money value from GraphQL, you can pass the
 *  `IFinancials2__Money` to any of the money functions and it will
 *   automatically get coerced to a `Money` type.
 *
 * - If you need to create a constant money value for a test, you can use the
 *  `money` constructor.
 *
 * - If you need to do calculations, there are functions that take two `Money`
 *   types and return a new `Money` type. These functions use Big.js internally
 *   to perform arbitrary-precision arithmetic.
 *
 * - If you need to display a money value as a string, use `displayText`.
 *
 * - If you need to send a money value in a GraphQL mutation, use
 *   `toGraphQLInput`.
 *
 * - If you need to work with JavaScript APIs that you don't control, you can
 *   use `toNumber` to get a `Number` from a `Money` type.
 */
export type Money = {
  currencyCode: IFinancials2__CurrencyCode;
  amount: Big.Big;
};

/**
 * The immutable `MoneyValue` makes it easy to see if a money value has already
 * been coereced to a `Money` type.
 */
class MoneyValue implements Money {
  constructor(
    public readonly amount: Money["amount"],
    public readonly currencyCode: Money["currencyCode"]
  ) {
    this.amount = amount;
    this.currencyCode = currencyCode;
  }
}

const DEFAULT_CURRENCY_CODE = "USD";
const CURRENCY_SYMBOLS = { USD: "$" };

type Coerceable = Money | GraphQLMoney | Big.Big | string | number;

/**
 * Returns a money string with currency formatting.
 */
export function displayText(m: Coerceable): string {
  const rounded = round(money(m));

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: rounded.currencyCode,
  }).format(toString(rounded) as unknown as number);
}

/**
 * Returns a new `Money` with the amount rounded to the nearest cent.
 */
export function round(m: Coerceable): Money {
  return money(money(m).amount.round(2));
}

/**
 * Returns `true` if `x` is a `Money` type.
 */
export function isMoney(x: unknown): x is Money {
  return x instanceof MoneyValue;
}

export type GraphQLMoney = Pick<IFinancials2__Money, "amount" | "currencyCode">;

/**
 * Returns `true` if `x` is an `IFinancials2__Money` type.
 */
export function isGraphQLMoney(x: unknown): x is GraphQLMoney {
  return (
    typeof x === "object" &&
    x !== null &&
    "amount" in x &&
    typeof x.amount === "string"
  );
}

function removeCurrency(s: string, currency: Money["currencyCode"]): string {
  return s.replace(CURRENCY_SYMBOLS[currency], "");
}

/**
 * Returns `x` as a `Money` type, coercing if possible.
 */
export function money(x: Coerceable): Money {
  const currencyCode = DEFAULT_CURRENCY_CODE;

  if (isMoney(x)) {
    return x;
  } else if (isGraphQLMoney(x)) {
    return new MoneyValue(Big(x.amount), currencyCode);
  } else if (x instanceof Big) {
    return new MoneyValue(x, currencyCode);
  } else if (typeof x === "string") {
    return new MoneyValue(
      Big(removeCurrency(x, currencyCode).trim()),
      currencyCode
    );
  } else if (typeof x === "number") {
    return new MoneyValue(Big(x), currencyCode);
  } else {
    throw new Error(`Cannot coerce to Money: ${x}`);
  }
}

type ArithmeticFnNames<T> = {
  [K in keyof T]: T[K] extends (a: T, b: T) => unknown ? K : never;
}[keyof T];

/**
 * Returns a transformed Big.js function that uses `Money` instead of `Big.js`
 * values as parameters and return values.
 */
function bigFn<T extends boolean | Money>(
  functionName: ArithmeticFnNames<Big.Big>
) {
  return (a: Coerceable, b: Coerceable): T => {
    const result = money(a).amount[functionName](money(b).amount);
    return (result instanceof Big ? money(result) : result) as T;
  };
}

// These arithmetic functions are available at the top-level to abstract
// away the internal Big.js type from users of the `Money` API. The functions
// that return `Money` are curried so you can use `flow` from Lodash to combine
// multiple operations.

export const div = curryRight(bigFn<Money>("div"));
export const minus = curryRight(bigFn<Money>("minus"));
export const plus = curryRight(bigFn<Money>("plus"));
export const times = curryRight(bigFn<Money>("times"));

export const eq = bigFn<boolean>("eq");
export const gt = bigFn<boolean>("gt");
export const gte = bigFn<boolean>("gte");
export const lt = bigFn<boolean>("lt");
export const lte = bigFn<boolean>("lte");

/**
 * Same as Math.max but for `Money` types.
 */
export const max = curryRight((a: Coerceable, b: Coerceable) => {
  const a2 = money(a);
  const b2 = money(b);
  return gte(a2, b2) ? a2 : b2;
});

/**
 * Returns a Number from a `Money` type.
 */
export function toNumber(x: Coerceable): number {
  if (typeof x === "number") return x;
  return money(x).amount.toNumber();
}

/**
 * Returns a string from a `Money` type.
 */
export function toString(x: Coerceable): string {
  if (typeof x === "string") return x;
  return money(x).amount.toString();
}

/**
 * Returns a fixed-precision string from a `Money` type.
 */
export function toFixed(x: Coerceable): string {
  return money(x).amount.toFixed(2);
}

/**
 * Returns an `IFinancials2__MoneyInput` from a `Money` type.
 */
export function toGraphQLInput(x: Coerceable): GraphQLMoney {
  const m = money(x);
  return {
    amount: toString(m),
    currencyCode: m.currencyCode,
  };
}
