import { useCodegenFetcher } from "@app/service/codegenFetcher";
import { UploadSiteLogoDocument } from "@app/service/generated";
import { useState } from "react";
import axios from "axios";
import { message } from "@reifyhealth/picasso-pkg";

const useFileUpload = (siteId: string) => {
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [uploadUrl, setUploadUrl] = useState<string>();

  const presignedLogoFetcher = useCodegenFetcher<
    {
      Financials2__uploadSiteLogo: IFinancials2__UploadSiteLogoResponse;
    },
    IQueryFinancials2__UploadSiteLogoArgs
  >(UploadSiteLogoDocument);

  const handleImageUpload = async (file: File) => {
    try {
      setIsUploading(true);
      const fetchPresignedURL = await presignedLogoFetcher({
        siteId,
        contentType: file.type,
      });

      if (fetchPresignedURL.Financials2__uploadSiteLogo.url) {
        const { url, previewUrl } =
          fetchPresignedURL.Financials2__uploadSiteLogo;

        const resp = await axios.put(url, file, {
          headers: { "Content-Type": file.type },
        });

        if (resp.status === 200) {
          setUploadUrl(previewUrl);
        }

        setIsUploading(false);
      }
    } catch (err) {
      console.error(err);
      setIsUploading(false);
      message.error({
        duration: 2,
        content: "Upload failed. Please try again",
      });
    }
  };

  return { handleImageUpload, uploadUrl, setUploadUrl, isUploading };
};
export default useFileUpload;
