import {
  useBudgetVersionListingItems3Query,
  useDeleteBudgetMutation,
  useUserSiteTrialsQuery,
} from "@app/service/generated";
import {
  Dropdown,
  Button,
  Modal,
  icons,
  Menu,
  Space,
  Typography,
} from "@reifyhealth/picasso-pkg";
import { QueryClient, useQueryClient } from "react-query";
import { useNavigate } from "@app/hooks";

const { EllipsisOutlined, CloseCircleFilled } = icons;
const { confirm } = Modal;

interface BudgetActionsProps {
  siteId: string;
  budgetId: string;
  disableBudgetDelete: boolean;
  budgetName: string;
  siteTrialId: string;
}

const showDeleteModal = (
  budgetId: string,
  deleteBudget: Function,
  queryClient: QueryClient,
  siteId: string
) => {
  confirm({
    icon: <CloseCircleFilled style={{ color: "red" }} />,
    title: "Delete Budget",
    content: (
      <section style={{ display: "flex" }}>
        This will remove all budget line items and related information. This
        cannot be undone.
      </section>
    ),
    okText: "Delete Budget",
    okType: "danger",
    okButtonProps: {
      color: "red",
      disabled: false,
    },
    cancelText: "Cancel",
    onOk() {
      deleteBudget(
        { budgetId },
        {
          onSuccess: (result: any) => {
            if (result.deleted.success) {
              queryClient.invalidateQueries(
                useBudgetVersionListingItems3Query.getKey({ siteId })
              );
              queryClient.invalidateQueries(
                useUserSiteTrialsQuery.getKey({
                  filter: { siteId, hasBudget: false },
                })
              );
            }
          },
        }
      );
    },
    onCancel() {},
  });
};

export const BudgetActions = ({
  disableBudgetDelete,
  budgetId,
  siteId,
  budgetName,
  siteTrialId,
}: BudgetActionsProps) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { mutate: deleteBudget } = useDeleteBudgetMutation();
  const handleCreateBudgetVersion = () => {
    navigate(
      `/finance/sites/${siteId}/budgets/${budgetId}/versions/new?budget-name=${budgetName}&trial-id=${siteTrialId}`
    );
  };

  const menu = (
    <Menu>
      <Menu.Item>
        {!disableBudgetDelete ? (
          <Typography.Link
            type="danger"
            data-testid="delete-budget-btn"
            onClick={() =>
              showDeleteModal(budgetId, deleteBudget, queryClient, siteId)
            }
          >
            Delete
          </Typography.Link>
        ) : (
          <Typography.Text data-testid="disabled-delete-budget-btn" disabled>
            Delete
          </Typography.Text>
        )}
      </Menu.Item>
    </Menu>
  );

  return (
    <section>
      <Space>
        <Button
          data-testid="create-budget-version-btn"
          type="default"
          onClick={handleCreateBudgetVersion}
        >
          Create Budget Version
        </Button>
        <Dropdown overlay={menu} trigger={["click"]}>
          <Button
            data-testid="budget-version-actions"
            icon={<EllipsisOutlined />}
          />
        </Dropdown>
      </Space>
    </section>
  );
};
