import { styled } from "@linaria/react";
import {
  Checkbox,
  CheckboxChangeEvent,
  Col,
  Row,
  Typography,
  Button,
  Popover,
  Space,
  icons,
  List,
} from "@reifyhealth/picasso-pkg";
import { useState } from "react";
import { WithTooltip } from "../common/TooltipButton";

const ContactListItem = styled.article`
  width: 100%;

  .contact-name.ant-typography {
    font-size: var(--font-size-h-5);
    line-height: normal;
  }

  .ant-descriptions-row .ant-descriptions-item {
    padding-bottom: 0;

    .ant-descriptions-item-label {
      color: var(--component-secondary-text);
    }
  }

  .contact-options {
    color: var(--component-placeholder-text);

    .ant-typography {
      color: var(--component-secondary-text);
    }
  }
`;

interface PartnerContactProps {
  contact: Partial<PartnerContact>;
  onDeleteClickFn: () => void;
  onCheckboxChange: (e: CheckboxChangeEvent) => void;
  hasPrimaryContact: boolean;
  toggleCreateContactFormFn: () => void;
  isContactTiedToDraftInvoice: boolean;
}

export const PartnerContact = ({
  contact,
  onDeleteClickFn,
  hasPrimaryContact,
  onCheckboxChange,
  toggleCreateContactFormFn,
  isContactTiedToDraftInvoice,
}: PartnerContactProps) => {
  const [visible, isVisible] = useState(false);

  const hide = () => {
    isVisible(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    if (!isContactTiedToDraftInvoice) {
      isVisible(newOpen);
    }
  };

  const { name, emailAddress, phoneNumber, isPrimary } = contact;

  return (
    <List.Item>
      <ContactListItem>
        <Row justify="space-between">
          <Col>
            <Typography.Text
              data-testid="partner-contact-name"
              className="contact-name"
            >
              {name}
            </Typography.Text>
          </Col>
          <Col>
            <Space>
              <Button
                size="small"
                type="link"
                onClick={toggleCreateContactFormFn}
                data-testid="partner-contact-edit-btn"
              >
                Edit
              </Button>
              <Popover
                placement="bottomLeft"
                visible={visible}
                onVisibleChange={handleOpenChange}
                content={
                  <>
                    <Typography.Text
                      style={{ fontSize: "var(--font-size-h-5)" }}
                    >
                      Remove{" "}
                      <strong>
                        <em>{contact.name ?? "Contact"}</em>
                      </strong>
                      ?
                    </Typography.Text>
                    <section
                      style={{
                        textAlign: "right",
                        marginTop: "var(--size-4)",
                      }}
                    >
                      <Space align="end" style={{ width: "100%" }}>
                        <Button
                          data-testid="partner-contact-cancel-btn"
                          type="default"
                          onClick={hide}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="primary"
                          data-testid="partner-contact-remove-btn"
                          disabled={isContactTiedToDraftInvoice}
                          danger
                          onClick={() => {
                            onDeleteClickFn();
                            hide();
                          }}
                        >
                          Remove
                        </Button>
                      </Space>
                    </section>
                  </>
                }
                trigger="click"
              >
                <WithTooltip
                  showTooltip={isContactTiedToDraftInvoice}
                  tooltip={{
                    title: `Contact is attached to a draft invoice and cannot be deleted`,
                    placement: "left",
                    trigger: "hover",
                  }}
                >
                  <Button
                    type="link"
                    data-testid="partner-contact-remove-btn"
                    danger
                    disabled={isContactTiedToDraftInvoice}
                    onClick={() => isVisible(true)}
                    size="small"
                  >
                    Remove
                  </Button>
                </WithTooltip>
              </Popover>
            </Space>
          </Col>
        </Row>
        <Row gutter={16} className="contact-options">
          <Col>
            <Checkbox
              onChange={onCheckboxChange}
              disabled={!isPrimary && hasPrimaryContact}
              checked={isPrimary}
            >
              <Typography.Text
                type="secondary"
                data-testid="partner-contact-primary-contact-checkbox"
              >
                Primary Contact
              </Typography.Text>
            </Checkbox>
            <Space>
              <icons.PhoneOutlined />
              <Typography.Text data-testid="partner-contact-phone-number">
                {phoneNumber}
              </Typography.Text>
            </Space>
          </Col>
          <Col>
            <Space>
              <icons.MailOutlined />
              <Typography.Text data-testid="partner-contact-email-address">
                {emailAddress}
              </Typography.Text>
            </Space>
          </Col>

          <Col></Col>
        </Row>
      </ContactListItem>
    </List.Item>
  );
};
