import { styled } from "@linaria/react";

interface StepSummaryBlockStyleProps {
  title: string;
  value: string | React.ReactNode;
  size?: "small" | "medium" | "large";
  align?: "center" | "flex-start";
}
const StepSummaryBlockStyle = styled.div<{
  size?: "small" | "medium" | "large";
  align?: "center" | "flex-start";
}>`
  display: flex;
  align-items: ${(props) => props.align ?? "flex-end"};
  flex-direction: column;
  font-size: var(--font-size-body);

  .title {
    color: var(--component-tertiary-text);
  }

  .value {
    color: var(--component-primary-text);
  }
`;

export const StepSummaryBlock = ({
  title,
  value,
  size,
  align,
}: StepSummaryBlockStyleProps) => {
  return (
    <StepSummaryBlockStyle size={size || "small"} align={align}>
      <span className="title">{title}</span>
      <span
        data-testid={`${title.toLowerCase().trim().replaceAll(" ", "-")}`}
        className="value"
      >
        {value}
      </span>
    </StepSummaryBlockStyle>
  );
};
