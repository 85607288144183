import { lineItems } from "@model/invoices";
import { useCodegenFetcher } from "@app/service/codegenFetcher";
import {
  GetProtocolForSiteTrialDocument,
  useFinancials2__InvoiceDetailsQuery,
  useGetSiteSettingsQuery,
} from "@app/service/generated";
import { useQueries } from "react-query";
import { useParams } from "react-router-dom";
import { useFlags } from "./useFlags";

export function useInvoiceLineItems() {
  const params = useParams();
  const flags = useFlags();

  const {
    data: invoiceDetails,
    isLoading: invoiceDetailsLoading,
    isFetching: invoiceDetailsFetching,
  } = useFinancials2__InvoiceDetailsQuery({
    siteId: params.siteId!,
    invoiceId: params.invoiceId!,
  });

  const uniqueSiteIds = [
    ...new Set(
      invoiceDetails?.data.invoice.lineItems.map(
        (lineItem) => lineItem.siteTrial.id
      )
    ),
  ];

  const protocolFetcher = useCodegenFetcher<
    IGetProtocolForSiteTrialQuery,
    IGetProtocolForSiteTrialQueryVariables
  >(GetProtocolForSiteTrialDocument);

  const protocolResults = useQueries(
    [...uniqueSiteIds].map((siteTrialId) => ({
      staleTime: 1000 * 10, // ten sec
      enabled: !!invoiceDetails,
      queryKey: ["protocol", siteTrialId],
      queryFn: () => protocolFetcher({ siteTrialId }),
    }))
  );

  const protocols = protocolResults
    .filter((r) => r.isSuccess)
    .map(
      (r) =>
        (r.data as IGetProtocolForSiteTrialQuery)
          .Financials2__getSiteTrialProtocol
    );

  const protocolsLoaded = protocolResults.every((r) => r.isSuccess);

  const { data: siteSettings } = useGetSiteSettingsQuery({
    siteId: params.siteId!,
  });

  return {
    siteId: params.siteId,
    isLoading: invoiceDetailsLoading || !protocolsLoaded,
    isFetching: invoiceDetailsFetching,
    invoiceDetails: invoiceDetails?.data.invoice,
    siteSettings: siteSettings?.Financials2__siteSettings,
    lineItems:
      (protocolsLoaded &&
        invoiceDetails &&
        lineItems(invoiceDetails?.data.invoice.lineItems, protocols, flags)) ||
      [],
  };
}
