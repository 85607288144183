import {
  Button,
  Checkbox,
  Form,
  FormInstance,
  Input,
  Modal,
  Space,
} from "@reifyhealth/picasso-pkg";
import { styled } from "@linaria/react";
import { validateEmail, validatePhoneNumber } from "./validators";
import { formatPhoneNumber } from "./formatters";

interface PartnerContactFormModalProps {
  toggleCreateContactForm: Function;
  showAddContactForm: boolean;
  form: FormInstance<{
    id?: string;
    name: string;
    emailAddress: string;
    phoneNumber: string;
    isPrimary: boolean;
  }>;
  onFormFinishFn: (values: {
    id?: string;
    name: string;
    emailAddress: string;
    phoneNumber: string;
    isPrimary: boolean;
  }) => void;
  hasPrimaryContact: boolean;
  isSavingContact: boolean;
  showPrimaryCheckbox?: boolean;
}

export const PartnerContactFormModal = ({
  form,
  toggleCreateContactForm,
  showAddContactForm,
  onFormFinishFn,
  hasPrimaryContact,
  showPrimaryCheckbox = false,
  isSavingContact,
}: PartnerContactFormModalProps) => {
  const ModalActions = styled.div`
    display: flex;
    justify-content: flex-end;
  `;

  return (
    <Modal
      title="Add Contact"
      visible={showAddContactForm}
      destroyOnClose
      footer={false}
      onCancel={() => {
        form.resetFields();
        toggleCreateContactForm();
      }}
      okText="Save Contact"
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={onFormFinishFn}
        initialValues={{
          id: "",
          name: "",
          phoneNumber: "",
          emailAddress: "",
          isPrimary: false,
        }}
      >
        <Form.Item name="id" label="ID" style={{ display: "none" }}>
          <Input disabled />
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: "Please enter a contact name" }]}
          name="name"
          label="Name"
        >
          <Input
            data-testid="partner-contact-form-modal-name-input"
            placeholder="Enter a name"
          />
        </Form.Item>
        <Form.Item
          name="phoneNumber"
          label="Phone"
          rules={[
            { message: "" },
            {
              validator: validatePhoneNumber,
              validateTrigger: "onSubmit",
            },
          ]}
        >
          <Input
            placeholder="Enter a phone number"
            data-testid="partner-contact-form-modal-phone-number-input"
            onChange={(e) => {
              const { value } = e.target;

              form.setFieldsValue({
                phoneNumber: formatPhoneNumber(value),
              });
            }}
          />
        </Form.Item>
        <Form.Item
          name="emailAddress"
          label="Email"
          rules={[
            { message: "", required: true },
            {
              validator: validateEmail,
              validateTrigger: "onSubmit",
            },
          ]}
        >
          <Input
            data-testid="partner-contact-form-modal-email-input"
            placeholder="Enter a email"
          />
        </Form.Item>
        <Form.Item
          hidden={!showPrimaryCheckbox}
          valuePropName="checked"
          name="isPrimary"
          help={hasPrimaryContact && "Primary contact exists already"}
        >
          <Checkbox
            data-testid="partner-contact-form-modal-primary-contact-checkbox"
            disabled={hasPrimaryContact}
          >
            Primary Contact
          </Checkbox>
        </Form.Item>
        <ModalActions>
          <Space>
            <Button
              disabled={isSavingContact}
              htmlType="reset"
              data-testid="partner-contact-form-modal-cancel-btn"
              onClick={() => {
                form.resetFields();
                toggleCreateContactForm();
              }}
            >
              Cancel
            </Button>
            <Button
              data-testid="partner-contact-form-modal-ok-btn"
              disabled={isSavingContact}
              type="primary"
              htmlType="submit"
            >
              OK
            </Button>
          </Space>
        </ModalActions>
      </Form>
    </Modal>
  );
};
