import { useMemo, useState, useCallback } from "react";
import { css } from "@linaria/core";
import {
  Table,
  Typography,
  Select,
  useForm,
  DatePicker,
  message,
  Tag,
  Input,
} from "@reifyhealth/picasso-pkg";
import { SaveIndication } from "../../common/SaveIndication";
import {
  useDeleteInvoiceTotalModificationMutation,
  useFinancials2__InvoiceDetailsQuery,
  useGetPartnerContactsQuery,
  useGetPartnerDropdownItemsQuery,
  useInvoiceAddPrimaryContactMutation,
  useUpdateInvoiceIssueDateMutation,
  useUpdateInvoiceMemoMutation,
  useUpdateInvoicePaymentTermsMutation,
  useUpdateInvoiceRecipientMutation,
  useUpdateInvoiceTotalModificationMutation,
} from "@app/service/generated";
import { useParams } from "react-router-dom";
import Loading from "../../Loading";
import { displayText } from "@lib/currency";
import {
  DEFAULT_DATEPICKER_FORMAT,
  formatDate,
  localDateToUTCDateString,
  utcDateStringToLocalDate,
} from "@lib/date";
import { useQueryClient } from "react-query";
import { useInvoiceLineItems } from "@app/hooks/invoice";
import { InvoiceDetailsActions } from "./components";
import { PartnerContactFormModal } from "../PartnerContactFormModal";
import moment from "moment";
import { useNavigate } from "@app/hooks";
import { formatExternalStatus } from "@model/invoices";
import NumericInput from "@components/inputs/NumericInput";
import useDebounce from "@hooks/useDebounce";
import sortBy from "lodash/sortBy";
import { DEFAULT_DEBOUNCE_TIME } from "@constants";
import { useFlags } from "@app/hooks/useFlags";
import InvoiceTotalModificationForm from "../InvoiceTotalModificationForm";

const invoiceHeaderControls = css`
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--size-6);

  & .from-block,
  & .to-block {
    width: var(--size-25);
  }
`;

const invoiceHeaderControlsCenterBlock = css`
  display: flex;
  flex-direction: column;
  gap: var(--size-11);
  align-items: center;
`;

const invoiceHeaderControlsSiteLogo = css`
  width: var(--size-20);
  height: var(--size-20);
  padding: var(--size-3);
  background: var(--component-background);
  border: 1px solid var(--component-border);
  border-radius: var(--size-2);
  margin-bottom: var(--size-4);

  img {
    width: 100%;
  }
`;

const invoiceViewWrapper = css`
  padding: var(--size-10);
`;

const invoiceWrapper = css`
  padding: var(--size-11);
  background: var(--component-background);
  border: 1px solid var(--component-border);
  box-shadow: var(--shadows-elevated);
  border-radius: var(--border-2);
`;

const invoiceHeaderControlsFormBlock = css`
  width: var(--size-25);
`;

const invoiceHeaderControlsFormBlockDescriptions = css`
  padding: 0;
  margin: 0;
  list-style: none;

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--size-3);
    border-bottom: 1px solid var(--component-border);
    gap: var(--size-4);

    &:nth-child(odd) {
      background: var(--component-background);
    }

    &:last-child {
      border-bottom: none;
    }

    .select {
      width: 155px;
    }

    .ant-typography {
      font-size: var(--font-size-footnote);

      &.label {
        width: 80px;
      }
    }
  }

  .value {
    text-align: right;
    max-width: 200px;
  }
`;

const invoiceHeaderStatusBadge = css`
  padding: var(--size-2) var(--size-4);
  text-align: center;
  text-transform: uppercase;
  letter-spacing: var(--size-2);
  font-weight: var(--font-weight-medium);
  transform: rotate(10deg);
  font-size: var(--font-size-h-3);
  border-radius: var(--size-3);
  background: var(--component-background-subtle);
  /* stylelint-disable */
  &.OPEN {
    background: var(--blue-3);
    color: var(--blue-10);
  }

  &.DRAFT {
    background: var(--amber-3);
    color: var(--amber-10);
  }

  &.PAST_DUE {
    background: var(--red-3);
    color: var(--red-10);
  }

  &.PAID {
    background: var(--green-3);
    color: var(--green-10);
  }
  /* stylelint-enable */
`;

const toSelector = css`
  margin-top: var(--size-4);

  .add-primary {
    text-align: center;
  }

  .to-seletor {
    display: flex;
    gap: var(--size-2);
    margin-bottom: var(--size-4);

    .prefix {
      padding: var(--size-2) var(--size-4);
      background: var(--component-background-subtle);
      border: 1px solid var(--component-border);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .ant-select {
    width: 100%;
  }
`;

const formAndinvoiceBlock = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: var(--size-6);
`;

const invoiceTotalBlock = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: var(--size-6);
  padding: var(--size-6);
  background: var(--component-background);
  border: 1px solid var(--component-border);

  .ant-typography {
    font-size: var(--font-size-h-5);
    font-weight: var(--font-weight-medium);
    line-height: normal;
  }
`;

const invoiceMemoBlock = css`
  margin-top: var(--size-6);
  background: var(--component-background-subtle);
  border: none;
  padding: var(--size-4);
`;

const addressLineStyle = css`
  display: block;
`;

const issueDateListItemStyle = css`
  padding-left: 0 !important;
  padding-right: 0 !important;
`;

const paymentTermDaysListItemStyle = css`
  padding-left: 0 !important;
  padding-right: 0 !important;
  border-bottom: 0 !important;
`;

const paymentTermDaysInputStyle = css`
  width: 155px !important;

  &::placeholder {
    color: black !important;
  }
`;

const dueDateListItemStyle = css`
  padding-left: 0 !important;
  padding-right: 0 !important;
  border-bottom: 1px solid var(--component-border) !important;
`;

const invoiceMemoStyle = css`
  background: #fff !important;
  color: black !important;
  cursor: text !important;
`;

const InvoiceMemo = ({
  invoice,
  updateMemo,
}: {
  invoice: Pick<Invoice, "memo" | "externalStatus" | "id">;
  updateMemo: Function;
}) => {
  const { id, memo, externalStatus } = invoice;
  const [memoValue, setMemoValue] = useState<string | null>(memo);
  const updateMemoDebounced = useDebounce(updateMemo, 800);
  const handleMemoChange = useCallback(
    (e: { target: { value: string } }) => {
      const newMemo = e.target.value ?? null;
      setMemoValue(newMemo);
      updateMemoDebounced({
        input: { invoiceId: id!, memo: newMemo },
      });
    },
    [id, updateMemoDebounced]
  );

  const draftMemo = (
    <Input.TextArea
      placeholder="Optional"
      autoSize={{ minRows: 2, maxRows: 20 }}
      value={memoValue ?? undefined}
      onChange={handleMemoChange}
    />
  );

  const nonDraftMemo = memo ? (
    <Input.TextArea
      className={invoiceMemoStyle}
      value={memo}
      disabled={true}
      autoSize={{ minRows: 2, maxRows: 20 }}
    />
  ) : (
    <Input.TextArea
      className={invoiceMemoStyle}
      disabled={true}
      value="Nothing added"
    />
  );

  return (
    <div className={invoiceMemoBlock}>
      <Typography.Text>Memo:</Typography.Text>
      <div>{externalStatus === "DRAFT" ? draftMemo : nonDraftMemo}</div>
    </div>
  );
};

export const InvoiceForm = () => {
  const params = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const flags = useFlags();
  const [showAddContactForm, setShowAddContactForm] = useState(false);
  const [partnerContactForm] = useForm<{
    id?: string;
    name: string;
    emailAddress: string;
    phoneNumber: string;
    isPrimary: boolean;
  }>();
  const { siteId, siteSettings, invoiceDetails, lineItems } =
    useInvoiceLineItems();

  const { data: partnerList } = useGetPartnerDropdownItemsQuery(
    {
      siteId: params.siteId!,
    },
    {
      enabled: invoiceDetails?.externalStatus === "DRAFT",
    }
  );

  const { mutate: updatePaymentTerms } = useUpdateInvoicePaymentTermsMutation({
    onMutate: (variables) => {
      const { paymentTermDays } = variables.input;
      const queryKey = useFinancials2__InvoiceDetailsQuery.getKey({
        siteId: siteId!,
        invoiceId,
      });

      const previousData: IFinancials2__InvoiceDetailsQuery | undefined =
        queryClient.getQueryData(queryKey);

      queryClient.setQueryData(queryKey, (oldData: any) => {
        if (oldData) {
          return {
            data: {
              invoice: {
                ...oldData.data.invoice,
                ...{
                  paymentTermDays,
                  overridePaymentTermDays,
                },
              },
            },
          };
        }

        return oldData;
      });

      if (previousData) {
        return { previousData };
      }
    },
    onError: (error, variables, context) => {
      message.error("Failure to update payment terms on invoice");
      console.error({ error, variables });
      const queryKey = useFinancials2__InvoiceDetailsQuery.getKey({
        siteId: params.siteId!,
        invoiceId: params.invoiceId!,
      });

      queryClient.setQueryData(queryKey, context?.previousData);
    },
    onSuccess: (resp) => {
      if (resp.data.success) {
        message.success("Invoice payment terms have been updated!");

        queryClient.invalidateQueries(
          useFinancials2__InvoiceDetailsQuery.getKey({
            siteId: params.siteId!,
            invoiceId: params.invoiceId!,
          })
        );
      }
    },
  });

  const { mutate: updateIssueDate } = useUpdateInvoiceIssueDateMutation({
    onMutate: (variables) => {
      const { issueDate } = variables.input;
      const queryKey = useFinancials2__InvoiceDetailsQuery.getKey({
        siteId: siteId!,
        invoiceId,
      });

      const previousData: IFinancials2__InvoiceDetailsQuery | undefined =
        queryClient.getQueryData(queryKey);

      queryClient.setQueryData(
        queryKey,
        (oldData: IFinancials2__InvoiceDetailsQuery | void) => {
          if (oldData) {
            return {
              data: {
                invoice: {
                  ...oldData.data.invoice,
                  ...{
                    issueDate,
                  },
                },
              },
            };
          }

          return oldData;
        }
      );

      if (previousData) {
        return { previousData };
      }
    },
    onError: (error, variables, context) => {
      message.error("Failure to update payment terms on invoice");
      console.error({ error, variables });
      const queryKey = useFinancials2__InvoiceDetailsQuery.getKey({
        siteId: params.siteId!,
        invoiceId: params.invoiceId!,
      });

      queryClient.setQueryData(queryKey, context?.previousData);
    },
    onSuccess: (resp) => {
      if (resp.data.success) {
        message.success("Invoice issue date have been updated!");

        queryClient.invalidateQueries(
          useFinancials2__InvoiceDetailsQuery.getKey({
            siteId: params.siteId!,
            invoiceId: params.invoiceId!,
          })
        );
      }
    },
  });

  const { mutate: updateInvoiceRecipient } = useUpdateInvoiceRecipientMutation({
    onMutate: () => {
      // TODO
    },
    onSuccess: () => {
      queryClient.invalidateQueries(
        useFinancials2__InvoiceDetailsQuery.getKey({
          siteId: params.siteId!,
          invoiceId: params.invoiceId!,
        })
      );
    },
    onError: () => {
      // TODO
    },
  });

  const {
    mutate: addInvoicePrimaryContact,
    isLoading: addingInvoicePrimaryContact,
  } = useInvoiceAddPrimaryContactMutation({
    onMutate: () => {
      // TODO: Enhance invoice mutation to add primary contact GUID
    },
    onError: () => {
      partnerContactForm.resetFields();

      toggleCreateContactForm({
        id: "",
        emailAddress: "",
        phoneNumber: "",
        name: "",
        isPrimary: true,
      });
    },
    onSuccess: () => {
      partnerContactForm.resetFields();

      toggleCreateContactForm({
        id: "",
        emailAddress: "",
        phoneNumber: "",
        name: "",
        isPrimary: true,
      });

      queryClient.invalidateQueries(
        useGetPartnerDropdownItemsQuery.getKey({
          siteId: params.siteId!,
        })
      );

      queryClient.invalidateQueries(
        useGetPartnerContactsQuery.getKey({
          partnerId: invoice?.toPartnerId!,
        })
      );

      queryClient.invalidateQueries(
        useFinancials2__InvoiceDetailsQuery.getKey({
          siteId: params.siteId!,
          invoiceId: params.invoiceId!,
        })
      );
    },
  });

  const { mutate: updateMemo } = useUpdateInvoiceMemoMutation({
    onMutate: (variables) => {
      const { memo } = variables.input;
      const queryKey = useFinancials2__InvoiceDetailsQuery.getKey({
        siteId: siteId!,
        invoiceId,
      });

      const previousData: IFinancials2__InvoiceDetailsQuery | undefined =
        queryClient.getQueryData(queryKey);

      queryClient.setQueryData(
        queryKey,
        (oldData: IFinancials2__InvoiceDetailsQuery | void) => {
          if (oldData) {
            return {
              data: {
                invoice: {
                  ...oldData.data.invoice,
                  ...{
                    memo: memo ?? null,
                  },
                },
              },
            };
          }

          return oldData;
        }
      );

      if (previousData) {
        return { previousData };
      }
    },
    onError: (error, variables, context) => {
      message.error("Failure to update memo on invoice");
      console.error({ error, variables });
      const queryKey = useFinancials2__InvoiceDetailsQuery.getKey({
        siteId: params.siteId!,
        invoiceId: params.invoiceId!,
      });

      queryClient.setQueryData(queryKey, context?.previousData);
    },
    onSuccess: (resp) => {
      if (resp.data.success) {
        message.success("Invoice memo has been updated!");

        queryClient.invalidateQueries(
          useFinancials2__InvoiceDetailsQuery.getKey({
            siteId: params.siteId!,
            invoiceId: params.invoiceId!,
          })
        );
      }
    },
  });

  const { mutate: updateTotalModification } =
    useUpdateInvoiceTotalModificationMutation({
      onMutate: (variables) => {
        const {
          fixedValue,
          percentageValue,
          invoiceId,
          modificationType,
          name,
        } = variables.input;

        const queryKey = useFinancials2__InvoiceDetailsQuery.getKey({
          siteId: siteId!,
          invoiceId,
        });

        queryClient.setQueryData(
          queryKey,
          (oldData: IFinancials2__InvoiceDetailsQuery | void) => {
            if (oldData) {
              return {
                data: {
                  invoice: {
                    ...oldData.data.invoice,
                    ...{
                      totalModification: {
                        name,
                        modificationType,
                        fixedValue: fixedValue ?? null,
                        percentageValue: percentageValue ?? null,
                      },
                    },
                  },
                },
              };
            }

            return oldData;
          }
        );

        const previousData: IFinancials2__InvoiceDetailsQuery | undefined =
          queryClient.getQueryData(queryKey);

        if (previousData) {
          return { previousData };
        }
      },
      onError: (error, variables, context) => {
        message.error("Failure to update total modification on invoice");
        console.error({ error, variables });
        const queryKey = useFinancials2__InvoiceDetailsQuery.getKey({
          siteId: params.siteId!,
          invoiceId,
        });
        queryClient.setQueryData(queryKey, context?.previousData);
      },
      onSuccess: (resp, _variables, _context) => {
        if (resp.data.success) {
          message.success("Invoice total modification has been updated!");

          queryClient.invalidateQueries(
            useFinancials2__InvoiceDetailsQuery.getKey({
              siteId: params.siteId!,
              invoiceId,
            })
          );
        }
      },
    });

  const { mutate: deleteTotalModification } =
    useDeleteInvoiceTotalModificationMutation({
      onMutate: (variables) => {
        const { invoiceId } = variables.input;

        const queryKey = useFinancials2__InvoiceDetailsQuery.getKey({
          siteId: siteId!,
          invoiceId,
        });

        queryClient.setQueryData(
          queryKey,
          (oldData: IFinancials2__InvoiceDetailsQuery | void) => {
            if (oldData) {
              return {
                data: {
                  invoice: {
                    ...oldData.data.invoice,
                    ...{
                      totalModification: null,
                    },
                  },
                },
              };
            }

            return oldData;
          }
        );

        const previousData: IFinancials2__InvoiceDetailsQuery | undefined =
          queryClient.getQueryData(queryKey);

        if (previousData) {
          return { previousData };
        }
      },
      onError: (error, variables, context) => {
        message.error("Failure to delete total modification on invoice");
        console.error({ error, variables });
        const queryKey = useFinancials2__InvoiceDetailsQuery.getKey({
          siteId: params.siteId!,
          invoiceId: params.invoiceId!,
        });
        queryClient.setQueryData(queryKey, context?.previousData);
      },
      onSuccess: (resp, _variables, _context) => {
        if (resp.data.success) {
          message.success("Invoice total modification has been deleted!");

          queryClient.invalidateQueries(
            useFinancials2__InvoiceDetailsQuery.getKey({
              siteId: params.siteId!,
              invoiceId: params.invoiceId!,
            })
          );
        }
      },
    });

  const invoice = invoiceDetails;
  const partners = useMemo(() => {
    if (invoice?.externalStatus === "DRAFT") {
      return partnerList?.Financials2__partnerList.partners ?? [];
    } else if (invoice?.toPartnerId) {
      return [
        {
          id: invoice.toPartnerId,
          name: invoice.toPartnerName,
        },
      ];
    } else {
      return [];
    }
  }, [partnerList?.Financials2__partnerList.partners, invoice]);

  const partnerOptions = useMemo(
    () =>
      sortBy(partners, "name").map((partner) => (
        <Select.Option key={partner.id} value={partner.id}>
          {partner.name}
        </Select.Option>
      )),
    [partners]
  );

  const { data: contacts } = useGetPartnerContactsQuery(
    { partnerId: invoice?.toPartnerId! },
    { enabled: !!invoice?.toPartnerId && invoice.externalStatus === "DRAFT" }
  );

  const contactOptions = useMemo(
    () =>
      (contacts?.Financials2__partnerDetails.partner.contacts || []).map(
        (contact) => ({
          value: contact.id,
          label: contact.name,
        })
      ),
    [contacts]
  );

  const [paymentTermDaysInputValue, setPaymentTermDaysInputValue] = useState<
    number | null | undefined
  >();
  const updatePaymentTermsDebounced = useDebounce(
    updatePaymentTerms,
    DEFAULT_DEBOUNCE_TIME
  );
  const handlePaymentTermDaysChange = useMemo(
    () => (e: { target: { value: string } }) => {
      const paymentTermDays = e.target.value
        ? parseInt(e.target.value, 10)
        : null;
      setPaymentTermDaysInputValue(paymentTermDays);
      updatePaymentTermsDebounced({
        input: { invoiceId: params.invoiceId!, paymentTermDays },
      });
    },
    [params.invoiceId, updatePaymentTermsDebounced]
  );

  if (!invoice) {
    return <Loading />;
  }

  const {
    id: invoiceId,
    invoiceNumber,
    site: { name: siteName },
    investigator,
    fromAddressLine1,
    fromAddressLine2,
    fromCity,
    fromState,
    fromZipCode,
    fromCompanyLogo,
    fromPhoneNumber,
    fromEmailAddress,
    toPartnerId,
    budgetPaymentTermDays,
    overridePaymentTermDays,
    dueDate,
    issueDate,
    toContactId,
    toContactName,
    toContactEmailAddress,
    toContactPhoneNumber,
    total,
    externalStatus,
    paidStatus,
    toAddressLine1,
    toAddressLine2,
    toCity,
    toState,
    toZipCode,
  } = invoice;

  const defaultLogo = "/images/site-logo-placeholder.png";

  const onPartnerContactFormFinishFn = (values: {
    id?: string;
    name: string;
    emailAddress: string;
    phoneNumber: string;
    isPrimary: boolean;
  }) => {
    const { id, ...valuesWithoutId } = values;

    const { emailAddress, name, phoneNumber } = valuesWithoutId;

    if (toPartnerId) {
      addInvoicePrimaryContact({
        input: {
          emailAddress,
          phoneNumber,
          name,
          invoiceId,
          partnerId: toPartnerId,
        },
      });
    }
  };

  const toggleCreateContactForm = (contact: {
    emailAddress: string;
    id: string;
    isPrimary: boolean;
    name: string;
    phoneNumber: string;
  }) => {
    if (!showAddContactForm) {
      partnerContactForm.setFieldsValue(contact);
    } else {
      partnerContactForm.resetFields();
    }

    setShowAddContactForm(!showAddContactForm);
  };

  return (
    <div className={invoiceViewWrapper}>
      <PartnerContactFormModal
        form={partnerContactForm}
        showAddContactForm={showAddContactForm}
        hasPrimaryContact={false}
        onFormFinishFn={onPartnerContactFormFinishFn}
        toggleCreateContactForm={toggleCreateContactForm}
        isSavingContact={addingInvoicePrimaryContact}
      />
      <InvoiceDetailsActions
        invoiceId={invoiceId}
        toPartnerId={toPartnerId}
        toContactId={toContactId}
        externalStatus={externalStatus}
        paidStatus={paidStatus}
        placement="top"
      />
      <div className={invoiceWrapper}>
        <div className={invoiceHeaderControls}>
          <div>
            <div className={invoiceHeaderControlsSiteLogo}>
              <img alt="logo" src={fromCompanyLogo ?? defaultLogo} />
            </div>
            <div className={invoiceHeaderControlsFormBlock}>
              <div className="from-block">
                <Typography.Text strong>From:</Typography.Text>
                <ul className={invoiceHeaderControlsFormBlockDescriptions}>
                  <li>
                    <Typography.Text className="label" type="secondary">
                      Site Name:
                    </Typography.Text>
                    {siteName && (
                      <Typography.Text
                        className="value"
                        data-testid="invoice-site-name"
                      >
                        {siteName}
                      </Typography.Text>
                    )}
                  </li>
                  <li>
                    <Typography.Text className="label" type="secondary">
                      Trial Name:
                    </Typography.Text>
                    <Typography.Text
                      className="value"
                      data-testid="trial-name-title"
                    >
                      {invoiceDetails.lineItems[0]?.siteTrial.name ?? "N/A"}
                    </Typography.Text>
                  </li>
                  <li>
                    <Typography.Text className="label" type="secondary">
                      PI:
                    </Typography.Text>
                    <Typography.Text
                      className="value"
                      data-testid="invoice-investigator-name"
                    >
                      {investigator?.name ?? "N/A"}
                    </Typography.Text>
                  </li>
                  <li>
                    <Typography.Text className="label" type="secondary">
                      Attention:
                    </Typography.Text>
                    <Typography.Text
                      className="value"
                      data-testid="invoice-attention"
                    >
                      {siteSettings?.contactName}
                    </Typography.Text>
                  </li>
                  <li>
                    <Typography.Text className="label" type="secondary">
                      Phone:
                    </Typography.Text>
                    <Typography.Text
                      className="value"
                      data-testid="invoice-from-phone-number"
                    >
                      {fromPhoneNumber}
                    </Typography.Text>
                  </li>
                  <li>
                    <Typography.Text className="label" type="secondary">
                      Address:
                    </Typography.Text>
                    <div className="value">
                      <Typography.Text
                        className={addressLineStyle}
                        data-testid="invoice-from-address-line-1"
                      >
                        {fromAddressLine1}
                      </Typography.Text>
                      {fromAddressLine2 ? (
                        <Typography.Text
                          className={addressLineStyle}
                          data-testid="invoice-from-address-line-2"
                        >
                          {fromAddressLine2}
                        </Typography.Text>
                      ) : null}
                      <Typography.Text
                        className={addressLineStyle}
                        data-testid="invoice-from-city-state-zip-code"
                      >
                        {fromCity}, {fromState} {fromZipCode}
                      </Typography.Text>
                    </div>
                  </li>
                  <li>
                    <Typography.Text className="label" type="secondary">
                      Email:
                    </Typography.Text>
                    <Typography.Text
                      className="value"
                      data-testid="invoice-from-email-address"
                    >
                      {`${fromEmailAddress}`}
                    </Typography.Text>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className={invoiceHeaderControlsCenterBlock}>
            <SaveIndication />
            <div
              data-testid="invoice-external-status"
              className={`${invoiceHeaderStatusBadge} ${externalStatus}`}
            >
              {formatExternalStatus(externalStatus)}
            </div>
          </div>
          <div className="to-block">
            <Typography.Title level={3} data-testid="invoice-number">
              Invoice: #{invoiceNumber}
            </Typography.Title>
            <div>
              <ul className={invoiceHeaderControlsFormBlockDescriptions}>
                <li className={issueDateListItemStyle}>
                  <Typography.Text className="label" type="secondary">
                    Issue Date:
                  </Typography.Text>
                  <DatePicker
                    className="select"
                    disabled={externalStatus !== "DRAFT"}
                    data-testid="effective-dates-datepicker"
                    format={DEFAULT_DATEPICKER_FORMAT}
                    defaultValue={moment(utcDateStringToLocalDate(issueDate))}
                    allowClear={false}
                    onChange={(date) => {
                      if (date) {
                        updateIssueDate({
                          input: {
                            invoiceId,
                            issueDate: localDateToUTCDateString(date),
                          },
                        });
                      }
                    }}
                  />
                </li>
                <li className={paymentTermDaysListItemStyle}>
                  <Typography.Text type="secondary">
                    Payment Terms:
                  </Typography.Text>
                  <NumericInput
                    disabled={externalStatus !== "DRAFT"}
                    className={paymentTermDaysInputStyle}
                    data-testid="invoice-payment-terms"
                    placeholder={budgetPaymentTermDays as any}
                    min={0}
                    max={999}
                    decimalScale={0}
                    value={
                      typeof paymentTermDaysInputValue === "undefined"
                        ? overridePaymentTermDays
                        : (paymentTermDaysInputValue as any)
                    }
                    onChange={handlePaymentTermDaysChange}
                  />
                </li>
                <li className={dueDateListItemStyle}>
                  <Typography.Text type="secondary">Due Date:</Typography.Text>
                  <Typography.Text
                    className="value"
                    data-testid="invoice-due-date"
                  >
                    {formatDate(dueDate)}
                  </Typography.Text>
                </li>
              </ul>
            </div>
            {partnerOptions.length === 0 ? (
              <div className={toSelector}>
                <div className="add-primary">
                  <Typography.Link
                    data-testid="create-partner-link"
                    onClick={() =>
                      navigate(`/finance/sites/${params.siteId}/partners/new`)
                    }
                  >
                    Create Partner
                  </Typography.Link>
                </div>
              </div>
            ) : (
              <div className={toSelector}>
                <div className="to-seletor">
                  <div className="prefix">
                    <Typography.Text strong>To:</Typography.Text>
                  </div>
                  <Select
                    data-testid="invoice-partner-select"
                    disabled={externalStatus !== "DRAFT"}
                    onChange={(partnerId) =>
                      updateInvoiceRecipient({
                        input: {
                          invoiceId,
                          invoiceVersionId: "",
                          toPartnerId: partnerId,
                          toContactId: null,
                        },
                      })
                    }
                    placeholder="Select Partner"
                    value={toPartnerId ?? undefined}
                  >
                    {partnerOptions}
                  </Select>
                </div>
                {toPartnerId && !toContactId && (
                  <div className="add-primary">
                    <Typography.Link
                      data-testid="add-primary-contact-link"
                      onClick={() =>
                        toggleCreateContactForm({
                          id: "",
                          emailAddress: "",
                          phoneNumber: "",
                          name: "",
                          isPrimary: true,
                        })
                      }
                    >
                      Add Primary Contact
                    </Typography.Link>
                  </div>
                )}
                {toContactId && (
                  <ul className={invoiceHeaderControlsFormBlockDescriptions}>
                    <li>
                      <Typography.Text className="label" type="secondary">
                        Attention:
                      </Typography.Text>
                      <Select
                        style={{
                          width: "200px",
                          marginBottom: "var(--size-3)",
                        }}
                        data-testid="contact-select"
                        disabled={!toPartnerId || externalStatus !== "DRAFT"}
                        options={
                          !contactOptions.some(
                            (option) => option.value === toPartnerId
                          ) && externalStatus !== "DRAFT"
                            ? [{ value: toContactId, label: toContactName }]
                            : contactOptions
                        }
                        onChange={(contactId) =>
                          updateInvoiceRecipient({
                            input: {
                              invoiceId,
                              invoiceVersionId: "",
                              toPartnerId: toPartnerId!,
                              toContactId: contactId,
                            },
                          })
                        }
                        placeholder="Select Contact"
                        value={toContactId ?? undefined}
                      />
                    </li>
                    <li>
                      <Typography.Text className="label" type="secondary">
                        Phone:
                      </Typography.Text>
                      <Typography.Text
                        className="value"
                        data-testid="invoice-to-contact-phone-number"
                      >
                        {toContactPhoneNumber}
                      </Typography.Text>
                    </li>
                    <li>
                      <Typography.Text className="label" type="secondary">
                        Address:
                      </Typography.Text>

                      <div
                        className="value"
                        data-testid="invoice-to-address-line-1"
                      >
                        <Typography.Text className={addressLineStyle}>
                          {toAddressLine1}
                        </Typography.Text>
                        {toAddressLine2 ? (
                          <Typography.Text
                            className={addressLineStyle}
                            data-testid="invoice-to-address-line-2"
                          >
                            {toAddressLine2}
                          </Typography.Text>
                        ) : null}
                        <Typography.Text
                          className={addressLineStyle}
                          data-testid="invoice-to-city-state-zip-code"
                        >
                          {toCity}, {toState} {toZipCode}
                        </Typography.Text>
                      </div>
                    </li>
                    <li>
                      <Typography.Text className="label" type="secondary">
                        Email:
                      </Typography.Text>
                      <Typography.Text
                        className="value"
                        data-testid="invoice-to-contact-email-address"
                      >
                        {`${toContactEmailAddress || "No email provided"}`}
                      </Typography.Text>
                    </li>
                  </ul>
                )}
              </div>
            )}
          </div>
        </div>

        <Table pagination={false} size="small" bordered dataSource={lineItems}>
          <Table.Column
            dataIndex="description"
            key="description"
            title={
              <Typography.Text data-testid="description-title">
                Description
              </Typography.Text>
            }
          />
          <Table.Column
            dataIndex="subjectId"
            key="subjectId"
            title="Subject ID"
            render={(_, record: InvoiceLineItem) => {
              return record.subjectId ? (
                <Tag data-testid="subject-id-title">{record.subjectId}</Tag>
              ) : (
                <Typography.Text data-testid="subject-id-title">
                  N/A
                </Typography.Text>
              );
            }}
          />
          <Table.Column
            key="visit"
            title="Visit"
            render={(_, record: { visitName?: string }) => {
              return (
                <Typography.Text data-testid="visit-title">
                  {record.visitName || "N/A"}
                </Typography.Text>
              );
            }}
          />
          <Table.Column
            key="completedDate"
            title="Completed Date"
            render={(_, record: InvoiceLineItem) => {
              return (
                <Typography.Text data-testid="completed-date-title">
                  {record.completedDate
                    ? formatDate(record.completedDate)
                    : record.adHocCompletedAt
                    ? formatDate(record.adHocCompletedAt)
                    : record.visitOutcomeDate
                    ? formatDate(record.visitOutcomeDate)
                    : ""}
                </Typography.Text>
              );
            }}
          />
          <Table.Column
            dataIndex="charge"
            key="charge"
            title="Charge"
            render={(_, record: InvoiceLineItem) => {
              return (
                <Typography.Text data-testid="charge-title">
                  {record.charge ? displayText(record.charge) : "N/A"}
                </Typography.Text>
              );
            }}
          />
        </Table>
        <InvoiceMemo invoice={invoice} updateMemo={updateMemo} />
        {flags["site-financials-invoice-total-modifications"] ? (
          <InvoiceTotalModificationForm
            invoice={invoice}
            updateTotalModification={updateTotalModification}
            deleteTotalModification={deleteTotalModification}
          />
        ) : (
          <div className={invoiceTotalBlock}>
            <Typography.Text>Invoice Total:</Typography.Text>
            <Typography.Text data-testid="invoice-total">
              {total && displayText(total)}
            </Typography.Text>
          </div>
        )}
      </div>
      <div className={formAndinvoiceBlock}>
        <InvoiceDetailsActions
          invoiceId={invoiceId}
          paidStatus={paidStatus}
          externalStatus={externalStatus}
          toPartnerId={toPartnerId}
          toContactId={toContactId}
          placement="bottom"
        />
      </div>
    </div>
  );
};
