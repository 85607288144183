import React, { useState } from "react";
import {
  Empty,
  icons,
  Col,
  Tooltip,
  Button,
  Input,
} from "@reifyhealth/picasso-pkg";
import { useNavigate } from "@app/hooks";
import {
  useBudgetVersionListingItems3Query,
  useUserSiteTrialsQuery,
} from "@service/generated";
import { BudgetVersion } from "@app/components/cards";
import { styled } from "@linaria/react";
import Loading from "@app/components/Loading";

const { SearchOutlined } = icons;

const PageActions = styled.section`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const budgetMessage = "All existing trials already have assigned budgets.";

export const BudgetVersionCardListView = ({
  siteId,
  budgets,
  onClickCreateBudgetBtnFn,
  enableCreateBudgetBtn,
  budgetVersionListingItemsIsLoading,
}: {
  siteId: string;
  budgets: BudgetVersion[];
  onClickCreateBudgetBtnFn: () => void;
  enableCreateBudgetBtn: boolean;
  budgetVersionListingItemsIsLoading: boolean;
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearchTerm(e.target.value);
  };

  if (budgetVersionListingItemsIsLoading) {
    return <Loading />;
  }

  if (!budgetVersionListingItemsIsLoading && budgets.length === 0) {
    return (
      <Col style={{ margin: "0 auto" }}>
        <Empty
          style={{ color: "#858585" }}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="No Site Budgets"
          data-testid="no-site-budgets"
        />
        {!enableCreateBudgetBtn ? (
          <Tooltip trigger={"hover"} placement="bottom" title={budgetMessage}>
            <Button
              data-testid="disabled-create-budget"
              type="primary"
              onClick={onClickCreateBudgetBtnFn}
              disabled
            >
              Create Budget
            </Button>
          </Tooltip>
        ) : (
          <Button
            data-testid="create-budget"
            type="primary"
            onClick={onClickCreateBudgetBtnFn}
          >
            Create Budget
          </Button>
        )}
      </Col>
    );
  }

  const cards = budgets.map((budget) => (
    <BudgetVersion key={budget.id} siteId={siteId} budget={budget} />
  ));

  const filteredCards = budgets
    .filter((budget) => {
      return (
        budget.name
          .trim()
          .toLowerCase()
          .includes(searchTerm.trim().toLowerCase()) ||
        budget.trial.name
          .trim()
          .toLowerCase()
          .includes(searchTerm.trim().toLowerCase())
      );
    })
    .map((budget) => (
      <BudgetVersion key={budget.id} siteId={siteId} budget={budget} />
    ));

  return (
    <>
      <PageActions>
        <Input
          style={{ width: "228px" }}
          allowClear
          prefix={<SearchOutlined className="search-icon" />}
          placeholder="Search Budgets"
          onChange={onChange}
          data-testid="search-budgets-input"
        />
        {!enableCreateBudgetBtn ? (
          <Tooltip trigger={"hover"} placement="left" title={budgetMessage}>
            <Button
              data-testid="disabled-create-budget"
              type="primary"
              onClick={onClickCreateBudgetBtnFn}
              disabled
            >
              Create Budget
            </Button>
          </Tooltip>
        ) : (
          <Button
            data-testid="create-budget"
            type="primary"
            onClick={onClickCreateBudgetBtnFn}
          >
            Create Budget
          </Button>
        )}
      </PageActions>
      {filteredCards.length === 0 && (
        <Empty
          data-testid="no-budgets-found"
          style={{ color: "var(--component-tertiary-text)" }}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={`No Site Budgets match for "${searchTerm}"`}
        />
      )}
      {searchTerm ? filteredCards : cards}
    </>
  );
};

interface BudgetListingTableProps {
  site: { value: string; label: string };
}

export const BudgetVersionCardList = ({ site }: BudgetListingTableProps) => {
  const navigate = useNavigate();
  const { data, isLoading: budgetVersionListingItemsIsLoading } =
    useBudgetVersionListingItems3Query({
      siteId: `${site.value}`,
    });

  const {
    data: userSiteTrials,
    isLoading: userSiteTrialsIsLoading,
    isFetching: userSiteTrialsIsFetching,
  } = useUserSiteTrialsQuery({
    filter: { siteId: site.value, hasBudget: false },
  });

  const budgetListingItems = data?.userBudgets.edges.map((edge) => edge.node);

  const userSiteTrialsWithNoBudgets =
    userSiteTrials?.Financials2__userSiteTrials.edges
      .filter((trial) => trial.node.protocol !== null)
      .map((edge) => edge.node) || [];

  return (
    <BudgetVersionCardListView
      siteId={site.value}
      budgetVersionListingItemsIsLoading={budgetVersionListingItemsIsLoading}
      budgets={budgetListingItems || []}
      onClickCreateBudgetBtnFn={() => {
        navigate(`/finance/sites/${site.value}/budgets/new`);
      }}
      enableCreateBudgetBtn={
        (!userSiteTrialsIsLoading || !userSiteTrialsIsFetching) &&
        userSiteTrialsWithNoBudgets.length > 0
      }
    />
  );
};
