import React from "react";
import { styled } from "@linaria/react";

const Icon = styled.div`
  width: 24px;
  height: 24px;

  .top {
    fill: var(--brand-sites);
  }

  .bottom {
    fill: var(--brand-spruce);
  }
`;

export const OSTLogo = () => {
  return (
    <Icon>
      <svg width="24" height="24" viewBox="0 0 24 24">
        <g clipPath="url(#clip0_1985_91866)">
          <path
            className="top"
            d="M17.9802 8.8096C17.9791 7.8292 17.5891 6.88927 16.8959 6.19602C16.2026 5.50277 15.2627 5.11282 14.2823 5.11172C13.7883 5.11787 13.3004 5.22169 12.8467 5.41719C12.393 5.6127 11.9825 5.89604 11.6388 6.25088C11.2951 6.60573 11.0249 7.02507 10.844 7.48475C10.663 7.94444 10.5748 8.43538 10.5844 8.92931C10.5596 9.51684 10.6751 10.1018 10.9214 10.6358C11.1677 11.1698 11.5376 11.6375 12.0006 12H6.07088C5.6021 10.7935 5.40495 9.49849 5.49346 8.20712C5.58197 6.91575 5.95397 5.65976 6.583 4.52847C7.21202 3.39719 8.08261 2.41843 9.13284 1.6618C10.1831 0.905169 11.3871 0.389271 12.6594 0.150802C13.9316 -0.0876674 15.2408 -0.0428482 16.4937 0.282071C17.7467 0.606991 18.9126 1.20403 19.9087 2.03071C20.9047 2.85739 21.7063 3.89341 22.2565 5.06506C22.8067 6.23671 23.0919 7.51521 23.0919 8.8096V12H17.9293L17.9802 8.8096Z"
          />
          <path
            className="bottom"
            d="M6.01993 15.1904C6.02103 16.1708 6.41098 17.1107 7.10423 17.804C7.79748 18.4972 8.73741 18.8872 9.71782 18.8883C10.2118 18.8821 10.6997 18.7783 11.1534 18.5828C11.6071 18.3873 12.0176 18.104 12.3613 17.7491C12.705 17.3943 12.9751 16.9749 13.1561 16.5152C13.3371 16.0556 13.4253 15.5646 13.4157 15.0707C13.4405 14.4831 13.325 13.8982 13.0787 13.3642C12.8324 12.8302 12.4624 12.3625 11.9994 12H17.9292C18.398 13.2065 18.5951 14.5015 18.5066 15.7929C18.4181 17.0842 18.0461 18.3402 17.4171 19.4715C16.7881 20.6028 15.9175 21.5816 14.8672 22.3382C13.817 23.0948 12.613 23.6107 11.3407 23.8492C10.0685 24.0877 8.75932 24.0428 7.50636 23.7179C6.25341 23.393 5.08745 22.796 4.09142 21.9693C3.0954 21.1426 2.29379 20.1066 1.7436 18.9349C1.19342 17.7633 0.908192 16.4848 0.908203 15.1904L0.908203 12H6.07089L6.01993 15.1904Z"
          />
        </g>
        <defs>
          <clipPath id="clip0_1985_91866">
            <rect width="22.1836" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Icon>
  );
};
