import { displayText, Money, toNumber } from "@lib/currency";
import { Progress, Typography } from "@reifyhealth/picasso-pkg";

interface PaymentChartProps {
  amount?: Money | null;
  remainingAmount?: Money | null;
  percentage?: number;
  allocatedAmount?: number;
  showInfo?: boolean;
}

const calculateProgressStatus = (percentage?: number) => {
  if (percentage) {
    if (percentage === 100) {
      return "success";
    } else if (percentage > 100) {
      return "exception";
    } else {
      return "normal";
    }
  } else {
    return "normal";
  }
};

export const PaymentChart = ({
  percentage,
  amount,
  remainingAmount,
  showInfo = true,
}: PaymentChartProps) => {
  return (
    <div>
      <Progress
        type="circle"
        percent={percentage ? Math.floor(percentage) : 0}
        showInfo={showInfo}
        trailColor="var(--component-background-subtle)"
        status={calculateProgressStatus(percentage)}
      />
      {amount && (
        <Typography.Title level={5}>
          <span>Payment: </span>
          {displayText(amount)}
        </Typography.Title>
      )}
      {(percentage ?? 0) < 100 ? (
        <Typography.Text type="secondary" data-testid="payment-chart-text">
          {amount && toNumber(amount) > 0 && remainingAmount ? (
            <>
              There is a <strong>{displayText(remainingAmount)}</strong> balance
              for this payment.
            </>
          ) : (
            <>Include an Amount for this payment.</>
          )}
        </Typography.Text>
      ) : null}
      {percentage && percentage === 100 ? (
        <Typography.Text type="success" data-testid="payment-chart-text">
          Payment has been fully allocated.
        </Typography.Text>
      ) : null}

      {percentage && percentage > 100 ? (
        <Typography.Text type="danger" data-testid="payment-chart-text">
          There are not enough funds to cover this payment.
        </Typography.Text>
      ) : null}
    </div>
  );
};
