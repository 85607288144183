import {
  useBudgetVersionListingItems3Query,
  useToggleBudgetVersionNoticeMutation,
} from "@app/service/generated";
import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import { Alert, Button } from "@reifyhealth/picasso-pkg";
import { useQueryClient } from "react-query";
import { useNavigate } from "@app/hooks";

const ProtocolVersionNotification = css`
  .ant-alert-message {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const Dismiss = styled.span`
  font-size: var(--font-size-caption);
  color: var(--component-tertiary-text);
  transition: var(--duration-2);
  margin-top: var(--size-5);
  display: block;

  &:hover {
    cursor: pointer;
    color: var(--component-primary-text);
  }
`;

interface IProtocolVersionAlertDismissBannerProps {
  siteId: string;
  budgetId: string;
  budgetConfigVersionId: string;
  protocolVersionId: string;
  isCreateBudgetVersionNoticeEnabled: boolean;
  siteTrialId: string;
  budgetName: string;
}

export const ProtocolVersionAlertDismissBanner = ({
  siteId,
  budgetId,
  budgetConfigVersionId,
  protocolVersionId,
  isCreateBudgetVersionNoticeEnabled,
  siteTrialId,
  budgetName,
}: IProtocolVersionAlertDismissBannerProps) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate } = useToggleBudgetVersionNoticeMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(
        useBudgetVersionListingItems3Query.getKey({ siteId })
      );
    },
  });

  return (
    <>
      <Alert
        banner
        className={ProtocolVersionNotification}
        type="info"
        message={
          <>
            <span>
              Create a new Budget Version when protocol amendments impact your
              costs.
            </span>
            <Button
              data-testid="create-budget-version-banner-btn"
              type="dashed"
              onClick={() => {
                navigate(
                  `/finance/sites/${siteId}/budgets/${budgetId}/versions/new?budget-name=${budgetName}&trial-id=${siteTrialId}`
                );
              }}
            >
              Create Budget Version
            </Button>
          </>
        }
      />
      <Dismiss
        data-testid="dismiss-protocol-version-banner"
        onClick={() =>
          mutate({
            input: {
              budgetId,
              budgetConfigVersionId,
              protocolVersionId,
              enabled: !isCreateBudgetVersionNoticeEnabled,
            },
          })
        }
      >
        Dismiss
      </Dismiss>
    </>
  );
};
