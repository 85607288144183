import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Auth0ProviderWithHistory, IdleTimerProvider } from "@components/auth";
import { isDevEnabled } from "@lib/environment";
import { Wrapper } from "@app/Wrapper";
import "index.scss";
import { worker } from "@mocks/index";
import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";
import { ErrorBoundary } from "./ErrorBoundary";

if (import.meta.env.VITE_ENABLED_MOCKS === "true") {
  worker.start();
}

if (!isDevEnabled()) {
  datadogLogs.init({
    clientToken: "pub7418f0eca9e8d387d6e3f0d6511d195f",
    site: "datadoghq.com",
    service: "finance",
    env: import.meta.env.MODE,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    forwardConsoleLogs: ["error", "warn", "info"],
    beforeSend: (_) => {
      // TODO: Add redactions as necessary here.
      return true;
    },
  });

  datadogRum.init({
    applicationId: "9aa484bf-9919-4b59-a34b-fa48c22342cc",
    clientToken: "pubf0fdcdb8cd12e9d228e390661d5e5877",
    site: "datadoghq.com",
    service: "finance",
    env: import.meta.env.MODE,
    version: import.meta.env.VITE_DATADOG_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    allowedTracingUrls: [/https:\/\/.*(?<!auth)\.studyteamapp\.com/],
  });
}

ReactDOM.render(
  <ErrorBoundary>
    <BrowserRouter>
      <Auth0ProviderWithHistory>
        <IdleTimerProvider>
          <Wrapper />
        </IdleTimerProvider>
      </Auth0ProviderWithHistory>
    </BrowserRouter>
  </ErrorBoundary>,

  document.getElementById("root")
);
