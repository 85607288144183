import { FinancialsFlagSet } from "@app/hooks/useFlags";
import { flow, keyBy } from "lodash";
import { addDescriptionAndVisitName, SourceEvent } from "./accountsReceivable";
import { getVisitsFromSchedule } from "@model/protocols";
import { toNumber } from "@lib/currency";
import { FilterValue } from "@reifyhealth/picasso-pkg";

function generateInvoiceProtocolLookupTable(
  protocols: ProtocolForSiteTrial[],
  flags: FinancialsFlagSet
) {
  const schedules = protocols
    .flatMap((p) => p?.versions ?? [])
    .flatMap((v) => v.schedules);

  const visits = keyBy(
    schedules.flatMap((s) => getVisitsFromSchedule(s, flags)),
    "crossVersionId"
  );

  const activities = keyBy(
    schedules.flatMap((s) => s.siteActivities).filter((activity) => activity),
    "crossVersionId"
  );

  return { visits, activities };
}

function addProtocolCrossVersionLookup(
  sourceEvents: SourceEvent[],
  protocols: any,
  flags: FinancialsFlagSet
) {
  const protocolVersionLookup = generateInvoiceProtocolLookupTable(
    protocols,
    flags
  );

  return sourceEvents.map((sourceEvent) => {
    return {
      ...sourceEvent,
      allProtocolVersions: protocolVersionLookup,
    };
  });
}

export function lineItems(
  lineItems: InvoiceLineItem[],
  protocols: ProtocolForSiteTrial[],
  flags: FinancialsFlagSet
) {
  const transform = flow(
    (lineItems) => addProtocolCrossVersionLookup(lineItems, protocols, flags),
    (lineItems) => addDescriptionAndVisitName(lineItems, flags)
  );

  return transform(lineItems);
}

export function formatExternalStatus(
  externalStatus:
    | Invoice["externalStatus"]
    | IFinancials2__AccountsReceivableLineItemExternalStatus
) {
  return externalStatus.replaceAll("_", " ");
}

export function hasDisabledActions(invoice: any) {
  return (
    invoice.externalStatus === "DRAFT" ||
    invoice.externalStatus === "VOID" ||
    invoice.externalStatus === "PAID" ||
    invoice.paidStatus === "PARTIALLY_PAID" ||
    invoice.paidStatus === "FULLY_PAID"
  );
}

export function tableTotals({
  filterValue,
  activeFilters,
}: {
  filterValue: PartnerInvoice[] | Invoice[];
  activeFilters: Record<string, FilterValue | null>;
}) {
  const rows = filterValue
    .filter((invoice) => {
      let included = true;

      if (activeFilters.externalStatus) {
        included =
          included &&
          activeFilters.externalStatus.includes(invoice.externalStatus);
      }

      if (activeFilters.siteTrialName) {
        const siteTrialName = invoice.lineItems[0].siteTrial.name;
        included =
          included &&
          activeFilters.siteTrialName.some((name) => name === siteTrialName);
      }

      return included;
    })
    .filter(
      (invoice) =>
        (invoice.externalStatus as string) === "OPEN" ||
        (invoice.externalStatus as string) === "PAST_DUE" ||
        (invoice.externalStatus as string) === "DRAFT"
    );

  const overallTotal = rows.reduce(
    (number, invoice) =>
      number +
      (invoice.paidStatus === "PARTIALLY_PAID"
        ? toNumber(invoice.remainingAmount ?? 0)
        : toNumber(invoice.total ?? 0)),
    0
  );

  const overallRecordCount = rows.length;

  const overallPastDueTotal = rows
    .filter((invoice) => (invoice.externalStatus as string) === "PAST_DUE")
    .filter((invoice) => {
      if (activeFilters.externalStatus) {
        return activeFilters.externalStatus.includes(invoice.externalStatus);
      }

      if (activeFilters.siteTrialName) {
        const siteTrialName = invoice.lineItems[0].siteTrial.name;
        return activeFilters.siteTrialName.some(
          (name) => name === siteTrialName
        );
      }

      return true;
    })
    .reduce((number, invoice) => number + toNumber(invoice.total ?? 0), 0);

  return {
    overallTotal,
    overallRecordCount,
    overallPastDueTotal,
  };
}
