import { capitalize, omit } from "lodash";
import {
  plus,
  div,
  eq,
  gt,
  lt,
  money,
  Money,
  times,
  minus,
  toNumber,
} from "@lib/currency";

export function calculateRemainingAmount(
  allocatedAmount: Money,
  wholeAmount: Money
): number {
  return toNumber(minus(wholeAmount, allocatedAmount));
}

export function calculatePercentage(
  allocatedAmount: Money,
  wholeAmount: Money
): number {
  if (eq(wholeAmount, 0)) {
    return 0.0;
  }

  const percentage = toNumber(times(div(allocatedAmount, wholeAmount), 100));

  return Math.max(0.0, Math.min(101.0, percentage));
}

export function formatAllocationStatus(
  allocationStatus: Payment["allocationStatus"]
) {
  if (!allocationStatus) {
    return "Unallocated";
  } else {
    return capitalize(allocationStatus.replaceAll("_", " "));
  }
}

enum AllocationStatus {
  Allocated = "ALLOCATED",
  Unallocated = "UNALLOCATED",
}

export type AllocatedPaymentAllocationTableItem = OpenPaymentItem & {
  readonly rowKey: string;
  readonly clientAllocationStatus: AllocationStatus.Allocated;
  readonly clientAllocation: Money | null;
};
export type UnallocatedPaymentAllocationTableItem = OpenPaymentItem & {
  readonly rowKey: string;
  readonly clientAllocationStatus: AllocationStatus.Unallocated;
};
export type PaymentAllocationTableItem =
  | AllocatedPaymentAllocationTableItem
  | UnallocatedPaymentAllocationTableItem;

export function createPaymentAllocationTableItem(
  item: OpenPaymentItem
): PaymentAllocationTableItem {
  const {
    invoiceId,
    adHocReceivableId,
    patientProtocolVisitId,
    unscheduledPatientProtocolVisitId,
    protocolActivityCrossVersionId,
  } = item;
  const rowKey = `${invoiceId}|${adHocReceivableId}|${patientProtocolVisitId}|${unscheduledPatientProtocolVisitId}|${protocolActivityCrossVersionId}`;

  if (item.paymentItemCurrentAllocationAmount) {
    return {
      ...item,
      rowKey,
      clientAllocation: money(item.paymentItemCurrentAllocationAmount),
      clientAllocationStatus: AllocationStatus.Allocated,
    };
  }

  return {
    ...item,
    rowKey,
    clientAllocationStatus: AllocationStatus.Unallocated,
  };
}

export function allocatePaymentAllocationTableItem(
  item: UnallocatedPaymentAllocationTableItem,
  paymentRemainingAmount: Money | null
): AllocatedPaymentAllocationTableItem {
  if (!paymentRemainingAmount) {
    return {
      ...item,
      clientAllocationStatus: AllocationStatus.Allocated,
      clientAllocation: null,
    };
  }

  const pra = money(paymentRemainingAmount);

  let clientAllocation: Money;

  if (item.paymentItemCurrentAllocationAmount) {
    const existingAllocation = money(item.paymentItemCurrentAllocationAmount);

    if (gt(existingAllocation, pra)) {
      clientAllocation = pra;
    } else {
      clientAllocation = existingAllocation;
    }
  } else {
    const maxAlloc = money(item.paymentItemMaxAllocationAmount!);

    if (gt(maxAlloc, pra)) {
      clientAllocation = pra;
    } else {
      clientAllocation = maxAlloc;
    }
  }

  return {
    ...item,
    clientAllocationStatus: AllocationStatus.Allocated,
    clientAllocation,
  };
}

export function deallocatePaymentAllocationTableItem(
  item: AllocatedPaymentAllocationTableItem
): UnallocatedPaymentAllocationTableItem {
  return {
    ...omit(item, "allocation"),
    clientAllocationStatus: AllocationStatus.Unallocated,
  };
}

export enum AllocationUpdateError {
  InsufficientFunds = "INSUFFICIENT_FUNDS",
  NegativeAmount = "NEGATIVE_AMOUNT",
  InvalidAmount = "INVALID_AMOUNT",
}

export function updatePaymentAllocationTableItemAmount(
  item: AllocatedPaymentAllocationTableItem,
  newAmount: Money | string | null,
  paymentRemainingAmount: Money | string
): AllocatedPaymentAllocationTableItem | AllocationUpdateError {
  const prevAlloc = item.clientAllocation;

  let nextAlloc: Money | null = null;

  if (!newAmount) {
    return { ...item, clientAllocation: nextAlloc };
  }

  try {
    nextAlloc = money(newAmount);
  } catch (e) {
    console.error(e);
    return AllocationUpdateError.InvalidAmount;
  }

  if (lt(nextAlloc, 0)) {
    return AllocationUpdateError.NegativeAmount;
  }

  const pra = money(paymentRemainingAmount);

  if (gt(nextAlloc, plus(pra, prevAlloc ? prevAlloc : 0))) {
    return AllocationUpdateError.InsufficientFunds;
  }

  return { ...item, clientAllocation: nextAlloc };
}

export function isAllocatedPaymentAllocationTableItem(
  item: PaymentAllocationTableItem
): item is AllocatedPaymentAllocationTableItem {
  return item.clientAllocationStatus === AllocationStatus.Allocated;
}
