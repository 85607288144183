import "antd/dist/antd.css";
import Routes from "./Routes";
import { useSearchParams } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { auth0Context } from "@reifyhealth/picasso-auth0";

function useErrorBoundaryTest() {
  const [searchParams] = useSearchParams();
  const v = searchParams.get("error-boundary-test");
  if (v && JSON.parse(v)) {
    throw Error(
      "Manual error triggered by error-boundary-test query string parameter"
    );
  }
}

function App() {
  useErrorBoundaryTest();
  return <Routes />;
}

export default withAuthenticationRequired(App, {
  context: auth0Context,
  loginOptions: {
    authorizationParams: {
      // NOTE: This is required to tell our Auth0-side idle timeout logic
      // that a login triggered by this component is an initial user login,
      // rather than a subsequent token refresh.
      firstLogin: true,
    },
  },
});
