import { PageLayout } from "@components/PageLayout";
import { BudgetVersionSiteSelectorHeader } from "@app/components/headers/BudgetVersionSiteSelectorHeader";
import { PartnersListingTable } from "@app/components/tables/PartnersTable";

export const PartnersPage = () => {
  return (
    <PageLayout Header={BudgetVersionSiteSelectorHeader} showSider>
      <section>
        <PartnersListingTable />
      </section>
    </PageLayout>
  );
};
