import { Button, Modal, Space, Typography } from "@reifyhealth/picasso-pkg";
import React, { Component, ErrorInfo } from "react";
import { ErrorBoundaryIcon } from "./components/common/icons/ErrorBoundaryIcon";

interface State {
  error: any; // Could be an exception thrown in synchronous code or could be a rejection reason from a Promise, we don't care
}

const technicalSupportMailToLink = (error?: string) =>
  `mailto:support@reifyhealth.com?subject=OST Financials Technical Support`;

export class ErrorBoundary extends Component<any> {
  private promiseRejectionHandler = (event: PromiseRejectionEvent) => {
    console.error("Uncaught error:", event.reason);
    this.setState({
      error: event.reason,
    });
  };

  public state: State = {
    error: null,
  };

  public static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    const filtered = error.message.includes("ResizeObserver") ? null : error;
    return { error: filtered };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  componentDidMount() {
    // Add an event listener to the window to catch unhandled promise rejections & stash the error in the state
    window.addEventListener("unhandledrejection", this.promiseRejectionHandler);
  }

  componentWillUnmount() {
    window.removeEventListener(
      "unhandledrejection",
      this.promiseRejectionHandler
    );
  }

  public render() {
    if (this.state.error) {
      const error = this.state.error;

      return (
        <Modal
          bodyStyle={{
            display: "flex",
            textAlign: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
          closable={false}
          footer={null}
          centered
          visible={true}
        >
          <ErrorBoundaryIcon />
          <Typography.Text strong>There's a problem</Typography.Text>
          <Typography.Text>
            An error report was sent to our support team. Please try to refresh
            the page. We apologize for the inconvenience.
          </Typography.Text>
          <div style={{ padding: "1rem" }}>
            <Space>
              <a href={technicalSupportMailToLink(error)}>Contact support</a>
              <Button type="default" onClick={() => window.location.reload()}>
                Refresh Page
              </Button>
            </Space>
          </div>
        </Modal>
      );
    } else {
      return this.props.children;
    }
  }
}
