import { Upload, UploadFile, message } from "@reifyhealth/picasso-pkg";
import { UploadSimpleRegular } from "@reifyhealth/picasso-icons";
import { useParams } from "react-router-dom";
import {
  GetAttachmentPreviewLinkDocument,
  PaymentAttachmentUploadLinkDocument,
} from "@app/service/generated";
import { useCodegenFetcher } from "@app/service/codegenFetcher";
import axios from "axios";
import { useEffect, useState } from "react";
import { PaymentAttachmentType } from "@app/hooks/usePayments";

const { Dragger } = Upload;

interface PaymentAttachmentsUploaderProps {
  resetFileList: boolean;
  setSelectedAttachments: React.Dispatch<
    React.SetStateAction<
      {
        contentType: string;
        filename: string;
        id: string;
        type: PaymentAttachmentType;
      }[]
    >
  >;
  selectedAttachments?: {
    contentType: string;
    filename: string;
    id: string;
    type: PaymentAttachmentType;
  }[];
}

export const PaymentAttachmentsUploader = ({
  setSelectedAttachments,
  selectedAttachments,
  resetFileList,
}: PaymentAttachmentsUploaderProps) => {
  const params = useParams();

  const [uploadIds, setUploadIds] = useState<Record<string, string>>({});
  const [tempPreviewUrls, setTempPreviewUrls] = useState<
    Record<string, string>
  >({});
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [hydratedInitialFileList, setHydrateInitialFileList] = useState(false);

  const paymentAttachmentUploadLinkFetcher = useCodegenFetcher<
    {
      Financials2__paymentAttachmentUploadLink: IFinancials2__PaymentAttachmentUploadLinkResponse;
    },
    IQueryFinancials2__PaymentAttachmentUploadLinkArgs
  >(PaymentAttachmentUploadLinkDocument);

  const paymentAttachmentPreviewUrlFetcher = useCodegenFetcher<
    IGetAttachmentPreviewLinkQuery,
    IGetAttachmentPreviewLinkQueryVariables
  >(GetAttachmentPreviewLinkDocument);

  const handleUploadDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];

    const maxSizeInBytes = 5000000; // 5 MB

    const accept = "image/png,image/jpeg,application/pdf";

    if (file.size > maxSizeInBytes) {
      message.error("File exceeds allowed file upload size! Please try again!");
    } else if (!accept.includes(file.type)) {
      message.error("File is not of an acceptable type! Please try again!");
    }
  };

  useEffect(() => {
    const generateUploadFileWithPreviewURLs = async () => {
      if (selectedAttachments) {
        const handlePreviewUrl = async (attachmentId: string) => {
          const resp = await paymentAttachmentPreviewUrlFetcher({
            siteId: params.siteId!,
            attachmentId,
          });
          const { url } = resp.Financials2__paymentAttachmentGetLink;

          return url;
        };

        const fileListWithPreviewURL: UploadFile[] = await Promise.all(
          selectedAttachments.map(async (attachment) => {
            setUploadIds((prevState) => ({
              ...prevState,
              [attachment.id]: attachment.id,
            }));

            return {
              uid: attachment.id,
              id: attachment.id,
              name: attachment.filename,
              fileName: attachment.filename,
              contentType: attachment.contentType,
              url:
                attachment.type === PaymentAttachmentType.EXISTING
                  ? await handlePreviewUrl(attachment.id)
                  : "",
            };
          })
        );

        setFileList(fileListWithPreviewURL);
        setHydrateInitialFileList(true);
      }
    };

    if (resetFileList && selectedAttachments?.length === 0) {
      setFileList([]);
    }

    if (selectedAttachments && !hydratedInitialFileList) {
      generateUploadFileWithPreviewURLs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAttachments, hydratedInitialFileList, resetFileList]);

  return (
    <section data-testid="payment-attachments-uploader">
      <Dragger
        multiple
        maxCount={5}
        fileList={fileList}
        accept="image/png,image/jpeg,application/pdf"
        onDrop={handleUploadDrop}
        customRequest={async (options) => {
          const { onSuccess, onError } = options;
          try {
            const resp = await axios.put(options.action, options.file, {
              headers: { "Content-Type": (options.file as File).type },
            });

            if (resp.status === 200) {
              onSuccess!({ url: "ok" });
            }
          } catch (err) {
            onError!({
              status: 500,
              method: "PUT",
              url: options.action,
            } as any);
          }
        }}
        method="PUT"
        action={async (file) => {
          const fetchPaymentAttachmanetUploadLink =
            await paymentAttachmentUploadLinkFetcher({
              siteId: params.siteId!,
              contentType: file.type,
            });

          const { id, url, previewUrl } =
            fetchPaymentAttachmanetUploadLink.Financials2__paymentAttachmentUploadLink;

          setUploadIds((prevState) => ({ ...prevState, [file.uid]: id }));
          setTempPreviewUrls((prevState) => ({
            ...prevState,
            [file.uid]: previewUrl,
          }));

          return url;
        }}
        onChange={async (info) => {
          const attachments = info.fileList.map((file) => {
            const foundAttachment = selectedAttachments?.filter(
              (attachment) => attachment.id === uploadIds[file.uid]
            );

            if (foundAttachment && foundAttachment.length > 0) {
              return {
                id: foundAttachment[0].id,
                filename: foundAttachment[0].filename,
                contentType: foundAttachment[0].contentType,
                type: foundAttachment[0].type,
              };
            } else {
              return {
                id: uploadIds[file.uid],
                filename: file.name,
                contentType: file.type || "",
                type: PaymentAttachmentType.TEMP,
              };
            }
          });

          setFileList(
            info.fileList.map((file) => {
              if (!file.url) {
                return { ...file, url: tempPreviewUrls[file.uid] };
              } else {
                return file;
              }
            })
          );
          setSelectedAttachments(attachments);
        }}
      >
        <UploadSimpleRegular className="upload-icon" />
        <p>Click or drag your document here.</p>
      </Dragger>
    </section>
  );
};
