export const ErrorBoundaryIcon = () => (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30 3.75C15.5039 3.75 3.75 15.5039 3.75 30C3.75 44.4961 15.5039 56.25 30 56.25C44.4961 56.25 56.25 44.4961 56.25 30C56.25 15.5039 44.4961 3.75 30 3.75ZM39.6914 39.9727L35.8242 39.9551L30 33.0117L24.1816 39.9492L20.3086 39.9668C20.0508 39.9668 19.8398 39.7617 19.8398 39.498C19.8398 39.3867 19.8809 39.2812 19.9512 39.1934L27.5742 30.1113L19.9512 21.0352C19.8804 20.9493 19.8411 20.8418 19.8398 20.7305C19.8398 20.4727 20.0508 20.2617 20.3086 20.2617L24.1816 20.2793L30 27.2227L35.8184 20.2852L39.6855 20.2676C39.9434 20.2676 40.1543 20.4727 40.1543 20.7363C40.1543 20.8477 40.1133 20.9531 40.043 21.041L32.4316 30.1172L40.0488 39.1992C40.1191 39.2871 40.1602 39.3926 40.1602 39.5039C40.1602 39.7617 39.9492 39.9727 39.6914 39.9727Z"
      fill="#FF4D4F"
    />
  </svg>
);
