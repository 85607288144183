import { formatInTimeZone } from "date-fns-tz";
import moment from "moment";

export const DEFAULT_DATE_FORMAT = "dd-MMM-yyy";
export const DEFAULT_DATEPICKER_FORMAT = "DD-MMM-yyy";

type CastableDate = moment.Moment | Date | string | number;

export function toDateObject(date: CastableDate) {
  if (moment.isMoment(date)) {
    return date.toDate();
  } else if (typeof date === "string" || Number.isInteger(date)) {
    return new Date(date);
  } else if (date instanceof Date) {
    return date;
  } else {
    throw new Error("Cannot convert argument to Date");
  }
}

export function formatDate(date: CastableDate, format = DEFAULT_DATE_FORMAT) {
  const dateObj = toDateObject(date);
  return formatInTimeZone(dateObj, "UTC", format);
}

/**
 * Returns a UTC date string from a `CastableDate`.
 *
 * The year, month, and day in the UTC date string will always match the
 * original local date, regardless of the local time zone.
 *
 * Example:
 *   Local Date: `Fri Aug 18 2023 02:00:00 GMT+0200`
 *   UTC Date String: `2023-08-18T00:00:00.000Z`
 */
export function localDateToUTCDateString(localDate: CastableDate): string {
  const d = toDateObject(localDate);
  const millis = Date.UTC(d.getFullYear(), d.getMonth(), d.getDate());
  return new Date(millis).toISOString();
}

/**
 * Returns a local `Date` object from a UTC date string.
 *
 * The year, month, and day in the Date object will always match the
 * original UTC date, regardless of the local time zone.
 *
 * Example:
 *   UTC Date String: `2023-08-18T00:00:00.000Z`
 *   Local Date: `Fri Aug 18 2023 00:00:00 GMT+0200`
 */
export function utcDateStringToLocalDate(utcDateString: string): Date {
  const d = toDateObject(utcDateString);
  return new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate());
}
