import { PageLayout } from "@components/PageLayout";
import {
  Button,
  Col,
  Input,
  Row,
  Space,
  Table,
  Empty,
  ConfigProvider,
  Tabs,
  Typography,
  Badge,
} from "@reifyhealth/picasso-pkg";
import { SearchOutlined } from "@ant-design/icons";
import { useAccountsReceivable } from "@hooks/accountsReceivable";
import { BudgetVersionSiteSelectorHeader } from "@app/components/headers/BudgetVersionSiteSelectorHeader";
import { isUUID } from "@lib/string";
import { useParams } from "react-router-dom";
import { ARLineItemsTable } from "@app/components/tables";
import { useNavigate, useSelectedSite } from "@app/hooks";
import { TotalHeader } from "@app/components/headers/TotalHeader";
import { useState } from "react";
import { money, Money } from "@lib/currency";

export const AccountsReceivablePage = () => {
  const [overallTotal, setOverallTotal] = useState<Money>(money(0));
  const [overallRecordNumber, setOverallRecordNumber] = useState<number>(0);
  const [pastDueTotal, setPastDueTotal] = useState<Money>(money(0));
  const [holdbackTotal, setHoldbackTotal] = useState<Money | null>(null);

  const params = useParams();
  const navigate = useNavigate();
  const site = useSelectedSite();
  const TabPane = Tabs.TabPane;
  const { siteId, isLoading, isFetching, lineItems, reactQueryKey } =
    useAccountsReceivable();

  const openLineItems = lineItems.filter(
    (lineItem) =>
      !lineItem.externalStatus ||
      lineItem.externalStatus === "OPEN" ||
      lineItem.externalStatus === "PAST_DUE" ||
      lineItem.externalStatus === "HOLDBACK"
  );

  if (params.siteId && !isUUID(params.siteId)) {
    return (
      <PageLayout Header={BudgetVersionSiteSelectorHeader} showSider>
        <ConfigProvider
          renderEmpty={() => {
            return (
              <>
                <Empty description="Site ID Unknown" />
                <Button
                  onClick={() => {
                    if (site.data?.selectedSite) {
                      navigate(
                        `/finance/sites/${site.data.selectedSite?.value}/accounts-receivable`
                      );
                    }
                  }}
                >
                  Refresh
                </Button>
              </>
            );
          }}
        >
          <Table
            title={() => {
              return (
                <Row justify="space-between">
                  <Col>
                    <Space>
                      <Input
                        allowClear
                        data-testid="search-accounts-receivable-input"
                        style={{ width: "288px" }}
                        prefix={<SearchOutlined className="search-icon" />}
                        placeholder="Search Accounts Receivable"
                        disabled
                      />
                    </Space>
                  </Col>
                  <Col>
                    <Space>
                      <Button
                        data-testid="export-btn"
                        disabled
                        data-pendo="accounts-receivable-export"
                      >
                        Export
                      </Button>
                    </Space>
                  </Col>
                </Row>
              );
            }}
            size="small"
          />
        </ConfigProvider>
      </PageLayout>
    );
  }

  return (
    <PageLayout Header={BudgetVersionSiteSelectorHeader} showSider>
      <>
        <TotalHeader
          title="Open Receivables"
          overallTotal={overallTotal}
          pastDueTotal={pastDueTotal}
          holdbackTotal={holdbackTotal}
        />
        <Tabs
          onChange={(tab) => {
            navigate(
              `/finance/sites/${params.siteId}/accounts-receivable/${tab}`
            );
          }}
          defaultActiveKey={params.tab ?? "open"}
        >
          <TabPane
            tab={
              <Typography.Text>
                Open{" "}
                <Badge
                  overflowCount={100}
                  style={{ backgroundColor: "#D7DBDF", color: "#000000A6" }}
                  count={overallRecordNumber}
                  showZero
                />
              </Typography.Text>
            }
            key="open"
          >
            <ARLineItemsTable
              siteId={siteId!}
              lineItems={openLineItems}
              isLoading={isLoading || isFetching}
              reactQueryKey={reactQueryKey}
              setOverallTotalFn={setOverallTotal}
              setPastDueTotalFn={setPastDueTotal}
              setOverallRecordNumberFn={setOverallRecordNumber}
              setHoldbackTotalFn={setHoldbackTotal}
            />
          </TabPane>
          <TabPane tab="All Receivables" key="all">
            <ARLineItemsTable
              siteId={siteId!}
              lineItems={lineItems}
              isLoading={isLoading || isFetching}
              reactQueryKey={reactQueryKey}
              setOverallTotalFn={setOverallTotal}
              setPastDueTotalFn={setPastDueTotal}
              setOverallRecordNumberFn={setOverallRecordNumber}
              setHoldbackTotalFn={setHoldbackTotal}
            />
          </TabPane>
        </Tabs>
      </>
    </PageLayout>
  );
};
