import {
  showDeleteModal,
  showFinalizedInvoiceModal,
  showSuccessModal,
} from "@app/components/common/modals";
import {
  useDeleteInvoiceMutation,
  useFinalizeInvoiceMutation,
  useFinancials2__InvoiceDetailsQuery,
  useInvoicePdf3Query,
  useMarkInvoiceAsPaidMutation,
  useMarkInvoiceAsVoidMutation,
  useReopenInvoiceMutation,
} from "@app/service/generated";
import { css } from "@linaria/core";
import { Button, Space, message, icons } from "@reifyhealth/picasso-pkg";
import { useParams } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useNavigate } from "@app/hooks";

const { LockFilled } = icons;

const invoiceTopActions = css`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: var(--size-3);
  margin-bottom: var(--size-6);
`;

const invoiceFooterActions = css`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: var(--size-3);
  margin-top: var(--size-6);
`;

interface InvoiceDetailsActionsProps {
  invoiceId: string;
  externalStatus: IFinancials2__InvoiceExternalStatus;
  paidStatus: IFinancials2__PaidStatus | null;
  placement: "top" | "bottom";
  toPartnerId: string | null;
  toContactId: string | null;
}

export const InvoiceDetailsActions = ({
  invoiceId,
  externalStatus,
  paidStatus,
  placement,
  toContactId,
  toPartnerId,
}: InvoiceDetailsActionsProps) => {
  const params = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { refetch: requestPdf3 } = useInvoicePdf3Query(
    { invoiceId: params.invoiceId! },
    { enabled: false }
  );

  const openPdf3 = async () => {
    const response = await requestPdf3();
    if (response.isSuccess) {
      window.open(response.data.Financials2__invoicePdf3.url, "_blank");
    }
  };

  const { mutate: deleteInvoice, isLoading: deletingInvoice } =
    useDeleteInvoiceMutation({
      onError: () => {
        message.error("Invoice failed to delete");
      },
      onSuccess: () => {
        navigate(-1);
      },
    });

  const { mutate: reopenInvoice, isLoading: reopeningInvoice } =
    useReopenInvoiceMutation({
      onMutate: () => {
        const queryKey = useFinancials2__InvoiceDetailsQuery.getKey({
          siteId: params.siteId!,
          invoiceId: params.invoiceId!,
        });

        const previousData: IFinancials2__InvoiceDetailsQuery | undefined =
          queryClient.getQueryData(queryKey);

        queryClient.setQueryData(
          queryKey,
          (oldData: IFinancials2__InvoiceDetailsQuery | void) => {
            if (oldData) {
              return {
                data: {
                  invoice: {
                    ...oldData.data.invoice,
                    ...{
                      externalStatus:
                        "OPEN" as IFinancials2__InvoiceExternalStatus,
                    },
                  },
                },
              };
            }

            return oldData;
          }
        );

        if (previousData) {
          return { previousData };
        }
      },
      onError: (error, variables, context) => {
        message.error("Failure reopening invoice");
        console.error({ error, variables });
        const queryKey = useFinancials2__InvoiceDetailsQuery.getKey({
          siteId: params.siteId!,
          invoiceId: params.invoiceId!,
        });

        queryClient.setQueryData(queryKey, context?.previousData);
      },
      onSuccess: (resp) => {
        if (resp.data.success) {
          message.success("Invoice reopened");

          queryClient.invalidateQueries(
            useFinancials2__InvoiceDetailsQuery.getKey({
              siteId: params.siteId!,
              invoiceId: params.invoiceId!,
            })
          );
        }
      },
    });

  const { mutate: markInvoiceAsPaid, isLoading: loadingInvoiceAsPaid } =
    useMarkInvoiceAsPaidMutation({
      onMutate: () => {
        const queryKey = useFinancials2__InvoiceDetailsQuery.getKey({
          siteId: params.siteId!,
          invoiceId: params.invoiceId!,
        });

        const previousData: IFinancials2__InvoiceDetailsQuery | undefined =
          queryClient.getQueryData(queryKey);

        queryClient.setQueryData(
          queryKey,
          (oldData: IFinancials2__InvoiceDetailsQuery | void) => {
            if (oldData) {
              return {
                data: {
                  invoice: {
                    ...oldData.data.invoice,
                    ...{
                      externalStatus:
                        "PAID" as IFinancials2__InvoiceExternalStatus,
                    },
                  },
                },
              };
            }

            return oldData;
          }
        );

        if (previousData) {
          return { previousData };
        }
      },
      onError: (error, variables, context) => {
        message.error("Failure to set invoice as paid");
        console.error({ error, variables });
        const queryKey = useFinancials2__InvoiceDetailsQuery.getKey({
          siteId: params.siteId!,
          invoiceId: params.invoiceId!,
        });

        queryClient.setQueryData(queryKey, context?.previousData);
      },
      onSuccess: (resp) => {
        if (resp.data.success) {
          message.success("Invoice marked as paid");

          queryClient.invalidateQueries(
            useFinancials2__InvoiceDetailsQuery.getKey({
              siteId: params.siteId!,
              invoiceId: params.invoiceId!,
            })
          );
        }
      },
    });

  const { mutate: markInvoiceAsVoid, isLoading: loadingInvoiceAsVoid } =
    useMarkInvoiceAsVoidMutation({
      onMutate: () => {
        const queryKey = useFinancials2__InvoiceDetailsQuery.getKey({
          siteId: params.siteId!,
          invoiceId: params.invoiceId!,
        });

        const previousData: IFinancials2__InvoiceDetailsQuery | undefined =
          queryClient.getQueryData(queryKey);

        queryClient.setQueryData(
          queryKey,
          (oldData: IFinancials2__InvoiceDetailsQuery | void) => {
            if (oldData) {
              return {
                data: {
                  invoice: {
                    ...oldData.data.invoice,
                    ...{
                      externalStatus:
                        "VOID" as IFinancials2__InvoiceExternalStatus,
                    },
                  },
                },
              };
            }

            return oldData;
          }
        );

        if (previousData) {
          return { previousData };
        }
      },
      onError: (error, variables, context) => {
        message.error("Failure to set invoice as void");
        console.error({ error, variables });
        const queryKey = useFinancials2__InvoiceDetailsQuery.getKey({
          siteId: params.siteId!,
          invoiceId: params.invoiceId!,
        });

        queryClient.setQueryData(queryKey, context?.previousData);
      },
      onSuccess: (resp) => {
        if (resp.data.success) {
          message.success("Invoice marked as void");

          queryClient.invalidateQueries(
            useFinancials2__InvoiceDetailsQuery.getKey({
              siteId: params.siteId!,
              invoiceId: params.invoiceId!,
            })
          );
        }
      },
    });

  const { mutate: finalizeInvoice, isLoading: finalizingInvoice } =
    useFinalizeInvoiceMutation({
      onMutate: () => {
        const queryKey = useFinancials2__InvoiceDetailsQuery.getKey({
          siteId: params.siteId!,
          invoiceId: params.invoiceId!,
        });

        const previousData: IFinancials2__InvoiceDetailsQuery | undefined =
          queryClient.getQueryData(queryKey);

        queryClient.setQueryData(
          queryKey,
          (oldData: IFinancials2__InvoiceDetailsQuery | void) => {
            if (oldData) {
              return {
                data: {
                  invoice: {
                    ...oldData.data.invoice,
                    ...{
                      externalStatus:
                        "OPEN" as IFinancials2__InvoiceExternalStatus,
                    },
                  },
                },
              };
            }

            return oldData;
          }
        );

        if (previousData) {
          return { previousData };
        }
      },
      onError: (error, variables, context) => {
        message.error("Failure to finalize invoice");
        console.error({ error, variables });
        const queryKey = useFinancials2__InvoiceDetailsQuery.getKey({
          siteId: params.siteId!,
          invoiceId: params.invoiceId!,
        });

        queryClient.setQueryData(queryKey, context?.previousData);
      },
      onSuccess: () => {
        message.success("Invoice finalized");

        queryClient.invalidateQueries(
          useFinancials2__InvoiceDetailsQuery.getKey({
            siteId: params.siteId!,
            invoiceId: params.invoiceId!,
          })
        );
      },
    });

  if (externalStatus === "DRAFT") {
    return (
      <div
        className={
          placement === "top" ? invoiceTopActions : invoiceFooterActions
        }
      >
        <section>
          {placement === "bottom" && (
            <Button
              data-testid="invoice-exit-btn"
              onClick={() => navigate(-1)} // delta backs-up one since it could invoice or partner details
            >
              Exit
            </Button>
          )}
        </section>
        <section>
          <Space>
            <Button data-testid="invoice-download-pdf-btn" onClick={openPdf3}>
              Download PDF
            </Button>
            <Button
              onClick={() =>
                showDeleteModal({
                  title: "Delete Invoice?",
                  content: <>Are you sure you want to delete this invoice?</>,
                  onOkFunction: () => deleteInvoice({ input: { invoiceId } }),
                  okText: "Delete Invoice",
                })
              }
              danger
              data-testid="invoice-delete-invoice-btn"
              loading={deletingInvoice}
              type="primary"
            >
              Delete
            </Button>
            <Button
              onClick={() =>
                showFinalizedInvoiceModal({
                  title: "Finalize Invoice",
                  content: (
                    <>
                      Once this invoice is finalized, you will not be able to
                      make any further changes.
                    </>
                  ),
                  onOkFunction: () => finalizeInvoice({ input: { invoiceId } }),
                  okText: "Finalize Invoice",
                })
              }
              icon={<LockFilled />}
              loading={finalizingInvoice}
              type="primary"
              disabled={!toContactId || !toPartnerId}
              data-testid="invoice-finalize-btn"
            >
              Finalize
            </Button>
          </Space>
        </section>
      </div>
    );
  }

  if (externalStatus === "VOID") {
    return (
      <div
        className={
          placement === "top" ? invoiceTopActions : invoiceFooterActions
        }
      >
        <section>
          {placement === "bottom" && (
            <Button
              data-testid="invoice-exit-btn"
              onClick={() => navigate(-1)} // delta backs-up one since it could invoice or partner details
            >
              Exit
            </Button>
          )}
        </section>
        <section>
          <Space>
            <Button data-testid="invoice-download-pdf-btn" onClick={openPdf3}>
              Download PDF
            </Button>
          </Space>
        </section>
      </div>
    );
  }

  if (externalStatus === "PAID") {
    return (
      <div
        className={
          placement === "top" ? invoiceTopActions : invoiceFooterActions
        }
      >
        <section>
          {placement === "bottom" && (
            <Button
              data-testid="invoice-exit-btn"
              onClick={() => navigate(-1)} // delta backs-up one since it could invoice or partner details
            >
              Exit
            </Button>
          )}
        </section>
        <section>
          <Space>
            <Button data-testid="invoice-download-pdf-btn" onClick={openPdf3}>
              Download PDF
            </Button>
            {paidStatus === "FULLY_PAID" ? null : (
              <Button
                onClick={() =>
                  showSuccessModal({
                    title: "Reopen Invoice",
                    content: (
                      <>
                        By reopening this invoice, you are changing the status
                        from <em>Paid</em> to <em>Open</em>.
                      </>
                    ),
                    onOkFunction: () => reopenInvoice({ input: { invoiceId } }),
                    okText: "Reopen",
                  })
                }
                data-testid="invoice-reopen-btn"
                loading={reopeningInvoice}
                type="default"
              >
                Reopen Invoice
              </Button>
            )}
          </Space>
        </section>
      </div>
    );
  }

  if (externalStatus === "OPEN" || externalStatus === "PAST_DUE") {
    return (
      <div
        className={
          placement === "top" ? invoiceTopActions : invoiceFooterActions
        }
      >
        <section>
          {placement === "bottom" && (
            <Button
              data-testid="invoice-exit-btn"
              onClick={() => navigate(-1)} // delta backs-up one since it could invoice or partner details
            >
              Exit
            </Button>
          )}
        </section>
        <section>
          <Space>
            <Button data-testid="invoice-download-pdf-btn" onClick={openPdf3}>
              Download PDF
            </Button>
            {paidStatus !== "PARTIALLY_PAID" ? (
              <>
                <Button
                  onClick={() =>
                    showDeleteModal({
                      title: "Would you like to void this Invoice?",
                      content: (
                        <>
                          This will remove the invoice and A/R items will be
                          returned to the A/R table.
                        </>
                      ),
                      onOkFunction: () =>
                        markInvoiceAsVoid({ input: { invoiceId } }),
                      okText: "Void Invoice",
                    })
                  }
                  danger
                  data-testid="invoice-void-btn"
                  loading={loadingInvoiceAsVoid}
                  type="primary"
                >
                  Void Invoice
                </Button>
                <Button
                  data-testid="invoice-mark-as-paid-btn"
                  onClick={() =>
                    showSuccessModal({
                      title: "Mark as Paid",
                      content: (
                        <>
                          That's excellent! You're getting paid for your
                          invoices.
                        </>
                      ),
                      onOkFunction: () =>
                        markInvoiceAsPaid({ input: { invoiceId } }),
                      okText: "Mark as Paid",
                    })
                  }
                  loading={loadingInvoiceAsPaid}
                  type="primary"
                >
                  Mark as Paid
                </Button>
              </>
            ) : null}
          </Space>
        </section>
      </div>
    );
  }

  return null;
};
