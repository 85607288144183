import { styled } from "@linaria/react";
import { Typography, Col, Row, icons } from "@reifyhealth/picasso-pkg";
import { UserControls } from "@components/headers/UserControls";
import { LoadingElement } from "@components/common/LoadingElement";
import { useParams } from "react-router-dom";
import { formatDate } from "@lib/date";
import { useNavigate } from "@app/hooks";
import {
  useBudgetOverviewDetails3Query,
  useBudgetVersionOverviewDetails3Query,
} from "@app/service/generated";

function useBudgetOverviewDetails(budgetId: string) {
  const { data, isLoading } = useBudgetOverviewDetails3Query({ budgetId });

  return {
    isLoading,
    ...data?.budget,
  };
}

const BudgetDetailHeaderComponent = styled.div`
  color: var(--component-text);
  background: var(--component-background);
  border-bottom: 1px solid var(--component-border);

  .back-action {
    display: flex;
    padding: 0 var(--space-5);
    align-items: center;
    border-right: 1px solid var(--component-border);
    justify-content: center;
    gap: var(--size-4);
    cursor: pointer;

    span {
      font-size: var(--font-size-body);
    }
  }

  .details {
    padding: var(--space-3) var(--space-5);
    display: flex;
    align-items: center;
    gap: var(--space-7);

    .caption {
      font-size: var(--font-size-caption);
      color: var(--application-secondary-text);

      .ant-typography {
        color: inherit;
        margin: 0;
      }
    }
  }
`;

interface BudgetDetailsHeaderProps {
  budget: any;
  trial?: any;
  protocol?: any;
  navigate: (to: string) => void;
  backPath: string;
}

export const BudgetDetailsHeaderView = ({
  budget,
  trial,
  protocol,
  navigate,
  backPath,
}: BudgetDetailsHeaderProps) => {
  return (
    <BudgetDetailHeaderComponent>
      <Row>
        <Col
          className="back-action"
          data-testid="back-action"
          onClick={() => navigate(backPath)}
        >
          <icons.ArrowLeftOutlined />
          <span>Back</span>
        </Col>
        <Col className="details" flex="1">
          <article>
            <div className="caption">
              <Typography.Text>Trial</Typography.Text>
            </div>
            <div data-testid="budget-details-header-trial-name">
              {budget.isLoading ? (
                <LoadingElement
                  size="small"
                  styles={{ width: 100, height: 16 }}
                />
              ) : (
                <Typography.Text>{trial?.name || "N/A"}</Typography.Text>
              )}
            </div>
          </article>

          <article>
            <div className="caption">
              <Typography.Text>Protocol</Typography.Text>
            </div>
            <div data-testid="protocol-name">
              {protocol?.name ? (
                <Typography.Text>{protocol.name}</Typography.Text>
              ) : (
                <LoadingElement
                  size="small"
                  styles={{ width: 100, height: 16 }}
                />
              )}
            </div>
          </article>

          <article>
            <div className="caption">
              <Typography.Text>Effective Date</Typography.Text>
            </div>
            <div data-testid="effective-date">
              {!budget.isLoading ? (
                <Typography.Text>
                  <>
                    {budget.effectiveDateStart &&
                      formatDate(budget.effectiveDateStart)}
                    &nbsp;&nbsp;
                    {budget.effectiveDateEnd &&
                      ` - ${formatDate(budget.effectiveDateEnd)}`}
                  </>
                </Typography.Text>
              ) : (
                <LoadingElement
                  size="small"
                  styles={{ width: 100, height: 16 }}
                />
              )}
            </div>
          </article>

          <article>
            <div className="caption">
              <Typography.Text>Created Date</Typography.Text>
            </div>
            <div data-testid="created-date">
              {!budget.isLoading ? (
                <Typography.Text>
                  {budget && formatDate(budget.createdAt)}
                </Typography.Text>
              ) : (
                <LoadingElement
                  size="small"
                  styles={{ width: 100, height: 16 }}
                />
              )}
            </div>
          </article>
        </Col>
        <Col>
          <UserControls />
        </Col>
      </Row>
    </BudgetDetailHeaderComponent>
  );
};

const BudgetVersionDetailHeaderComponent = styled.div`
  color: var(--component-text);
  background: var(--component-background);
  border-bottom: 1px solid var(--component-border);

  .back-action {
    display: flex;
    padding: 0 var(--space-5);
    align-items: center;
    border-right: 1px solid var(--component-border);
    justify-content: center;
    gap: var(--size-4);
    cursor: pointer;

    span {
      font-size: var(--font-size-body);
    }
  }

  .details {
    padding: var(--space-3) var(--space-5);
    display: flex;
    align-items: center;
    gap: var(--space-7);

    .caption {
      font-size: var(--font-size-caption);
      color: var(--application-secondary-text);

      .ant-typography {
        color: inherit;
        margin: 0;
      }
    }
  }
`;

interface BudgetVersionDetailsHeaderProps {
  budget?: OverviewBudget;
  trial?: any;
  protocol?: any;
  hasProtocolVersions: boolean;
  isLoading: boolean;
  configVersion: any;
  configVersionProtocolVersion?: BudgetConfigProtocolVersion;
  navigate: (to: string) => void;
  backPath: string;
}

export const BudgetVersionDetailsHeaderView = ({
  budget,
  trial,
  protocol,
  isLoading,
  hasProtocolVersions,
  configVersion,
  configVersionProtocolVersion,
  navigate,
  backPath,
}: BudgetVersionDetailsHeaderProps) => {
  return (
    <BudgetVersionDetailHeaderComponent>
      <Row>
        <Col
          data-testid="back-btn"
          className="back-action"
          onClick={() => navigate(backPath)}
        >
          <icons.ArrowLeftOutlined />
          <span>Back</span>
        </Col>
        <Col className="details" flex="1">
          <article>
            <div className="caption">
              <Typography.Text data-testid="trial-label">Trial</Typography.Text>
            </div>
            <div>
              {isLoading ? (
                <LoadingElement
                  size="small"
                  styles={{ width: 100, height: 16 }}
                />
              ) : (
                <Typography.Text data-testid="budget-details-header-trial-name">
                  {trial?.name || "N/A"}
                </Typography.Text>
              )}
            </div>
          </article>

          <article>
            <div className="caption">
              <Typography.Text data-testid="payment-terms-label">
                Payment Terms
              </Typography.Text>
            </div>
            <div>
              {isLoading ? (
                <LoadingElement
                  size="small"
                  styles={{ width: 100, height: 16 }}
                />
              ) : (
                <Typography.Text data-testid="payment-term-days">
                  {budget?.paymentTermDays
                    ? `Net ${budget.paymentTermDays}`
                    : "N/A"}
                </Typography.Text>
              )}
            </div>
          </article>

          <article>
            <div className="caption">
              <Typography.Text data-testid="protocol-label">
                Protocol
              </Typography.Text>
            </div>
            <div>
              {protocol?.name ? (
                <Typography.Text data-testid="protocol-name">
                  {protocol.name}
                </Typography.Text>
              ) : (
                hasProtocolVersions && (
                  <LoadingElement
                    size="small"
                    styles={{ width: 100, height: 16 }}
                  />
                )
              )}
            </div>
          </article>

          <article>
            <div className="caption">
              <Typography.Text data-testid="effective-date-label">
                Effective Date
              </Typography.Text>
            </div>
            <div>
              {!isLoading &&
              configVersionProtocolVersion?.effectiveDateStart ? (
                <Typography.Text data-testid="effective-dates">
                  {formatDate(configVersionProtocolVersion.effectiveDateStart)}{" "}
                  -{" "}
                  {configVersionProtocolVersion.effectiveDateEnd
                    ? formatDate(configVersionProtocolVersion.effectiveDateEnd)
                    : "onward"}
                </Typography.Text>
              ) : (
                hasProtocolVersions && (
                  <LoadingElement
                    size="small"
                    styles={{ width: 100, height: 16 }}
                  />
                )
              )}
            </div>
          </article>

          <article>
            <div className="caption">
              <Typography.Text data-testid="created-date-label">
                Created Date
              </Typography.Text>
            </div>
            <div>
              {!isLoading ? (
                <Typography.Text data-testid="created-date">
                  {budget && formatDate(budget.createdAt)}
                </Typography.Text>
              ) : (
                hasProtocolVersions && (
                  <LoadingElement
                    size="small"
                    styles={{ width: 100, height: 16 }}
                  />
                )
              )}
            </div>
          </article>

          {configVersion.holdback?.percentage ? (
            <article>
              <div className="caption">
                <Typography.Text data-testid="holdback-label">
                  Holdback
                </Typography.Text>
              </div>
              <div>
                <Typography.Text data-testid="holdback-percentage">
                  {configVersion.holdback.percentage}%
                </Typography.Text>
              </div>
            </article>
          ) : null}

          {configVersion.overhead?.percentage ? (
            <article>
              <div className="caption">
                <Typography.Text data-testid="overhead-label">
                  Overhead
                </Typography.Text>
              </div>
              <div>
                <Typography.Text data-testid="overhead-percentage">
                  {configVersion.overhead.percentage}%
                </Typography.Text>
              </div>
            </article>
          ) : null}
        </Col>
        <Col>
          <UserControls />
        </Col>
      </Row>
    </BudgetVersionDetailHeaderComponent>
  );
};

export const BudgetOverviewDetailsHeader = ({ siteTrialId }: any) => {
  const params = useParams();
  const navigate = useNavigate();

  const backPath = params.siteId
    ? `/finance/sites/${params.siteId}/budgets`
    : "/finance";

  const { data: overviewDetails, isLoading } =
    useBudgetVersionOverviewDetails3Query({
      budgetConfigVersionId: params.budgetConfigVersionId as string,
      budgetId: params.budgetId as string,
      siteTrialId,
    });

  if (overviewDetails && overviewDetails?.budget.configVersions.length > 0) {
    const budget = overviewDetails?.budget;
    const budgetConfig = overviewDetails?.budget.configVersions[0];

    const protocolVersionIds = budgetConfig!.protocolVersions.map(
      (version) => version.protocolVersionId
    );
    const budgetConfigProtocol = budget!.protocol?.versions.filter((version) =>
      protocolVersionIds.includes(version.id)
    );

    return (
      <BudgetVersionDetailsHeaderView
        budget={budget}
        hasProtocolVersions={
          (budgetConfigProtocol && budgetConfigProtocol?.length > 0) || false
        }
        isLoading={isLoading}
        configVersion={budgetConfig!}
        configVersionProtocolVersion={budgetConfig!.protocolVersions[0]}
        trial={budget!.trial}
        protocol={
          budgetConfigProtocol &&
          budgetConfigProtocol?.length > 0 &&
          budgetConfigProtocol[0]
        }
        navigate={navigate}
        backPath={backPath}
      />
    );
  }

  return null;
};

export const BudgetDetailsHeader = () => {
  const params = useParams();
  const navigate = useNavigate();
  const backPath = `/finance/sites/${params.siteId}/budgets`;

  const budgetDetails: any = useBudgetOverviewDetails(
    params.budgetId as string
  );

  return (
    <BudgetDetailsHeaderView
      budget={budgetDetails}
      trial={budgetDetails?.trial}
      protocol={budgetDetails?.protocol}
      navigate={navigate}
      backPath={backPath}
    />
  );
};
