import {
  isWithinAvailableQuantity,
  isWithinEffectiveDates,
} from "@model/budgets";
import {
  Button,
  ColumnType,
  Space,
  TableColumnsType,
  Typography,
} from "@reifyhealth/picasso-pkg";
import { displayText, Money, toNumber } from "@lib/currency";

function nonNull<T>(array: T[]) {
  return array.filter((e): e is Exclude<typeof e, null> => e !== null);
}

export const columns = ({
  onSendToReceivablesClick,
  disableSendToReceivables,
}: {
  onSendToReceivablesClick: (additionalChargeId: string) => void;
  disableSendToReceivables: boolean;
}): TableColumnsType<BudgetVersionOverviewTableRow> =>
  nonNull<ColumnType<BudgetVersionOverviewTableRow> | null>([
    {
      title: (
        <Typography.Text data-testid="description-title">
          Description
        </Typography.Text>
      ),
      width: 320,
      sorter: (a, b) => a.description.localeCompare(b.description),
      key: "description",
      showSorterTooltip: false,
      dataIndex: "description",
      fixed: "left",
      render: (description) => {
        return (
          <Typography.Text data-testid="description">
            {description}
          </Typography.Text>
        );
      },
    },
    {
      title: (
        <>
          <Space>
            <Typography.Text data-testid="cost-title">Cost</Typography.Text>
          </Space>
        </>
      ),
      width: 177,
      sorter: (a, b) => toNumber(a.cost ?? 0) - toNumber(b.cost ?? 0),
      showSorterTooltip: false,
      dataIndex: "cost",
      render: (cost?: Money) => {
        return (
          <Typography.Text data-testid="cost">
            {cost ? displayText(cost) : null}
          </Typography.Text>
        );
      },
    },
    {
      title: (
        <>
          <Space>
            <Typography.Text data-testid="charge-title">Charge</Typography.Text>
          </Space>
        </>
      ),
      width: 177,
      sorter: (a, b) =>
        toNumber(a.derivedCharge ?? 0) - toNumber(b.derivedCharge ?? 0),
      showSorterTooltip: false,
      dataIndex: "derivedCharge",
      render: (derivedCharge?: Money) => {
        return (
          <Typography.Text data-testid="derived-charge">
            {derivedCharge ? displayText(derivedCharge) : null}
          </Typography.Text>
        );
      },
    },
    {
      title: (
        <>
          <Space>
            <Typography.Text data-testid="holdback-title">
              Holdback
            </Typography.Text>
          </Space>
        </>
      ),
      width: 177,
      sorter: (a, b) =>
        toNumber(a.holdback?.money ?? 0) - toNumber(b.holdback?.money ?? 0),
      showSorterTooltip: false,
      dataIndex: "holdback",
      render: (holdback) => {
        return (
          <Typography.Text data-testid="holdback">
            {holdback?.enabled ? (
              <span>
                {holdback?.money ? `-${displayText(holdback.money)}` : null}
                {holdback ? " " : null}
                {holdback?.percentage ? `${holdback.percentage}%` : null}
              </span>
            ) : null}
          </Typography.Text>
        );
      },
    },
    {
      title: (
        <>
          <Space>
            <Typography.Text data-testid="total-charge-title">
              Total Charge
            </Typography.Text>
          </Space>
        </>
      ),
      width: 177,
      sorter: (a, b) =>
        toNumber(a.totalCharge ?? 0) - toNumber(b.totalCharge ?? 0),
      showSorterTooltip: false,
      dataIndex: "totalCharge",
      render: (totalCharge?: Money) => {
        return (
          <Typography.Text data-testid="total-charge">
            {totalCharge ? displayText(totalCharge) : null}
          </Typography.Text>
        );
      },
    },
    {
      title: (
        <>
          <Space>
            <Typography.Text data-testid="overhead-title">
              Overhead
            </Typography.Text>
          </Space>
        </>
      ),
      width: 177,
      sorter: (a, b) =>
        toNumber(a.overhead?.money ?? 0) - toNumber(b.overhead?.money ?? 0),
      showSorterTooltip: false,
      dataIndex: "overhead",
      render: (overhead) => {
        return (
          <Typography.Text data-testid="overhead">
            {overhead?.enabled ? (
              <span>
                {overhead?.money ? `${displayText(overhead.money)}` : null}
                {overhead ? " " : null}
                {overhead?.percentage ? `${overhead.percentage}%` : null}
              </span>
            ) : null}
          </Typography.Text>
        );
      },
    },
    {
      title: (
        <Typography.Text data-testid="quantity-title">Quantity</Typography.Text>
      ),
      width: 146,
      sorter: (a, b) => a.quantity - b.quantity,
      showSorterTooltip: false,
      dataIndex: "quantity",
      render: (quantity: number | null, row) => {
        let text;
        if (quantity && quantity > 0) {
          text = `${row.arCount ? `${row.arCount} of ${quantity}` : quantity}`;
        } else if (!quantity && row.chargeType.toLowerCase() === "visit") {
          text = "1";
        } else {
          text = `${row.arCount} of Unlimited`;
        }

        return <span data-testid="quantity">{text}</span>;
      },
    },
    {
      sorter: (a, b) => +a.canBeAddedToReceivables - +b.canBeAddedToReceivables,
      showSorterTooltip: false,
      width: 178,
      render: (_, record) => {
        if (record.chargeType === "Additional Charge") {
          if (
            !isWithinAvailableQuantity(
              Number(record.quantity),
              Number(record.arCount)
            )
          ) {
            return (
              <Button
                type="dashed"
                data-testid="send-to-receivables-btn"
                disabled={
                  disableSendToReceivables ||
                  !isWithinEffectiveDates(record, new Date().toString()) ||
                  !isWithinAvailableQuantity(
                    Number(record.quantity),
                    Number(record.arCount)
                  )
                }
              >
                Send to Receivables
              </Button>
            );
          }

          return (
            <Button
              type="dashed"
              data-testid="send-to-receivables-btn"
              onClick={() => onSendToReceivablesClick(record.id)}
              disabled={
                disableSendToReceivables ||
                !isWithinEffectiveDates(record, new Date().toString()) ||
                !isWithinAvailableQuantity(
                  Number(record.quantity),
                  Number(record.arCount)
                )
              }
            >
              Send to Receivables
            </Button>
          );
        }
      },
    },
  ]);
