import {
  Modal,
  Button,
  Typography,
  Radio,
  Space,
  Col,
  Menu,
} from "@reifyhealth/picasso-pkg";
import { useState } from "react";
import { css } from "@linaria/core";
import { useNavigate } from "react-router-dom";
import {
  transformForARActions,
  transformForNewPayment,
} from "@model/accountsReceivable";

const radioButton = css`
  padding: var(--size-5) 16px !important;
  border-radius: 2px;
  border: 1px solid var(--component-border);

  &:hover,
  &:checked {
    border: 1px solid var(--component-toggle-on);
  }
`;

const actionName = css`
  font-size: 16px;
  font-style: normal;
  font-weight: var(--font-weight-bold);
  color: var(--component-primary-text);
`;

const actionLabel = css`
  color: var(--component-tertiary-text);
  font-weight: var(--font-weight-normal);
`;

interface PaymentActionsModalProps {
  shouldPaidBeDisabled: boolean;
  lineItems: any[];
  selectedRowKeys: any;
  onPaidClickFn: Function;
  siteId: string;
  setSelectedRowKeysFn: Function;
}

export const PaymentActionsModal = ({
  siteId,
  onPaidClickFn,
  lineItems,
  selectedRowKeys,
  shouldPaidBeDisabled,
  setSelectedRowKeysFn,
}: PaymentActionsModalProps) => {
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const [value, setValue] = useState<null | "mark" | "new">("mark");

  const onChange = (e: any) => {
    setValue(e.target.value);
  };

  const handleNext = () => {
    if (value) {
      if (value === "mark") {
        const { tableIds, transformedLineItems } = transformForARActions(
          lineItems,
          selectedRowKeys
        );

        setSelectedRowKeysFn(tableIds); // in case paired down to a single sponsor

        onPaidClickFn({
          input: {
            siteId,
            lineItems: transformedLineItems,
          },
        });

        setVisible(false);
      } else if (value === "new") {
        const { selectedSiteTrialId, rowKeys } = transformForNewPayment(
          lineItems,
          selectedRowKeys
        );

        navigate(`/finance/sites/${siteId}/payments/new`, {
          state: { data: { selectedSiteTrialId, rowKeys } },
        });
      } else {
        // Additional Actions Add Here...
      }
    }
  };

  const handleCancel = () => {
    setVisible(false);
    setValue("mark");
  };

  const showModal = () => {
    if (!shouldPaidBeDisabled) {
      setVisible(true);
    }
  };

  return (
    <>
      <Menu.Item
        key="mark-as-paid"
        disabled={shouldPaidBeDisabled}
        onClick={showModal}
        data-testid="ar-mark-as-paid-btn"
      >
        <Typography.Text disabled={shouldPaidBeDisabled}>
          Mark as Paid
        </Typography.Text>
      </Menu.Item>
      <Modal
        visible={visible}
        width={467}
        title="Payment Actions"
        onOk={handleNext}
        onCancel={handleCancel}
        footer={[
          <Button
            key="cancel"
            data-testid="paid-modal-cancel-btn"
            onClick={handleCancel}
          >
            Cancel
          </Button>,
          <Button
            type="primary"
            data-testid="paid-modal-next-btn"
            disabled={!value}
            onClick={handleNext}
          >
            Next
          </Button>,
        ]}
      >
        <Radio.Group onChange={onChange} value={value}>
          <Space direction="vertical">
            <Radio
              value={"mark"}
              data-testid="mark-paid-radio-btn"
              className={radioButton}
            >
              <Col>
                <Typography.Text className={actionName}>
                  Manually Mark Paid
                </Typography.Text>
              </Col>
              <Col>
                <Typography.Text className={actionLabel} type="secondary">
                  Pay without associating to a payment in StudyTeam
                </Typography.Text>
              </Col>
            </Radio>
            <Radio
              value={"new"}
              data-testid="new-paid-radio-btn"
              className={radioButton}
            >
              <Col>
                <Typography.Text className={actionName}>
                  Create New Payment
                </Typography.Text>
              </Col>
              <Col>
                <Typography.Text className={actionLabel} type="secondary">
                  Pay by creating and associating to a new payment in StudyTeam
                </Typography.Text>
              </Col>
            </Radio>
          </Space>
        </Radio.Group>
      </Modal>
    </>
  );
};
