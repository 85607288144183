import { useState, useEffect } from "react";
import type { ReactNode } from "react";
import type { IIdleTimer } from "react-idle-timer";
import { IdleTimerProvider } from "react-idle-timer";
import { logoutWithRedirect } from "@lib/auth";
import { useAuth0 } from "@reifyhealth/picasso-auth0";
import {
  notification,
  Button,
  Space,
  Statistic,
} from "@reifyhealth/picasso-pkg";
import { ENVIRONMENTS } from "@app/constants";

const notificationKey = "ST-INACTIVITY";

const defaultIdleTimeoutSeconds = 36000;

const defaultCountdownSeconds = 30;

type NotificationBodyProps = {
  duration: number;
};

const NotificationBody = ({ duration }: NotificationBodyProps) => {
  const durationMs = duration * 1000;
  return (
    <>
      <div>
        We noticed you have been inactive for a while.
        <br />
        Your session will expire in:
      </div>
      <Space>
        <Statistic.Countdown value={Date.now() + durationMs} format="ss" />
        seconds
      </Space>
    </>
  );
};

type NotificationButtonProps = {
  onLogout: () => void;
  refresh: () => void;
};

const NotificationButton = ({ onLogout, refresh }: NotificationButtonProps) => {
  return (
    <Space>
      <Button type="primary" size="small" onClick={onLogout}>
        Log Out
      </Button>
      <Button
        type="primary"
        size="small"
        // NOTE: closing the notification is sufficient,
        // because we have getting a new token as an `onClose`
        // behavior of the notification
        onClick={() => {
          refresh();
          notification.close(notificationKey);
        }}
      >
        Stay Logged In
      </Button>
    </Space>
  );
};

const IDLE_TIMEOUT_CLAIM = "https://studyteamapp.com/user-idle-timeout";

const OSTIdleTimerProvider = ({ children }: { children: ReactNode }) => {
  const [timeoutMs, setTimeoutMs] = useState(1000 * defaultIdleTimeoutSeconds);
  const { getIdTokenClaims, getAccessTokenSilently, logout, isLoading } =
    useAuth0();
  useEffect(() => {
    // NOTE: this depends on loading status because if you try to get the id token claims
    // before the token call has resolved, it'll happily give you `undefined`
    if (!isLoading) {
      getIdTokenClaims().then((idToken) => {
        if (idToken?.[IDLE_TIMEOUT_CLAIM]) {
          setTimeoutMs(idToken?.[IDLE_TIMEOUT_CLAIM]);
        }
      });
    }
  }, [getIdTokenClaims, isLoading]);
  const onPrompt = (_evt?: Event, idleTimer?: IIdleTimer) => {
    const refresh = () => {
      idleTimer?.activate();
      getAccessTokenSilently();
    };
    notification.open({
      message: "Session timeout",
      duration: null,
      description: <NotificationBody duration={defaultCountdownSeconds} />,
      btn: (
        <NotificationButton
          onLogout={logoutWithRedirect(logout)}
          refresh={refresh}
        />
      ),
      key: notificationKey,
      onClose: refresh,
    });
  };

  const onIdle = (_evt?: Event, idleTimer?: IIdleTimer) => {
    logoutWithRedirect(logout)();
  };

  const onActive = () => {
    // Ensure the notification is closed if the user is no longer idle
    // Note that this works cross-tab
    notification.close(notificationKey);
  };

  return (
    <IdleTimerProvider
      timeout={timeoutMs}
      promptBeforeIdle={1000 * defaultCountdownSeconds}
      // Activity in one tab will reset the timer in another tab
      crossTab
      onIdle={onIdle}
      onPrompt={onPrompt}
      onActive={onActive}
      disabled={ENVIRONMENTS.isProduction}
    >
      {children}
    </IdleTimerProvider>
  );
};

export default OSTIdleTimerProvider;
