import type { User } from "@auth0/auth0-react";

declare global {
  interface Window {
    CookieConsent: {
      consent: CookieConsentState;
    };
  }
}

export function getPendoInstance() {
  return pendo;
}

export function isPendoAvailable() {
  return typeof getPendoInstance() !== "undefined";
}

/**
 * Initialization function for Pendo; modeled after shell-app.src.services.pendo
 */
export function initializePendo(
  pendo: pendo.Pendo,
  consentState: CookieConsentState,
  userId: string
) {
  const didNotConsentToStatistics = consentState.statistics !== true;

  pendo.initialize({
    // Not sure why disableCookies is not in the type definition,
    // since it is in the official docs: https://developers.pendo.io/docs/?bash#options;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    disableCookies: didNotConsentToStatistics,
    excludeAllText: true,
    visitor: {
      id: userId,
      cookiebot_analytical: consentState.statistics,
    },
  });
}

export function updateOptions(pendo: pendo.Pendo, user: User) {
  pendo.updateOptions({
    visitor: {
      id: user?.id,
      email: user?.email ?? null,
    },
  });
}
