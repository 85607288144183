import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useUserAllowedSitesQuery } from "@service/generated";
import { useAuth } from "@hooks/auth";
import { sortBy } from "lodash";

export const useAllowedSites = () => {
  const { user } = useAuth();

  const allowedSites = useUserAllowedSitesQuery(
    {
      userId: user.id,
      first: 100,
    },
    {
      staleTime: 1000 * 10, // ten sec
    }
  );

  const edges = sortBy(
    allowedSites.data?.Financials2__userAllowedSites.edges,
    "node.site.name"
  );

  return {
    ...allowedSites,
    data: allowedSites.data && {
      ...allowedSites.data,
      Financials2__userAllowedSites: {
        ...allowedSites.data.Financials2__userAllowedSites,
        edges,
      },
    },
  };
};

export const useSelectedSite = () => {
  const params = useParams();
  const allowedSites = useAllowedSites();

  return {
    ...allowedSites,
    data: useMemo(() => {
      const edges = allowedSites.data?.Financials2__userAllowedSites.edges;

      if (edges && edges.length > 0) {
        const allowedSites = edges.map((edge) => ({
          value: edge.node.site.id,
          label: edge.node.site.name,
        }));

        if (!allowedSites.length) {
          return null;
        } else if (allowedSites.length && params.siteId === "undefined") {
          const selectedSite = allowedSites[0];

          return { selectedSite, allowedSites };
        } else if (allowedSites.length && params.siteId) {
          const selectedSite = allowedSites.find(
            (site) => site.value === params.siteId
          );
          return { selectedSite, allowedSites };
        } else if (allowedSites.length > 0 && !params.siteId) {
          return { selectedSite: allowedSites[0], allowedSites };
        }
      }

      return null;
    }, [allowedSites.data, params.siteId]),
  };
};
