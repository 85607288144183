import { BudgetAggregateSlice, StoreCreator } from "@app/store/types";

export const createBudgetAggregateSlice: StoreCreator<BudgetAggregateSlice> = (
  set
) => ({
  budgetAggregateCache: {},
  setBudgetAggregateCache(budgetAggregateCache) {
    return set((prev) => ({
      ...prev,
      budgetAggregateCache,
    }));
  },
});
